import React, {CSSProperties, useRef, useState} from 'react';

import css from './Chunk.module.scss';
import {CaptionChunk} from '@autocut/types/Captions';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import {BsDot} from 'react-icons/bs';
import {InfoText} from '@autocut/components/InfoText/InfoText';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Emoji} from '../EmojiSelector/EmojiPicker/EmojiPicker';
import PopupPortal from '@autocut/designSystem/components/atoms/PopupPortal/PopupPortal';

const CAPTIONS_CONFIDENCE_THRESHOLD = 0.6;

export const Chunk = ({
  chunk,
  onSelect,
  onMerge,
  selected,
  isRtl,
  isLast,
}: {
  chunk: CaptionChunk;
  onSelect: () => Promise<void>;
  onMerge: () => void;
  selected: boolean;
  isRtl: boolean;
  isLast: boolean;
}) => {
  const iconRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  const classNames = [css.text];

  const isLowConfidence = chunk.highlight.some(
    highlight => highlight.confidence < CAPTIONS_CONFIDENCE_THRESHOLD,
  );

  if (isLowConfidence) classNames.push(css.lowConfidence);

  if (selected) classNames.push(css.selected);

  if (chunk.deleted) classNames.push(css.deleted);

  const textStyle: any = chunk.text.trim().length
    ? undefined
    : {
        border: '1px solid white',
        opacity: 0.3,
        borderRadius: '4px',
        minWidth: '20px',
        height: '100%',
      };

  const iconAnimationVariables = isRtl
    ? {
        '--margin-left': '-6px',
        '--margin-right': '0',
        'justify-content': 'flex-end',
      }
    : {
        '--margin-left': '0',
        '--margin-right': '-6px',
        'justify-content': 'flex-start',
      };

  return (
    <FlexContainer
      flexDirection={isRtl ? 'row-reverse' : 'row'}
      style={{
        textAlign: isRtl ? 'right' : 'left',
      }}
      alignItems="center"
      flexWrap="nowrap"
      gap={0}
      className={css.chunk}
    >
      <FlexContainer
        flexDirection={isRtl ? 'row-reverse' : 'row'}
        alignItems="center"
        flexWrap="nowrap"
        gap={4}
        onClick={onSelect}
        className={classNames.join(' ')}
        style={textStyle}
      >
        {chunk.text}
        {chunk.emoji && chunk.emojiUrl ? (
          <Emoji
            emoji={{
              name: chunk.emoji,
              url: chunk.emojiUrl,
            }}
            style={{
              width: '20px',
              height: '20px',
            }}
          />
        ) : null}

        {isLowConfidence && (
          <InfoText className={css.tooltip}>
            <TranslatedMessage
              id={'captions_editor_chunk_low_confidence_tooltip' as any}
              defaultMessage="Low confidence words"
            />
          </InfoText>
        )}
      </FlexContainer>

      {!isLast && (
        <div
          ref={iconRef}
          className={css.mergeIcon}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onMerge}
          style={iconAnimationVariables as CSSProperties}
        >
          <BsDot strokeWidth="4px" />

          {isHovered && (
            <PopupPortal
              target={iconRef}
              position="bottom"
              containerStyle={{
                cursor: 'pointer',
              }}
            >
              <InfoText
                style="info"
                hideHelper
              >
                <p style={{whiteSpace: 'nowrap'}}>
                  <TranslatedMessage
                    id={'captions_editor_chunk_merge_tooltip' as any}
                    defaultMessage="Merge chunks"
                  />
                </p>
              </InfoText>
            </PopupPortal>
          )}
        </div>
      )}
    </FlexContainer>
  );
};
