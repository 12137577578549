import css from './PreviewFrame.module.css';
import React, {useMemo, useEffect} from 'react';
import {Button} from '../Button/Button';
import {LoaderInfinity} from '../LoaderInfinity/LoaderInfinity';
import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import {useRelativeMousePosition} from './useMousePosition';
import {getPositionWithAnchors} from './usePreviewAnchors';
import {ZoomAnchor, ZoomAnchorProps} from '../ZoomAnchor/ZoomAnchor';
import {getCurrentFrameJPGBlobUrl} from '@autocut/utils/previewFrame';
import {daVinci} from '@autocut/utils/davinci';
import {getTimelineInfos} from '@autocut/utils/timeline';
import {ExampleCaption} from '@autocut/pages/modes/captions/customization/parts/CustomizationModal/Parts/ExampleCaption/ExampleCaption';

type InterfaceZoomAnchorProps = Omit<ZoomAnchorProps, 'sequenceDimension'>;

export type AnchorPreviewFrameInterface = {
  type: 'anchor';
  additionalProps: Omit<InterfaceZoomAnchorProps, 'x' | 'y'>;
};

export type CaptionPreviewFrameInterface = {
  type: 'captions';
};

export type PreviewFrameInterfaces =
  | AnchorPreviewFrameInterface
  | CaptionPreviewFrameInterface;

export type PreviewFrameProps = {
  interfaces?: PreviewFrameInterfaces[];
  initialPosition?: {x: number; y: number};
  anchors?: boolean | number | {x?: boolean | number; y?: boolean | number};
  anchorsDisplay?: boolean | {x?: boolean; y?: boolean};
  debug?: boolean;
  maxHeight?: `${number}px` | '100%';
  withPreviewButton?: boolean;
  onClick?: () => void;
};

const DEFAULT_ANCHOR_THRESHOLD = {x: 10, y: 10};

export const PreviewFrame = ({
  interfaces,
  initialPosition,
  anchors,
  anchorsDisplay,
  debug,
  maxHeight = '400px',
  withPreviewButton = true,
  onClick,
}: PreviewFrameProps) => {
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const lastTimelineNameRef = React.useRef('');
  const cursorBoundsRef = React.useRef([0, 0]);
  const [src, setSrc] = React.useState('');

  const [placeholderContainerStyle, setPlaceholderContainerStyle] =
    React.useState<React.CSSProperties>({});
  const [imageInterfaceStyle, setImageInterfaceStyle] = React.useState<{
    height: number;
    width: number;
  }>({
    height: 0,
    width: 0,
  });

  const imgContainerRef = React.useRef<HTMLDivElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);

  const threshold = !anchors
    ? {x: 0, y: 0}
    : anchors === true
      ? DEFAULT_ANCHOR_THRESHOLD
      : typeof anchors === 'number'
        ? {x: anchors, y: anchors}
        : {
            x:
              typeof anchors.x === 'number'
                ? anchors.x
                : DEFAULT_ANCHOR_THRESHOLD.x,
            y:
              typeof anchors.y === 'number'
                ? anchors.y
                : DEFAULT_ANCHOR_THRESHOLD.y,
          };

  const thresholdX = threshold.x;
  const thresholdY = threshold.y;

  const {
    x,
    xClicked,
    y,
    yClicked,
    boundingBox,
    mouseIn,
    isClicked,
    reset: resetMousePos,
  } = useRelativeMousePosition(imgRef, {
    clickRef: imgContainerRef,
    defaultValue: initialPosition,
  });

  const [anchoredX, showXAnchor, anchoredY, showYAnchor] = useMemo(() => {
    if (!anchors || !boundingBox) return [x, y];

    const xMiddle = boundingBox?.width / 2;
    const yMiddle = boundingBox?.height / 2;

    const newX = Math.abs(x - xMiddle) < thresholdX ? xMiddle : x;
    const newY = Math.abs(y - yMiddle) < thresholdY ? yMiddle : y;

    const showXAnchor =
      anchorsDisplay === undefined
        ? true
        : typeof anchorsDisplay === 'boolean'
          ? anchorsDisplay
          : (anchorsDisplay.x ?? true);
    const showYAnchor =
      anchorsDisplay === undefined
        ? true
        : typeof anchorsDisplay === 'boolean'
          ? anchorsDisplay
          : (anchorsDisplay.y ?? true);

    return [
      newX,
      newX === xMiddle && showXAnchor,
      newY,
      newY === yMiddle && showYAnchor,
    ];
  }, [anchors, boundingBox, x, y]);

  const {
    newPosition: [anchoredClickedX, anchoredClickedY],
  } = getPositionWithAnchors(
    [boundingBox?.width / 2, boundingBox?.height / 2],
    [xClicked, yClicked],
    [thresholdX, thresholdY],
  );

  const onImageLoaded = () => {
    checkIfRatioChanged();
    updatePlaceholderWidth();
    updateImageInterfaceDimension();
  };

  // Ratio of image can change if switching between 2 differents sequence
  const checkIfRatioChanged = () => {
    if (!imgRef.current) return;

    const lastBBox = imageInterfaceStyle;
    const lastRatio = lastBBox.width / lastBBox.height;
    const bbImg = imgRef.current.getBoundingClientRect();
    const currentRatio = bbImg.width / bbImg.height;

    if (lastRatio != currentRatio) resetMousePos();
  };

  const updateImageInterfaceDimension = () => {
    if (!imgRef.current) return;

    const bbImg = imgRef.current.getBoundingClientRect();
    setImageInterfaceStyle({
      height: bbImg.height,
      width: bbImg.width,
    });
  };

  const updatePlaceholderWidth = () => {
    const placeholderStyle = {
      background: '#ffffff20',
      borderRadius: 8,
    };

    if (!imgContainerRef.current) {
      setPlaceholderContainerStyle(placeholderStyle);
      return;
    }

    const bboxImgContainer = imgContainerRef.current.getBoundingClientRect();
    if (bboxImgContainer.height <= 0) {
      setPlaceholderContainerStyle(placeholderStyle);
      return;
    }

    setPlaceholderContainerStyle({
      ...placeholderStyle,
      height: bboxImgContainer.height,
    });
  };

  const updatePreview = async () => {
    const currentTimelineName = await daVinci.timeline.getName();

    if (currentTimelineName != lastTimelineNameRef.current) {
      lastTimelineNameRef.current = currentTimelineName;
      await computeCursorBounds();
    }

    const cursorTime = await daVinci.timeline.getCurrentTimecode();
    const isPlayerOutOfBounds = !(
      cursorTime >= cursorBoundsRef.current[0] &&
      cursorTime <= cursorBoundsRef.current[1]
    );
    if (isPlayerOutOfBounds) {
      await daVinci.timeline.setCurrentTimecode(cursorBoundsRef.current[0]);
      await new Promise(resolve => setTimeout(resolve, 1000)); // timeout otherwise a black image is exported
    }

    const filepath = await daVinci.timeline.exportFrame();

    if (src) {
      URL.revokeObjectURL(src);
    }
    const blobUrl = getCurrentFrameJPGBlobUrl(filepath);

    setSrc(blobUrl);
    setIsError(false);
    updatePlaceholderWidth();
  };

  const computeCursorBounds = async () => {
    const sequenceInfos = await getTimelineInfos();

    const minVideoStart = sequenceInfos.videoTracks.reduce(
      (res, track) => Math.min(res, track.start),
      Infinity,
    );
    const maxVideoEnd = sequenceInfos.videoTracks.reduce(
      (res, track) => Math.max(res, track.start + track.duration),
      Infinity,
    );
    cursorBoundsRef.current = [minVideoStart, maxVideoEnd];
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);

      const currentTimelineName = await daVinci.timeline.getName();
      lastTimelineNameRef.current = currentTimelineName;

      await computeCursorBounds();
      await updatePreview();

      setIsLoading(false);
    };

    try {
      void init();
    } catch (err) {
      console.error(err);
      setIsError(true);
      setIsLoading(false);
    }
  }, []);

  // Manage window resize
  useEffect(() => {
    window.addEventListener('resize', updateImageInterfaceDimension);

    return () => {
      window.removeEventListener('resize', updateImageInterfaceDimension);
    };
  }, []);

  return (
    <div className={css.previewContainer}>
      {isError ? (
        <div
          className={css.placeholderContainer}
          style={placeholderContainerStyle}
        >
          <TranslatedMessage
            id={'modes_zoom_parameters_preview_errorMessage'}
            defaultMessage={'An error occurred while loading the preview'}
          />
          <div
            className={css.center}
            style={{width: '50%'}}
            onClick={updatePreview}
          >
            <TranslatedMessage
              id="globals_misc_retry"
              defaultMessage="Retry"
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className={css.placeholderContainer}
            style={{
              ...placeholderContainerStyle,
              visibility: isLoading ? 'visible' : 'hidden', // Use of visibility to keep the img loaded and prevent flashing
            }}
          >
            <LoaderInfinity height={300} />
          </div>
          <div
            className={css.imgContainer}
            style={{
              visibility: isLoading ? 'hidden' : 'visible',
            }}
            ref={imgContainerRef}
          >
            <div
              className={css.interfacesContainer}
              style={imageInterfaceStyle}
              onClick={onClick}
            >
              {/* If we want to push this system further we can add a HOC that handle all the mouseEvent/coordinates computation
                  and nest all the interfaces.
                  For now each interfaces will have it's own logic.
              */}
              {!!imageInterfaceStyle.width &&
                !!imageInterfaceStyle.height &&
                interfaces &&
                interfaces.map((interfaceComponent, index) => {
                  switch (interfaceComponent.type) {
                    case 'anchor':
                      return (
                        <ZoomAnchor
                          key={`anchor${index}`}
                          {...interfaceComponent.additionalProps}
                          x={anchoredClickedX}
                          y={anchoredClickedY}
                        />
                      );
                    // TODO  : captions
                    case 'captions':
                      return (
                        <ExampleCaption
                          key={`captions${index}`}
                          boundingBox={imageInterfaceStyle}
                          debug={debug}
                        />
                      );
                  }
                })}
            </div>
            {anchors ? (
              <div
                className={css.anchorContainer}
                style={imageInterfaceStyle}
              >
                {showXAnchor && isClicked ? (
                  <div className={css.anchorVertical} />
                ) : null}
                {showYAnchor && isClicked ? (
                  <div className={css.anchorHorizontal} />
                ) : null}
              </div>
            ) : null}
            <img
              ref={imgRef}
              onLoad={onImageLoaded}
              src={src}
              style={{
                maxHeight,
                height: '100%',
              }}
            />
            {debug ? (
              <div className={css.debuger}>
                <span>
                  x:{x} ({anchoredX}) | y:{y} ({anchoredY})
                </span>
                <br />
                <span>
                  xClicked:{xClicked} ({anchoredClickedX}) | yClicked:
                  {yClicked} ({anchoredClickedY})
                </span>
                <br />
                <span>
                  Box: x: {boundingBox?.left} | y: {boundingBox?.top} (
                  {boundingBox?.width}x{boundingBox?.height})
                </span>
                <br />
                <span>{mouseIn ? 'Inside' : 'Outside'}</span>
              </div>
            ) : null}
          </div>
          {isLoading || isError
            ? null
            : withPreviewButton && (
                <Button
                  buttonType="tertiary"
                  compact
                  style={{
                    width: '100%',
                    height: '28px',
                    minWidth: '240px',
                    color: 'white',
                  }}
                  onClickFunction={async () => {
                    try {
                      await updatePreview();
                    } catch {
                      setIsError(true);
                      setIsLoading(false);
                    }
                  }}
                >
                  <TranslatedMessage
                    id="globals_defaults_components_PreviewFrame_previewOnIndicator"
                    defaultMessage="Preview on indicator"
                  />
                </Button>
              )}
        </>
      )}
    </div>
  );
};
