const translations = {
  progress: {
    footer: {
      warning:
        'DaVinciで何も行わないでください。問題を引き起こす可能性があります',
    },
    steps: {
      global: {
        starting: '開始中...',
        processing: '処理中...',
        ending: '終了中...',
      },
      dev: {
        ping: 'DaVinci Resolveにpingを送信中...',
        pong: 'DaVinci Resolveにpongを送信中...',
      },
      common: {
        getSilences: 'サイレンスを取得中...',
        getAudioInfos: 'オーディオ情報を取得中...',
        readAudioFile: 'オーディオファイルを読み込み中...',
        computeRMS: 'オーディオファイルを分析中...',
        convertRMSToDB: 'オーディオファイルを分析中...',
        exportDrt: 'タイムラインを読み込み中...',
        extractDrt: 'タイムラインを読み込み中...',
        getXmlTimelineFolderPath: 'タイムラインを読み込み中...',
        getXmlTimelineFullpath: 'タイムラインを読み込み中...',
        loadXmlTimeline: 'タイムラインを読み込み中...',
        writeFile: 'ファイルを書き込み中...',
        writeXml: '修正されたタイムラインをインポートしています...',
        updateDrt: '修正されたタイムラインをインポートしています...',
        importXml: '修正されたタイムラインをインポートしています...',
      },
      podcast: {
        mainProcess: 'ポッドキャスト編集中...',
        getVideoTracksWeight: 'スピーカーの録音',
        getAudioTracks: 'オーディオトラックの録音',
        getPodcastInterval: 'ポッドキャストの期間を計算中',
        getPodcastDuration: 'ポッドキャストの期間を計算中',
        renders:
          'Da Vinciによるオーディオトラックのレンダリング\n（数分かかる場合があります）',
        getTalkingTimelines: '発言時間の配分',
        getTransitions: 'カメラの変更を計算中',
        getCutTimecodes: 'カットの準備中',
        cutAtTimecodes: 'タイムラインのカット',
      },
      silences: {
        mainProcess: 'サイレンス編集中...',
        getTimelineInfos: 'タイムライン情報を取得中...',
        renders:
          'Da Vinciによるオーディオトラックのレンダリング\n（数分かかる場合があります）',
        convertAudioToMono: 'オーディオファイルを処理中...',
        getCutTimecodes: 'カットの準備中...',
        cutAtTimecodes: 'タイムラインのカット...',
        deleteSilentTrackItems: 'サイレンスを削除中...',
      },
      zoom: {
        mainProcess: 'ズームを追加中...',
        getTimelineInfos: 'タイムライン情報を取得中...',
        getWavData:
          'Da Vinciによるオーディオトラックのレンダリング\n（数分かかる場合があります）',
        computeBestZoomIntervals: '最適なズーム間隔を計算中...',
        createVideoTrack: 'ビデオトラックを作成中...',
        createZoomFusionComps: 'Fusionコンポジションをインポート中...',
      },
      captions: {
        transcript: {
          mainProcess: 'トランスクリプト生成中...',
          get_language: '言語を取得中',
          render_wav:
            'DaVinciでWAVをレンダリング中（数分かかる場合があります）',
          split_chunks: 'チャンクを分割中',
          uploadFile: 'ファイルをアップロード中...',
          getTranscript:
            'AIによるトランスクリプション生成\n（数分かかる場合があります）',
          writeFile: 'ファイルを書き込み中...',
        },
        generation: {
          mainProcess: 'キャプション追加中...',
          initialization: 'キャプションの初期化...',
          readTranscript: 'トランスクリプトを読み込み中...',
          getTimelineInfos: 'タイムライン情報を取得中...',
          appendAdjustmentLayer: '調整レイヤーを追加中...',
          importFusionComp: 'Fusionコンポジションをインポート中...',
        },
      },
    },
  },
  launchManager: {
    steps: {
      preload: {
        title: 'プリロード',
      },
      python: {
        title: 'Pythonをインストール',
      },
      ffmpeg: {
        title: 'FFMPEGをインストール',
      },
      resources: {
        title: 'リソースをインストール',
      },
      computeIPC: {
        title: 'AIに接続',
      },
      daVinciIPC: {
        title: 'DaVinci Resolveに接続',
      },
    },
    errors: {
      compute: '計算AIに接続できません。サポートに連絡してください。',
      davinci:
        'DaVinciに接続できません。DaVinci Resolveが実行中で、自動クラスタリングスクリプトが実行されたことを確認してください。',
      cta: '再試行',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: '試用版',
        upgrade: 'アップグレード',
        coming_soon: '近日公開',
        version_not_supported: 'PProバージョンはサポートされていません',
        upgrade_to: '{plan}にアップグレード',
      },
    },
  },
  modes: {
    podcast: {
      title: 'オートポッドキャスト',
      steps: {
        select: {
          title:
            '少なくとも2つのビデオおよび音声トラックを持つタイムラインを選択',
          label: 'タイムラインを選択',
          descriptions: {
            '1': '編集タブに移動',
            '2': '編集したいタイムラインに移動',
            cta: 'アクティブタイムラインを選択',
          },
          customization: {
            label: 'ポッドキャストのパラメータ',
            steps: {
              speakers: {
                title: 'スピーカー',
                description:
                  '各スピーカーに音声トラックを割り当て、彼らの名前を入力することでスピーカーを追加します。スピーカーごとに選択できる音声トラックは1つのみです。複数のスピーカーが1つの音声トラックを共有する場合は、両方の名前を入力してください（例：「アリス＆ボブ」）。',
                header: {
                  audio: '音声トラック',
                  name: 'スピーカーの名前',
                },
                textInputPlaceholder: 'スピーカーの名前を入力...',
                trackTemplate: 'トラックA{index}',
                cta: 'スピーカーを追加',
              },
              cameras: {
                title: 'カメラ',
                description:
                  '各カメラにビデオトラックを割り当て、画面上で表示されるスピーカーを選択することでカメラを追加します。カメラごとに選択できるビデオトラックは1つのみです。カメラごとに任意の数のスピーカーを選択できます。',
                header: {
                  audio: 'ビデオトラック',
                  name: 'スピーカー(s)',
                },
                speakerSelectPlaceholder: 'スピーカーを選択...',
                trackTemplate: 'トラックV{index}',
                cta: 'カメラを追加',
              },
              cameraTime: {
                title: 'カメラの表示時間',
                description:
                  'カメラが切り替わるまでの最小および最大表示時間を定義します。',
                presets: {
                  title: 'デフォルトプリセット',
                  items: {
                    calm: '落ち着いた',
                    paced: 'ペースがある',
                    energetic: 'エネルギッシュ',
                    hyperactive: 'ハイパーアクティブ',
                  },
                },
                minimumCamTime: {
                  title: '最小カメラ時間',
                  description:
                    'カメラが切り替わることなく表示される最小時間（秒）。',
                  unit: '秒',
                },
                maximumCamTime: {
                  title: '最大カメラ時間',
                  description:
                    'カメラが強制的に切り替わる前に表示される最大時間（秒）。',
                  unit: '秒',
                },
              },
              silentVideoClips: {
                title: 'サイレントビデオクリップ',
                description:
                  'ポッドキャストの未使用部分を削除するか無効にするかを選択してください。',
                items: {
                  remove: '削除',
                  disable: '無効',
                },
              },
            },
            cta: {
              disable: '未使用クリップを編集して無効にする',
              delete: '未使用クリップを編集して削除',
            },
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: '最小カメラ時間は少なくとも0でなければなりません',
            higherThanMax:
              '最小カメラ時間は最大カメラ時間より小さくなければなりません',
          },
          maximumCamTime: {
            min: '最大カメラ時間は少なくとも0でなければなりません',
          },
          deleteUnusedClips: {
            required:
              '未使用クリップを削除するか無効にすることを選択してください',
          },
          speakers: {
            videoTracks: {
              min: '各スピーカーごとに少なくとも1つのビデオトラックを選択する必要があります',
            },
            audioTrack: {
              min: '各スピーカーごとにオーディオトラックを選択する必要があります',
            },
          },
        },
      },
      usageStats: {
        title: '使用統計',
        select: {
          label: 'モードを選択',
        },
        stats: {
          thisMonth: '今月',
          allTime: '全期間',
          mostUsed: '最も使用された',
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'ノイズレベル',
          description:
            '沈黙として考慮される音のレベル。以下の視覚化でその効果を見ることができます。',
        },
        silenceSize: {
          title: '静寂の持続時間',
          items: {
            silence: {
              title: 'より長い静寂を削除する',
              description:
                '静寂が削除されるために必要な最小の持続時間（ミリ秒単位）。',
              unit: 'ミリ秒',
            },
            talk: {
              title: 'より短い会話を削除する',
              description:
                '会話セグメントが保持されるために必要な最小の持続時間（ミリ秒単位）。',
              unit: 'ミリ秒',
            },
          },
        },
        paddingSize: {
          title: 'パディング',
          items: {
            before: {
              title: '前のパディング',
              description:
                'ノイズ前の静かな時間 - 突然の話しの再開を避けるため',
              unit: 'ミリ秒',
            },
            after: {
              title: '後のパディング',
              description:
                'ノイズ後の静かな時間 - 話しの厳しい中断を避けるため',
              unit: 'ミリ秒',
            },
          },
        },
        transition: {
          params: {
            title: 'トランジション',
            warning: '静音を削除しないとトランジションを使用できません。',
            default: {
              title: 'デフォルト',
              choices: {
                none: 'なし',
              },
            },
            j_l_cut: {
              title: 'J-Cut＆L-Cut',
              helper:
                'これは各カットの後または前にパディングを追加します。カット間の滑らかなトランジションを作成します。選択されると、トランジション用の新しいトラックが作成されます。',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: '両方',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: '定常パワー',
              },
            },
          },
          progress: {
            insert: '新しいオーディオトラックを挿入中…',
            move: 'トランジションのために新しいトラックにオーディオを移動中…',
            handling: 'トランジションを追加中：{transition}',
          },
        },
        preview: {
          title: 'プレビュー',
          description:
            '保存および削除されたセグメントのプレビュー。矢印を使用して前後に移動するか、時間カーソルの位置に基づいてプレビューを中心に配置します。',
          legend: {
            kept: '保持',
            deleted: '削除',
            margin: 'マージン',
            cta: 'プレビューを生成',
            center: 'インジケータを中央に配置してください',
          },
          cta: 'プレビューを生成',
          center: 'インジケータを中央に配置',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'ノイズレベルは負の数でなければなりません',
            int: 'ノイズレベルは数値でなければなりません',
          },
          minTimeOfSilence: {
            required: '最小静音時間が必要です',
            min: '最小静音時間は少なくとも0でなければなりません',
          },
          minTimeOfTalk: {
            required: '最小発話時間が必要です',
            min: '最小発話時間は少なくとも0でなければなりません',
          },
          marginBefore: {
            min: '前の余白は少なくとも0でなければなりません',
          },
          marginAfter: {
            min: '後の余白は少なくとも0でなければなりません',
          },
        },
      },
    },
    zoom: {
      parameters: {
        preview: {
          title: 'ズームプレビュー',
          description: 'アンカーポイントを定義',
          maximumZoom: '最大ズーム',
          errorMessage: 'プレビューの読み込み中にエラーが発生しました',
        },
        zoomTypes: 'ズームタイプ',
        defaultsPresets: {
          title: 'デフォルトのプリセット',
          calm: '穏やか',
          paced: 'ペース',
          energetic: 'エネルギッシュ',
          hyperactive: '過活動',
        },
        zoomCoverage: '総ズーム範囲',
        constraint: {
          title: 'クリップにズームを制約',
          helper:
            'はいの場合、ズームは1つのクリップを超えません。いいえの場合、選択全体が1つのオーディオファイルとして処理され、ズームはタイムラインの個々のクリップを無視します。',
        },
        maximumZoomDuration: '最大ズーム持続時間',
        smoothZooms: {
          title: 'スムーズズーム',
          coverage: 'スムーズズームの範囲',
          nervousness: '神経質',
        },
        dynamicZooms: {
          title: 'ダイナミックズーム',
          coverage: 'ダイナミックズームの範囲',
        },
      },
      types: {
        smooth: 'スムーズ',
        dynamic: 'ダイナミック',
      },
      footerButton: 'ズーム',
      infoText:
        'AutoZoomは、デシベルに基づいて選択した各クリップに自動的にズームを追加します。',
    },
    captions: {
      steps: {
        language: {
          button: '字幕エディタに移動',
        },
        customization: {
          button: '字幕を追加',
          parts: {
            style: {
              title: '字幕のスタイル',
              modal: {
                title: 'スタイルを編集',
                apply: 'スタイルを適用',
                save: 'スタイルを適用してプリセットとして保存',
                text: {
                  title: 'フォント',
                  font: 'フォント名',
                  help: '新しいフォントファミリーを追加する方法は？',
                  color: '色',
                },
                animation: {
                  title: 'アニメーション',
                  wordBox: 'テキスト入りボックス',
                },
                outline: {
                  title: 'アウトライン',
                  width: '太さ',
                },
                box: {
                  title: 'テキストボックス',
                  paddingX: 'パディング X',
                  paddingY: 'パディング Y',
                  opacity: '不透明度',
                  radius: '半径',
                  disabled:
                    '選択した言語に対して選んだフォントはボックス機能をサポートしていません。',
                  tips: 'この機能を使用するには、選択した言語をサポートするフォントを選択してください。',
                },
              },
            },
            preview: {
              title: 'プレビュー',
              modal: {
                title: '位置とサイズ',
                apply: 'スタイルを適用',
                fontSize: 'フォントサイズ',
                nbLines: '行数',
                maxWidth: '最大幅',
                help: 'プレビューはまだ開発中で、正確でない可能性があります。',
              },
            },
            transcription: {
              title: '転写を編集',
              modal: {
                save: '保存',
                select: '編集したい字幕を選択',
                placeholder:
                  '編集/カスタマイズしたいトランスクリプトの部分を選択',
                formatNumbers: '数字をフォーマット',
                checkTranscript: {
                  title: 'トランスクリプトを確認しましたか？',
                  description: 'まだトランスクリプトを確認していないようです。',
                  dontShowAgain: 'このメッセージを再度表示しない',
                },
              },
            },
            presets: {
              delete: {
                title: 'プリセットを削除',
                cancel: 'キャンセル',
                confirm: 'プリセットを削除',
              },
              search: {
                title: 'コミュニティプリセット',
                input: 'プリセットを検索',
                cancel: 'キャンセル',
                confirm: 'プリセットをインポート',
                usage: '{count} ユーザーが使用中',
                error:
                  'プリセットのコピー中にエラーが発生しました。discordまたはcontact@autocut.frにご連絡ください。',
              },
              create: {
                title: '新しいプリセットを作成',
                placeholder: 'プリセット名（最低2語）',
                public: 'コミュニティと共有 - 公開',
                cancel: 'キャンセル',
                confirm: 'プリセットを作成',
                alreadyExists:
                  'このプリセットはすでに存在します。別の名前を選んでください。',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'ユーザープリセットが見つかりません。ヘッダーメニューで設定をリセットしてください。',
          },
          position: {
            xPercentage: {
              min: 'テキストのx位置は少なくとも0でなければなりません',
              max: 'テキストのx位置は最大で1でなければなりません',
            },
            yPercentage: {
              min: 'テキストのy位置は少なくとも0でなければなりません',
              max: 'テキストのy位置は最大で1でなければなりません',
            },
            emojiXPercentage: {
              min: '絵文字のx位置は少なくとも0でなければなりません',
              max: '絵文字のx位置は最大で1でなければなりません',
            },
            emojiYPercentage: {
              min: '絵文字のy位置は少なくとも0でなければなりません',
              max: '絵文字のy位置は最大で1でなければなりません',
            },
          },
          text: {
            fontSize: {
              int: 'フォントサイズは数値でなければなりません',
              min: 'フォントサイズは少なくとも1でなければなりません',
            },
            font: {
              missing:
                '選択したフォントに何かが足りません... もう一度選択するか、別のものを選んでください。',
            },
          },
          outline: {
            width: {
              int: 'アウトラインの幅は数値でなければなりません',
              min: 'アウトラインの幅は少なくとも0でなければなりません',
            },
          },
          formating: {
            nbLines: {
              int: '行数は数値でなければなりません',
              min: '行数は少なくとも1でなければなりません',
            },
            maxWidth: {
              int: '最大幅は数値でなければなりません',
              min: '最大幅は少なくとも0でなければなりません',
            },
          },
          textBox: {
            xPadding: {
              int: 'xパディングは数値でなければなりません',
              min: 'xパディングは少なくとも0でなければなりません',
            },
            yPadding: {
              int: 'yパディングは数値でなければなりません',
              min: 'yパディングは少なくとも0でなければなりません',
            },
            opacity: {
              int: '不透明度は数値でなければなりません',
              min: '不透明度は少なくとも0でなければなりません',
              max: '不透明度は最大で100でなければなりません',
            },
            radius: {
              int: '半径は数値でなければなりません',
              min: '半径は少なくとも0でなければなりません',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: '入出力ポイントを設定',
          descriptions: {
            '1': 'カットしたいタイムラインの部分でDavinciの入出力ポイントを定義してください。',
            '2': 'オーディオ分析に含まれないように、クリップやトラックを手動でミュートできます。',
          },
          cta: '入出力ポイントを確認',
        },
        transcription: {
          title: 'オーディオクリップの言語を選択',
        },
        customization: {
          title: 'パラメータのカスタマイズ',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'AutoCutを活性化するためにライセンスキーを入力してください。',
      placeholder: 'ライセンスキーを入力',
    },
    trial: {
      title: '無料トライアルをゲット',
      steps: {
        email: {
          title: 'メールアドレスを入力してください',
          subtitle:
            '無料トライアルライセンスを受け取るためにメールアドレスを入力してください',
          placeholder: 'email@email.com',
          cta: '次へ',
        },
        sendingEmail: {
          title: 'メールを送信中',
          success: '{email}へのメールが正常に送信されました',
        },
      },
    },
    errors: {
      no_key: 'ライセンスキーを入力してください。',
      is_key_valid_error:
        'キーを確認中にエラーが発生しました。後でもう一度お試しください。\n',
      invalid_response: '応答がサーバーから来ていません。',
      cant_read_host_error: 'コンピュータの名前を読み取れません。',
      cant_read_uuid_error: 'コンピュータのUUIDを読み取れません。',
    },
  },
  modals: {
    endProcess: {
      title: 'プロセス完了',
      time: '{minutes}分{seconds}秒でプロセスが完了しました！',
      timeSaved:
        '節約できた時間: <b>{minutesSaved}</b> 分と <b>{secondsSaved}</b> 秒',
      timeSavedAllTime:
        'AutoCutを使用してから節約した時間: {hourSaved} 時間と {minutesSaved} 分！ 🎉',
      feedback: '結果の品質に関するフィードバックをお寄せください：',
      review: 'AutoCutを評価！',
      captions: {
        title: 'キャプションが追加されました！',
        footer:
          '字幕のスタイルやトランスクリプションが適していない場合は、字幕エディタに戻ることができます。',
        button: 'トランスクリプトの編集に戻る',
        text_1: 'アニメーション付きキャプションが追加されました！ 🎉',
        text_2: 'キャプションを修正する必要がある場合：',
        list_1: '更新したいキャプションにタイムラインカーソルを置く',
        list_2: 'フュージョンページに移動',
        list_3: 'TextPlusノードをクリックし、キャプションを手動で更新する',
        text_3:
          'より多くのヒントが必要な場合は、この<a>チュートリアル</a>をご覧ください。',
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'サポートされていないDaVinci Resolveバージョン',
      currentVersion:
        '使用中のDaVinci Resolveバージョンが{version}であることを検出しました',
      text: 'AutoCutはDaVinci Resolve 18.6以降のバージョンとしか互換性がありません。',
      update: 'こちらで更新できます: <a>DaVinciのウェブサイト</a>',
    },
  },
  globals: {
    defaults: {
      components: {
        KebabMenu: {
          account: 'ライセンスキーを管理する',
          bug: 'バグレポート',
          review: 'AutoCutレビュー',
          tuto: 'ビデオチュートリアル',
          logout: 'ログアウト',
          settings: '設定をリセットする',
          featureRequest: '機能リクエスト',
          academy: 'ヘルプ',
        },
        LicenseInfos: {
          timeLeft: '残り{daysLeft}日',
          info: '{licenceType}ライセンス',
          trialButton: 'もっと見る',
          paidButton: '管理',
        },
        PromoBanner: {
          promo: '次の24時間で最大20％OFF',
          shop: '今すぐ購入',
        },
        FileInput: {
          upload_screenshot: 'スクリーンショットをアップロード',
        },
        LinkToUserFileInput: {
          send_log_link_to_user_file: {
            title:
              'ビデオおよびオーディオファイルへのリンクを提供してください。',
            description:
              '(WeTransfer、Google Drive、または他のファイル共有サービス)',
            helper:
              'WeTransferにファイルをアップロードするには、wetransfer.comに行き、「ファイルをアップロード」をクリックします。 その後、リンクが取得されるので、ここにコピーしてください。',
            end: 'これにより問題を迅速に解決できます。',
          },
        },
        Select: {
          'no-more': '利用可能な選択肢はありません',
          'select-all': 'すべて選択',
          placeholder: 'オプションを選択',
        },
        PreviewFrame: {
          previewOnIndicator: 'インジケーターでプレビュー',
        },
      },
    },
    misc: {
      retry: '再試行',
    },
    units: {
      second: '秒',
      millisecond: 'ミリ秒',
      percentage: '%',
      px: 'px',
    },
  },
  old: {
    text: {
      errorSelection:
        '選択が無効です。{br}音声または動画ファイルから直接トラックを選択してください。{br}シーケンスが選択されている場合は、シーケンス内のビデオを分割してください。',
      loading: 'カット中...',
      transition: {
        step: {
          title: 'トランジション',
          j_l_cut: 'Jカット＆Lカット',
          default: 'デフォルト',
          premiere: {
            pro: 'DaVinci Resolve',
          },
          none: 'なし',
          j_cut: 'Jカット',
          l_cut: 'Lカット',
          both: '両方',
        },
        constant: {
          power: 'コンスタントパワー',
        },
        progress: {
          insert: '新しいオーディオトラックを挿入中...',
          move: 'トランジション用にオーディオを新しいトラックへ移動中…',
          handling: 'トランジションを追加中：{transition}',
        },
      },
      Step4: {
        AutoZoom: {
          ratio: '最大ズーム係数',
          step: 'ズームされたビデオの割合',
        },
        Title: 'AutoZoom（ベータ）',
      },
      Step5: {
        Backup: 'バックアップを有効にする',
        Title: 'プレビュー',
      },
      autocutv2:
        'AutoCut v2は、最適な無音検出のために多くの設定オプションを備えたエキスパートバージョンです。',
      autocutAi:
        'AutoCut AIは、AIに残りを任せることで、ただ1つのパラメーターで自動的に無音を検出します。',
      autocutPodcast:
        'AutoCut Podcastを使用すると、カメラ設定を提供するだけで、数秒でポッドキャスト動画を編集できます。カットされた最新のビデオを楽しみましょう。',
      autocutRepeat:
        'リラックスして、AutoCut Repeatにビデオの悪いテイクをすべて削除させ、最高のものだけを残してもらいましょう。',
      autocutCaptions:
        '驚くほど速くあなたのビデオにTikTokスタイルのキャプションを生成します。',
      Step3: {
        Remove: '削除',
        Keep: '保持',
        CenterButton: 'インジケータにセンターする',
        Previsualisation: {
          __text__: 'プレビュー：',
          loading: '読み込み中...',
        },
        Noise: {
          unit: 'デシベル',
        },
        DeleteSilence: '無音を削除',
        KeepSilence: {
          Yes: 'はい',
          No: 'いいえ',
        },
        Title: 'ノイズレベルを調整する',
      },
      Step2: {
        After: '後のパディング',
        Before: '前のパディング',
        Title: 'パディング',
      },
      Step2Ai: {
        Title: 'プレビューを確認する',
      },
      Step1: {
        Talks: '話し手が短いトークを削除する',
        Silences: '長い無音を削除する',
        Title: '無音の持続時間',
      },
      Step1Ai: {
        Silences: 'AIの攻撃性を選択する',
        Title: 'AIの攻撃性',
      },
      Step1Podcast: {
        Title: '話し手',
      },
      step6: {
        selected: {
          clips: '選択されたクリップ',
        },
      },
      NoInternet: 'インターネットへの接続が必要です。',
      noClip: {
        title: 'タイムラインでカットするクリップを選択してください',
        no_selection:
          'AutoCutが反応しない場合は、DaVinci Resolveを再起動してみてください。',
        tuto: {
          __text__: 'ヘルプが必要ですか？私たちの',
          link: ' ビデオチュートリアルをご覧ください',
        },
        loading: {
          bar: '読み込み中 - シーケンス分析進行中...',
        },
      },
      needPProUpdate:
        'DaVinci Resolveバージョンは15以降のものである必要があります。',
      No_Key: 'ライセンスキーを入力してください',
      Login: {
        SubmitButton: 'アクティベート',
        SubmitButton2: 'アクティベート',
        PasteButton: '貼り付け',
      },
      Welcome: {
        Title: 'ようこそ',
        freeTrial: '無料トライアルを取得',
        UseLicence: 'ライセンスキーを使用する',
        BuyLicence: 'ライセンスキーを購入する',
      },
      UseLicence: {
        Title: 'ライセンスキーを使用する',
        Text: 'ライセンスキーを入力してAutoCutを使い始めましょう！',
      },
      GetLicence: {
        Title: 'メールで無料トライアルライセンスを受け取る',
        Select: 'Autocutをどこで発見しましたか？',
        Text: 'メールアドレスを入力して、無料の試用ライセンスを受け取ってください',
        Button: '無料ライセンスを取得する',
      },
      UseNewLicence: {
        Title: '{s}宛にライセンスキーが送信されました！',
        Text: 'メールを確認し、ライセンスキーを入力してAutoCutを使用し始めましょう！数分以内にキーが届かない場合は、迷惑メールフォルダを確認してください。',
        Resend: 'メールが届きませんでしたか？',
        ResendButton: '{mail}宛にキーを再送する',
        ResendSucces: 'メールが送信されました！',
      },
      NoFFMPEG: 'FFMPEGに問題が発生しましたので、サポートにご連絡ください',
      legend: {
        kept: '保持',
        deleted: '削除',
      },
      yes: 'はい',
      no: 'いいえ',
      undo: {
        modal: 'カットに満足していませんか？',
      },
      since: {
        your: {
          last: {
            visit: '前回の訪問以来',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted:
              '申し訳ありませんが、ファイル {filename} はサポートされていない形式のようです...',
            speed:
              '申し訳ありませんが、AutoCutは変更されたスピードのクリップのカットはサポートしていません...',
            multi: {
              audio:
                '申し訳ありませんが、AutoCutはファイル内のすべてのオーディオトラックが選択されていない場合、正しく機能しない場合があります。',
              stream:
                '申し訳ありませんが、選択したファイルの1つに複数のオーディオストリームがあるようです...',
            },
            '51_stereo':
              '申し訳ありませんが、選択したファイルの1つが5.1のオーディオレイアウトでDaVinci Resolveによってステレオで表示されているようです...',
            audio: {
              layout:
                '申し訳ありませんが、選択したファイルの1つにサポートされていないオーディオレイアウトがあるようです...',
            },
            repeat: {
              multi: {
                audio:
                  '申し訳ありませんが、AutoCut Repeatは現在、複数のオーディオクリップをサポートしていません。',
              },
              no_audio:
                'AutoCut Repeatを使用するためには、オーディオクリップを選択する必要があります。',
            },
            remote: {
              file: '申し訳ありませんが、ファイル{filename}にアクセスできなかったようです...',
            },
          },
        },
        maintained: {
          modal:
            'AutoCutを使用するには、DaVinci Resolveを新しいバージョンにアップグレードしてください。',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: '異なるクリップを選択してください。',
              speed:
                'クリップのスピードをリセット（100%に）するか、別のクリップを選択してください。',
              multi: {
                audio:
                  'すべてのオーディオチャンネルが選択されており、デフォルトのDaVinci Resolveのオーダーのままであることを確認してください。',
                stream: '異なるクリップを選択してください。',
              },
              '51_stereo':
                '異なるクリップを選択するか、シーケンスのチャンネルレイアウトを変更してください。',
              audio: {
                layout:
                  '別のクリップを選択してください。問題が続く場合は、シーケンスのスクリーンショットをお送りください。',
              },
              repeat: {
                multi: {
                  audio:
                    'カットするために1つのオーディオクリップのみを選択してください。',
                },
              },
              remote: {
                file: 'ファイルがコンピュータ上で利用可能であり、クラウドにないことを確認してください。',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'ライセンスキーを入力してAutoCutを使い始めましょう！',
        },
      },
      no_audios:
        'このオーディオは無音を検出し、カットして削除するために使用されます。',
      step1: {
        podcast: {
          title: 'カメラの時間持続',
          minimum: 'た最小カメラ時間',
          maximum: '最大カメラ時間',
        },
      },
      silences: {
        step: {
          delete: {
            __text__: '{mode}を削除',
            keep: {
              spaces: '{mode}を削除してスペースを保持',
            },
          },
          keep: '{mode}を保持',
          mute: '{mode}をミュート',
        },
      },
      repetitions: '繰り返し',
      podcast: {
        add: {
          speaker: '話者を追加',
          camera: 'カメラを追加',
        },
        track: 'トラック',
        camera: {
          title: 'カメラ',
          helper:
            'カメラを追加して、それぞれのカメラで見える話者を指定してください。カメラごとに任意の数の話者を選択できます。',
        },
        speaker: '話者',
        all: {
          speakers: '全スピーカー',
        },
      },
    },
    help: {
      bug: 'バグレポート',
      review: 'AutoCutレビュー',
      multipiste:
        'マルチトラックについてのヘルプが必要ですか？ビデオチュートリアルをご覧ください',
      tuto: 'ビデオチュートリアル',
      academy: 'ヘルプ',
      buy: 'ライセンスキーを購入する',
      account: 'ライセンスキーを管理する',
      logout: 'ログアウト',
      reset: {
        settings: '設定をリセットする',
      },
      transition: {
        step: 'カットの前後にパディングを追加します。カット間の移行をよりスムーズにするためです。選択すると、移行用の新しいトラックが作成されます。',
      },
      transcription: {
        provider: {
          step: '選択したモデルによって、転写の品質と期間が異なる場合があります。',
        },
      },
    },
    bugBtnDialog: '電子メールを送信する: contact@autocut.fr',
    ButtonCutText: 'カット',
    ButtonCutAndDeleteText: 'カットして削除',
    OptionCutText: 'カットして無音を保つ',
    OptionCutAndDeleteText: 'カットして無音を削除',
    first: {
      launch: {
        error:
          'AutoCutの起動中にエラーが発生しました。プラグインを再起動してください。',
      },
    },
    helper: {
      Step4: {
        '1': 'AutoZoomオプションを有効にすると、カット後にクリップがズームされます。',
        '2': 'ズーム係数は、以下に提供された値とサブクリップの最大音量に基づいて計算されます。',
        '3': 'ズームされたビデオの割合を選択してください。',
      },
      Step5:
        'バックアップにより、カット後のすべての変更を元に戻すことができます。',
      Step1: {
        '1': '無音が削除されるために持たなければならない最小持続時間（秒単位）。',
        '2': '保持されるべきトークセグメントの最小持続時間（秒単位）。',
        podcast: {
          minium: '最小持続時間（秒）はカメラが変わらずに表示される期間です。',
          maximum: '最大持続時間（秒）はカメラが変わらずに表示される期間です。',
        },
      },
      Step1Ai:
        '攻撃性は、最小1から最大4まで、AIがいくら無音を削除するかを決定します。',
      Step2: {
        '1': 'トークセグメントの前の無音期間。',
        '2': 'トークセグメントの後の無音期間。',
      },
      Step3: {
        '1': '無音と見なされる音量レベル。下のビジュアライゼーションで効果を確認できます。',
        '2': '保持されたセグメントと削除されたセグメントのプレビュー。前後に移動するには矢印を使用するか、時間カーソルの位置に基づいてプレビューを中央に合わせてください。',
        '3': '無音を削除しないことを選択した場合、クリップはカットされますが、無音は削除されません。',
      },
      Save: {
        Default:
          '設定を保存する。現在の設定を保存すると、次回起動時に読み込まれます。',
      },
      Undo: {
        Cut: '最後のカットを元に戻す。最後のカットの前の状態にクリップを復元します。',
      },
      speakers: {
        podcast: {
          video: {
            tracks:
              '話し手が表示されるビデオトラックを選択してください。いくつかのトラックを選択できます。',
          },
          audio: {
            tracks:
              '話者が聞こえるオーディオトラックを選択してください。話者ごとに1つのトラックだけを選べます。',
          },
        },
      },
    },
    warn: {
      Step5:
        '警告：バックアップを無効にすると、カットを元に戻すことができなくなります。',
    },
    title: {
      Step5: {
        Backup: 'バックアップを有効にする',
      },
      undo: {
        modal: '完了しました！',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'クリップの1つがサポートされていない形式です',
            speed: 'AutoCutはデフォルトスピードのクリップのみサポートします',
            multi: {
              audio:
                'AutoCutは複数のオーディオトラックをうまく処理できない可能性があります',
              stream: 'クリップの1つに複数のオーディオストリームがあります',
            },
            '51_stereo':
              'クリップの1つが5.1のオーディオレイアウトでステレオ表示されています',
            audio: {
              layout:
                'クリップの1つにサポートされていないオーディオレイアウトがあります',
            },
            repeat: {
              multi: {
                audio: '選択に複数のオーディオクリップが含まれています',
              },
              no_audio: '選択にオーディオクリップが含まれていません',
            },
            remote: {
              file: 'クリップの1つがコンピュータにありません',
            },
          },
        },
        maintained: {
          modal:
            'DaVinci Resolveの2021年版より古いバージョンではAutoCutは維持されていません。',
        },
      },
      use: {
        trial: {
          licence: 'メールをチェックしてライセンスキーを使用してください',
        },
        paid: {
          licence: 'ライセンスキーを使用する',
        },
      },
      no_audios: '選択にオーディオがありません',
      banner: {
        podcast: {
          beta: '新しいポッドキャストモードをお試しいただきありがとうございます。',
        },
      },
      silences: {
        step: '{mode}管理',
      },
      podcast: {
        silences: {
          step: '無音を削除するか、ミュートする',
        },
      },
    },
    secondUnit: '秒',
    millisecondUnit: 'ミリ秒',
    percentageUnit: '%',
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: '無音部分をJ-Cutして削除',
          l_cut: '無音部分をL-Cutして削除',
          both: '無音部分をJ&L-Cutして削除',
          constant: {
            power: 'カット、無音を削除し、オーディオトランジションを適用',
          },
          none: '{mode}を切り取って削除',
          keep: {
            spaces: {
              none: '{mode}を切ってスペースを保持する',
            },
          },
        },
        keep: {
          none: '{mode}を切り取って保持',
        },
        mute: {
          none: '{mode}を切り取ってミュート',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'トラックを取得中...',
        silences: '無音を取得中...',
      },
      edit: {
        track: 'トラックの編集中 {indexTrackInfo}: {current} / {max}',
      },
      final: {
        message: {
          __text__: '成功！あなたのポッドキャストが編集されました',
          error: 'ポッドキャストの編集中にエラーが発生しました',
        },
      },
      button: {
        disable: '編集して使用されていないクリップを無効にする',
        delete: '編集して使用されていないクリップを削除する',
      },
      not: {
        available: {
          title:
            '少なくとも2つのビデオおよびオーディオトラックがあるシーケンスを選択してください',
        },
      },
      minimum: {
        higher: {
          maximum: 'カメラの最小時間は最大時間よりも低い必要があります。',
        },
      },
      empty: {
        audio: {
          track: 'オーディオトラックは空にできません。',
        },
        video: {
          track: 'ビデオトラックは空にすることはできません。',
        },
      },
      preset: {
        title: 'デフォルトのプリセット',
        calm: '落ち着いた',
        paced: 'ペースド',
        energetic: 'エネルギッシュ',
        hyperactive: 'ハイパーアクティブ',
      },
    },
    error: {
      UseLicence: {
        __text__: '他のコンピュータからキーをリンク解除するには、',
        link: 'AutoCutクライアントエリアをご利用ください。',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'サブシークエンスに到達できません',
          unknown: '不明なエラーが発生しました',
          noSpace: 'エラーが発生しました：空き容量が不足しています',
          exportUnknown:
            'DaVinci Resolveがエクスポート中にエラーを発見しました',
          invalidInputData: '一時ファイルの生成中にエラーが発生しました',
          fileNotFound: 'ファイルの読み取り中にエラーが発生しました',
          scriptNotLoaded: 'AutoCutの読み込み中にエラーが発生しました',
          ffmpegError: 'ファイルの読み取りに失敗しました。',
          exportCancelled: 'エクスポートがキャンセルされました',
          timelineMuted: '聞こえるトラックがありません',
          noAudibleAudio: '聞こえるオーディオなし',
          offline: 'オフラインのようです',
          resourceTimeout: 'リソースを取得できません',
          backupNotFound: 'バックアップが見つかりません',
          setCursorOutInterval: 'イン/アウト ポイントから外れています',
          emptyPath: '選択解析中にエラーが発生しました',
          sequenceMismatch: '操作を実行できません',
        },
        text: {
          subsequenceNotReachable:
            '申し訳ありませんが、選択されたサブシークエンスの一つに到達できませんでした。',
          unknown:
            'AutoCutの使用中にエラーが発生しましたが、それを特定することはできませんでした。',
          noSpace: 'デバイスに十分な空き容量がないようです。',
          exportUnknown:
            'このエラーは、ディスクのストレージスペースが不足していることが最も可能性が高いです。',
          invalidInputData:
            '申し訳ありませんが、一時ファイルの生成中にエラーが発生したようです。',
          fileNotFound:
            'ファイル{filename}がまだ場所{filepath}で利用可能であることを確認してください',
          scriptNotLoaded:
            '他のすべての拡張機能とDaVinci Resolveを閉じてから再試行してください。',
          ffmpegError:
            'ファイル {filepath} が破損していないか確認してください。',
          exportCancelled:
            'エンコーディングを停止するとAutoCutはオーディオを分析できません。もう一度お試しください。',
          timelineMuted:
            '使用中のすべてのオーディオトラックがミュートされています。',
          noAudibleAudio: '聞こえるオーディオが検出されませんでした。',
          offline: 'サーバーにアクセスできません。',
          resourceTimeout:
            'このプロセスに必要なオンラインリソースを30秒以内にダウンロードできませんでした',
          backupNotFound:
            'バックアップシーケンスが見つからないため、タイムラインを復元することができません',
          setCursorOutInterval:
            'イン/アウト ポイントに含まれていないチャンクを選択しました。',
          emptyPath:
            '申し訳ありませんが、選択したクリップの一つにコンピュータ上のパスが存在しないようです。',
          sequenceMismatch:
            'AutoCutが処理している間にシーケンスが変更されました。シーケンスを変更せずに再試行してください。',
        },
        tips: {
          subsequenceNotReachable:
            'タイムライン上の各サブシークエンスがプロジェクトパネルの元の対応するシークエンスと同じ名前を共有していることを確認してください。',
          unknown:
            '選択または設定を変更してみてください。問題が解決しない場合は、私たちに連絡してください。',
          noSpace:
            'AutoCutを再起動する前に、いくつかのファイルを削除してみてください。',
          exportUnknown:
            'AutoCutを再起動する前に、いくつかのファイルを削除してみてください。',
          invalidInputData:
            '同じ選択で再試行してください。問題が解決しない場合は、ファイルを添えて私たちに連絡してください。',
          fileNotFound:
            '同じ選択で再試行してください。問題が解決しない場合は、ファイルを添えて私たちに連絡してください',
          scriptNotLoaded: '問題が解決しない場合は、私たちに連絡してください。',
          ffmpegError: '問題が解決しない場合は、お問い合わせください。',
          exportCancelled: ' ',
          timelineMuted: '少なくとも1つのミュートを解除してください。',
          noAudibleAudio:
            '少なくとも1つのクリップがアクティブで聞こえることを確認してください。',
          offline: 'インターネット接続を確認して、もう一度お試しください。',
          resourceTimeout:
            'インターネット接続を確認して、もう一度お試しください。',
          backupNotFound:
            'プロジェクトのルートにある「AutoCut-Backup」ビンを確認して、バックアップがまだそこにあるかどうかを確認してください。',
          setCursorOutInterval:
            'この部分のトランスクリプトを聞くには、イン/アウト ポイントを削除する必要があります。',
          emptyPath:
            '全てのクリップがアトミックメディアまたはサブシーケンスからのものであることを確認してください。',
          sequenceMismatch:
            'AutoCutを使用する場合は、処理が完了するまでシーケンスを変更したり、現在のシーケンス内で何かを変更したりしないでください。',
          noSoundDetected:
            'AutoCutは音を検出できませんでした。以下を確認してください: {br}{br} <li>選択した言語がクリップの言語と一致していることを確認してください。</li><li>トラックがミュートされていないことを確認してください。</li><li>インポイントとアウトポイントに音のあるクリップが含まれていることを確認してください。</li> {br}これらのポイントを確認した後も問題が解決しない場合は、Discordまたはcontact@autocut.frでお気軽にお問い合わせください。',
        },
        lost: {
          cep: {
            title: 'エラーが発生しました：接続が失われました',
            text: {
              '1': 'Adobe DaVinci Resolveへの接続が失われました。拡張機能を再起動し、再試行してください。',
              '2': '拡張機能を再起動しても問題が解決しない場合は、DaVinci Resolveを再起動してください。',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'このモードではサポートされていない言語です。',
        },
      },
    },
    retour: 'このキーは存在しません！',
    countdown: {
      days: 'トライアル残り日数',
    },
    saveas: {
      default: {
        settings: '現在の設定を保存する',
      },
    },
    button: {
      no_silences: '無音が検出されませんでした',
      no_audios: '音声クリップが選択されていません',
      SavingBackup: 'プロジェクトのバックアップ中...',
      RestoringBackup: 'プロジェクトを復元中...',
      ExportingAudio: 'オーディオをエクスポートしています...',
      UploadingAudio: 'オーディオのアップロード...',
      SearchingSilences: '無音を検索中...',
      Cuttingtrack: 'トラックのカット中 {current}',
      CuttingStep: {
        __text__: 'カットを実行中 {current} / {max}',
        Audio:
          'オーディオトラック{numTrack}のカットを実行中: {current} / {max}',
        Video: 'ビデオトラック{numTrack}のカットを実行中: {current} / {max}',
      },
      PreDeletingStep: '削除する無音クリップを検索中...',
      DeletingStart: '無音を削除中...',
      AssociatingStep: '関連付けを実行中 {current} / {max}',
      Success: '完了！処理は成功裏に終了しました',
      Failure: '失敗... 何か問題が発生しました',
      NoSilences: '無音が見つかりませんでした',
      finalizing: '最終処理中...',
      undo: {
        modal: '元に戻す',
      },
      not: {
        supported: {
          modal: '閉じる',
        },
      },
      PreZoomingStep: 'ズーム用のサブクリップを取得中...',
      ZoomingStep: 'ズームを追加中：{current} / {max}',
      Autozoom: 'AutoZoomの準備中...',
      no_video: {
        to_zoom: 'ズームするビデオがありません。',
      },
      getSubclip: {
        step: 'AutoZoom用のサブクリップを取得中...',
      },
      checkSilenceSuppression: {
        step: '無音が正しく削除されているかをチェック中...',
      },
      back: '戻る',
      DeletingStep: {
        delete: {
          Audio:
            'オーディオトラック{numTrack}の無音部分を削除しています：{current}/{max} ',
          Video:
            'ビデオトラック{numTrack}の無音部分を削除しています：{current}/{max}',
        },
        mute: {
          Audio:
            'オーディオトラック{numTrack}の無音部分をミュートしています：{current}/{max}',
          Video:
            'ビデオトラック{numTrack}の無音部分をミュートしています：{current}/{max}',
        },
      },
      skip: 'スキップ',
      save: 'セーブ',
    },
    warning: {
      DontTouchTimeline: 'この処理中はタイムラインを変更しないでください',
      undo: {
        modal:
          'オーディオファイルを分析する際に一部のメタデータが存在しないことが確認されています。',
      },
      modal: {
        title: {
          ffmpeg: 'AutoCutが予想どおりに機能しない可能性があります',
        },
        text: {
          ffmpeg:
            '申し訳ありませんが、ファイル{filename}にいくつかのメタデータが欠けているようです。',
        },
        tips: {
          ffmpeg: '問題が発生した場合はファイルを再エンコードしてください。',
        },
      },
      transition: {
        step: '無音部分を削除しないとトランジションは使用できません。',
      },
      beta: {
        version:
          '現在DaVinci Resolveのベータ版を使用中のようです。AutoCutにいくつかの問題が生じる可能性があります。最新の安定版DaVinci Resolveでテストしてみてください。',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: '選択が有効かどうか確認中...',
          parsing: {
            step: '選択したデータを取得中：クリップ {current} / {max}...',
          },
        },
        silence: {
          detection: {
            step: '選択した無音を取得中：クリップ {current} / {max}...',
          },
        },
        drawing: 'プレビューを生成中...',
      },
      frame: {
        no_sequence:
          'フォーマットをプレビューするにはシーケンスを開いてください',
      },
    },
    contact: {
      us_title: 'バグに遭遇したことをお詫び申し上げます',
      us_text: {
        '2': 'アカウント、キー、支払い、またはビデオ編集プロジェクトに関連しないその他の問題がある場合：',
        __text__:
          'プロジェクトでAutoCutがうまく機能しなかった場合は、問題を報告できます：',
      },
      us_button: '問題を報告する',
      us_contact: 'お問い合わせ先：',
      us_copy: '連絡先をクリップボードにコピーしました！',
    },
    switch: {
      to_send: {
        log: {
          button: 'またはログファイルを送信する',
        },
      },
    },
    send: {
      log: {
        title: 'このバグが原因で生じた不便にお詫び申し上げます',
        details:
          'このログファイルでは、アルゴリズムの動作に関する情報のみにアクセスできます。',
        select:
          '日別のログファイルを選択してください。これにより、問題を解決するのに役立ちます。',
        text: {
          area: '問題について可能な限り詳細に説明してください：',
        },
        button: 'ログファイルを送信する',
        success: {
          title: 'ファイルが送信されました！問題を調査いたします。',
        },
        thank: {
          text: 'ビデオのカットを続けることができます。',
          text2: '問題が解決され次第、ご連絡いたします。',
        },
        message: {
          error: '10文字以上のメッセージを入力してください',
        },
        link: {
          to_user: {
            file: {
              __text__:
                'ビデオとオーディオファイルへのリンクを提供してください。',
              not: {
                valid:
                  'ビデオとオーディオファイルへの有効なリンクを提供してください。',
              },
              platform: {
                __text__:
                  '（WeTransfer、Google Drive、その他のファイル共有サービス）',
                helper:
                  'WeTransferにファイルをアップロードするには、wetransfer.comにアクセスして「ファイルをアップロード」をクリックしてください。その後、リンクが得られますので、こちらにコピーしてください。',
              },
              end: 'これにより、問題を迅速に解決することができます。',
            },
          },
        },
        screen: {
          timeline: {
            __text__:
              'DaVinci Resolveでの選択をスクリーンショットで添付してください。',
            before:
              'カット前のDaVinci Resolveでの選択をスクリーンショットで添付してください。',
            after:
              'カット後のDaVinci Resolveでの選択をスクリーンショットで添付してください。',
          },
          error: {
            message: 'エラーメッセージのスクリーンショットを添付してください。',
          },
        },
        trial: {
          mail: '試用期間中に使用したメールアドレスを教えてください。',
        },
        other: '問題解決に役立つ可能性のある情報を提供してください。',
      },
    },
    select: {
      issue: {
        title: '以下のリストから問題を選択してください。',
        subtitle: '問題を解決するのに役立ちます。',
      },
      one: {
        audios:
          'AutoCutを使用するには、少なくとも1つのオーディオクリップを選択してください。',
      },
    },
    upload: {
      screenshot: 'スクリーンショットをアップロードする',
    },
    modal: {
      feedback: {
        title: 'フィードバック',
        input: {
          feedback: {
            title: 'AutoCutについてどう思いますか？',
            placeholder: 'ここにフィードバックを書いてください...',
          },
          improve: {
            title: 'どのように改善すべきですか？',
            placeholder: '改善点をここに書いてください...',
          },
        },
        button: {
          text: '送信する',
        },
        sent: {
          title: 'フィードバックをありがとうございます！',
          text: 'AutoCutに実装してほしい機能アイデアがあれば、どうぞご提案ください：',
          button: {
            text: '機能要望を送る',
          },
        },
      },
      bug: {
        report: {
          title: 'バグ報告',
          description: {
            placeholder: 'ここにバグを記述してください...',
            error: '最低20文字のバグ記述を提供してください。',
          },
          reproduce: {
            placeholder: 'ここにバグを再現する手順を記述してください...',
            error: '最低20文字のバグ再現を提供してください。',
          },
          blocking: '問題がブロッキングであった',
          button: {
            text: 'バグ報告を送信する',
          },
          link: {
            error: 'ファイルへの有効なリンクを提供してください。',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'カットに問題はありませんでしたか？',
      },
    },
    star: {
      review: {
        title: 'AutoCutにご意見をお寄せください！',
        captions: {
          title: 'AutoCaptionsを評価する！',
        },
        text: 'AutoCutについての考えを共有してください！',
      },
    },
    review: {
      title: 'もっと詳しく教えてください',
      text: 'カットは満足のいくものでしたか？AutoCutを改善するためのフィードバックはありますか？私たちはあなたの声を聞きたいと思っています：',
      form: {
        placeholder: 'メッセージを入力してください...',
      },
      button: '送信する',
      thank: {
        title: 'フィードバックをありがとう！',
        text: 'ビデオのカッティングを続けることができます。',
      },
    },
    exchange: {
      title: '試用期間を追加7日入手する！',
      text: 'Adobe ExchangeにアクセスしてAutoCutを評価し、試用期間を追加で7日間入手しましょう！',
      button: 'Adobe ExchangeでAutoCutを評価',
      info: 'もしAutoCutを私たちのウェブサイトからインストールした場合は、もう一度エクステンションをインストールする必要はありません。ただ「無料」をクリックしてダウンロードを開始し、評価にアクセスしてください。',
    },
    licence: {
      trial: '試用',
      paid: '有料',
      info: '{licenceType}ライセンス',
      time: {
        left: '残り{daysLeft}日',
      },
    },
    trial: {
      promo: {
        text: '次の24時間で最大20％OFF',
      },
      resend: {
        error:
          '試用キーの再送信中にエラーが発生しました。後でもう一度お試しください。\n',
      },
      expired: {
        modal: {
          title: 'お試しキーの有効期限が切れています。',
          text1:
            '無料トライアルが終了しました。拡張機能を引き続き使用するには、AutoCutに登録してください。',
          text2: 'AutoCutダッシュボードにアクセスしてください：',
          text3: 'ライセンスキーに登録する。',
          text4: 'サポートチームにお問い合わせください：{supportEmail}',
          button: '購読する（-20％）',
        },
      },
      title: '無料トライアル',
      stepper: {
        onboarding: 'AutoCutの改善にご協力ください',
        email: 'メールアドレスを入力する',
        sending: {
          email: '電子メールの送信',
        },
        login: 'ライセンスキーを使用する',
      },
      onboarding: {
        subtitle:
          '拡張機能を改善し、新しい機能を想像するために、DaVinci Resolveの使用方法を理解するお手伝いをしてください',
        discover: 'AutoCutをどこで発見しましたか？',
        job: {
          status: 'あなたの職業状況は何ですか？',
        },
        type: {
          creation: 'DaVinci Resolveで行う作品の種類',
        },
      },
      sending: {
        email: {
          success: '電子メールは{email}宛に正常に送信されました。',
        },
      },
      task: {
        captions: 'AutoCaptionsを使用： <link>詳しくはこちら</link>',
        podcast: 'AutoCut Podcastを使用： <link>詳しくはこちら</link>',
        zoom: 'AutoZoomを使用： <link>詳しくはこちら</link>',
        repeat: 'AutoCut Repeatを使用： <link>詳しくはこちら</link>',
        broll: 'AutoB-Rollを使用： <link>詳しくはこちら</link>',
        swear: {
          word: 'AutoProfanity Filterを使用： <link>詳しくはこちら</link>',
        },
        preset:
          'AutoCaptions、AutoZoom、またはAutoResize用に新しいプリセットを作成する',
        testimonial: 'お客様の声をお聞かせください：<link>お客様の声</link>',
      },
    },
    shop: {
      now: '今すぐ購入',
    },
    back: {
      to_autocut: 'AutoCutに戻る',
    },
    'discover-option-youtube-video': 'YouTube動画',
    'discover-option-youtube-comment': 'YouTubeコメント',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': '友達',
    'discover-option-school': '学校',
    'discover-option-other': 'その他',
    link: {
      not: {
        supported: {
          modal: {
            __text__:
              'このエラーの詳細と修正方法を理解するには、以下にアクセスしてください：',
            no_solution:
              'このエラーの詳細を理解するには、以下にアクセスしてください：',
            end: '当社のドキュメントおよびチュートリアルサイト',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'あなたのEメール ({email}) にメールが届きました。',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            'メールが届かない場合は、迷惑メールフォルダまたはプロモーションフォルダを確認してください。',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'ライセンスキーを入力してください',
      },
    },
    "can't": {
      reach: {
        server: {
          error: 'サーバーに到達できません。後でもう一度お試しください。',
        },
      },
      read: {
        uuid: {
          error: 'コンピュータのUUIDを読み取れません。',
        },
        host: {
          error: 'コンピュータの名前を読み取れません。',
        },
      },
    },
    asking: {
      key: {
        error:
          'サーバーからのキーの要求中にエラーが発生しました。もう一度試してください。\n',
      },
    },
    sending: {
      log: {
        file: {
          error: 'ログファイルを送信できません。後でもう一度お試しください。\n',
        },
      },
    },
    is_key: {
      valid: {
        error:
          'キーをチェック中にエラーが発生しました。もう一度試してください。\n',
      },
    },
    base: {
      error: {
        message: 'エラーが発生しました ',
      },
    },
    invalid: {
      response: {
        error: 'レスポンスがサーバーからのものではありません。',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'まだ開発中のため、バグが発生した場合やご提案がある場合は、contact@autocut.frまでご連絡ください。',
        },
      },
    },
    header: {
      trial: {
        button: 'もっと見る',
      },
      paid: {
        button: '管理',
      },
      speakers: {
        name: '話者の名前',
        track: '話者のオーディオトラック',
      },
      cameras: {
        name: {
          __text__: 'カメラのビデオトラック',
          helper:
            'カメラがあるビデオトラックを選択してください。カメラごとに1つのトラックだけを選べます。',
        },
        speakers: {
          __text__: 'カメラに映る話者',
          helper:
            'このカメラに映る話者を選択してください。任意の数の話者を選択できます。',
        },
      },
    },
    game: {
      saved: {
        hours: '{hourSavedText}時間節約しました、',
      },
      have: {
        a_break: '休憩を取るには良いタイミングです！',
      },
    },
    generate: {
      preview: 'プレビューを生成する',
    },
    onboarding: {
      skip: 'オンボーディングをスキップ',
      close: '閉じる',
      previous: '前に',
      next: '次へ（{current}/{max}）',
      onboarding: {
        step1: {
          title: 'AutoCutへようこそ！',
          body: '初回使用時には、AutoCutのさまざまな機能がステップバイステップで紹介され、プロセスを通じてあなたをサポートします。では、旅を始めましょう！',
        },
        step2: {
          title: '無料試用キー。',
          body: 'AutoCutの機能を自分でテストしたい場合は、いつでも無料の試用キーをリクエストできます。14日間の試用キーをリクエストできるのは1回のみであることにご注意ください。',
        },
        step3: {
          title: 'ライセンスキー。',
          body: '試用または有料のライセンスキーを既にお持ちの場合は、それを直接使用してAutoCutツールにアクセスできます。',
        },
        step4: {
          title: 'ライセンスキーの購入。',
          body: 'ライセンスキーの購入を希望する場合は、提供されているボタンをクリックするとAutoCutのWebサイトにリダイレクトされ、AutoCutを購入するために必要なすべての情報が見つかります。',
        },
      },
      modeSelection: {
        step1: {
          title: 'AutoCutモード。',
          body: 'AutoCutには、さまざまな編集ニーズに対応するための3つの異なるモードがあります。要件に合ったモードを選択するには、モードの1つをクリックするだけです。',
        },
        step2: {
          title: 'ヘルプが必要ですか？',
          body: 'インターフェース上のすべての要素にはツールチップが付属しており、その機能に関する追加情報が提供されています。特定の要素の詳細を知りたい場合は、そのツールチップ上にマウスポインターを置くことで詳細を学ぶことができます。',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2。',
          body: 'AutoCut V2機能は、オーディオファイルのノイズレベルに基づいて無音を精密に検出します。ただし、使用する前に設定する必要があります。',
        },
        step2: {
          title: 'ノイズレベル。',
          body: 'スライダーを使用して、無音として検出される最小ノイズレベルを調整します。',
        },
        step3: {
          title: '次に何をするか？',
          body: '矢印をクリックしてステップをナビゲートし、AutoCutの機能について詳しく知りましょう。',
        },
        step4: {
          title: 'プレビューを生成しています。',
          body: "タイムラインのオーディオは、処理される前にエクスポートする必要があります。'プレビューを生成する'をクリックすると、オーディオを事前にエクスポートして、現在の設定パラメーターで結果を視覚化することができます。",
        },
        step6: {
          title: 'カットの時間。',
          body: 'すべてをレビューして問題ないようであれば、カットの時間です！',
        },
        step7: {
          title: 'カットの時間。',
          body: '矢印をクリックして、好みのカットオプションを選択してください。',
        },
        step8: {
          title: 'カットの時間。',
          body: 'ボタンをクリックしてリラックスし、AutoCutに残りを任せてコーヒーを楽しんでください！',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCutポッドキャスト。',
          body: 'AutoCutポッドキャストは、ビデオポッドキャストを迅速に編集するための強力な機能です。',
        },
        step2: {
          title: '話し手。',
          body: '"話し手を追加"をクリックすることで新しい話し手を作成できます。ポッドキャストで発言する各人に対して、話し手を1人追加する必要があります。',
        },
        step3: {
          title: '話し手。',
          body: '話し手を作成したら、ニーズに合わせて設定できます。',
        },
        step4: {
          title: '話し手の名前。',
          body: 'このセクションでは、話し手に名前を割り当て、それを視覚的に識別できるようにします。',
        },
        step5: {
          title: 'ビデオトラックの選択。',
          body: 'ドロップダウンメニューを使用して、話し手が登場するすべてのビデオトラックを選択できます。',
        },
        step6: {
          title: 'オーディオトラックの選択。',
          body: 'ドロップダウンメニューを使用して、話し手のオーディオトラックを選択できます。各話し手には1つのオーディオトラックのみを持つことができます。',
        },
        step7: {
          title: '編集の時間。',
          body: 'すべての話し手と彼らが登場する場所を定義したら、設定完了です！',
        },
        step8: {
          title: '編集の時間。',
          body: '矢印を使用して、好みの編集オプションを選択してください。',
        },
        step9: {
          title: '編集の時間。',
          body: 'ボタンをクリックしてリラックスし、AutoCutが残りを処理しながらコーヒーを楽しんでください。',
        },
      },
      title: 'AutoCutへようこそ',
      subtitle: 'あなたの究極のDaVinci Resolve拡張機能',
    },
    start: {
      transcription: '書き起こしを開始',
    },
    no_more: {
      credits: 'クレジットが不足しています',
    },
    file: {
      too: {
        long: 'オーディオファイルが長すぎます',
      },
      sent: 'ファイルがアップロードされました、書き起こしを待っています...',
    },
    please: {
      wait1: 'オーディオクリップを書き起こし中ですので、お待ちください。',
      wait2: '長さに応じて、このプロセスには数分かかる場合があります。',
      wait3: 'ファイルをアップロード中：',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'オーディオ言語',
            helper: 'オーディオクリップの言語を選択',
          },
        },
        french: {
          __text__: 'フランス語',
          beta: 'フランス語（ベータ版）',
        },
        danish: 'デンマーク語',
        dutch: 'オランダ語',
        english: {
          __text__: '英語',
          united: {
            states: '英語 - アメリカ合衆国',
          },
        },
        flemish: 'フラマン語',
        german: {
          __text__: 'ドイツ語',
          beta: 'ドイツ語（ベータ版）',
        },
        hindi: {
          __text__: 'ヒンディー語',
          beta: 'ヒンディー語（ベータ版）',
        },
        italian: 'イタリア語',
        japanese: '日本語',
        korean: '韓国語',
        norwegian: {
          __text__: 'ノルウェー語',
          nynorsk: 'ノルウェー語ニーノシュク',
        },
        polish: 'ポーランド語',
        portuguese: {
          __text__: 'ポルトガル語',
          beta: 'ポルトガル語（ベータ版）',
          brazil: {
            __text__: 'ポルトガル語 - ブラジル',
            beta: 'ポルトガル語 - ブラジル（ベータ版）',
          },
        },
        spanish: {
          __text__: 'スペイン語',
          beta: 'スペイン語（ベータ版）',
          latin: {
            america: {
              __text__: 'スペイン語 - ラテンアメリカ',
              beta: 'スペイン語 - ラテンアメリカ（ベータ版）',
            },
          },
        },
        swedish: 'スウェーデン語',
        tamil: 'タミル語',
        chinese: '中国語',
        russian: 'ロシア語',
        turkish: 'トルコ語',
        catalan: 'カタロニア語',
        arabic: {
          __text__: 'アラビア語',
          algeria: 'アラビア語 - アルジェリア',
          bahrain: 'アラビア語 - バーレーン',
          egypt: 'アラビア語 - エジプト',
          iraq: 'アラビア語 - イラク',
          israel: 'アラビア語 - イスラエル',
          jordan: 'アラビア語 - ヨルダン',
          kuwait: 'アラビア語 - クウェート',
          lebanon: 'アラビア語 - レバノン',
          mauritania: 'アラビア語 - モーリタニア',
          morocco: 'アラビア語 - モロッコ',
          oman: 'アラビア語 - オマーン',
          qatar: 'アラビア語 - カタール',
          saudi: {
            arabia: 'アラビア語 - サウジアラビア',
          },
          palestine: 'アラビア語 - パレスチナ',
          syria: 'アラビア語 - シリア',
          tunisia: 'アラビア語 - チュニジア',
          united: {
            arab: {
              emirates: 'アラビア語 - アラブ首長国連邦',
            },
          },
          yemen: 'アラビア語 - イエメン',
        },
        indonesian: 'インドネシア語',
        finnish: 'フィンランド語',
        vietnamese: 'ベトナム語',
        hebrew: 'ヘブライ語',
        ukrainian: 'ウクライナ語',
        greek: 'ギリシャ語',
        malay: 'マレー語',
        czech: 'チェコ語',
        romanian: 'ルーマニア語',
        hungarian: 'ハンガリー語',
        thai: 'タイ語',
        urdu: 'ウルドゥー語',
        croatian: 'クロアチア語',
        bulgarian: 'ブルガリア語',
        lithuanian: 'リトアニア語',
        latin: 'ラテン語',
        maori: 'マオリ語',
        malayalam: 'マラヤーラム語',
        welsh: 'ウェールズ語',
        slovak: 'スロバキア語',
        telugu: 'テルグ語',
        persian: 'ペルシャ語',
        latvian: 'ラトビア語',
        bengali: 'ベンガル語',
        serbian: 'セルビア語',
        azerbaijani: 'アゼルバイジャン語',
        slovenian: 'スロベニア語',
        kannada: 'カンナダ語',
        estonian: 'エストニア語',
        macedonian: 'マケドニア語',
        breton: 'ブルトン語',
        basque: 'バスク語',
        icelandic: 'アイスランド語',
        armenian: 'アルメニア語',
        nepali: 'ネパール語',
        mongolian: 'モンゴル語',
        bosnian: 'ボスニア語',
        kazakh: 'カザフ語',
        albanian: 'アルバニア語',
        swahili: 'スワヒリ語',
        galician: 'ガリシア語',
        marathi: 'マラーティー語',
        punjabi: 'パンジャブ語',
        sinhala: 'シンハラ語',
        khmer: 'クメール語',
        shona: 'ショナ語',
        yoruba: 'ヨルバ語',
        somali: 'ソマリ語',
        afrikaans: 'アフリカーンス語',
        occitan: 'オクシタン語',
        georgian: 'ジョージア語',
        belarusian: 'ベラルーシ語',
        tajik: 'タジク語',
        sindhi: 'シンド語',
        gujarati: 'グジャラート語',
        amharic: 'アムハラ語',
        yiddish: 'イディッシュ語',
        lao: 'ラオ語',
        uzbek: 'ウズベク語',
        faroese: 'フェロー語',
        haitian: {
          creole: 'ハイチクレオール語',
        },
        pashto: 'パシュト語',
        turkmen: 'トルクメン語',
        maltese: 'マルタ語',
        sanskrit: 'サンスクリット語',
        luxembourgish: 'ルクセンブルク語',
        burmese: 'ビルマ語',
        tibetan: 'チベット語',
        tagalog: 'タガログ語',
        malagasy: 'マダガスカル語',
        assamese: 'アッサム語',
        tatar: 'タタール語',
        hawaiian: 'ハワイ語',
        lingala: 'リンガラ語',
        hausa: 'ハウサ語',
        bashkir: 'バシキール語',
        javanese: 'ジャワ語',
        sundanese: 'スンダ語',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            '多くのクリップがシーケンスに含まれているようです！読み込みに時間がかかる場合があります。すべてのクリップをカットする必要がない場合は、必要なクリップのみを選択し、空の別のシーケンスでカットしてください。',
        },
      },
    },
    homepage: {
      title: 'ホームページ',
      comming: {
        soon: '近日公開！',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'ダイナミズム',
        normal: '通常',
        high: '高い',
        extreme: '極端',
        custom: 'カスタム：',
      },
      button: {
        __text__: 'ズーム',
        message: {
          step1: 'AutoZoomの準備中...',
          step2: 'オーディオデータを抽出...',
          step3: 'ズーム間隔を計算中...',
          step4: 'ズームを適用中 {current} / {max} ...',
        },
      },
      success: '成功！選択された区間がズームされました',
      info: {
        text: 'AutoZoomは、デシベルに基づいて各クリップに自動的にズームを追加します。',
      },
      invalid: {
        selection: {
          screen: {
            title:
              'タイムラインで少なくとも1つのオーディオクリップと1つのビデオクリップを選択してください',
            text1:
              'AutoZoomを使用するには、タイムラインでビデオを構成するすべてのクリップを選択してください。',
          },
        },
      },
      anchor: {
        title: 'アンカーポイントを定義する',
        helper:
          'アンカーポイントは、ズームが適用される場所です。最大および最小ズームの効果を色付きボックスを使用して視覚化できます。',
      },
      dual: {
        range: {
          title: '適用される最小および最大ズーム係数',
          helper:
            'ズーム係数は、以下で提供された値とサブクリップの最大音量に基づいて計算されます。',
        },
      },
      preview: {
        on_indicator: 'インジケーターにプレビュー',
        error: {
          message: 'プレビューの読み込み中にエラーが発生しました',
          button: '再試行',
        },
      },
      min: {
        zoom: {
          title: '最小ズーム期間',
          helper: '各ズームは少なくともこの時間長になります。',
        },
      },
      max: {
        zoom: {
          title: '最大ズーム期間',
          helper: '各ズームは最大でこの時間長になります。',
        },
      },
      constraint: {
        zoom: {
          title: 'クリップへのズーム制約',
          helper:
            'はいの場合、ズームは1つのクリップを超えて広がることはありません。いいえの場合、選択した全体が単一のオーディオファイルとして処理され、ズームはタイムライン上の個々のクリップを無視します。',
        },
      },
      zoom: {
        types: {
          __text__: 'ズームのタイプ',
          helper:
            '使用するズームのタイプを決定します。スムース：時間とともに徐々にズーム。ダイナミック：即時、鋭いズーム。',
        },
        coverage: {
          __text__: '総ズームカバレッジ',
          helper: 'ズームされるビデオの割合',
        },
      },
      smooth: {
        __text__: 'スムース',
        zoom: {
          __text__: 'スムースズーム',
          coverage: 'スムースズームのカバレッジ',
          nervousness: {
            __text__: '緊張感',
            helper:
              '低い緊張感は遅く、弱いズームを意味します。高い緊張感は速く、強烈なズームを意味します。',
          },
        },
      },
      dynamic: {
        __text__: 'ダイナミック',
        zoom: {
          __text__: 'ダイナミックズーム',
          coverage: 'ダイナミックズームのカバレッジ',
        },
      },
    },
    zoom: {
      preset: {
        title: 'デフォルトのプリセット',
        calm: '落ち着いた',
        paced: 'ペースド',
        energetic: 'エネルギッシュ',
        hyperactive: 'ハイパーアクティブ',
      },
    },
    repeat: {
      info: {
        banner:
          'これで、どの文がカットされるかどうかを修正できるようになりました。{br}グレー表示されている文は繰り返しと見なされます。{br}ハサミで文の状態（繰り返しかどうか）を定義できます。また、文の一部をハイライトして、正確にどの単語がカットされるかをターゲットにすることもできます',
      },
      invalid: {
        selection: {
          screen: {
            title: 'タイムラインでクリップを選択してください',
            text1:
              'AutoCut Repeatを使用するには、タイムラインでオーディオクリップを1つ、任意でビデオクリップを選択してください。',
          },
        },
      },
      select: {
        algorithm: 'アルゴリズムの選択',
      },
      precise: {
        algorithm: {
          info: '正確なアルゴリズムは、私たちの最も最近で効率的なものですが、ビデオ6分ごとに約1分の処理時間が必要です。',
        },
      },
      fast: {
        algorithm: '高速',
      },
      text: {
        step: {
          edit: {
            transcript: '書き起こしを修正',
          },
        },
      },
      toggle: {
        cut: {
          button: {
            text: 'カット／カット解除',
          },
        },
      },
      play: {
        button: {
          text: '再生',
        },
      },
    },
    captions: {
      step: {
        customization: '字幕のパラメータ',
        script: 'クリップと言語の選択',
        transcription: '書き起こしの編集',
      },
      script: {
        transcript: {
          part: '書き起こし',
        },
        sequence: {
          part: 'シーケンス',
          name: '名前',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} クリップ | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: '字幕の追加',
        coming: {
          soon: '書き起こしエディタが近日公開予定です！{br}現時点では、Essential Graphicsパネルにインポートされた書き起こしを編集することができます。{br}タイムラインで字幕を選択し、パネルの修正タブで編集してください。',
        },
        transcription: {
          part: '書き起こし',
        },
        info: '字幕エディタへようこそ - もちろん、追加した後にDaVinci Resolveのタイムラインで字幕を編集することもできます。',
        add: {
          emojis: {
            cta: '絵文字を生成する',
          },
        },
      },
      text: {
        noClip: {
          title: 'タイムラインで字幕を追加するクリップを選択してください',
          no_selection:
            'AutoCutが反応しない場合は、DaVinci Resolveを再起動してみてください。',
        },
      },
      process: {
        transcript: {
          button: '処理',
        },
      },
      customization: {
        info: 'AutoCaptionは現在ベータ版であり、最初の安定版が11月10日に利用可能になる予定です',
        back: {
          button: '書き起こしを削除して戻る',
        },
        example: {
          __text__:
            'プレビュー <opacity50><i>キャプションまたは絵文字をドラッグして位置を変更できます </i></opacity50>',
          text: 'AutoCaptionsを使用して字幕を追加します。これはAutoCaptionsのプレビューであり、簡単にカスタマイズすることができます。必要に応じて書き起こしを開始して編集し、AutoCaptionsが自動的に字幕をタイムラインに追加します。',
        },
        animation: {
          __text__: 'アニメーション',
          helper: 'テキストアニメーションを管理',
          color: {
            __text__: 'テキストの<color>色</color>',
            text: {
              color: '色',
            },
            before: {
              highlight: {
                color: 'ハイライト前の色',
              },
            },
            after: {
              highlight: {
                color: 'ハイライト後の色',
              },
            },
          },
          advanced: {
            color: {
              options: '高度な色のオプション',
            },
          },
          word: {
            box: '<color>フレーム</color>付きのテキスト',
          },
          bold: {
            with: {
              text: '<b>太字</b>のテキスト',
            },
          },
          floating: {
            text: 'フローティングテキスト',
          },
        },
        transition: {
          __text__: 'トランジション',
          helper:
            '字幕のトランジションを管理します。タイミングは、例と同じであるとは限りません。',
          effect: {
            zoomIn: 'ズームイン',
            blurIn: 'ブラーイン',
          },
        },
        outline: {
          __text__: 'アウトライン',
          helper: 'テキストアウトラインスタイルを管理',
          color: '色',
          width: '幅',
        },
        font: {
          __text__: 'フォント',
          helper: 'テキストスタイルを管理',
          font: 'フォント名',
          help: '新しいフォントファミリーを追加するには？',
          size: 'フォントサイズ',
          weight: 'フォントの太さ',
          color: '色',
        },
        formating: {
          __text__: 'テキストのフォーマット',
          helper: 'テキストフォーマットを管理',
          ponctuation: '句読点を削除',
          uppercase: '大文字',
          bold: '太字',
          italic: '斜体',
          glow: {
            __text__: 'グロー',
            color: '色',
            intensity: '強度',
          },
        },
        transform: {
          __text__: '変形',
          x: 'x',
          y: 'y',
          helper: '字幕の位置と回転を管理',
          position: {
            __text__: '垂直位置',
            emoji: '絵文字の垂直位置',
            info: 'プレビューでテキストを移動することもできます',
          },
          rotation: '回転',
        },
        multiple: {
          lines: '複数の行',
        },
        box: {
          __text__: 'ボックス',
          helper: 'ボックススタイルを管理',
        },
        boxes: {
          padding: {
            x: '水平パディング',
            y: '垂直パディング',
          },
          opacity: 'ボックスの不透明度',
          radius: '半径',
          color: 'ボックスの色',
        },
        emojis: {
          __text__: '絵文字',
          helper: 'AIで絵文字を生成',
          size: '絵文字のサイズ',
        },
        shadow: '影',
        transcript: '書き起こし',
        action: '次のステップ',
        select: {
          chunk:
            '編集／カスタマイズするトランスクリプトの部分を選択してください',
        },
        delete: {
          caption: {
            modal: {
              title: 'この字幕を削除してもよろしいですか？',
              cancel: 'いいえ - 保持する',
              confirm: 'はい - 削除する',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'プリセットの数を取得中にエラーが発生しました。お手数ですが、Discordかcontact@autocut.frまでお問い合わせください。',
            },
            error:
              'プリセットの取得中にエラーが発生しました。お手数ですが、Discordかcontact@autocut.frまでお問い合わせください。',
          },
          public: {
            presets: {
              error:
                '公共のプリセットを取得する際にエラーが発生しました。お問い合わせは、discordまたはcontact@autocut.frまでお願いします。',
            },
          },
        },
        presets: {
          title: 'プリセット',
          save: {
            new: {
              preset: '現在のパラメータを保存します',
            },
          },
          view: {
            more: 'もっと見る',
            less: 'もっと少なく見る',
          },
        },
        save: {
          preset: {
            info: {
              text: 'パラメータを変更しました。下のプリセットとして保存して、無限に再利用できます！',
            },
          },
        },
      },
      editor: {
        title: '字幕エディタ',
        description: {
          title: '編集したい字幕を選択してください',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: '不確実な単語',
            },
          },
          merge: {
            tooltip: 'チャンクを統合する',
          },
          split: {
            tooltip: 'チャンクを分割する',
          },
        },
        sound: {
          play: 'サウンド再生を有効にする',
        },
      },
      presetCustomization: {
        createPresetModal: {
          title: {
            length: {
              error:
                'プリセット名には少なくとも2つの単語を含める必要があります。',
            },
          },
          error:
            'プリセットの作成中にエラーが発生しました。お手数ですが、Discordかcontact@autocut.frまでお問い合わせください。',
          placeholder: 'プリセット名（少なくとも2つの単語）',
          create: 'プリセットを作成',
          isPublic: 'コミュニティに共有 - 公開',
        },
        searchPresetModal: {
          title: 'コミュニティプリセットを検索',
        },
        error: {
          preset: {
            already: {
              exists:
                'このプリセットはすでに存在しています。別の名前を選択してください。',
            },
          },
        },
        copyPreset: {
          error:
            'プリセットのコピー中にエラーが発生しました。お問い合わせは、discordまたはcontact@autocut.frまでお願いします。',
        },
        no_preset: {
          selected: 'プレセットを選択してプレビューを表示してください',
        },
        no_preview: {
          available: 'プレビューは利用できません',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'フォントが見つかりません',
          message:
            'フォント「<i><b>{fontLabel}</b></i>」がシステムに見つかりませんでした。デフォルトのフォントが使用されます。',
          helper:
            '新しいフォントファミリーを追加するには、このチュートリアルに従ってください。',
        },
      },
    },
    version: {
      not: {
        supported: 'DaVinci Resolveのバージョンがサポートされていない',
      },
    },
    transcript: {
      usage: {
        unit: '分',
      },
      upgrade: {
        button: '{mode}を続けるためにAIにアップグレードする',
      },
      too: {
        short: {
          error:
            '申し訳ありませんが、お客様のトランスクリプトが短すぎる可能性があります。または、お使いの言語が正しくサポートされていない可能性があります。問題が解決しない場合は、contact@autocut.fr までお問い合わせください。',
        },
      },
    },
    feature: {
      request: {
        __text__: '機能リクエスト',
        form: {
          placeholder:
            '機能リクエストを入力してください。リクエストを詳しく説明していただけると、研究がより容易になります。',
        },
        screenshot:
          'スクリーンショット/動画を添付して、機能リクエストをより良く理解するのを助けてください：',
        button: '機能リクエストを送信',
        success: {
          title: '機能リクエストが送信されました！🥳',
          body: 'AutoCutの成長にご協力いただきありがとうございます！リクエストを最も注意深く検討します。',
        },
        error: {
          title: 'おっと、何かがうまくいかなかった... 🤕',
          body: 'ご不便をおかけして申し訳ありません。後でもう一度試してみるか、contact@autocut.frまでお問い合わせください。',
        },
      },
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'タイムラインにイン・アウトポイントを追加',
            text1:
              'AutoResizeを使用するためには、イン・アウトポイントを追加する必要があります。',
            cta: 'フルシーケンスでイン点とアウト点を設定',
          },
        },
      },
      text: {
        info: 'AutoResizeは、すべてのソーシャルメディアに最適なサイズのシーケンスを自動的にリサイズし、リフレーミングします。',
      },
      in_out: {
        step: {
          title: 'イン / アウト ポイント',
          helper:
            'タイムラインのイン・アウトポイントを確認してください。以下に示す値と同じである必要があります。',
        },
      },
      preset: {
        step: {
          title: 'プリセット',
          helper:
            'シークエンスをリサイズするためのプリセットを選択します。自分自身のプリセットを作成することもできます。新しいシークエンスの寸法は以下に表示されます。',
        },
        undefined: 'プリセットを選択してください。',
      },
      watermark: {
        step: {
          title: 'ウォーターマーク',
          helper:
            'シークエンスにウォーターマークを追加することができます。それはシークエンスの右下隅に追加されます。',
          text: 'ウォーターマークをアップロード',
        },
      },
      button: 'ソーシャルクリップを生成',
      reframe: {
        undefined: '対象者に従う」スピードを選択してください。',
        step: {
          title: '対象をフォロー',
          helper: 'カメラが対象をフォローする速度を選択できます。',
          slower: '遅い',
          default: '通常',
          faster: '速い',
        },
      },
      backup: 'シークエンスをバックアップしています...',
      create: {
        new: {
          sequence: '新しいシークエンスを作成しています...',
        },
      },
      resizing: {
        sequence: '新しいシークエンスのサイズ変更をしています...',
      },
      adding: {
        watermark: 'ウォーターマークを追加しています...',
      },
      cleaning: {
        sequence: 'シークエンスをクリーニングしています...',
      },
    },
    endprocess: {
      modal: {
        title: '処理完了！',
        text: {
          '1': 'プロセスが正常に終了しました！',
          '2': '皆様からのフィードバックは非常に貴重です – 私たちのパフォーマンスや、もっと良い体験にするためにはどうすればよいかお知らせください！',
        },
        next: {
          mode: {
            silence: {
              text: 'シームレスなオーディオ体験の準備はできましたか？AutoCut Silencesで気まずい沈黙を簡単に取り除きましょう。',
              button: 'AutoCut Silencesへ',
            },
            podcast: {
              text: 'AutoCut Podcastでポッドキャスト制作をレベルアップ。簡素化された編集機能と自動カメラ切り替えをお楽しみください。',
              button: 'AutoCut Podcastへ',
            },
            repeat: {
              text: '視聴者を引きつけましょう！AutoCut Repeatを使ってテキストエディタで動画編集をし、内容の最もエンゲージメントの高い部分だけを保持します。',
              button: 'AutoCut Repeatへ',
            },
            zoom: {
              text: 'AutoZoomで動画に命を吹き込みましょう！視聴者を魅了して彼らの注意を引きつけるフローイングズームエフェクトを追加します。',
              button: 'AutoZoomへ',
            },
            caption: {
              text: 'AutoCaptionsを利用して視聴者とのつながりを強化しましょう。動画のストーリーテリングを強化するスタイリッシュで同期した字幕を統合します。',
              button: 'AutoCaptionsへ',
            },
            resize: {
              text: 'AutoResizeでクロスプラットフォームシェアリングをマスターしましょう。対象を見失うことなく、動画を完璧なフォーマットにしましょう。',
              button: 'AutoResizeへ',
            },
            broll: {
              text: 'AutoB-Rollで視覚的なナラティブを増幅しましょう。AIが関連するストック映像をシームレスに織り交ぜ、あなたのストーリーを補完します。',
              button: 'AutoB-Rollへ',
            },
            swear: {
              word: {
                text: 'AutoProfanity Filterで洗練された対話を維持しましょう。不要な言葉を自動的にマスキングして、コンテンツのプロフェッショナリズムを保ちます。',
                button: 'AutoProfanity Filterへ',
              },
            },
          },
        },
      },
      captions: {
        modal: {
          text: {
            '1': 'あなたのアニメーション字幕が追加されました！ 🎉 {br} 字幕を変更する必要がある場合は：',
            '2': '必要に応じて<a>詳細なチュートリアル</a>があります。',
          },
          bullet: {
            point: {
              '1': 'サブシーケンスをダブルクリックしてください："Double click to edit"',
              '2': '編集したい字幕を選択してください',
              '3': 'エッセンシャルグラフィックスを開き、「編集」タブをクリックしてください',
            },
          },
        },
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title:
              'Bロールベースとなるクリップをタイムラインから選んでください',
            text1:
              'オートBロールを使用するには、タイムラインで少なくとも1つのオーディオ付きクリップを選択する必要があります。',
          },
        },
      },
      text: {
        info: 'AIのおかげで、オートBロールはあなたのオーディオを分析し、ビデオの内容に関連する {br} @Pexels Bロールを自動的に追加します。',
      },
      duration: {
        step: {
          title: 'Bロールの長さ',
          minimum: {
            title: 'Bロールの最短長さ',
            helper: '各Bロールは少なくともこの長さになります。',
          },
          maximum: {
            title: 'Bロールの最長長さ',
            helper: '各Bロールは最長でもこの長さになります。',
          },
        },
      },
      choice: {
        step: {
          title: 'Bロール選択',
        },
      },
      no_broll: {
        screen: {
          title:
            'トランスクリプト処理が完了しましたが、何も見つかりませんでした。',
          text1:
            'オートBロールを使用するには、タイムラインに少なくとも1つの音声クリップを選択する必要があります。',
          text2:
            '既に行った場合は、トランスクリプトの言語を確認し、オーディオトラックがミュートされていないか確認してください。',
        },
      },
      button: {
        __text__: 'Bロールを追加',
        next: '次へ',
      },
      exporting: '選択されたオーディオクリップをエクスポート中...',
      analyse: 'オーディオを分析中...',
      getting: {
        broll: 'Bロールを取得中... (数分かかる場合があります',
      },
      transcript: {
        finished: 'トランスクリプト生成完了',
      },
      step: {
        parameters: 'Bロールのパラメータ',
        language: 'クリップと言語を選択',
        brolls: 'Bロール選択を編集',
      },
      adding: {
        broll: 'Bロールを追加中...',
      },
      restore: 'あなたのシーケンスを復元中...',
      backup: 'あなたのシーケンスをバックアップ中...',
      credit: {
        modal: {
          title:
            'あなたのビデオで使用されているBロールのクレジットはこちらです：',
          provided: {
            by: 'Pexels提供',
          },
          link: 'リンク',
          button: 'クリップボードにコピー',
        },
      },
      search: {
        modal: {
          title: 'Bロールを選択',
          input: {
            placeholder: 'Bロールを検索するタイプ',
          },
          searching: 'Bロールを検索中…',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'ダブルクリックして編集',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title:
                '不適切な言葉をピー音で消したいタイムライン上のクリップを選択してください',
              text1:
                'AutoProfanity Filterを使用するには、タイムライン上で少なくとも1つのオーディオクリップを選択する必要があります。',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filterは、AIを駆使してあなたのオーディオを分析し、不適切な言葉の上に自動的に{br} ピー音を追加します。',
        },
        mute: 'ミュート',
        button: '不適切な言葉を削除',
        audio: {
          export: 'オーディオをエクスポートしています...',
          analyse: 'オーディオ解析中...',
          insert: 'ピー音を挿入しています...',
          filter: 'ボリュームフィルターを適用しています...',
        },
        script: {
          transcript: {
            part: '書き起こし',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: {
            helper: 'ピー音効果音を選択',
          },
        },
      },
    },
    create: {
      format: {
        modal: {
          title: '新しいフォーマットを作成',
          button: {
            __text__: '作成',
            disabled: '既に使用されている名前です',
          },
          name: '名前',
          width: '幅',
          height: '高さ',
        },
      },
    },
    process: {
      modal: {
        warning: 'DaVinci Resolve上では何もしないでください。',
        generating: {
          emojis: '字幕から絵文字を生成中...',
        },
        formatting: {
          numbers: '転写からの数字の書式設定...',
        },
      },
    },
    backup: {
      modal: {
        title: 'オートカット バックアップ',
        text: {
          '1': 'AutoCut処理を行う前に、必ずバックアップが作成されます。これらのバックアップは「AutoCut-Backup」フォルダ内にあります。',
          '2': 'ご自由にご利用いただけます。',
        },
      },
    },
    confirm: {
      modal: {
        title: '本当に？',
        confirm: {
          label: '確認',
        },
        cancel: {
          label: 'キャンセル',
        },
      },
    },
    login: {
      resend: {
        email: '届きませんでしたか？ {trialEmail}宛にメールを再送信する',
      },
    },
    typeofcreation: {
      option: {
        interview: 'インタビュー',
        podcast: 'ポッドキャスト',
        youtube: 'YouTube動画',
        short: 'TikTok/Shorts/Reels',
        internal: '社内動画',
        other: 'その他',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'コンテンツクリエイター',
        },
        video: {
          editor: {
            professional: 'プロのビデオ編集者',
            semi: {
              professional: 'セミプロのビデオ編集者',
            },
            beginner: '初心者ビデオ編集者',
          },
        },
        student: '学生',
        other: 'その他',
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: '話者の名前を入力してください…',
        },
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: '字幕の作成中...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal:
          '素晴らしい仕事です！たったの{minutes}分{seconds}秒でプロセスを完了しました。',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: '時間節約：<b>{minutesSaved} {minutesSaved, plural, one {分} other {分}}</b>と<b>{secondsSaved} {secondsSaved, plural, one {秒} other {秒}}</b>。',
        },
        all: {
          cuts: 'AutoCutでの総時間節約：なんと<b>{hourSaved} {hourSaved, plural, one {時間} other {時間}}</b>と<b>{minutesSaved} {minutesSaved, plural, one {分} other {分}}</b>です！🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          '特定のオーディオトラックを無音検出から除外するには、それらを無効にします。これは、バックグラウンドミュージックやゲームの音を保持するのに理想的です。特定のクリップの無音を変更したくない場合は、そのクリップを選択しないでください。',
        video: '選択されたビデオトラック:',
        audio: '選択されたオーディオトラック:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: '無料トライアルの制限に達しました',
            cta: '購読する',
            text: 'AI機能を1000分以上使用しました。{br} AutoCut AIの機能を継続して使用するには、AIプランに申し込んでください。',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: '転写の上限に達しました',
          text: 'AI機能を6000分以上使用しました。{br} 状況を解除するには、私たちに連絡してください',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          '有効なトランジションタイプを選択してください（<a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>フィールドを参照）',
        ai_silences:
          '有効な無音処理を選択してください（<a><b><embedTranslation>title_silences_step</embedTranslation></b></a>フィールドを参照）',
        broll: {
          min: {
            max: {
              duration:
                '最大B-Roll時間は最小B-Roll時間より小さくすることはできません（<a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>フィールドを参照）',
            },
          },
          minimumBRollTime:
            '最小B-Roll時間は正の数である必要があります（<a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>フィールドを参照）',
          maximumBRollTime:
            '最大B-Roll時間は正の数である必要があります（<a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>フィールドを参照）',
        },
        caption: {
          position:
            'キャプションはプレビュー内にある必要があります（<a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a>フィールドを参照）',
          emoji: {
            position:
              '絵文字はプレビュー内にある必要があります（<a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a>フィールドを参照）',
          },
          text: {
            fontSize:
              'フォントサイズは正の数である必要があります（<a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>フィールドを参照）',
          },
          outline: {
            width:
              'アウトラインの幅は正の数である必要があります（<a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a>フィールドを参照）',
          },
          transition: {
            effects:
              '有効なキャプションのトランジション効果を選択してください（<a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>フィールドを参照）',
          },
          emojis: {
            size: '絵文字のサイズは正の数である必要があります（<a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>フィールドを参照）',
          },
          formating: {
            nbLines:
              'キャプションのフォーマットには少なくとも1行を選択してください（<a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>フィールドを参照）',
          },
          title: {
            too: {
              short: 'タイトルは5文字以上でなければなりません',
              long: 'タイトルは25文字以下である必要があります',
            },
            no_space:
              'タイトルには、スペースで区切られた少なくとも2つの単語が含まれている必要があります',
          },
        },
        font: {
          fontFamily:
            'フォントファミリーが必要です（<a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>フィールドを参照）',
          label:
            'フォントを選択してください（<a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>フィールドを参照）',
        },
        languageOfTranscription:
          '転写の言語を選択してください（<a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>フィールドを参照）',
        cameras: {
          speakerIds:
            '各カメラには少なくとも1人のスピーカーが必要です（<a><b><embedTranslation>header_cameras_speakers</embedTranslation></b></a>フィールドを参照）',
          label:
            'カメラのトラックが欠落しています（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>フィールドを参照）',
        },
        podcast: {
          minimum: {
            cam: {
              time: '最小カメラ時間は正の数である必要があります（<a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>フィールドを参照）',
            },
          },
          maximum: {
            cam: {
              time: '最大カメラ時間は正の数である必要があります（<a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>フィールドを参照）',
            },
          },
          deleteUnusedClips:
            '削除または無音化のいずれかを選択してください（<a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>フィールドを参照）',
          speakers: {
            cameras:
              '各スピーカーには少なくとも1つのカメラが必要です（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>フィールドを参照）',
            audio:
              '各スピーカーには1つのオーディオトラックが必要です（<a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>フィールドを参照）',
          },
          cameras: {
            __text__:
              'カメラは同じトラックを持つことはできません（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>フィールドを参照）',
            length:
              '少なくとも2つの異なるカメラを設定する必要があります (<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a> フィールドを参照)',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              '自動リフレームプリセットが必要です（<a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>フィールドを参照）',
            invalid:
              '自動リフレームプリセットはslower、default、またはfasterである必要があります（<a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>フィールドを参照）',
          },
          watermark:
            'ウォーターマークは有効な画像ファイル形式（JPEG、PNG、またはWebP）である必要があります（<a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>フィールドを参照）',
          preset: {
            name: 'プリセットの名前を入力してください',
            width: '幅は1px以上である必要があります',
            height: '高さは1px以上である必要があります',
          },
        },
        rgb: 'RGB値は0から255の間である必要があります',
        silence: {
          noiseLevel: {
            type: 'ノイズレベルは数値である必要があります（<a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>フィールドを参照）',
            nonpositive:
              'ノイズレベルは正の数である必要があります（<a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>フィールドを参照）',
            integer:
              'ノイズレベルは整数である必要があります（<a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>フィールドを参照）',
          },
          minTimeOfSilence: {
            type: '無音の最小時間は数値である必要があります（<a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>フィールドを参照）',
          },
          maxTimeOfSilence: {
            type: '無音の最大時間は数値である必要があります（<a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>フィールドを参照）',
          },
          marginBefore: {
            type: '前の余白は数値である必要があります（<a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>フィールドを参照）',
          },
          marginAfter: {
            type: '後の余白は数値である必要があります（<a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>フィールドを参照）',
          },
          transitions:
            '有効なトランジションタイプを選択してください（<a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>フィールドを参照）',
          silences:
            '有効な無音処理を選択してください（<a><b><embedTranslation>title_silences_step</embedTranslation></b></a>フィールドを参照）',
        },
        swearWord: {
          bleepFile:
            'ブリープ効果音を選択してください（<a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>フィールドを参照）',
        },
        textBox: {
          xPadding:
            '水平パディングは正の数である必要があります（<a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>フィールドを参照）',
          yPadding:
            '垂直パディングは正の数である必要があります（<a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>フィールドを参照）',
          opacity: {
            min: '不透明度は0%以上である必要があります（<a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>フィールドを参照）',
            max: '不透明度は100%以下である必要があります（<a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>フィールドを参照）',
          },
        },
        anchor:
          'アンカーポイントはプレビュー内にある必要があります（<a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>フィールドを参照）',
        zoom: {
          autoZoomMinCoef: {
            min: '最小ズーム係数は1以上である必要があります（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>フィールドを参照）',
            max: '最小ズーム係数は2未満である必要があります（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>フィールドを参照）',
          },
          autoZoomMaxCoef: {
            min: '最大ズーム係数は1以上である必要があります（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>フィールドを参照）',
            max: '最大ズーム係数は2未満である必要があります（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>フィールドを参照）',
          },
          minZoomTime: '最小ズーム時間は0秒以上である必要があります',
          maxZoomTime: '最大ズーム時間は0秒以上である必要があります',
          dynamicZoomPercentage: {
            min: 'ズームパーセンテージは0%以上である必要があります',
            max: 'ズームパーセンテージは100%以下である必要があります',
          },
          coef: '最大ズーム係数は最小ズーム係数より小さくすることはできません（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>フィールドを参照）',
          time: '最大ズーム時間は最小ズーム時間より小さくすることはできません',
          dynamicMinZoomIntensity:
            'ダイナミックズームの強度は0%から100%の間である必要があります（<a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>フィールドを参照）',
          smoothZoomPercentage:
            'スムーズズームのパーセンテージは0%から100%の間である必要があります（<a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>フィールドを参照）',
          nervousness:
            '神経質さは0%から100%の間である必要があります（<a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>フィールドを参照）',
          totalZoomPercentage:
            '総ズームカバレッジは0%より大きく100%以下である必要があります（<a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>フィールドを参照）',
          types: {
            at_least: {
              one: {
                true: '少なくとも1つのズームタイプを選択してください（<a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>フィールドを参照）',
              },
            },
          },
        },
        repeat: {
          algorithm: '選択されたアルゴリズムは有効ではありません',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: '支払い不足',
          button: {
            recover: 'サブスクリプションの回復',
          },
          to_continue:
            'お支払いの問題により現在のサブスクリプションは<b>{status}</b>されました。AutoCutの使用を続けるには、次の手順を行ってください:',
          deactivated: '停止',
          deleted: '削除',
          recover: {
            list: {
              click:
                '<b>下のボタンをクリック</b>してサブスクリプションを回復するか、私たちまたはstripeからの最新の請求書を支払うように求めるメールがメールで届いているかを確認してください',
              re_activated:
                'お支払い更新後、キーは<b>すぐに再アクティベート</b>されます',
            },
          },
          new: {
            list: {
              website:
                'AutoCutのウェブサイトを訪れてください: <span>https://www.autocut.fr/#pricing</span>',
              subscribe: '新しいライセンスキーへのサブスクライブ',
            },
          },
          support:
            'サポートチームに連絡する必要がある場合: <span>contact@autocut.fr</span>',
          thanks: 'ありがとうございます！',
        },
        moda: {
          button: {
            new: '新しいサブスクリプションを取得',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'AutoCutアドベンチャーを始めよう',
          header: {
            days: {
              left: 'AutoCutの14日間無料トライアルで創造の旅が始まります。{completedElements}日分をアンロックし、<b>{daysLeft}日間</b>の探索を残しています！',
            },
            more: {
              days: 'しかしなぜそこで止まるのでしょうか？<b>{bonusDays}日分の追加</b>とAutoCutの全パワーを解き放つためのクエストに乗り出しましょう。',
            },
          },
          timeline: {
            left: '14日間の無料トライアル',
            right: '24日間の無料トライアル',
          },
          task: {
            list: {
              title: 'タスク完了：{completed}/{maximum}',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'AutoCutを再読み込みする必要があります',
          cta: 'AutoCutを再読み込み',
          text: 'AutoCutが最後に開かれてから30分以上経過しました。{br}最適なパフォーマンスのために、今すぐAutoCutを再読み込みすることをおすすめします。{br}AutoCutを再読み込みするには、以下のボタンをクリックしてください',
        },
      },
    },
    'key-already-activated':
      'このキーはすでに別のコンピューターでアクティブ化されています。再試行する前に、AutoCutダッシュボード (https://www.autocut.fr/jp/) でキーを解除してください。',
    'key-does-not-exist':
      'このキーは存在しません。キーを確認してもう一度お試しください。',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'サポートされていないDaVinci Resolveバージョン',
            '24.4.0':
              'DaVinci Resolveを24.3にダウングレードするか、24.5にアップグレードしてください',
          },
          cta: '了解しました',
          text: {
            __text__:
              '現在サポートされていないバージョンのDaVinci Resolve ({version})を使用しています。<b>必ず</b>このバージョンではAutoCutを十分に利用することはできません。{br}{br}AutoCutを最適な状態で使用するために、<b>最新の安定版リリース</b>を使用してください。',
            '24.4.0':
              '現在、サポートされていないバージョンのDaVinci Resolve({version})を使用しています。このバージョンでは重大な変更がありました。AutoCutを使用するには、24.3にダウングレードするか、24.5にアップグレードしてください。',
          },
        },
      },
      rendering: {
        engine: {
          title: 'サポートされていないレンダリングエンジン',
          modal: {
            text: '現在、サポートされていないレンダリングエンジンを使用しています。AutoCaptionsやAutoZoomは<b>使用できません</b>。{br}{br}プロジェクト設定でGPUアクセラレーションレンダリングエンジンを選択してください。',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'ハードリセットが必要です',
          cta: 'すべての拡張機能を閉じる',
          text: '複数のAutoCutインスタンスが実行されていることが検出されました。問題を回避するために、すべてを閉じるボタンをクリックしてください。',
        },
      },
    },
    disabled: {
      features: {
        info: '次の機能をサポートしていない言語/フォントの組み合わせを選択しました：<b>{features}</b> これらの機能はインポートされた字幕で無効になります。',
        tips: '選択したフォントがサポートしている文字に関連している可能性があります。これらの機能を有効にするには、フォントまたは言語を変更してください。',
      },
    },
    upgrade: {
      to: '{plan}へのアップグレード',
    },
    deepgram: {
      error: {
        modal: {
          title: '翻訳エラーが発生しました',
          cta: 'Discordに参加する',
          text: '音声の翻訳中にエラーが発生しました。これは音声の品質や話されている言語に関連している可能性がありますが、現時点では詳細な情報はありません。{br} Discordで問題を報告するか、contact@autocut.frまでメールでお問い合わせください。',
        },
      },
    },
    completing: {
      preset: {
        preview:
          'プリセットプレビューを完了しています... 数秒かかる場合があります',
      },
    },
    chapters: {
      preview: {
        error:
          'プレビューの生成中にエラーが発生しました。タイムスタンプを更新するか、問題が解決しない場合はAutoCutを再起動してください。',
      },
      step: {
        language: 'クリップと言語を選択',
        editor: '章のエディタ',
      },
      button: {
        chapters: '章を追加',
      },
      editor: {
        chapters: {
          title: '検出された章',
        },
        parameters: {
          title: '章のパラメータ',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'YouTube章ファイルをダウンロード',
                success: 'chapter.txtがPPROプロジェクトに保存されました',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'タイムライン上で章をベースにするクリップを選択してください',
            text1:
              '章を使用するには、タイムラインにオーディオを含む少なくとも1つのクリップを選択する必要があります。',
          },
        },
      },
      process: {
        transcript: {
          button: '章を生成',
        },
      },
      adding: {
        chapters: '章を追加中...',
      },
      backup: 'シーケンスをバックアップ中...',
      analyse: 'オーディオを分析中...',
      getting: {
        chapters: '章を生成中...',
      },
      transcript: {
        finished: '章が生成されました',
      },
      disabled: {
        language: '選択された言語はまだAutoChaptersでサポートされていません',
        file: {
          too: {
            long: '選択されたクリップが長すぎます。{limit}分以内のクリップを選択してください。',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format:
              '時間の形式が間違っています。HH:MM:SS形式を使用してください。',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'アニメーション',
        },
        static: {
          option: '静止した',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                '自動ウイルスクリップ検出器の基になるタイムライン上のクリップを選択してください',
              text1:
                '自動ウイルスクリップ検出器を使用するには、少なくとも1つのオーディオ付きクリップをタイムラインから選択する必要があります。',
            },
          },
        },
        disabled: {
          language:
            'この言語では自動ウイルスクリップ検出器はまだ利用できません',
          file: {
            too: {
              long: '選択されたクリップが長すぎます。{limit}分以内のクリップを選択してください。',
            },
          },
        },
        process: {
          transcript: {
            button: 'ウイルスクリップを生成する',
          },
        },
        button: {
          process: 'クリップを作成する',
        },
        selector: {
          title: '{number}件の潜在的なバイラルクリップを検出しました',
        },
        virality: {
          score: 'ウイルスの潜在スコア',
        },
        step: {
          language: 'クリップと言語を選択する',
          selector: 'ウイルスクリップを選択する',
        },
        backup: 'シーケンスをバックアップしています...',
        analyse: 'オーディオを解析しています...',
        getting: {
          clips: 'ウイルスクリップを生成しています...',
        },
        transcript: {
          finished: 'ウイルスクリップが生成されました',
        },
        adding: {
          creating: {
            clips: 'クリップを作成しています...',
          },
        },
      },
      score: 'バイラルスコア',
    },
    mode: {
      description: {
        silence: 'タイムラインから無音を削除します',
        ai: 'タイムラインから無音を削除します',
        podcast: 'ポッドキャストを即座に編集します',
        repeat: 'AIで悪いテイクを削除します',
        zoom: '自動的にズームを追加します',
        captions: 'AIでアニメーション付きの字幕を追加します',
        resize: 'AIでシーケンスのサイズを変更します',
        broll: 'AIでB-Rollを自動的に追加します',
        profanity: '卑語を消音します',
        chapter: '章を検出し、AIでマーカーを追加します',
        viral: 'ワンクリックで長いビデオの中のバイラルショートを特定する',
      },
    },
    formatted: {
      numbers: {
        title: '数字が書式設定されました',
        modal: {
          cta: 'モーダルを閉じる',
          text: '転写内の<b>{number}単語</b>が数字に置き換えられました。',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: '転写モデルを選択',
          model: {
            '1': 'デフォルト',
            '2': 'モデル2',
          },
        },
      },
    },
  },
} as const;

export default translations;
