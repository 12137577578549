import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, {useState} from 'react';
import {IoSearchOutline} from 'react-icons/io5';

import {CaptionsPreset} from '@autocut/types/CaptionsParameters';
import {setAutocutStore} from '@autocut/utils/zustand';
import {PresetPreview} from '../PresetPreview';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';

import {PresetsGrid} from './PresetsGrid';
import {PresetSearchInput} from './PresetsSearchInput';
import {CURRENT_ENV} from '@autocut/utils/currentEnv.utils';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {AUTOCUT_CONSTANTS} from '@autocut/constants/configs';
import {handleApplyPreset} from '@autocut/pages/modes/captions/utils/captionsPresets/applyPresets';
import {copyPresetFromPresetId} from '@autocut/pages/modes/captions/utils/captionsPresets/copyPresetFromPresetId';
import {InstantSearch} from 'react-instantsearch';
import {searchClient} from '@autocut/utils/algolia.util';

export const SearchPublicPresetModal = () => {
  const [selectedPreset, setSelectedPreset] = useState<
    (CaptionsPreset & {objectID: string}) | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<React.ReactNode | undefined>(undefined);

  const handleImportPreset = async () => {
    if (selectedPreset === null) return;
    setIsLoading(true);

    await copyPresetFromPresetId(selectedPreset?.id).then(newPresets => {
      if (newPresets === null) {
        setError(
          <TranslatedMessage
            id={'modes_captions_steps_customization_parts_presets_search_error'}
            defaultMessage="Error while copying the preset, please contact us on discord or at contact@autocut.fr"
          />,
        );
        return;
      }

      handleApplyPreset(selectedPreset);

      setAutocutStore('ui.parameters.caption.userPresets', newPresets);

      setAutocutStore('ui.openedModalName', '');

      setIsLoading(false);
    });
  };

  return (
    <Modal
      icon={<IoSearchOutline size="1.5rem" />}
      title={
        <TranslatedMessage
          id={'modes_captions_steps_customization_parts_presets_search_title'}
          defaultMessage="Community presets"
        />
      }
      closeModalFunction={() => setAutocutStore('ui.openedModalName', '')}
      fullScreen
      footer={
        <FlexContainer
          flexDirection="column"
          justifyContent="center"
          style={{
            width: '100%',
            paddingTop: '24px',
          }}
          gap={32}
        >
          <FlexContainer
            justifyContent="center"
            alignItems="center"
            style={{
              height: '40px',
              fontSize: '20px',
            }}
          >
            <PresetPreview selectedPreset={selectedPreset} />
          </FlexContainer>

          <FlexContainer
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="nowrap"
            gap={128}
            style={{
              padding: '0 16px',
            }}
          >
            <Button
              onClick={() => setAutocutStore('ui.openedModalName', '')}
              variant="secondary"
              disabled={isLoading}
            >
              <TranslatedMessage
                id={
                  'modes_captions_steps_customization_parts_presets_search_cancel'
                }
                defaultMessage="Cancel"
              />
            </Button>

            <Button
              onClick={handleImportPreset}
              color={colors.primary600}
              disabled={selectedPreset === null || isLoading}
            >
              <TranslatedMessage
                id={
                  'modes_captions_steps_customization_parts_presets_search_confirm'
                }
                defaultMessage="Import preset"
              />
            </Button>
          </FlexContainer>
        </FlexContainer>
      }
      shouldScroll={false}
    >
      <FlexContainer
        flexDirection="column"
        gap={16}
        style={{
          width: '100%',
          height: '100%',

          overflow: 'hidden',
        }}
      >
        <InstantSearch
          searchClient={searchClient}
          indexName={
            AUTOCUT_CONSTANTS[CURRENT_ENV].ALGOLIA_PUBLIC_CAPTIONS_PRESETS_INDEX
          }
        >
          <PresetSearchInput />

          <FlexContainer
            style={{
              width: '100%',
              height: '100%',

              overflowY: 'auto',

              paddingInline: '16px',
            }}
          >
            <PresetsGrid
              onSelect={setSelectedPreset}
              selectedPreset={selectedPreset}
            />
          </FlexContainer>
        </InstantSearch>

        {error}
      </FlexContainer>
    </Modal>
  );
};
