const translations = {
  progress: {
    footer: {
      warning:
        'Por favor, não faça nada no DaVinci, isso causará alguns problemas',
    },
    steps: {
      global: {
        starting: 'Iniciando...',
        processing: 'Processando...',
        ending: 'Finalizando...',
      },
      dev: {
        ping: 'Pinging DaVinci Resolve...',
        pong: 'Ponging DaVinci Resolve...',
      },
      common: {
        getSilences: 'Obtendo silêncios...',
        getAudioInfos: 'Obtendo informações de áudio...',
        readAudioFile: 'Lendo arquivo de áudio...',
        computeRMS: 'Analisando arquivo de áudio...',
        convertRMSToDB: 'Analisando arquivo de áudio...',
        exportDrt: 'Lendo linha do tempo...',
        extractDrt: 'Lendo linha do tempo...',
        getXmlTimelineFolderPath: 'Lendo linha do tempo...',
        getXmlTimelineFullpath: 'Lendo linha do tempo...',
        loadXmlTimeline: 'Lendo linha do tempo...',
        writeFile: 'Escrevendo arquivo...',
        writeXml: 'Importando linha do tempo modificada...',
        updateDrt: 'Importando linha do tempo modificada...',
        importXml: 'Importando linha do tempo modificada...',
      },
      podcast: {
        mainProcess: 'Edição de podcast...',
        getVideoTracksWeight: 'Gravação dos falantes',
        getAudioTracks: 'Gravação das faixas de áudio',
        getPodcastInterval: 'Calculando a duração do podcast',
        getPodcastDuration: 'Calculando a duração do podcast',
        renders:
          'Renderização das faixas de áudio pelo Da Vinci\n(pode levar alguns minutos)',
        getTalkingTimelines: 'Distribuição dos tempos de fala',
        getTransitions: 'Calculando mudanças de câmera',
        getCutTimecodes: 'Preparando os cortes',
        cutAtTimecodes: 'Cortes da linha do tempo',
      },
      silences: {
        mainProcess: 'Edição de silêncios...',
        getTimelineInfos: 'Obtendo informações da linha do tempo...',
        renders:
          'Renderização das faixas de áudio pelo Da Vinci\n(pode levar alguns minutos)',
        convertAudioToMono: 'Processando arquivo de áudio...',
        getCutTimecodes: 'Preparando os cortes...',
        cutAtTimecodes: 'Cortes da linha do tempo...',
        deleteSilentTrackItems: 'Deletando silêncios...',
      },
      zoom: {
        mainProcess: 'Adicionando Zooms...',
        getTimelineInfos: 'Obtendo informações da linha do tempo...',
        getWavData:
          'Renderização das faixas de áudio pelo Da Vinci\n(pode levar alguns minutos)',
        computeBestZoomIntervals:
          'Computando os melhores intervalos de zoom...',
        createVideoTrack: 'Criando faixas de vídeo...',
        createZoomFusionComps: 'Importando composições Fusion...',
      },
      captions: {
        transcript: {
          mainProcess: 'Gerando transcrição...',
          get_language: 'Obter idioma',
          render_wav:
            'Renderizando WAVs no DaVinci (pode levar vários minutos)',
          split_chunks: 'Dividindo chunks',
          uploadFile: 'Fazendo upload do arquivo...',
          getTranscript:
            'Geração da transcrição pela IA\n(pode levar alguns minutos)',
          writeFile: 'Escrevendo arquivo...',
        },
        generation: {
          mainProcess: 'Adicionando legendas...',
          initialization: 'Inicializando legendas...',
          readTranscript: 'Lendo transcrição...',
          getTimelineInfos: 'Obtendo informações da linha do tempo...',
          appendAdjustmentLayer: 'Anexando camada de ajuste...',
          importFusionComp: 'Importando composições Fusion...',
        },
      },
    },
  },
  launchManager: {
    steps: {
      preload: {
        title: 'Pré-carregar',
      },
      python: {
        title: 'Instalar Python',
      },
      ffmpeg: {
        title: 'Instalar FFMPEG',
      },
      resources: {
        title: 'Instalar recursos',
      },
      computeIPC: {
        title: 'Conectar à IA',
      },
      daVinciIPC: {
        title: 'Conectar ao DaVinci Resolve',
      },
    },
    errors: {
      compute:
        'Não é possível se conectar à IA de computação, entre em contato com o suporte',
      davinci:
        'Não é possível se conectar ao DaVinci, por favor, certifique-se de que o DaVinci Resolve está em execução e que o script AutoCut foi executado',
      cta: 'Tentar novamente',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Teste',
        upgrade: 'Atualizar',
        coming_soon: 'Em breve',
        version_not_supported: 'Versão PPro não suportada',
        upgrade_to: 'Atualizar para {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title:
            'Selecione uma linha do tempo com pelo menos 2 trilhas de vídeo e áudio',
          label: 'Selecionar linha do tempo',
          descriptions: {
            '1': 'Vá para a aba de edição',
            '2': 'Navegue até a linha do tempo que deseja editar',
          },
          cta: 'Selecionar linha do tempo ativa',
        },
        customization: {
          label: 'Parâmetros do podcast',
          steps: {
            speakers: {
              title: 'Palestrantes',
              description:
                'Adicione seus palestrantes atribuindo a cada um uma trilha de áudio e inserindo seus respectivos nomes. Apenas uma trilha de áudio pode ser selecionada por palestrante. Se múltiplos palestrantes compartilham uma única trilha de áudio, insira ambos os nomes (por exemplo, "Alice & Bob").',
              header: {
                audio: 'Trilha de áudio',
                name: 'Nome do palestrante',
              },
              textInputPlaceholder: 'Insira o nome do palestrante...',
              trackTemplate: 'Trilha A{index}',
              cta: 'Adicionar um palestrante',
            },
            cameras: {
              title: 'Câmeras',
              description:
                'Adicione suas câmeras atribuindo a cada uma uma trilha de vídeo e selecionando quais palestrantes estão visíveis na tela. Apenas uma trilha de vídeo pode ser selecionada por câmera. Você pode selecionar qualquer número de palestrantes por câmera.',
              header: {
                audio: 'Trilha de vídeo',
                name: 'Palestrante(s)',
              },
              speakerSelectPlaceholder: 'Selecionar palestrante(s)...',
              trackTemplate: 'Trilha V{index}',
              cta: 'Adicionar uma câmera',
            },
            cameraTime: {
              title: 'Durações de tempo da câmera',
              description:
                'Defina a duração mínima e máxima que uma câmera será exibida antes de mudar.',
              presets: {
                title: 'Predefinições padrão',
                items: {
                  calm: 'Calmo',
                  paced: 'Rítmico',
                  energetic: 'Energetico',
                  hyperactive: 'Hiperativo',
                },
              },
              minimumCamTime: {
                title: 'Tempo mínimo da câmera',
                description:
                  'Duração mínima (em segundos) que uma câmera será exibida sem mudar.',
                unit: 'segundos',
              },
              maximumCamTime: {
                title: 'Tempo máximo da câmera',
                description:
                  'Duração máxima (em segundos) que uma câmera será exibida antes de ser forçada a mudar.',
                unit: 'segundos',
              },
            },
            silentVideoClips: {
              title: 'Clipes de vídeo silenciosos',
              description:
                'Escolha se deseja remover ou desabilitar as partes não utilizadas do podcast.',
              items: {
                remove: 'Remover',
                disable: 'Desabilitar',
              },
            },
          },
          cta: {
            disable: 'Editar e desabilitar clipes não utilizados',
            delete: 'Editar e excluir clipes não utilizados',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: 'O tempo mínimo de câmera deve ser no mínimo 0',
            higherThanMax:
              'O tempo mínimo de câmera deve ser menor que o tempo máximo de câmera',
          },
          maximumCamTime: {
            min: 'O tempo máximo de câmera deve ser no mínimo 0',
          },
          deleteUnusedClips: {
            required:
              'Você deve escolher excluir ou desativar clipes não utilizados',
          },
          speakers: {
            videoTracks: {
              min: 'Você deve selecionar pelo menos uma faixa de vídeo por orador',
            },
            audioTrack: {
              min: 'Você deve selecionar uma faixa de áudio para cada orador',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'Nível de ruído',
          description:
            'Nível de som considerado como silêncio. Você pode ver os efeitos na visualização abaixo.',
        },
        silenceSize: {
          title: 'Duração do silêncio',
          items: {
            silence: {
              title: 'Remover silêncios mais longos que',
              description:
                'Duração mínima (em milissegundos) que um silêncio deve ter para ser removido.',
              unit: 'milissegundos',
            },
            talk: {
              title: 'Remover falas mais curtas que',
              description:
                'Duração mínima (em milissegundos) que um segmento de fala deve ter para ser mantido.',
              unit: 'milissegundos',
            },
          },
        },
        paddingSize: {
          title: 'Preenchimento',
          items: {
            before: {
              title: 'Preenchimento antes',
              description:
                'Tempo de silêncio antes de um ruído - para evitar recomeço repentino da fala',
              unit: 'milissegundos',
            },
            after: {
              title: 'Preenchimento depois',
              description:
                'Tempo de silêncio após um ruído - para evitar interrupções bruscas na fala',
              unit: 'milissegundos',
            },
          },
        },
        transition: {
          params: {
            title: 'Transições',
            warning: 'Você não pode usar transições sem excluir silêncios.',
            default: {
              title: 'Padrão',
              choices: {
                none: 'Nenhum',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'Isto adicionará o amortecimento depois e/ou antes de cada corte. Criando uma transição mais suave entre os cortes. Se selecionado, criará uma nova faixa para as transições.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Ambos',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Potência Constante',
              },
            },
          },
          progress: {
            insert: 'Inserindo novas faixas de áudio…',
            move: 'Movendo áudio para novas faixas para transições…',
            handling: 'Adicionando transições: {transition}',
          },
        },
        preview: {
          title: 'Pré-visualização',
          description:
            'Pré-visualização dos segmentos preservados e removidos. Use as setas para avançar ou retroceder, ou centralize a pré-visualização com base na posição do cursor de tempo.',
          legend: {
            kept: 'Mantido',
            deleted: 'Deletado',
            margin: 'Margens',
          },
          cta: 'Gerar a pré-visualização',
          center: 'Centralizar no indicador',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'O nível de ruído deve ser um número negativo',
            int: 'O nível de ruído deve ser um número',
          },
          minTimeOfSilence: {
            required: 'A duração mínima do silêncio é obrigatória',
            min: 'A duração mínima do silêncio deve ser no mínimo 0',
          },
          minTimeOfTalk: {
            required: 'A duração mínima da fala é obrigatória',
            min: 'A duração mínima da fala deve ser no mínimo 0',
          },
          marginBefore: {
            min: 'A margem antes deve ser no mínimo 0',
          },
          marginAfter: {
            min: 'A margem depois deve ser no mínimo 0',
          },
        },
      },
    },
    zoom: {
      parameters: {
        preview: {
          title: 'Prévia do Zoom',
          description: 'Defina o ponto de ancoragem',
          maximumZoom: 'Zoom máximo',
          errorMessage: 'Ocorreu um erro ao carregar a prévia',
        },
        zoomTypes: 'Tipos de Zoom',
        defaultsPresets: {
          title: 'Predefinições padrão',
          calm: 'Calma',
          paced: 'Ritmado',
          energetic: 'Enérgico',
          hyperactive: 'Hiperativo',
        },
        zoomCoverage: 'Cobertura total do zoom',
        constraint: {
          title: 'Restringir zooms a clipes',
          helper:
            'Se sim, nenhum zoom abrangerá mais de um clipe. Se não, sua seleção inteira será processada como um único arquivo de áudio e os zooms ignorarão os clipes individuais na linha do tempo.',
        },
        maximumZoomDuration: 'Duração máxima do zoom',
        smoothZooms: {
          title: 'Zooms suaves',
          coverage: 'Cobertura de zooms suaves',
          nervousness: 'Nervosismo',
        },
        dynamicZooms: {
          title: 'Zooms dinâmicos',
          coverage: 'Cobertura de zooms dinâmicos',
        },
      },
      types: {
        smooth: 'Suave',
        dynamic: 'Dinâmico',
      },
      footerButton: 'Zoom',
      infoText:
        'AutoZoom adicionará automaticamente zoom a cada clipe selecionado com base em decibéis.',
    },
    captions: {
      steps: {
        language: {
          button: 'Ir para o editor de legendas',
        },
        customization: {
          button: 'Adicionar legendas',
          parts: {
            style: {
              title: 'Estilo das legendas',
              modal: {
                title: 'Editar estilo',
                apply: 'Aplicar estilo',
                save: 'Aplicar estilo e salvar como predefinição',
                text: {
                  title: 'Fonte',
                  font: 'Nome da fonte',
                  help: 'Como adicionar uma nova família de fontes?',
                  color: 'Cor',
                },
                animation: {
                  title: 'Animação',
                  wordBox: 'Caixa com texto',
                },
                outline: {
                  title: 'Contorno',
                  width: 'Espessura',
                },
                box: {
                  title: 'Caixa de texto',
                  paddingX: 'Preenchimento X',
                  paddingY: 'Preenchimento Y',
                  opacity: 'Opacidade',
                  radius: 'Raio',
                  disabled:
                    'A fonte escolhida não suporta o recurso de caixa para o idioma selecionado.',
                  tips: 'Escolha uma fonte que suporte o idioma selecionado se quiser usar este recurso.',
                },
              },
            },
            preview: {
              title: 'Pré-visualização',
              modal: {
                title: 'Posição e tamanho',
                apply: 'Aplicar estilo',
                fontSize: 'Tamanho da fonte',
                nbLines: 'Número de linhas',
                maxWidth: 'Largura máxima',
                help: 'A pré-visualização ainda está em desenvolvimento e pode não ser precisa.',
              },
            },
            transcription: {
              title: 'Editar transcrição',
              modal: {
                save: 'Salvar',
                select: 'Selecione as legendas que deseja editar',
                placeholder:
                  'Selecione a parte da transcrição que deseja editar/personalizar',
                formatNumbers: 'Formatar números',
                checkTranscript: {
                  title: 'Você verificou a transcrição?',
                  description:
                    'Notamos que você ainda não verificou a transcrição.',
                  dontShowAgain: 'Não mostrar esta mensagem novamente',
                },
              },
            },
            presets: {
              delete: {
                title: 'Excluir predefinições',
                cancel: 'Cancelar',
                confirm: 'Excluir predefinição',
              },
              search: {
                title: 'Predefinições da comunidade',
                input: 'Pesquisar predefinição',
                cancel: 'Cancelar',
                confirm: 'Importar predefinição',
                usage: 'Usado por {count} usuários',
                error:
                  'Erro ao copiar a predefinição, entre em contato conosco no discord ou em contact@autocut.fr',
              },
              create: {
                title: 'Criar nova predefinição',
                placeholder: 'Nome da predefinição (no mínimo duas palavras)',
                public: 'Compartilhar com a comunidade - público',
                cancel: 'Cancelar',
                confirm: 'Criar predefinição',
                alreadyExists:
                  'Esta predefinição já existe. Escolha outro nome.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'Os predefinições do usuário estão faltando, por favor, redefina suas configurações no menu do cabeçalho.',
          },
          position: {
            xPercentage: {
              min: 'A posição x do texto deve ser no mínimo 0',
              max: 'A posição x do texto deve ser no máximo 1',
            },
            yPercentage: {
              min: 'A posição y do texto deve ser no mínimo 0',
              max: 'A posição y do texto deve ser no máximo 1',
            },
            emojiXPercentage: {
              min: 'A posição x do emoji deve ser no mínimo 0',
              max: 'A posição x do emoji deve ser no máximo 1',
            },
            emojiYPercentage: {
              min: 'A posição y do emoji deve ser no mínimo 0',
              max: 'A posição y do emoji deve ser no máximo 1',
            },
          },
          text: {
            fontSize: {
              int: 'O tamanho da fonte deve ser um número',
              min: 'O tamanho da fonte deve ser no mínimo 1',
            },
            font: {
              missing:
                'Algo está faltando na fonte que você selecionou... Por favor, selecione-a novamente ou escolha outra.',
            },
          },
          outline: {
            width: {
              int: 'A largura do contorno deve ser um número',
              min: 'A largura do contorno deve ser no mínimo 0',
            },
          },
          formating: {
            nbLines: {
              int: 'O número de linhas deve ser um número',
              min: 'O número de linhas deve ser no mínimo 1',
            },
            maxWidth: {
              int: 'A largura máxima deve ser um número',
              min: 'A largura máxima deve ser no mínimo 0',
            },
          },
          textBox: {
            xPadding: {
              int: 'O preenchimento x deve ser um número',
              min: 'O preenchimento x deve ser no mínimo 0',
            },
            yPadding: {
              int: 'O preenchimento y deve ser um número',
              min: 'O preenchimento y deve ser no mínimo 0',
            },
            opacity: {
              int: 'A opacidade deve ser um número',
              min: 'A opacidade deve ser no mínimo 0',
              max: 'A opacidade deve ser no máximo 100',
            },
            radius: {
              int: 'O raio deve ser um número',
              min: 'O raio deve ser no mínimo 0',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'Definir pontos de entrada/saída',
          descriptions: {
            '1': 'Por favor, defina os pontos de entrada e saída no Davinci na parte da linha do tempo que você deseja cortar',
            '2': 'Você pode silenciar manualmente clipes ou faixas se não quiser que eles sejam incluídos na análise de áudio',
          },
          cta: 'Confirmar pontos de entrada/saída',
        },
        transcription: {
          title: 'Selecione o idioma do clipe de áudio',
        },
        customization: {
          title: 'Personalizar parâmetros',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Insira sua chave de licença para ativar o AutoCut.',
      placeholder: 'Insira sua chave de licença',
    },
    trial: {
      title: 'Obtenha uma avaliação gratuita',
      steps: {
        email: {
          title: 'Insira seu endereço de email',
          subtitle:
            'Insira seu endereço de email para receber uma licença de avaliação gratuita',
          placeholder: 'email@email.com',
          cta: 'Próximo',
        },
        sendingEmail: {
          title: 'Enviando o email',
          success: 'Email enviado com sucesso para {email}',
        },
      },
    },
    errors: {
      no_key: 'Por favor, insira uma chave de licença',
      is_key_valid_error:
        'Erro ao verificar a chave, por favor tente novamente mais tarde.\n',
      invalid_response: 'A resposta não vem do servidor.',
      cant_read_host_error: 'Não é possível ler o nome do computador.',
      cant_read_uuid_error: 'Não é possível ler o UUID do computador.',
    },
  },
  modals: {
    usageStats: {
      title: 'Estatísticas de uso',
      select: {
        label: 'Selecione um modo',
      },
      stats: {
        thisMonth: 'Este mês',
        allTime: 'Todo o tempo',
        mostUsed: 'Mais usado',
      },
    },
    endProcess: {
      title: 'Processo concluído',
      time: 'Processo concluído em {minutes} minutos e {seconds} segundos!',
      timeSaved:
        'Você economizou: <b>{minutesSaved}</b> minuto(s) e <b>{secondsSaved}</b> segundo(s)',
      timeSavedAllTime:
        'Desde que você usa o AutoCut, você economizou: {hourSaved} hora(s) e {minutesSaved} minuto(s)! 🎉',
      feedback:
        'Sinta-se à vontade para nos dar feedback sobre a qualidade do resultado:',
      review: 'Avalie o AutoCut!',
      captions: {
        title: 'Legendas adicionadas!',
        footer:
          'Se o estilo ou a transcrição das suas legendas não estiverem corretos, você pode voltar ao editor de legendas.',
        button: 'Voltar ao editor de transcrição',
        text_1: 'Suas legendas animadas foram adicionadas! 🎉',
        text_2: 'Se você precisar modificar as legendas:',
        list_1:
          'Coloque o cursor da linha do tempo nas legendas que você deseja atualizar',
        list_2: 'Vá para a página de fusão',
        list_3: 'Clique no nó TextPlus e atualize manualmente as legendas',
        text_3: 'Se você quiser mais dicas, pode visitar este <a>tutorial</a>.',
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Versão do DaVinci Resolve não suportada',
      currentVersion:
        'Detectamos que você está usando o DaVinci Resolve {version}',
      text: 'O AutoCut é compatível apenas com o DaVinci Resolve 18.6 ou superior.',
      update: 'Você pode atualizá-lo aqui: <a>Site da DaVinci</a>',
    },
  },
  globals: {
    defaults: {
      components: {
        KebabMenu: {
          account: 'Gerenciar chave de licença',
          bug: 'Relatar um bug',
          review: 'Avaliar AutoCut',
          tuto: 'Tutorial em vídeo',
          logout: 'Sair',
          settings: 'Redefinir configurações',
          featureRequest: 'Solicitação de recurso',
          academy: 'Ajuda',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} dias restantes',
          info: 'Licença {licenceType}',
          trialButton: 'Obter mais',
          paidButton: 'Gerenciar',
        },
        PromoBanner: {
          promo: 'Até 20% de desconto nas próximas 24 horas',
          shop: 'Compre agora',
        },
        FileInput: {
          upload_screenshot: 'Carregue sua captura de tela',
        },
        LinkToUserFileInput: {
          send_log_link_to_user_file: {
            title:
              'Por favor, forneça um link para seu arquivo de vídeo e áudio.',
            description:
              '(WeTransfer, Google Drive ou qualquer outro serviço de compartilhamento de arquivos)',
            helper:
              'Para enviar seu arquivo no WeTransfer, vá para wetransfer.com e clique em "Enviar arquivos". Depois disso, você receberá um link, copie-o aqui.',
            end: 'Isso nos ajudará a resolver seu problema rapidamente.',
          },
        },
        Select: {
          'no-more': 'Nenhuma opção disponível',
          'select-all': 'Selecionar tudo',
          placeholder: 'Selecione uma opção',
        },
        PreviewFrame: {
          previewOnIndicator: 'Prévia no indicador',
        },
      },
    },
    misc: {
      retry: 'Tentar novamente',
    },
    units: {
      second: 'segundos',
      millisecond: 'milissegundos',
      percentage: '%',
      px: 'px',
    },
  },
  old: {
    text: {
      errorSelection:
        'Seleção inválida.{br}Por favor, selecione faixas diretamente de um arquivo de áudio ou vídeo.{br}Se uma sequência for selecionada, divida os vídeos dentro da sequência.',
      loading: 'Cortando...',
      transition: {
        step: {
          title: 'Transições',
          j_l_cut: 'Corte J e Corte L',
          default: 'Predefinição',
          premiere: {
            pro: 'DaVinci Resolve',
          },
          none: 'Nenhum',
          j_cut: 'Corte J',
          l_cut: 'Corte L',
          both: 'Ambos',
        },
        constant: {
          power: 'Potência constante',
        },
        progress: {
          insert: 'Inserindo novas faixas de áudio...',
          move: 'Movendo áudio para novas faixas para transições…',
          handling: 'Adicionando transições: {transition}',
        },
      },
      Step4: {
        AutoZoom: {
          ratio: 'Coeficiente máximo de zoom',
          step: 'Porcentagem de vídeo com zoom',
        },
        Title: 'AutoZoom (Beta)',
      },
      Step5: {
        Backup: 'Ativar backup',
        Title: 'Pré-visualização',
      },
      autocutv2:
        'AutoCut v2 é a versão especialista com muitas opções configuráveis para detecção ótima de silêncios.',
      autocutAi:
        'AutoCut AI detecta automaticamente silêncios com apenas um parâmetro, deixando a AI cuidar do restante.',
      autocutPodcast:
        'AutoCut Podcast permite editar vídeos de podcasts em questão de segundos. Simplesmente forneça a configuração da câmera e aproveite seu vídeo final recém editado.',
      autocutRepeat:
        'Relaxe e deixe o AutoCut Repeat remover todas as tomadas ruins do seu vídeo e deixar apenas as melhores.',
      autocutCaptions:
        'Gere legendas no estilo tiktok para seus vídeos de forma rápida.',
      Step3: {
        Remove: 'Remover',
        Keep: 'Manter',
        CenterButton: 'Centralizar no indicador',
        Previsualisation: {
          __text__: 'Pré-visualização:',
          loading: 'carregando...',
        },
        Noise: {
          unit: 'decibéis',
        },
        DeleteSilence: 'Remover silêncios',
        KeepSilence: {
          Yes: 'SIM',
          No: 'NÃO',
        },
        Title: 'Ajustar nível de ruído',
      },
      Step2: {
        After: 'Preenchimento depois',
        Before: 'Preenchimento antes',
        Title: 'Preenchimento',
      },
      Step2Ai: {
        Title: 'Verificar pré-visualização',
      },
      Step1: {
        Talks: 'Remover falas menores que',
        Silences: 'Remover silêncios maiores que',
        Title: 'Durações de silêncio',
      },
      Step1Ai: {
        Silences: 'Escolha a agressividade da AI',
        Title: 'Agressividade da AI',
      },
      Step1Podcast: {
        Title: 'Palestrantes',
      },
      step6: {
        selected: {
          clips: 'Clipes selecionados',
        },
      },
      NoInternet: 'Conexão à internet é necessária.',
      noClip: {
        title: 'Selecione clipe(s) para cortar na sua linha do tempo',
        no_selection:
          'Se o AutoCut não responder, tente reiniciar o DaVinci Resolve.',
        tuto: {
          __text__: 'Precisa de ajuda? Fique à vontade para assistir o nosso',
          link: ' tutorial em vídeo',
        },
        loading: {
          bar: 'Carregando - Análise da sequência em andamento...',
        },
      },
      needPProUpdate:
        'Sua versão do DaVinci Resolve deve ser a 15 ou mais recente.',
      No_Key: 'Por favor, insira uma chave de licença',
      Login: {
        SubmitButton: 'Ativar',
        SubmitButton2: 'Ativar',
        PasteButton: 'Colar',
      },
      Welcome: {
        Title: 'Bem-vindo',
        freeTrial: 'Obter um teste gratuito',
        UseLicence: 'Usar uma chave de licença',
        BuyLicence: 'Comprar uma chave de licença',
      },
      UseLicence: {
        Title: 'Usar uma chave de licença',
        Text: 'Insira sua chave de licença e comece a usar o AutoCut!',
      },
      GetLicence: {
        Title: 'Receba uma licença de teste gratuito por email',
        Select: 'Onde você descobriu o Autocut?',
        Text: 'Insira seu endereço de email e receba uma licença de teste gratuita',
        Button: 'Obter uma licença gratuita',
      },
      UseNewLicence: {
        Title: 'Sua chave de licença foi enviada para {s}!',
        Text: 'Confira seu email, insira sua chave de licença e comece a usar o AutoCut! Se você não receber a chave nos próximos minutos, verifique sua caixa de spam.',
        Resend: 'Não recebeu o email?',
        ResendButton: 'Reenviar a chave para {mail}',
        ResendSucces: 'Email enviado!',
      },
      NoFFMPEG:
        'Houve um problema com o FFMPEG, por favor entre em contato com o suporte',
      legend: {
        kept: 'Mantidos',
        deleted: 'Deletados',
      },
      yes: 'Sim',
      no: 'Não',
      undo: {
        modal: 'Não está satisfeito com os cortes?',
      },
      since: {
        your: {
          last: {
            visit: 'Desde sua última visita',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted:
              'Pedimos desculpa, mas parece que o arquivo {filename} tem um formato não suportado...',
            speed:
              'Pedimos desculpa, mas AutoCut não suporta cortar clipes com velocidades alteradas...',
            multi: {
              audio:
                'Pedimos desculpa, mas AutoCut pode não funcionar corretamente se todos os rastros de áudio em um arquivo não forem selecionados.',
              stream:
                'Pedimos desculpa, mas parece que um dos seus arquivos selecionados tem múltiplos fluxos de áudio...',
            },
            '51_stereo':
              'Pedimos desculpa, mas parece que um dos seus arquivos selecionados tem um layout de áudio 5.1 exibido em estéreo pelo DaVinci Resolve...',
            audio: {
              layout:
                'Lamentamos, mas parece que um dos seus arquivos selecionados tem um layout de áudio não suportado...',
            },
            repeat: {
              multi: {
                audio:
                  'Lamentamos, mas o Autocut Repeat atualmente não suporta múltiplos clipes de áudio.',
              },
              no_audio:
                'Você deve selecionar um clipe de áudio para usar o Autocut Repeat.',
            },
            remote: {
              file: 'Pedimos desculpa, mas parece que não conseguimos acessar o arquivo {filename}...',
            },
          },
        },
        maintained: {
          modal:
            'Por favor, atualize para uma versão mais recente do DaVinci Resolve para usar o AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: 'Por favor, selecione clipes diferentes para cortar.',
              speed:
                'Por favor, redefina as velocidades dos clipes (para 100%) ou selecione clipes diferentes para cortar.',
              multi: {
                audio:
                  'Certifique-se de que todos os canais de áudio estejam selecionados e permaneçam na ordem padrão do DaVinci Resolve.',
                stream: 'Por favor, selecione clipes diferentes para cortar.',
              },
              '51_stereo':
                'Por favor, selecione clipes diferentes ou mude o layout do canal da sua sequência para cortar.',
              audio: {
                layout:
                  'Por favor, selecione outros clipes para poder cortá-los, se o problema persistir, por favor nos envie uma captura de tela da sua sequência.',
              },
              repeat: {
                multi: {
                  audio:
                    'Por favor, selecione apenas um clipe de áudio para cortar.',
                },
              },
              remote: {
                file: 'Por favor, certifique-se de que os arquivos estejam disponíveis no seu computador e não na nuvem.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'Insira sua chave de licença e comece a usar o AutoCut!',
        },
      },
      no_audios:
        'Este áudio será usado para detectar, cortar e remover silêncios.',
      step1: {
        podcast: {
          title: 'Durações de câmera',
          minimum: 'Tempo mínimo de câmera',
          maximum: 'Tempo máximo de câmera',
        },
      },
      silences: {
        step: {
          delete: {
            __text__: 'Remover {mode}',
            keep: {
              spaces: 'Remover os {mode} mas manter os espaços',
            },
          },
          keep: 'Manter {mode}',
          mute: 'Silenciar {mode}',
        },
      },
      repetitions: 'repetições',
      podcast: {
        add: {
          speaker: 'Adicionar um palestrante',
          camera: 'Adicionar uma câmera',
        },
        track: 'Faixa',
        camera: {
          title: 'Câmeras',
          helper:
            'Adicione suas câmeras e especifique qual palestrante está visível em cada câmera. Você pode selecionar qualquer número de palestrantes por câmera.',
        },
        speaker: 'Palestrante',
        all: {
          speakers: 'Todos os oradores',
        },
      },
    },
    help: {
      bug: 'Relatar um bug',
      review: 'Avaliar AutoCut',
      multipiste:
        'Precisa de ajuda com multifaixa? Veja nosso tutorial em vídeo',
      tuto: 'Tutorial em vídeo',
      academy: 'Ajuda',
      buy: 'Comprar uma chave de licença',
      account: 'Gerenciar chave de licença',
      logout: 'Sair',
      reset: {
        settings: 'Redefinir configurações',
      },
      transition: {
        step: 'Isso irá adicionar um acolchoamento depois e/ou antes de cada corte. Criando uma transição mais suave entre os cortes. Se selecionado, irá criar uma nova faixa para as transições.',
      },
      transcription: {
        provider: {
          step: 'A qualidade e a duração da transcrição podem variar dependendo do modelo selecionado.',
        },
      },
    },
    bugBtnDialog: 'Envie um email para: contact@autocut.fr',
    ButtonCutText: 'Cortar',
    ButtonCutAndDeleteText: 'Cortar e deletar',
    OptionCutText: 'Cortar e manter silêncios',
    OptionCutAndDeleteText: 'Cortar e deletar silêncios',
    first: {
      launch: {
        error:
          'Ocorreu um erro durante o lançamento do AutoCut. Por favor, reinicie o plugin.',
      },
    },
    helper: {
      Step4: {
        '1': 'Se você ativar a opção de AutoZoom, os clipes serão ampliados após o corte.',
        '2': 'O coeficiente de zoom é calculado com base nos valores fornecidos abaixo e no volume máximo do sub-clipe.',
        '3': 'Escolha a porcentagem do vídeo para aplicar zoom.',
      },
      Step5: 'O backup permite desfazer todas as modificações após os cortes.',
      Step1: {
        '1': 'Duração mínima (em segundos) que o silêncio deve ter para ser removido.',
        '2': 'Duração mínima (em segundos) que um segmento de fala deve ter para ser mantido.',
        podcast: {
          minium:
            'Duração mínima (em segundos) que uma câmera será exibida sem mudar.',
          maximum:
            'Duração máxima (em segundos) que uma câmera será exibida antes de ser forçada a mudar.',
        },
      },
      Step1Ai:
        'A agressividade determina quanto silêncio a AI removerá, com um mínimo de 1 e um máximo de 4.',
      Step2: {
        '1': 'Duração do silêncio antes do segmento de fala.',
        '2': 'Duração do silêncio após o segmento de fala.',
      },
      Step3: {
        '1': 'Nível de som considerado como silêncio. Você pode ver os efeitos na visualização abaixo.',
        '2': 'Pré-visualização de segmentos preservados e removidos. Use as setas para avançar ou voltar, ou centralize a pré-visualização com base na posição do cursor do tempo.',
        '3': 'Se você escolher não deletar os silêncios, o clipe será cortado, mas os silêncios não serão removidos.',
      },
      Save: {
        Default:
          'Salvar configurações. Salve as configurações atuais e elas serão carregadas na próxima vez que iniciar.',
      },
      Undo: {
        Cut: 'Desfazer o último corte. Restaura os clipes ao estado antes do último corte.',
      },
      speakers: {
        podcast: {
          video: {
            tracks:
              'Selecione as faixas de vídeo onde o palestrante aparece, você pode selecionar qualquer número de faixas.',
          },
          audio: {
            tracks:
              'Selecione a faixa de áudio onde podemos ouvir o palestrante, você só pode selecionar uma faixa por palestrante.',
          },
        },
      },
    },
    warn: {
      Step5:
        'Aviso: se você desabilitar o backup, não poderá desfazer seus cortes.',
    },
    title: {
      Step5: {
        Backup: 'Ativar backup',
      },
      undo: {
        modal: 'Concluído!',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Um dos seus clipes tem um formato não suportado',
            speed: 'AutoCut suporta apenas clipes com velocidade padrão',
            multi: {
              audio:
                'AutoCut pode não funcionar bem com múltiplos rastros de áudio',
              stream: 'Um dos seus clipes tem múltiplos fluxos de áudio',
            },
            '51_stereo':
              'Um dos seus clipes tem um layout de áudio 5.1 exibido em estéreo',
            audio: {
              layout: 'Um dos seus clipes tem um layout de áudio não suportado',
            },
            repeat: {
              multi: {
                audio: 'Sua seleção contém vários clipes de áudio',
              },
              no_audio: 'Sua seleção não contém clipes de áudio',
            },
            remote: {
              file: 'Um dos seus clipes não está no seu computador',
            },
          },
        },
        maintained: {
          modal:
            'AutoCut não é mantido em versões do DaVinci Resolve anteriores à edição de 2021.',
        },
      },
      use: {
        trial: {
          licence: 'Verifique seu email e use sua chave de licença',
        },
        paid: {
          licence: 'Use sua chave de licença',
        },
      },
      no_audios: 'Não há áudio na sua seleção',
      banner: {
        podcast: {
          beta: 'Obrigado por experimentar o nosso novo modo Podcast.',
        },
      },
      silences: {
        step: 'Gerir os {mode}',
      },
      podcast: {
        silences: {
          step: 'Remover ou silenciar os silêncios',
        },
      },
    },
    secondUnit: 'segundos',
    millisecondUnit: 'milissegundos',
    percentageUnit: '%',
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut e deletar silêncios',
          l_cut: 'L-Cut e deletar silêncios',
          both: 'J&L-Cut e deletar silêncios',
          constant: {
            power: 'Cortar, excluir silêncios e aplicar transição de áudio',
          },
          none: 'Cortar e deletar {mode}',
          keep: {
            spaces: {
              none: 'Cortar {mode} e manter os espaços',
            },
          },
        },
        keep: {
          none: 'Cortar e manter {mode}',
        },
        mute: {
          none: 'Cortar e silenciar {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Obtendo faixas...',
        silences: 'Obtendo silêncios...',
      },
      edit: {
        track: 'Editando faixa {indexTrackInfo}: {current} de {max}',
      },
      final: {
        message: {
          __text__: 'Sucesso! Seu podcast foi editado',
          error: 'Ocorreu um erro ao editar seu podcast',
        },
      },
      button: {
        disable: 'Editar e desabilitar clipes não utilizados',
        delete: 'Editar e deletar clipes não utilizados',
      },
      not: {
        available: {
          title:
            'Selecione uma sequência com pelo menos 2 faixas de vídeo e áudio',
        },
      },
      minimum: {
        higher: {
          maximum:
            'Tempo mínimo de câmera deve ser menor que o tempo máximo de câmera.',
        },
      },
      empty: {
        audio: {
          track: 'Faixas de áudio não podem estar vazias.',
        },
        video: {
          track: 'Faixas de vídeo não podem estar vazias.',
        },
      },
      preset: {
        title: 'Predefinições padrão',
        calm: 'Calmo',
        paced: 'Cadenciado',
        energetic: 'Energético',
        hyperactive: 'Hiperativo',
      },
    },
    error: {
      UseLicence: {
        __text__:
          'Você pode desvincular sua chave de outro computador através da',
        link: 'área do cliente AutoCut.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'A subsequência não pode ser alcançada',
          unknown: 'Ocorreu um erro desconhecido',
          noSpace: 'Ocorreu um erro: não há espaço livre suficiente',
          exportUnknown:
            'DaVinci Resolve encontrou um erro durante a exportação',
          invalidInputData:
            'Ocorreu um erro durante a geração de arquivos temporários',
          fileNotFound: 'Ocorreu um erro ao ler um dos seus arquivos',
          scriptNotLoaded: 'Ocorreu um erro ao carregar o AutoCut',
          ffmpegError: 'Não foi possível ler um dos seus arquivos.',
          exportCancelled: 'Exportação cancelada',
          timelineMuted: 'Nenhuma faixa audível',
          noAudibleAudio: 'Sem áudio audível',
          offline: 'Parece que você está offline',
          resourceTimeout: 'Não é possível obter o recurso',
          backupNotFound: 'Não foi possível encontrar o seu backup',
          setCursorOutInterval: 'Fora dos pontos de entrada/saída',
          emptyPath: 'Ocorreu um erro durante a análise da seleção',
          sequenceMismatch: 'Não é possível realizar a operação',
        },
        text: {
          subsequenceNotReachable:
            'Lamentamos, mas não conseguimos alcançar uma das suas subsequências selecionadas.',
          unknown:
            'Ocorreu um erro ao usar o AutoCut, mas não conseguimos identificá-lo.',
          noSpace:
            'Parece que você não tem espaço livre suficiente no seu dispositivo.',
          exportUnknown:
            'Este erro é mais provável que seja causado por falta de espaço de armazenamento no disco.',
          invalidInputData:
            'Pedimos desculpas, mas parece que ocorreu um erro durante a geração do arquivo temporário.',
          fileNotFound:
            'Por favor, verifique se o arquivo {filename} ainda está disponível no local: {filepath}',
          scriptNotLoaded:
            'Por favor, feche todas as outras extensões e o DaVinci Resolve e tente novamente.',
          ffmpegError:
            'Por favor, verifique se o arquivo {filepath} não está corrompido.',
          exportCancelled:
            'AutoCut não pode analisar o áudio se você parar a codificação. Por favor, tente novamente.',
          timelineMuted:
            'Todas as suas faixas de áudio utilizadas estão silenciadas.',
          noAudibleAudio: 'Nenhum áudio audível foi detectado.',
          offline: 'Não conseguimos acessar nossos servidores.',
          resourceTimeout:
            'O recurso online necessário para esse processo não pôde ser baixado em 30 segundos',
          backupNotFound:
            'Não podemos restaurar a sua linha do tempo, pois não conseguimos encontrar a sequência de backup',
          setCursorOutInterval:
            'Você selecionou um trecho que não está contido nos seus pontos de entrada/saída.',
          emptyPath:
            'Pedimos desculpas, mas parece que um dos seus clipes selecionados não possui um caminho no seu computador.',
          sequenceMismatch:
            'Você mudou de sequência enquanto o AutoCut estava processando. Por favor, tente novamente sem mudar de sequência.',
        },
        tips: {
          subsequenceNotReachable:
            'Por favor, certifique-se de que cada subsequência na linha do tempo compartilha o mesmo nome com a sequência original correspondente no painel do projeto.',
          unknown:
            'Tente alterar sua seleção ou configurações. Se o problema persistir, entre em contato conosco.',
          noSpace:
            'Tente remover alguns arquivos antes de reiniciar o AutoCut.',
          exportUnknown:
            'Tente remover alguns arquivos antes de reiniciar AutoCut.',
          invalidInputData:
            'Por favor, tente novamente com a mesma seleção. Se o problema persistir, entre em contato conosco com seu arquivo.',
          fileNotFound:
            'Por favor, tente novamente com a mesma seleção. Se o problema persistir, entre em contato conosco com seu arquivo',
          scriptNotLoaded: 'Se o problema persistir, entre em contato conosco.',
          ffmpegError: 'Se o problema persistir, entre em contato conosco.',
          exportCancelled: ' ',
          timelineMuted:
            'Por favor, desative o silenciamento de pelo menos uma delas.',
          noAudibleAudio:
            'Por favor, verifique que pelo menos um clipe esteja ativo e seja audível.',
          offline: 'Verifique sua conexão com a internet e tente novamente.',
          resourceTimeout:
            'Verifique sua conexão com a internet e tente novamente.',
          backupNotFound:
            'Por favor, verifique a pasta "AutoCut-Backup" na raiz do seu projeto para ver se o backup ainda está lá.',
          setCursorOutInterval:
            'Você deve remover os pontos de entrada/saída para ouvir esta parte da transcrição.',
          emptyPath:
            'Por favor, certifique-se de que todos os seus clipes sejam de mídias atômicas ou subsequências.',
          sequenceMismatch:
            'Quando você usar o AutoCut, por favor, não mude de sequência, ou altere algo na sequência atual, até que o processo esteja concluído.',
          noSoundDetected:
            'O AutoCut não conseguiu detectar nenhum som. Por favor, verifique o seguinte: {br}{br} <li>Certifique-se de que o idioma selecionado corresponde ao idioma de seus clipes.</li><li>Verifique se suas faixas não estão silenciadas.</li><li>Confirme que seus pontos de entrada e saída incluem clipes com som.</li> {br}Se o problema persistir após verificar esses pontos, sinta-se à vontade para nos contatar no Discord ou em contact@autocut.fr.',
        },
        lost: {
          cep: {
            title: 'Ocorreu um erro: Perda de conexão',
            text: {
              '1': 'Você perdeu a conexão com o Adobe DaVinci Resolve. Por favor, reinicie a extensão e tente novamente.',
              '2': 'Se reiniciar a extensão não resolver o problema, tente reiniciar o DaVinci Resolve.',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'O idioma não é suportado para este modo.',
        },
      },
    },
    retour: 'Esta chave não existe!',
    countdown: {
      days: 'dias de teste restantes',
    },
    saveas: {
      default: {
        settings: 'Salvar configurações atuais',
      },
    },
    button: {
      no_silences: 'Nenhum silêncio detectado',
      no_audios: 'Nenhum clipe de áudio selecionado',
      SavingBackup: 'Fazendo backup do seu projeto...',
      RestoringBackup: 'Restaurando seu projeto...',
      ExportingAudio: 'Exportando áudio...',
      UploadingAudio: 'Carregamento de áudio...',
      SearchingSilences: 'Procurando por silêncios...',
      Cuttingtrack: 'Cortando faixa {current}',
      CuttingStep: {
        __text__: 'Realizando corte {current} de {max}',
        Audio: 'Cortando faixa de áudio {numTrack}: {current} de {max}',
        Video: 'Cortando faixa de vídeo {numTrack}: {current} de {max}',
      },
      PreDeletingStep: 'Procurando por clipes de silêncio para deletar...',
      DeletingStart: 'Deletando silêncios...',
      finalizing: 'Finalizando...',
      AssociatingStep: 'Realizando associação {current} de {max}',
      Success: 'Concluído! Processo terminado com sucesso',
      Failure: 'Falha... Algo deu errado',
      NoSilences: 'Nenhum silêncio encontrado',
      undo: {
        modal: 'Desfazer',
      },
      not: {
        supported: {
          modal: 'Fechar',
        },
      },
      PreZoomingStep: 'Obtendo sub-clipes para zoom...',
      ZoomingStep: 'Adicionando zoom: {current} de {max}',
      Autozoom: 'Preparando AutoZoom...',
      no_video: {
        to_zoom: 'Nenhum vídeo para aplicar zoom.',
      },
      getSubclip: {
        step: 'Recuperando sub-clipe para AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Verificando se os silêncios estão corretamente removidos...',
      },
      back: 'Voltar',
      DeletingStep: {
        delete: {
          Audio:
            'Excluindo silêncio na faixa de áudio {numTrack}: {current} de {max}',
          Video:
            'Excluindo silêncio na faixa de vídeo {numTrack}: {current} de {max}',
        },
        mute: {
          Audio:
            'Silenciando trechos sem áudio na faixa {numTrack}: {current} de {max}',
          Video:
            'Silenciando trechos sem vídeo na faixa {numTrack}: {current} de {max}',
        },
      },
      skip: 'Pular',
      save: 'Guardar',
    },
    warning: {
      DontTouchTimeline:
        'Por favor, não modifique sua linha do tempo durante este processo',
      undo: {
        modal:
          'Notamos que alguns metadados não estavam presentes ao analisar seus arquivos de áudio.',
      },
      modal: {
        title: {
          ffmpeg: 'AutoCut pode não funcionar como esperado',
        },
        text: {
          ffmpeg:
            'Pedimos desculpas, mas parece que alguns metadados estão faltando no arquivo {filename}.',
        },
        tips: {
          ffmpeg: 'Tente re-codificar seu arquivo se encontrar problemas.',
        },
      },
      transition: {
        step: 'Você não pode usar transições sem deletar silêncios.',
      },
      beta: {
        version:
          'Parece que você está utilizando uma versão beta do DaVinci Resolve. Você pode encontrar alguns problemas com o AutoCut. Por favor, teste na última versão estável do DaVinci Resolve.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Verificando se sua seleção é válida...',
          parsing: {
            step: 'Obtendo dados da sua seleção: clipe {current} de {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Obtendo silêncio da sua seleção: clipe {current} de {max}...',
          },
        },
        drawing: 'Gerando pré-visualização...',
      },
      frame: {
        no_sequence: 'Abra uma sequência para pré-visualizar o seu formato',
      },
    },
    contact: {
      us_title: 'Lamento que você encontrou um bug',
      us_text: {
        '2': 'Se você tiver algum problema com sua conta, sua chave, seu pagamento ou qualquer outra coisa não ligada ao seu projeto de edição de vídeo:',
        __text__:
          'Se o AutoCut não funcionar perfeitamente com seu projeto e você quiser relatar o problema:',
      },
      us_button: 'Relatar um problema',
      us_contact: 'Contate-nos:',
      us_copy: 'Contato copiado para área de transferência!',
    },
    switch: {
      to_send: {
        log: {
          button: 'Ou envie um arquivo de log',
        },
      },
    },
    send: {
      log: {
        title: 'Pedimos desculpa pelo inconveniente causado por este bug',
        details:
          'Com este arquivo de log, só temos acesso a informações sobre o trabalho dos algoritmos.',
        select:
          'Por favor, selecione um arquivo de log diário, isso nos ajudará a resolver seu problema.',
        text: {
          area: 'Por favor, descreva seu problema com o máximo de detalhes possível:',
        },
        button: 'Enviar arquivo de log',
        success: {
          title: 'Arquivo enviado! Vamos investigar seu problema.',
        },
        thank: {
          text: 'Você pode continuar cortando seus vídeos.',
          text2: 'Entraremos em contato quando o problema for resolvido.',
        },
        message: {
          error: 'Por favor, insira uma mensagem com pelo menos 10 caracteres',
        },
        link: {
          to_user: {
            file: {
              __text__:
                'Por favor, forneça um link para o seu arquivo de vídeo e áudio.',
              not: {
                valid:
                  'Por favor, forneça um link válido para o seu arquivo de vídeo e áudio.',
              },
              platform: {
                __text__:
                  '(WeTransfer, Google Drive ou qualquer outro serviço de compartilhamento de arquivos)',
                helper:
                  "Para enviar seu arquivo no WeTransfer, vá para wetransfer.com e clique em 'Enviar arquivos'. Depois disso, você obterá um link, copie-o aqui.",
              },
              end: 'Isso nos ajudará a resolver seu problema rapidamente.',
            },
          },
        },
        screen: {
          timeline: {
            __text__:
              'Por favor, anexe uma captura de tela da sua seleção no DaVinci Resolve.',
            before:
              'Por favor, anexe uma captura de tela da sua seleção no DaVinci Resolve antes dos cortes.',
            after:
              'Por favor, anexe uma captura de tela da sua seleção no DaVinci Resolve após os cortes.',
          },
          error: {
            message:
              'Por favor, anexe uma captura de tela da mensagem de erro.',
          },
        },
        trial: {
          mail: 'Por favor, forneça o email que você pediu um teste com.',
        },
        other:
          'Por favor, forneça qualquer informação que possa ajudar a resolver seu problema.',
      },
    },
    select: {
      issue: {
        title: 'Por favor, selecione seu problema na lista abaixo.',
        subtitle: 'Isso nos ajudará a resolver seu problema.',
      },
      one: {
        audios:
          'Por favor, selecione pelo menos um clipe de áudio para poder usar o AutoCut.',
      },
    },
    upload: {
      screenshot: 'Enviar sua captura de tela',
    },
    modal: {
      feedback: {
        title: 'Feedback',
        input: {
          feedback: {
            title: 'O que você acha do AutoCut?',
            placeholder: 'Escreva seu feedback aqui...',
          },
          improve: {
            title: 'Diga-nos o que pode ser melhorado?',
            placeholder: 'Escreva o que poderia ser melhorado aqui...',
          },
        },
        button: {
          text: 'Enviar',
        },
        sent: {
          title: 'Obrigado pelo seu feedback!',
          text: 'Se você tiver alguma ideia de recurso para implementar no AutoCut, pode enviá-la para nós:',
          button: {
            text: 'Solicitar recurso',
          },
        },
      },
      bug: {
        report: {
          title: 'Relatório de bug',
          description: {
            placeholder: 'Descreva o bug aqui...',
            error:
              'Por favor, forneça uma descrição do bug de pelo menos 20 caracteres.',
          },
          reproduce: {
            placeholder: 'Etapas para reproduzir o bug aqui...',
            error:
              'Por favor, forneça uma reprodução de pelo menos 20 caracteres.',
          },
          blocking: 'O problema foi bloqueante',
          button: {
            text: 'Enviar relatório de bug',
          },
          link: {
            error: 'Por favor, forneça um link válido para o seu arquivo.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'Você encontrou um problema com seus cortes?',
      },
    },
    star: {
      review: {
        title: 'Deixe um testemunho à AutoCut!',
        captions: {
          title: 'Avalie o AutoCaptions!',
        },
        text: 'Compartilhe seus pensamentos sobre o AutoCut!',
      },
    },
    review: {
      title: 'Conte-nos mais',
      text: 'Os cortes foram satisfatórios? Você tem algum feedback para nos ajudar a melhorar o AutoCut? Estamos atentos:',
      form: {
        placeholder: 'Insira sua mensagem...',
      },
      button: 'Enviar',
      thank: {
        title: 'Obrigado pelo seu feedback!',
        text: 'Você pode continuar cortando seus vídeos.',
      },
    },
    exchange: {
      title: 'Ganhe mais 7 dias de teste!',
      text: 'Vá para o Adobe Exchange e avalie o AutoCut para obter mais 7 dias de teste!',
      button: 'Avaliar AutoCut no Adobe Exchange',
      info: 'Se você instalou o AutoCut pelo nosso site, você não precisa instalar a extensão novamente. Basta clicar em "Grátis" para iniciar o download e acessar a avaliação.',
    },
    licence: {
      trial: 'Teste',
      paid: 'Pago',
      info: 'Licença {licenceType}',
      time: {
        left: '{daysLeft} dias restantes',
      },
    },
    trial: {
      promo: {
        text: 'Até 20% de desconto nas próximas 24 horas',
      },
      resend: {
        error:
          'Erro ao reenviar chave de teste, por favor tente novamente mais tarde.\n',
      },
      expired: {
        modal: {
          title: 'Sua chave de teste expirou.',
          text1:
            'Seu teste gratuito expirou. Assine o AutoCut para continuar usando a extensão.',
          text2: 'Visite o painel AutoCut em:',
          text3: 'Assine uma chave de licença.',
          text4:
            'Entre em contato com nossa equipe de suporte se precisar de ajuda: {supportEmail}',
          button: 'Obter uma assinatura (-20%)',
        },
      },
      title: 'Teste gratuito',
      stepper: {
        onboarding: 'Ajude-nos a melhorar o AutoCut',
        email: 'Insira seu e-mail',
        sending: {
          email: 'Enviar a mensagem de correio eletrónico',
        },
        login: 'Use sua chave de licença',
      },
      onboarding: {
        subtitle:
          'Para melhorar a extensão e imaginar novas funcionalidades, por favor ajude-nos a entender seu uso do DaVinci Resolve',
        discover: 'Onde você descobriu o AutoCut?',
        job: {
          status: 'Qual é o seu status profissional?',
        },
        type: {
          creation: 'Tipo de criações que você faz no DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Correio eletrónico enviado com sucesso para {email}',
        },
      },
      task: {
        captions: 'Use AutoCaptions: <link>saiba mais</link>',
        podcast: 'Use AutoCut Podcast: <link>saiba mais</link>',
        zoom: 'Use AutoZoom: <link>saiba mais</link>',
        repeat: 'Use AutoCut Repeat: <link>saiba mais</link>',
        broll: 'Use AutoB-Roll: <link>saiba mais</link>',
        swear: {
          word: 'Use AutoProfanity Filter: <link>saiba mais</link>',
        },
        preset:
          'Criar um novo preset para AutoCaptions, AutoZoom ou AutoResize',
        testimonial:
          'Envie-nos um testemunho (utilizando o mesmo correio eletrónico): <link>testemunho</link>',
      },
    },
    shop: {
      now: 'Compre agora',
    },
    back: {
      to_autocut: 'Voltar ao AutoCut',
    },
    'discover-option-youtube-video': 'Vídeo no YouTube',
    'discover-option-youtube-comment': 'Comentário no YouTube',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Amigo',
    'discover-option-school': 'Escola',
    'discover-option-other': 'Outro',
    link: {
      not: {
        supported: {
          modal: {
            __text__:
              'Para entender melhor este erro e como corrigi-lo, vá para:',
            no_solution: 'Para entender melhor este erro, por favor vá para:',
            end: ' o nosso site de documentação e tutoriais',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence:
            'Acabou de receber uma mensagem no seu correio eletrónico ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            'Se você não ver o email, verifique sua pasta de spam ou promocionais.',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Insira sua chave de licença',
      },
    },
    "can't": {
      reach: {
        server: {
          error:
            'Não é possível alcançar o servidor. Por favor, tente novamente mais tarde.',
        },
      },
      read: {
        uuid: {
          error: 'Não é possível ler o UUID do computador.',
        },
        host: {
          error: 'Não é possível ler o nome do computador.',
        },
      },
    },
    asking: {
      key: {
        error:
          'Erro ao solicitar a chave no servidor, por favor tente novamente mais tarde.\n',
      },
    },
    sending: {
      log: {
        file: {
          error:
            'Não é possível enviar o log diário, por favor tente novamente mais tarde.\n',
        },
      },
    },
    is_key: {
      valid: {
        error:
          'Erro ao verificar a chave, por favor tente novamente mais tarde.\n',
      },
    },
    base: {
      error: {
        message: 'Ocorreu um erro ',
      },
    },
    invalid: {
      response: {
        error: 'Resposta não é do servidor.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'Ele ainda está em desenvolvimento intenso, se você tiver bugs ou sugestões, por favor entre em contato conosco em contact@autocut.fr',
        },
      },
    },
    header: {
      trial: {
        button: 'Obter mais',
      },
      paid: {
        button: 'Gerenciar',
      },
      speakers: {
        name: 'Nome do palestrante',
        track: 'Faixa de áudio do palestrante',
      },
      cameras: {
        name: {
          __text__: 'Faixa de vídeo da câmera',
          helper:
            'Selecione a faixa de vídeo onde a câmera está, você só pode selecionar uma faixa por câmera.',
        },
        speakers: {
          __text__: 'Palestrante(s) na câmera',
          helper:
            'Selecione os palestrantes que estão visíveis nesta câmera, você pode selecionar qualquer número de palestrantes.',
        },
      },
    },
    game: {
      saved: {
        hours: 'Você economizou {hourSavedText} hora(s),',
      },
      have: {
        a_break: 'faça uma pausa, você merece!',
      },
    },
    generate: {
      preview: 'Gerar a pré-visualização',
    },
    onboarding: {
      skip: 'Pular introdução',
      close: 'Fechar',
      previous: 'Anterior',
      next: 'Próximo ({current}/{max})',
      onboarding: {
        step1: {
          title: 'Bem-vindo ao AutoCut!',
          body: 'Durante seu primeiro uso, diversas funcionalidades do AutoCut serão apresentadas passo a passo, guiando você pelo processo. Então, vamos começar a jornada!',
        },
        step2: {
          title: 'Chave de teste gratuita.',
          body: 'Se você está interessado em testar as funcionalidades do AutoCut por si mesmo, você pode solicitar uma chave de teste gratuita a qualquer momento. Por favor, note que você só poderá solicitar uma chave de teste por até 14 dias.',
        },
        step3: {
          title: 'Chave de licença.',
          body: 'Se você já possui uma chave de licença - seja teste ou paga, você pode usá-la diretamente para acessar as ferramentas do AutoCut.',
        },
        step4: {
          title: 'Comprando uma chave de licença.',
          body: 'Se você quiser comprar uma chave de licença, você pode clicar no botão fornecido para ser redirecionado para o site do AutoCut, onde encontrará todas as informações necessárias para comprar o AutoCut.',
        },
      },
      modeSelection: {
        step1: {
          title: 'Modos AutoCut.',
          body: 'O AutoCut oferece três modos diferentes para atender a diferentes necessidades de edição. Você pode selecionar o modo que se encaixa em suas necessidades simplesmente clicando em um dos modos.',
        },
        step2: {
          title: 'Precisa de ajuda?',
          body: 'Cada elemento na interface vem equipado com uma dica, fornecendo informações adicionais sobre sua função. Você pode aprender mais sobre um elemento específico colocando o ponteiro do mouse sobre sua dica.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2.',
          body: 'O recurso AutoCut V2 confia no nível de ruído do seu arquivo de áudio para detectar silêncios com precisão. No entanto, você precisa configurá-lo antes de usá-lo.',
        },
        step2: {
          title: 'Nível de ruído.',
          body: 'Ajuste o nível mínimo de ruído que será detectado como um silêncio usando o controle deslizante.',
        },
        step3: {
          title: 'E agora?',
          body: 'Navegue pelas etapas clicando nas setas para saber mais sobre a funcionalidade do AutoCut.',
        },
        step4: {
          title: 'Gerando pré-visualização.',
          body: "O áudio da sua linha do tempo precisa ser exportado antes de ser processado. Ao clicar em 'Gerar a pré-visualização', você pode pré-exportar o áudio e visualizar o resultado com os seus parâmetros atuais.",
        },
        step5: {
          title: 'Pré-visualização de Áudio.',
          body: 'Os botões de navegação permitem que você se mova para os 10 segundos anteriores ou seguintes do arquivo ou centralize a pré-visualização com o cursor do DaVinci Resolve.',
        },
        step6: {
          title: 'Hora de Cortar.',
          body: 'Depois de revisar tudo e ter certeza de que está bom, é hora de cortar!',
        },
        step7: {
          title: 'Hora de Cortar.',
          body: 'Clique na seta para escolher as opções de corte que você prefere.',
        },
        step8: {
          title: 'Hora de Cortar.',
          body: 'Sente-se e relaxe enquanto o AutoCut cuida do resto clicando no botão!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast.',
          body: 'AutoCut Podcast é um recurso poderoso que permite editar rapidamente seus podcasts em vídeo.',
        },
        step2: {
          title: 'Palestrantes.',
          body: 'Você pode criar um novo palestrante clicando em "Adicionar um palestrante". Para cada pessoa falando no seu podcast, é necessário adicionar um palestrante.',
        },
        step3: {
          title: 'Palestrantes.',
          body: 'Depois de criar um palestrante, você pode configurá-lo de acordo com suas necessidades.',
        },
        step4: {
          title: 'Nome do palestrante.',
          body: 'Nesta seção, você pode atribuir um nome ao palestrante que aparecerá visualmente apenas para você identificá-los.',
        },
        step5: {
          title: 'Seleção de faixas de vídeo.',
          body: 'O menu suspenso permite que você selecione todas as faixas de vídeo nas quais o palestrante aparece.',
        },
        step6: {
          title: 'Seleção de faixa de áudio.',
          body: 'Você pode selecionar a faixa de áudio do palestrante usando o menu suspenso. Cada palestrante pode ter apenas uma faixa de áudio.',
        },
        step7: {
          title: 'Hora de editar.',
          body: 'Depois de definir todos os palestrantes e onde eles aparecem, você está pronto para começar!',
        },
        step8: {
          title: 'Hora de editar.',
          body: 'Use a seta para selecionar as opções de edição que você prefere.',
        },
        step9: {
          title: 'Hora de editar.',
          body: 'Clique no botão, relaxe e deixe o AutoCut cuidar do restante enquanto você aproveita uma xícara de café.',
        },
      },
      title: 'Bem-vindo ao AutoCut',
      subtitle: 'Sua extensão definitiva para o DaVinci Resolve',
    },
    start: {
      transcription: 'Iniciar transcrição',
    },
    no_more: {
      credits: 'Não há mais créditos disponíveis',
    },
    file: {
      too: {
        long: 'O arquivo de áudio é muito longo',
      },
      sent: 'Arquivo enviado, aguardando transcrição...',
    },
    please: {
      wait1: 'Aguarde enquanto seu clipe de áudio está sendo transcrito.',
      wait2:
        'Esse processo pode levar vários minutos, dependendo do comprimento.',
      wait3: 'Enviando arquivo: ',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Idioma do áudio',
            helper: 'Selecione o idioma do clipe de áudio',
          },
        },
        french: {
          __text__: 'Francês',
          beta: 'Francês (beta)',
        },
        danish: 'Dinamarquês',
        dutch: 'Holandês',
        english: {
          __text__: 'Inglês',
          united: {
            states: 'Inglês - Estados Unidos',
          },
        },
        flemish: 'Flamengo',
        german: {
          __text__: 'Alemão',
          beta: 'Alemão (beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (beta)',
        },
        italian: 'Italiano',
        japanese: 'Japonês',
        korean: 'Coreano',
        norwegian: {
          __text__: 'Norueguês',
          nynorsk: 'Norueguês Nynorsk',
        },
        polish: 'Polonês',
        portuguese: {
          __text__: 'Português',
          beta: 'Português (beta)',
          brazil: {
            __text__: 'Português - Brasil',
            beta: 'Português - Brasil (beta)',
          },
        },
        spanish: {
          __text__: 'Espanhol',
          beta: 'Espanhol (beta)',
          latin: {
            america: {
              __text__: 'Espanhol - América Latina',
              beta: 'Espanhol - América Latina (beta)',
            },
          },
        },
        swedish: 'Sueco',
        tamil: 'Tâmil',
        chinese: 'Chinês',
        russian: 'Russo',
        turkish: 'Turco',
        catalan: 'Catalão',
        arabic: {
          __text__: 'Árabe',
          algeria: 'Árabe - Argélia',
          bahrain: 'Árabe - Bahrein',
          egypt: 'Árabe - Egito',
          iraq: 'Árabe - Iraque',
          israel: 'Árabe - Israel',
          jordan: 'Árabe - Jordânia',
          kuwait: 'Árabe - Kuwait',
          lebanon: 'Árabe - Líbano',
          mauritania: 'Árabe - Mauritânia',
          morocco: 'Árabe - Marrocos',
          oman: 'Árabe - Omã',
          qatar: 'Árabe - Catar',
          saudi: {
            arabia: 'Árabe - Arábia Saudita',
          },
          palestine: 'Árabe - Palestina',
          syria: 'Árabe - Síria',
          tunisia: 'Árabe - Tunísia',
          united: {
            arab: {
              emirates: 'Árabe - Emirados Árabes Unidos',
            },
          },
          yemen: 'Árabe - Iêmen',
        },
        indonesian: 'Indonésio',
        finnish: 'Finlandês',
        vietnamese: 'Vietnamita',
        hebrew: 'Hebraico',
        ukrainian: 'Ucraniano',
        greek: 'Grego',
        malay: 'Malaio',
        czech: 'Tcheco',
        romanian: 'Romeno',
        hungarian: 'Húngaro',
        thai: 'Tailandês',
        urdu: 'Urdu',
        croatian: 'Croata',
        bulgarian: 'Búlgaro',
        lithuanian: 'Lituano',
        latin: 'Latim',
        maori: 'Maori',
        malayalam: 'Malaiala',
        welsh: 'Galês',
        slovak: 'Eslovaco',
        telugu: 'Telugu',
        persian: 'Persa',
        latvian: 'Letão',
        bengali: 'Bengali',
        serbian: 'Sérvio',
        azerbaijani: 'Azerbaijano',
        slovenian: 'Esloveno',
        kannada: 'Canarês',
        estonian: 'Estoniano',
        macedonian: 'Macedônio',
        breton: 'Bretão',
        basque: 'Basco',
        icelandic: 'Islandês',
        armenian: 'Armênio',
        nepali: 'Nepalês',
        mongolian: 'Mongol',
        bosnian: 'Bósnio',
        kazakh: 'Cazaque',
        albanian: 'Albanês',
        swahili: 'Suaíli',
        galician: 'Galego',
        marathi: 'Marata',
        punjabi: 'Punjabi',
        sinhala: 'Cingalês',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Iorubá',
        somali: 'Somali',
        afrikaans: 'Africâner',
        occitan: 'Occitano',
        georgian: 'Georgiano',
        belarusian: 'Bielorrusso',
        tajik: 'Tajique',
        sindhi: 'Sindi',
        gujarati: 'Gujarati',
        amharic: 'Amárico',
        yiddish: 'Iídiche',
        lao: 'Laosiano',
        uzbek: 'Uzbeque',
        faroese: 'Feroês',
        haitian: {
          creole: 'Crioulo haitiano',
        },
        pashto: 'Pashto',
        turkmen: 'Turcomeno',
        maltese: 'Maltês',
        sanskrit: 'Sânscrito',
        luxembourgish: 'Luxemburguês',
        burmese: 'Birmanês',
        tibetan: 'Tibetano',
        tagalog: 'Tagalo',
        malagasy: 'Malgaxe',
        assamese: 'Assamês',
        tatar: 'Tártaro',
        hawaiian: 'Havaiano',
        lingala: 'Lingala',
        hausa: 'Hauçá',
        bashkir: 'Bashkir',
        javanese: 'Javanês',
        sundanese: 'Sundanês',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            'Parece que você tem muitos clipes na sua sequência! Isso pode demorar um pouco para carregar. Se você não precisa cortar todos esses clipes, por favor, selecione apenas os que você precisa cortar e coloque-os em uma sequência separada e vazia.',
        },
      },
    },
    homepage: {
      title: 'Página inicial',
      comming: {
        soon: 'Em breve!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dinamismo',
        normal: 'Normal',
        high: 'Alto',
        extreme: 'Extremo',
        custom: 'Personalizado:',
      },
      button: {
        __text__: 'Zoom',
        message: {
          step1: 'Preparação do Autozoom...',
          step2: 'Extraindo dados de áudio...',
          step3: 'Calculando intervalos de zoom...',
          step4: 'Aplicando zoom {current} de {max}...',
        },
      },
      success: 'Sucesso! Sua seleção foi ampliada',
      info: {
        text: 'AutoZoom aplicará zoom automático em cada clipe selecionado com base nos decibéis.',
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Selecione pelo menos um clipe de áudio e um de vídeo na sua linha do tempo',
            text1:
              'Para usar AutoZoom, selecione todos os clipes que compõem o seu vídeo na sua linha do tempo.',
          },
        },
      },
      anchor: {
        title: 'Defina o ponto de ancoragem',
        helper:
          'O ponto de ancoragem é onde o zoom será aplicado. Você pode visualizar o efeito do zoom máximo e mínimo usando as caixas coloridas.',
      },
      dual: {
        range: {
          title: 'Coeficiente mínimo e máximo de zoom aplicado',
          helper:
            'O coeficiente de zoom é calculado com base nos valores fornecidos abaixo e no volume máximo do sub-clipe.',
        },
      },
      preview: {
        on_indicator: 'Pré-visualização no indicador',
        error: {
          message: 'Ocorreu um erro ao carregar a pré-visualização',
          button: 'Tentar novamente',
        },
      },
      min: {
        zoom: {
          title: 'Duração mínima do zoom',
          helper: 'Cada zoom terá pelo menos esta duração.',
        },
      },
      max: {
        zoom: {
          title: 'Duração máxima do zoom',
          helper: 'Cada zoom terá no máximo esta duração.',
        },
      },
      constraint: {
        zoom: {
          title: 'Restrição de zoom para clipes',
          helper:
            'Se sim, nenhum zoom se estenderá por mais de um clipe. Se não, toda a sua seleção será processada como um único arquivo de áudio e os zooms ignorarão clipes individuais na linha do tempo.',
        },
      },
      zoom: {
        types: {
          __text__: 'Tipos de zoom',
          helper:
            'Determina o tipo de zoom a ser usado. Suave: Zoom progressivo ao longo do tempo. Dinâmico: Zoom instantâneo, nítido.',
        },
        coverage: {
          __text__: 'Cobertura total do zoom',
          helper: 'Porcentagem do vídeo a ser ampliado',
        },
      },
      smooth: {
        __text__: 'Suave',
        zoom: {
          __text__: 'Zooms suaves',
          coverage: 'Cobertura dos zooms suaves',
          nervousness: {
            __text__: 'Nervosismo',
            helper:
              'Um baixo nervosismo significa zooms lentos, fracos. Um alto nervosismo significa zooms rápidos, intensos.',
          },
        },
      },
      dynamic: {
        __text__: 'Dinâmico',
        zoom: {
          __text__: 'Zooms dinâmicos',
          coverage: 'Cobertura dos zooms dinâmicos',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Predefinições padrão',
        calm: 'Calmo',
        paced: 'Cadenciado',
        energetic: 'Energético',
        hyperactive: 'Hiperativo',
      },
    },
    repeat: {
      info: {
        banner:
          'Agora você pode corrigir quais frases serão cortadas ou não.{br}As frases esmaecidas são consideradas repetições.{br}As tesouras permitem definir o status de uma frase (repetição ou não). Você também pode destacar uma parte da frase e mirar precisamente as palavras que serão cortadas',
      },
      text: {
        step: {
          edit: {
            transcript: 'Corrigir transcrição',
          },
        },
      },
      toggle: {
        cut: {
          button: {
            text: 'Cortar/Não Cortar',
          },
        },
      },
      play: {
        button: {
          text: 'Reproduzir',
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Selecione um clipe na sua linha do tempo',
            text1:
              'Para usar AutoCut Repeat, selecione um clipe de áudio e, opcionalmente, um clipe de vídeo na sua linha do tempo.',
          },
        },
      },
      select: {
        algorithm: 'Selecionar algoritmo',
      },
      precise: {
        algorithm: {
          info: 'O algoritmo preciso é o nosso mais recente e eficiente, mas requer aproximadamente 1 minuto de processamento por 6 minutos de vídeo.',
        },
      },
      fast: {
        algorithm: 'Rápido',
      },
    },
    captions: {
      step: {
        customization: 'Parâmetros das legendas',
        script: 'Selecionar clipes e idioma',
        transcription: 'Editar transcrição',
      },
      script: {
        transcript: {
          part: 'Transcrição',
        },
        sequence: {
          part: 'Sequência',
          name: 'Nome',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} clipes | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Adicionar legendas',
        coming: {
          soon: 'O editor de transcrição está chegando! {br}Por enquanto, você pode editar sua transcrição depois de importá-la no painel Essential Graphics. {br}Basta selecionar uma legenda na linha do tempo e editá-la na guia de modificação do painel.',
        },
        transcription: {
          part: 'Transcrição',
        },
        info: 'Bem-vindo ao editor de legendas - você também poderá editar suas legendas na linha do tempo do DaVinci Resolve depois de adicioná-las.',
        add: {
          emojis: {
            cta: 'Gerar emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Selecione clipes para legendar na sua linha do tempo',
          no_selection:
            'Se o AutoCut não responder, tente reiniciar o DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Processar',
        },
      },
      customization: {
        info: 'AutoCaption está atualmente em Beta, planejamos a primeira versão estável para estar disponível em 10 de novembro',
        back: {
          button: 'Excluir transcrição e voltar',
        },
        example: {
          __text__:
            'Visualização <opacity50><i>Você pode arrastar a legenda ou o emoji para mudar sua posição </i></opacity50>',
          text: 'Adicione legendas com o AutoLegendas. Esta é uma prévia do AutoLegendas e permite que você personalize facilmente o AutoLegendas. Em seguida, você pode iniciar a transcrição e editá-la, se necessário, e o AutoLegendas adicionará automaticamente suas legendas à sua linha do tempo.',
        },
        animation: {
          __text__: 'Animação',
          helper: 'Gerenciar animações de texto',
          color: {
            __text__: 'Destaque com <color>áudio</color>',
            text: {
              color: 'Cor do texto',
            },
            before: {
              highlight: {
                color: 'Cor antes do destaque',
              },
            },
            after: {
              highlight: {
                color: 'Cor após o destaque',
              },
            },
          },
          advanced: {
            color: {
              options: 'Opções avançadas de cor',
            },
          },
          word: {
            box: 'Texto com <color>destaque</color>',
          },
          bold: {
            with: {
              text: 'Texto em <b>negrito</b>',
            },
          },
          floating: {
            text: 'Texto flutuante',
          },
        },
        transition: {
          __text__: 'Transição',
          helper:
            'Gerenciar transições de legendas. O tempo pode não ser o mesmo que no exemplo.',
          effect: {
            zoomIn: 'Zoom de entrada',
            blurIn: 'Desfoque de entrada',
          },
        },
        outline: {
          __text__: 'Contorno',
          helper: 'Gerenciar estilo do contorno de texto',
          color: 'Cor',
          width: 'Largura',
        },
        font: {
          __text__: 'Fonte',
          helper: 'Gerenciar estilo de texto',
          font: 'Nome da fonte',
          help: 'Como adicionar uma nova família de fontes?',
          size: 'Tamanho da fonte',
          weight: 'Peso da fonte',
          color: 'Cor',
        },
        formating: {
          __text__: 'Formatação de texto',
          helper: 'Gerenciar formatação de texto',
          ponctuation: 'Remover pontuação',
          uppercase: 'Maiúsculas',
          bold: 'Negrito',
          italic: 'Itálico',
          glow: {
            __text__: 'Brilho',
            color: 'Cor',
            intensity: 'Intensidade',
          },
        },
        transform: {
          __text__: 'Transformar',
          x: 'x',
          y: 'y',
          helper: 'Gerenciar posição e rotação das legendas',
          position: {
            __text__: 'Posição vertical',
            emoji: 'Posição vertical do emoji',
            info: 'Você também pode mover o texto na pré-visualização',
          },
          rotation: 'Rotação',
        },
        multiple: {
          lines: 'Múltiplas linhas',
        },
        box: {
          __text__: 'Caixas',
          helper: 'Gerenciar estilo da caixa',
        },
        boxes: {
          padding: {
            x: 'Preenchimento horizontal',
            y: 'Preenchimento vertical',
          },
          opacity: 'Opacidade da caixa',
          radius: 'Raio',
          color: 'Cor da caixa',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'Gerar emojis com IA',
          size: 'Tamanho dos emojis',
        },
        shadow: 'Sombra',
        transcript: 'Transcrição',
        action: 'Próximo passo',
        select: {
          chunk:
            'Selecione a parte da transcrição que você deseja editar/personalizar',
        },
        delete: {
          caption: {
            modal: {
              title: 'Tem certeza de que deseja remover esta legenda?',
              cancel: 'Não - mantenha',
              confirm: 'Sim - remova',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Erro ao obter o número de predefinidos, por favor entre em contato conosco no Discord ou em contact@autocut.fr',
            },
            error:
              'Erro ao obter os predefinidos, por favor entre em contato conosco no Discord ou em contact@autocut.fr',
          },
          public: {
            presets: {
              error:
                'Erro ao obter os presets públicos, por favor entre em contato conosco no discord ou em contact@autocut.fr',
            },
          },
        },
        presets: {
          title: 'Predefinidos',
          save: {
            new: {
              preset: 'Salvar parâmetros atuais',
            },
          },
          view: {
            more: 'Ver mais',
            less: 'Ver menos',
          },
        },
        save: {
          preset: {
            info: {
              text: 'Você alterou seus parâmetros, pode salvá-los como um preset abaixo e reutilizá-los infinitamente!',
            },
          },
        },
      },
      editor: {
        title: 'Editor de legendas',
        description: {
          title: 'Selecione as legendas que você deseja editar',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Palavras incertas',
            },
          },
          merge: {
            tooltip: 'Unir pedaços',
          },
          split: {
            tooltip: 'Dividir pedaços',
          },
        },
        sound: {
          play: 'Ativar reprodução de som',
        },
      },
      presetCustomization: {
        createPresetModal: {
          title: {
            length: {
              error:
                'O nome do seu predefinido deve conter pelo menos 2 palavras.',
            },
          },
          error:
            'Erro ao criar o predefinido, por favor entre em contato conosco no Discord ou em contact@autocut.fr',
          placeholder: 'Nome do predefinido (pelo menos 2 palavras)',
          create: 'Criar predefinido',
          isPublic: 'Compartilhar com a comunidade - público',
        },
        searchPresetModal: {
          title: 'Pesquisar preset da comunidade',
        },
        error: {
          preset: {
            already: {
              exists: 'Este preset já existe. Por favor, escolha outro nome.',
            },
          },
        },
        copyPreset: {
          error:
            'Erro ao copiar o preset, por favor entre em contato conosco no discord ou em contact@autocut.fr',
        },
        no_preset: {
          selected: 'Selecione um preset para ver a pré-visualização',
        },
        no_preview: {
          available: 'Nenhuma pré-visualização disponível',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Fonte não encontrada',
          message:
            'A fonte <i><b>{fontLabel}</b></i> não foi encontrada no seu sistema, a fonte padrão será usada.',
          helper:
            'Você pode adicionar uma nova família de fontes seguindo este tutorial.',
        },
      },
    },
    version: {
      not: {
        supported: 'Versão do DaVinci Resolve não suportada',
      },
    },
    transcript: {
      usage: {
        unit: 'minutos',
      },
      upgrade: {
        button: 'Atualize para IA para continuar com {mode}',
      },
      too: {
        short: {
          error:
            'Infelizmente, a sua transcrição pode estar muito curta ou o idioma que está a utilizar pode não estar bem suportado. Se o problema persistir, por favor, entre em contacto connosco através do contact@autocut.fr.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Solicitação de recurso',
        form: {
          placeholder:
            'Insira sua solicitação de recurso. Sinta-se à vontade para explicar em detalhes sua solicitação para que possamos estudá-la mais facilmente.',
        },
        screenshot:
          'Você pode anexar capturas de tela/vídeos para nos ajudar a entender melhor sua solicitação de recurso:',
        button: 'Enviar solicitação de recurso',
        success: {
          title: 'Solicitação de recurso enviada! 🥳',
          body: 'Obrigado por ajudar o AutoCut a crescer! Vamos revisar sua solicitação com a maior atenção.',
        },
        error: {
          title: 'Ops, algo deu errado... 🤕',
          body: 'Lamentamos profundamente o inconveniente. Por favor, tente novamente mais tarde ou entre em contato conosco em contact@autocut.fr.',
        },
      },
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Adicione pontos de Entrada & Saída na sua linha do tempo',
            text1:
              'Para usar o AutoResize, você precisa adicionar pontos de entrada & saída.',
            cta: 'Definir pontos de entrada e saída na sequência completa',
          },
        },
      },
      text: {
        info: 'O AutoResize redimensiona e reenquadra automaticamente a sua sequência para as melhores dimensões de todas as redes sociais.',
      },
      in_out: {
        step: {
          title: 'Pontos de Entrada / Saída',
          helper:
            'Por favor, verifique seus pontos de entrada & saída na linha do tempo. Eles devem ser iguais aos valores abaixo.',
        },
      },
      preset: {
        step: {
          title: 'Predefinições',
          helper:
            'Selecione uma predefinição para redimensionar sua sequência. Você também pode criar sua própria predefinição. As dimensões da sua nova sequência são exibidas abaixo.',
        },
        undefined: 'Por favor, selecione uma predefinição.',
      },
      watermark: {
        step: {
          title: "Marca D'água",
          helper:
            "Você pode adicionar uma marca d'água à sua sequência. Ela será adicionada ao canto inferior direito da sua sequência.",
          text: "Envie sua marca d'água",
        },
      },
      button: 'Gerar clipe para redes sociais',
      reframe: {
        undefined: 'Seleccione a velocidade "Seguir assunto".',
        step: {
          title: 'Seguir o assunto',
          helper:
            'Você pode escolher a velocidade com que a câmera irá seguir o assunto.',
          slower: 'Lento',
          default: 'Normal',
          faster: 'Rápido',
        },
      },
      backup: 'Fazendo backup da sua sequência...',
      create: {
        new: {
          sequence: 'Criando uma nova sequência...',
        },
      },
      resizing: {
        sequence: 'Redimensionando a nova sequência...',
      },
      adding: {
        watermark: "Adicionando marca d'água...",
      },
      cleaning: {
        sequence: 'Limpando a sequência...',
      },
    },
    endprocess: {
      modal: {
        title: 'Processo concluído!',
        text: {
          '1': 'Processo finalizado com sucesso!',
          '2': 'Seu feedback é inestimável – nos informe como foi e como podemos tornar sua experiência ainda melhor!',
        },
        next: {
          mode: {
            silence: {
              text: 'Pronto para uma experiência de áudio contínua? Use o AutoCut Silêncios para eliminar sem esforço os silêncios constrangedores do seu conteúdo.',
              button: 'Ir para AutoCut Silêncios',
            },
            podcast: {
              text: 'Eleve sua produção de podcast com AutoCut Podcast. Aproveite a edição simplificada e transições automáticas de câmeras.',
              button: 'Ir para AutoCut Podcast',
            },
            repeat: {
              text: 'Mantenha sua audiência cativada! Use o AutoCut Repetir para editar seu vídeo através de um editor de texto, mantendo apenas as partes mais envolventes do seu conteúdo.',
              button: 'Ir para AutoCut Repetir',
            },
            zoom: {
              text: 'Dê vida aos seus vídeos com AutoZoom! Adicione efeitos de zoom fluídos que atraem os espectadores e mantêm toda a atenção deles.',
              button: 'Ir para AutoZoom',
            },
            caption: {
              text: 'Conecte-se melhor com sua audiência usando AutoLegendas. Integre legendas estilizadas e sincronizadas que aprimoram a narrativa dos seus vídeos.',
              button: 'Ir para AutoLegendas',
            },
            resize: {
              text: 'Domine o compartilhamento entre plataformas com AutoRedimensionar. Formate seus vídeos à perfeição sem perder o foco no seu assunto.',
              button: 'Ir para AutoRedimensionar',
            },
            broll: {
              text: 'Amplifique sua narrativa visual com AutoB-Roll, onde a IA tece sem esforço filmagens de arquivo relevantes para complementar sua história.',
              button: 'Ir para AutoB-Roll',
            },
            swear: {
              word: {
                text: 'Mantenha um diálogo polido com o Filtro AutoProfanity. Máscara automaticamente linguagem indesejada para manter o profissionalismo do seu conteúdo.',
                button: 'Ir para Filtro AutoProfanity',
              },
            },
          },
        },
      },
      captions: {
        modal: {
          text: {
            '1': 'Suas legendas animadas foram adicionadas! 🎉 {br} Se você precisar modificar as legendas:',
            '2': 'Há um <a>tutorial detalhado se necessário.</a>',
          },
          bullet: {
            point: {
              '1': 'Clique duas vezes na subsequência: "Double click to edit"',
              '2': 'Selecione a legenda que você deseja editar',
              '3': 'Abra os gráficos essenciais e clique na guia "editar"',
            },
          },
        },
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title:
              'Selecione clipes na sua linha do tempo nos quais você deseja basear B-Roll',
            text1:
              'Para usar o Auto B-Roll, você precisa selecionar pelo menos um clipe com áudio na sua linha do tempo.',
          },
        },
      },
      text: {
        info: 'O Auto B-Roll, graças à IA, analisa seu áudio e adiciona automaticamente {br} B-Rolls do @Pexels relacionados ao tema do seu vídeo.',
      },
      duration: {
        step: {
          title: 'Durações de B-Roll',
          minimum: {
            title: 'Duração mínima do B-Roll',
            helper: 'Cada B-Roll terá pelo menos esta duração.',
          },
          maximum: {
            title: 'Duração máxima do B-Roll',
            helper: 'Cada B-Roll terá no máximo esta duração.',
          },
        },
      },
      choice: {
        step: {
          title: 'Seleção de B-Roll',
        },
      },
      no_broll: {
        screen: {
          title:
            'O processamento da transcrição terminou, mas nada foi encontrado.',
          text1:
            'Para usar o Auto B-Roll, você precisa selecionar pelo menos um clipe de áudio com fala na sua linha do tempo.',
          text2:
            'Se você já fez isso, verifique o idioma da sua transcrição e confira se as suas faixas de áudio não estão silenciadas.',
        },
      },
      button: {
        __text__: 'Adicionar B-Rolls',
        next: 'Próximo',
      },
      exporting: 'Exportando clipes de áudio selecionados...',
      analyse: 'Analisando áudio...',
      getting: {
        broll: 'Obtendo B-Rolls... (pode demorar alguns minutos)',
      },
      transcript: {
        finished: 'Transcrição gerada',
      },
      step: {
        parameters: 'Parâmetros do B-Roll',
        language: 'Selecione clipes e idioma',
        brolls: 'Editar seleção de B-Roll',
      },
      adding: {
        broll: 'Adicionando B-Rolls...',
      },
      restore: 'Restaurando sua sequência...',
      backup: 'Fazendo backup de sua sequência...',
      credit: {
        modal: {
          title: 'Aqui estão os créditos dos B-Rolls usados no seu vídeo: ',
          provided: {
            by: 'fornecidos por Pexels',
          },
          link: 'link',
          button: 'Copiar para a área de transferência',
        },
      },
      search: {
        modal: {
          title: 'Escolher B-Roll',
          input: {
            placeholder: 'Tipo para procurar B-Rolls',
          },
          searching: 'Procurando por B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Clique duas vezes para editar',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title:
                'Selecione clipes em sua linha do tempo onde você quer bleepar palavrões',
              text1:
                'Para usar o AutoProfanity Filter, você precisa selecionar pelo menos um clipe com áudio na sua linha do tempo.',
            },
          },
        },
        text: {
          info: 'O AutoProfanity Filter, graças à IA, analisa o seu áudio e automaticamente adiciona {br} sons de bleep em cima de palavrões.',
        },
        mute: 'Silenciar',
        button: 'Remover palavrões',
        audio: {
          export: 'Exportando áudio...',
          analyse: 'Analisando áudio...',
          insert: 'Inserindo bleeps...',
          filter: 'Aplicando filtros de volume...',
        },
        script: {
          transcript: {
            part: 'Transcrição',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: {
            helper: 'Selecione efeito sonoro de bleep',
          },
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Criar um novo formato',
          button: {
            __text__: 'Criar',
            disabled: 'Nome já utilizado',
          },
          name: 'Nome',
          width: 'Largura',
          height: 'Altura',
        },
      },
    },
    process: {
      modal: {
        warning:
          'Não faça nada no DaVinci Resolve, pois isso causará problemas.',
        generating: {
          emojis: 'Gerando emojis a partir da transcrição...',
        },
        formatting: {
          numbers: 'Formatando números a partir da transcrição...',
        },
      },
    },
    backup: {
      modal: {
        title: 'Backup do AutoCut',
        text: {
          '1': 'Antes de qualquer processo de AutoCut, é feito um backup. Você pode encontrar esses backups na pasta "AutoCut-Backup".',
          '2': 'Você pode usá-los como desejar.',
        },
      },
    },
    confirm: {
      modal: {
        title: 'Tem certeza?',
        confirm: {
          label: 'Confirmar',
        },
        cancel: {
          label: 'Cancelar',
        },
      },
    },
    login: {
      resend: {
        email: 'Não recebeu? Reenviar e-mail para {trialEmail}',
      },
    },
    typeofcreation: {
      option: {
        interview: 'Interviews',
        podcast: 'Podcasts',
        youtube: 'Vídeos do Youtube',
        short: 'TikTok/Shorts/Reels',
        internal: 'Vídeos internos',
        other: 'Outro',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Criador de conteúdo',
        },
        video: {
          editor: {
            professional: 'Editor de vídeo profissional',
            semi: {
              professional: 'Editor de vídeo semi-profissional',
            },
            beginner: 'Editor de vídeo iniciante',
          },
        },
        student: 'Estudante',
        other: 'Outro',
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: 'Digite o nome do palestrante…',
        },
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Criando legendas...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal:
          'Trabalho fantástico! Você completou o processo em apenas {minutes} minutos e {seconds} segundos.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Tempo economizado: <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b> e <b>{secondsSaved} {secondsSaved, plural, one {segundo} other {segundos}}</b>.',
        },
        all: {
          cuts: 'Tempo total economizado com AutoCut: Incríveis <b>{hourSaved} {hourSaved, plural, one {hora} other {horas}}</b> e <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Exclua certas faixas de áudio da detecção de silêncio desativando-as. Isso é ideal para manter a música de fundo ou os sons do jogo. Para manter os silêncios em um clipe específico inalterados, simplesmente não selecione esse clipe.',
        video: 'Faixas de vídeo selecionadas:',
        audio: 'Faixas de áudio selecionadas:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: 'Limite de teste gratuito atingido',
            cta: 'Inscrever-se',
            text: 'Você usou mais de 1000 minutos de recursos de IA. {br} Inscreva-se no plano de IA para continuar usando os recursos do AutoCut AI.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Limite de transcrição atingido',
          text: 'Você usou mais de 6000 minutos de recursos de IA. {br} Por favor, entre em contato conosco para desbloquear a situação.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Por favor, escolha um tipo de transição válido (Veja o campo <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>)',
        ai_silences:
          'Por favor, escolha um tratamento de silêncio válido (Veja o campo <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>)',
        broll: {
          min: {
            max: {
              duration:
                'O tempo máximo de B-Roll não pode ser menor que o tempo mínimo de B-Roll (Veja o campo <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>)',
            },
          },
          minimumBRollTime:
            'O tempo mínimo de B-Roll deve ser um número positivo (Veja o campo <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>)',
          maximumBRollTime:
            'O tempo máximo de B-Roll deve ser um número positivo (Veja o campo <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>)',
        },
        caption: {
          position:
            'As legendas devem estar dentro da visualização (Veja o campo <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a>)',
          emoji: {
            position:
              'Os emojis devem estar dentro da visualização (Veja o campo <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a>)',
          },
          text: {
            fontSize:
              'O tamanho da fonte deve ser um número positivo (Veja o campo <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>)',
          },
          outline: {
            width:
              'A largura do contorno deve ser um número positivo (Veja o campo <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a>)',
          },
          transition: {
            effects:
              'Por favor, selecione um efeito de transição de legenda válido (Veja o campo <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>)',
          },
          emojis: {
            size: 'O tamanho dos emojis deve ser um número positivo (Veja o campo <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>)',
          },
          formating: {
            nbLines:
              'Por favor, selecione pelo menos uma linha para formatação da legenda (Veja o campo <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>)',
          },
          title: {
            too: {
              short: 'O título deve conter pelo menos 5 caracteres',
              long: 'O título deve conter no máximo 25 caracteres',
            },
            no_space:
              'O título deve conter pelo menos duas palavras, separadas por um espaço',
          },
        },
        font: {
          fontFamily:
            'A família da fonte é obrigatória (Veja o campo <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>)',
          label:
            'Por favor, selecione uma fonte (Veja o campo <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>)',
        },
        languageOfTranscription:
          'Por favor, selecione um idioma de transcrição (Veja o campo <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>)',
        cameras: {
          speakerIds:
            'Pelo menos um alto-falante é necessário para cada câmera (Veja o campo <a><b><embedTranslation>header_cameras_speakers</embedTranslation></b></a>)',
          label:
            'Uma das câmeras está sem sua faixa de áudio (Veja o campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'O tempo mínimo da câmera deve ser um número positivo (Veja o campo <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>)',
            },
          },
          maximum: {
            cam: {
              time: 'O tempo máximo da câmera deve ser um número positivo (Veja o campo <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>)',
            },
          },
          deleteUnusedClips:
            'Por favor, escolha entre remover ou silenciar os silêncios (Veja o campo <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>)',
          speakers: {
            cameras:
              'Pelo menos uma câmera é necessária para cada alto-falante (Veja o campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
            audio:
              'Uma faixa de áudio é necessária para cada alto-falante (Veja o campo <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>)',
          },
          cameras: {
            __text__:
              'As câmeras não podem ter a mesma faixa de áudio (Veja o campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
            length:
              'Deve ter configurado pelo menos 2 câmeras diferentes (Veja o campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'O Preset de Auto Reframe é obrigatório (Veja o campo <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>)',
            invalid:
              'O Preset de Auto Reframe deve ser mais lento, padrão ou mais rápido (Veja o campo <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>)',
          },
          watermark:
            "A marca d'água deve ser um formato de arquivo de imagem válido: JPEG, PNG ou WebP (Veja o campo <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>)",
          preset: {
            name: 'Por favor, insira um nome para o preset',
            width: 'A largura deve ser maior que 1px',
            height: 'A altura deve ser maior que 1px',
          },
        },
        rgb: 'Os valores RGB devem estar entre 0 e 255',
        silence: {
          noiseLevel: {
            type: 'O Nível de Ruído deve ser um número (Veja o campo <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
            nonpositive:
              'O Nível de Ruído deve ser um número positivo (Veja o campo <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
            integer:
              'O Nível de Ruído deve ser um número inteiro (Veja o campo <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
          },
          minTimeOfSilence: {
            type: 'O Tempo Mínimo de Silêncio deve ser um número (Veja o campo <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>)',
          },
          maxTimeOfSilence: {
            type: 'O Tempo Máximo de Silêncio deve ser um número (Veja o campo <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>)',
          },
          marginBefore: {
            type: 'O Preenchimento Antes deve ser um número (Veja o campo <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>)',
          },
          marginAfter: {
            type: 'O Preenchimento Depois deve ser um número (Veja o campo <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>)',
          },
          transitions:
            'Por favor, escolha um tipo de transição válido (Veja o campo <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>)',
          silences:
            'Por favor, escolha um tratamento válido para os silêncios (Veja o campo <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>)',
        },
        swearWord: {
          bleepFile:
            'Por favor, selecione um efeito sonoro de censura (Veja o campo <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>)',
        },
        textBox: {
          xPadding:
            'O preenchimento horizontal deve ser um número positivo (Veja o campo <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>)',
          yPadding:
            'O preenchimento vertical deve ser um número positivo (Veja o campo <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>)',
          opacity: {
            min: 'A opacidade deve ser maior que 0% (Veja o campo <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>)',
            max: 'A opacidade deve ser menor que 100% (Veja o campo <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>)',
          },
        },
        anchor:
          'O ponto de ancoragem deve estar dentro da visualização (Veja o campo <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>)',
        zoom: {
          autoZoomMinCoef: {
            min: 'O coeficiente de zoom mínimo deve ser maior que 1 (Veja o campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
            max: 'O coeficiente de zoom mínimo deve ser menor que 2 (Veja o campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          },
          autoZoomMaxCoef: {
            min: 'O coeficiente de zoom máximo deve ser maior que 1 (Veja o campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
            max: 'O coeficiente de zoom máximo deve ser menor que 2 (Veja o campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          },
          minZoomTime: 'A duração mínima do zoom deve ser maior que 0 segundos',
          maxZoomTime: 'A duração máxima do zoom deve ser maior que 0 segundos',
          dynamicZoomPercentage: {
            min: 'A porcentagem de zoom deve ser maior que 0%',
            max: 'A porcentagem de zoom deve ser menor que 100%',
          },
          coef: 'O coeficiente de zoom máximo não pode ser menor que o coeficiente de zoom mínimo (Veja o campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          time: 'A duração máxima do zoom não pode ser menor que a duração mínima do zoom',
          dynamicMinZoomIntensity:
            'A intensidade de zoom dinâmico deve estar entre 0% e 100% (Veja o campo <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>)',
          smoothZoomPercentage:
            'A porcentagem de zoom suave deve estar entre 0% e 100% (Veja o campo <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>)',
          nervousness:
            'A nervosidade deve estar entre 0% e 100% (Veja o campo <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>)',
          totalZoomPercentage:
            'A cobertura total do zoom deve ser maior que 0% e até 100% (Veja o campo <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>)',
          types: {
            at_least: {
              one: {
                true: 'Pelo menos um tipo de zoom deve ser selecionado (Veja o campo <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>)',
              },
            },
          },
        },
        repeat: {
          algorithm: 'O algoritmo selecionado não é válido',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Falta de pagamento',
          button: {
            recover: 'Recuperar assinatura',
          },
          to_continue:
            'Sua assinatura atual foi <b>{status}</b> devido a um problema de pagamento. Para continuar usando o AutoCut, por favor:',
          deactivated: 'desativada',
          deleted: 'excluída',
          recover: {
            list: {
              click:
                '<b>Clique no botão abaixo</b> para recuperar sua assinatura ou verifique a fatura enviada por e-mail para um e-mail nosso ou do Stripe solicitando o pagamento da sua última fatura',
              re_activated:
                'Sua chave será <b>reativada imediatamente</b> após a atualização do pagamento',
            },
          },
          new: {
            list: {
              website:
                'Visite o site do AutoCut: <span>https://www.autocut.fr/#pricing</span>',
              subscribe: 'Assine uma nova chave de licença',
            },
          },
          support:
            'Entre em contato com nossa equipe de suporte se precisar de ajuda: <span>contact@autocut.fr</span>',
          thanks: 'Obrigado!',
        },
        moda: {
          button: {
            new: 'Obter uma nova assinatura',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Comece sua Aventura AutoCut',
          header: {
            days: {
              left: 'Sua jornada criativa começa com uma avaliação gratuita de 14 dias do AutoCut, você desbloqueou {completedElements} mais dias e ainda tem <b>{daysLeft} dias</b> para explorar a extensão!',
            },
            more: {
              days: 'Mas por que parar por aí? Embarque em uma missão para desbloquear <b>{bonusDays} dias adicionais</b> e liberar totalmente o poder do AutoCut.',
            },
          },
          timeline: {
            left: 'Avaliação gratuita de 14 dias',
            right: 'Avaliação gratuita de 24 dias',
          },
          task: {
            list: {
              title: 'Conclusão de Tarefas: {completed}/{maximum}',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'AutoCut precisa ser recarregado',
          cta: 'Recarregar AutoCut',
          text: 'Já se passaram mais de 30 minutos desde a última vez que o AutoCut foi aberto. {br} Para um desempenho ideal, recomendamos recarregar o AutoCut agora.{br}Basta clicar no botão abaixo para recarregar o AutoCut',
        },
      },
    },
    'key-already-activated':
      'Esta chave já foi ativada em outro computador. Por favor, desassocie-a em seu painel AutoCut (https://www.autocut.fr/pt/) antes de tentar novamente.',
    'key-does-not-exist':
      'Esta chave não existe. Por favor, verifique sua chave e tente novamente.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Versão do DaVinci Resolve não suportada',
            '24.4.0':
              'Por favor, faça o downgrade do DaVinci Resolve para 24.3 ou atualize para 24.5',
          },
          cta: 'Eu entendo',
          text: {
            __text__:
              'Você está utilizando uma versão do DaVinci Resolve que não é suportada ({version}). Você <b>não poderá</b> utilizar o AutoCut em sua plenitude com esta versão.{br}{br}Por favor, use a <b>última versão estável</b> para ter a melhor experiência com o AutoCut.',
            '24.4.0':
              'Você está usando atualmente uma versão não suportada do DaVinci Resolve ({version}). Houve algumas mudanças significativas nesta versão. Por favor, faça o downgrade para a versão 24.3 ou atualize para a versão 24.5 para usar o AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Motor de renderização não suportado',
          modal: {
            text: 'Atualmente, você está usando um motor de renderização não suportado. Você <b>não poderá</b> usar AutoCaptions nem AutoZoom.{br}{br}Por favor, selecione um motor de renderização acelerado por GPU nas configurações do seu projeto.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Necessário reinício completo',
          cta: 'Fechar todas as extensões',
          text: 'Detectamos várias instâncias do AutoCut em execução. Clique no botão abaixo para fechá-las todas e evitar qualquer problema.',
        },
      },
    },
    disabled: {
      features: {
        info: 'Você selecionou uma combinação de idioma/fonte que não suporta os seguintes recursos: <b>{features}</b> Esses recursos serão desativados nas legendas importadas.',
        tips: 'Isso pode estar relacionado aos caracteres suportados pela fonte que você escolheu. Altere a fonte ou o idioma para habilitar esses recursos.',
      },
    },
    upgrade: {
      to: 'Atualizar para {plan}',
    },
    deepgram: {
      error: {
        modal: {
          title: 'Ocorreu um erro de tradução',
          cta: 'Participar no Discord',
          text: 'Ocorreu um erro ao traduzir o áudio. Pode estar relacionado com a qualidade do áudio ou o idioma falado, mas não temos mais informações por agora. {br} Por favor, relate o seu problema no Discord ou envie-nos um e-mail para contact@autocut.fr.',
        },
      },
    },
    completing: {
      preset: {
        preview:
          'Completando a pré-visualização do preset... Pode levar alguns segundos.',
      },
    },
    chapters: {
      preview: {
        error:
          'Ocorreu um erro ao gerar a pré-visualização. Tente atualizar os carimbos de data/hora ou reiniciar o AutoCut se o problema persistir.',
      },
      step: {
        language: 'Selecionar clipes e idioma',
        editor: 'Editor de capítulos',
      },
      button: {
        chapters: 'Adicionar capítulos',
      },
      editor: {
        chapters: {
          title: 'Capítulos detetados',
        },
        parameters: {
          title: 'Parâmetros dos capítulos',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Descarregar ficheiro de capítulos do YouTube',
                success: 'chapter.txt foi guardado no seu projeto PPRO',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Selecione clipes na sua linha de tempo nos quais deseja basear os capítulos',
            text1:
              'Para usar capítulos, você precisa selecionar pelo menos um clipe com áudio na sua linha de tempo.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Gerar capítulos',
        },
      },
      adding: {
        chapters: 'Adicionando capítulos...',
      },
      backup: 'Fazendo backup da sua sequência...',
      analyse: 'Analisando o seu áudio...',
      getting: {
        chapters: 'Gerando capítulos...',
      },
      transcript: {
        finished: 'Os capítulos foram gerados',
      },
      disabled: {
        language:
          'O idioma selecionado ainda não é suportado pelo AutoChapters',
        file: {
          too: {
            long: 'Os clipes selecionados são muito longos. Por favor, selecione clipes com menos de {limit} minutos de duração.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format:
              'Formato de hora incorreto. Por favor, utilize o formato HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animado',
        },
        static: {
          option: 'Estático',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Selecione os clips em sua linha do tempo nos quais deseja basear o Detector Automático de Clips Virais',
              text1:
                'Para usar o Detector Automático de Clips Virais, você precisa selecionar pelo menos um clip com áudio em sua linha do tempo.',
            },
          },
        },
        disabled: {
          language:
            'O Detector Automático de Clips Virais não está disponível para este idioma ainda',
          file: {
            too: {
              long: 'Seus clips selecionados estão muito longos. Por favor, selecione clips que tenham menos de {limit} minutos de duração.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Gerar clips virais',
          },
        },
        button: {
          process: 'Criar clips',
        },
        selector: {
          title: 'Detectados {number} clipes potencialmente virais',
        },
        virality: {
          score: 'Pontuação de potencial viral',
        },
        step: {
          language: 'Selecionar clips e idioma',
          selector: 'Selecionar clips virais',
        },
        backup: 'Fazendo backup da sua sequência...',
        analyse: 'Analisando o seu áudio...',
        getting: {
          clips: 'Gerando clips virais...',
        },
        transcript: {
          finished: 'Clips virais foram gerados',
        },
        adding: {
          creating: {
            clips: 'Criando clips...',
          },
        },
      },
      score: 'Pontuação viral',
    },
    mode: {
      description: {
        silence: 'Remove os silêncios na sua linha do tempo',
        ai: 'Remove os silêncios na sua linha do tempo',
        podcast: 'Edita podcasts instantaneamente',
        repeat: 'Remove gravações ruins com IA',
        zoom: 'Adiciona automaticamente zooms',
        captions: 'Adiciona legendas animadas com IA',
        resize: 'Redimensiona sua sequência com IA',
        broll: 'Adiciona automaticamente B-Roll com IA',
        profanity: 'Censura palavras obscenas',
        chapter: 'Detecta capítulos e adiciona marcadores com IA',
        viral: 'Identifique shorts virais em vídeos longos com um clique',
      },
    },
    formatted: {
      numbers: {
        title: 'Números formatados',
        modal: {
          cta: 'Fechar modal',
          text: '<b>{number} palavras</b> foram substituídas por números na sua transcrição.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Selecione um modelo de transcrição',
          model: {
            '1': 'Modelo 1 (padrão)',
            '2': 'Modelo 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
