import {preload} from '@autocut/types/ElectronPreload';
import {ProcessTranscriptFunction} from '@autocut/types/Transcription';
import {compute} from '@autocut/utils/compute.utils';
import {processCaptionsChunksState} from '../../../pages/modes/captions/utils/processCaptionsChunks';
import {Utterance} from '@autocut/types/Deepgram';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {daVinci} from '@autocut/utils/davinci';
import {convertFramesToSeconds} from '@autocut/utils/frames';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';

export const handleCaptionsProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    const processProgress = initProcessProgress(
      'captions_transcript',
      'progress_steps_captions_transcript_mainProcess',
    );
    addProcessSteps(processProgress, [
      {
        id: 'captions_transcript_get_language',
        translationKey: 'progress_steps_captions_transcript_get_language',
        progress: 1,
      },
      {
        id: 'captions_transcript_render_wav',
        translationKey: 'progress_steps_captions_transcript_render_wav',
        progress: 1,
      },
      {
        id: 'captions_transcript_mainProcess',
        translationKey: 'progress_steps_captions_transcript_mainProcess',
        progress: '',
        countFor: 3,
      },
      {
        id: 'captions_transcript_split_chunks',
        translationKey: 'progress_steps_captions_transcript_split_chunks',
        progress: 1,
      },
    ]);

    startProcessProgress(processProgress);

    const parameters = getParametersForMode('caption');

    const language = getTranscriptionLanguage({
      languageOfTranscriptionValue: parameters.languageOfTranscription.value,
      usedModel,
    });
    endProcessStep(processProgress, 'captions_transcript_get_language');

    const {inPoint, outPoint} = await daVinci.timeline.getInOut();
    const [[inPointSeconds, outPointSeconds]] = await convertFramesToSeconds([
      [inPoint, outPoint],
    ]);
    const filePath = await daVinci.timeline.wav.export<string>({
      export_between_markers: true,
      mark_in: inPoint,
      mark_out: outPoint,
    });
    endProcessStep(processProgress, 'captions_transcript_render_wav');

    const transcriptProcess = compute.transcription({
      type: 'utterances',
      skipEmbedding: true,
      language,
      file: {
        path: filePath,
        start: inPointSeconds,
        end: outPointSeconds,
      },
    });

    updateProcessStep(processProgress, 'captions_transcript_mainProcess', {
      progress: {
        computeTaskId: transcriptProcess.requestId,
      },
    });

    const transcriptPath = await transcriptProcess;

    endProcessStep(processProgress, 'captions_transcript_mainProcess');

    if (!preload().fs.existsSync(transcriptPath)) return;

    const transcript = preload()
      .fs.readFileSync(transcriptPath, 'utf-8')
      .trim();

    let JSONTranscript: Utterance[] = [];
    try {
      JSONTranscript = JSON.parse(transcript);
    } catch (error) {
      console.error('Error while parsing the transcript', error);
      throw new Error('Error while parsing the transcript');
    }

    const finalChunks = await processCaptionsChunksState(
      JSONTranscript.flatMap(utterance => utterance.words),
    );

    endProcessStep(processProgress, 'captions_transcript_split_chunks');

    console.log('finalChunks', finalChunks, finalChunks.length);
  };
