import {BORDER_RADIUS, CARD_SIZE} from '@autocut/pages/Homepage/utils';
import * as React from 'react';
import css from './HomepageCard.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

export type HomepageCardProps = {
  disabled?: boolean;
  upgrade?: boolean;
  showTrialbanner?: boolean;
  additionalText?: string | JSX.Element;
  text: string;
  description: React.ReactNode;
  thumbnail: string;
  onClick: () => void;
};

export const HomepageCard = ({
  disabled,
  upgrade,
  showTrialbanner,
  additionalText,
  text,
  description,
  thumbnail,
  onClick,
}: HomepageCardProps) => {
  // const key = useAutoCutStore(state => state.user.key);

  const handleOnClick = () => {
    // if (disabled) {
    //   if (upgrade) openLinkInBrowser(`https://autocut.fr/app/&upgrade=${key}`); // TODO: fonction pour ouvrir le navigateur
    //   return;
    // }

    onClick();
  };

  return (
    <FlexContainer
      // className is used to display the homepage blobs
      className="card"
      style={{
        position: 'relative',
      }}
    >
      <FlexContainer
        className={`${css.card} ${disabled ? css.disabled : ''} ${
          upgrade ? css.upgrade : ''
        }`}
        onClick={handleOnClick}
        flexDirection="column"
        gap={8}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          borderRadius: BORDER_RADIUS,
          overflow: 'hidden',
        }}
      >
        {showTrialbanner ? <div className={css.trialBanner}>Trial</div> : null}

        <div
          className={css.thumbnail}
          style={{
            backgroundImage: `url(${thumbnail})`,
            backgroundSize: `${CARD_SIZE}px`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <FlexContainer
          flexDirection="column"
          gap={8}
          className={css.cardContent}
          justifyContent="flex-start"
        >
          <div
            className={css.text}
            style={{
              color: 'white',
              textAlign: 'center',
              fontSize: '12px',
              lineHeight: '14px',
              margin: '0 auto',
            }}
          >
            {text}
          </div>

          <p
            style={{
              color: 'white',
              opacity: 0.8,
              fontSize: '9px',
              lineHeight: '10px',
              textAlign: 'center',
            }}
          >
            {description}
          </p>
        </FlexContainer>
      </FlexContainer>

      {additionalText ? (
        <div className={css.additionalContainer}>
          {typeof additionalText === 'string' ? (
            <div className={css.additionalText}>{additionalText}</div>
          ) : (
            additionalText
          )}
        </div>
      ) : null}
    </FlexContainer>
  );
};
