const translations = {
  progress: {
    footer: {
      warning: 'Veuillez ne rien faire sur DaVinci, cela causera des problèmes',
    },
    steps: {
      global: {
        starting: 'Démarrage...',
        processing: 'Traitement...',
        ending: 'Fin...',
      },
      dev: {
        ping: 'Ping vers DaVinci Resolve...',
        pong: 'Pong vers DaVinci Resolve...',
      },
      common: {
        getSilences: 'Obtention des silences...',
        getAudioInfos: 'Obtention des infos audio...',
        readAudioFile: 'Lecture du fichier audio...',
        computeRMS: 'Analyse du fichier audio...',
        convertRMSToDB: 'Analyse du fichier audio...',
        exportDrt: 'Lecture de la timeline...',
        extractDrt: 'Lecture de la timeline...',
        getXmlTimelineFolderPath: 'Lecture de la timeline...',
        getXmlTimelineFullpath: 'Lecture de la timeline...',
        loadXmlTimeline: 'Lecture de la timeline...',
        writeFile: 'Écriture du fichier...',
        writeXml: 'Importation de la timeline modifiée...',
        updateDrt: 'Importation de la timeline modifiée...',
        importXml: 'Importation de la timeline modifiée...',
      },
      podcast: {
        mainProcess: 'Montage du podcast...',
        getVideoTracksWeight: 'Enregistrement des intervenants',
        getAudioTracks: 'Enregistrement des pistes audio',
        getPodcastInterval: 'Calcul de la durée du podcast',
        getPodcastDuration: 'Calcul de la durée du podcast',
        renders:
          'Rendu des pistes audio par Da Vinci\n(peut prendre quelques minutes)',
        getTalkingTimelines: 'Distribution des temps de parole',
        getTransitions: 'Calcul des changements de caméra',
        getCutTimecodes: 'Préparation des coupes',
        cutAtTimecodes: 'Coupes de la timeline',
      },
      silences: {
        mainProcess: 'Montage des silences...',
        getTimelineInfos: 'Obtention des infos de la timeline...',
        renders:
          'Rendu des pistes audio par Da Vinci\n(peut prendre quelques minutes)',
        convertAudioToMono: 'Traitement du fichier audio...',
        getCutTimecodes: 'Préparation des coupes...',
        cutAtTimecodes: 'Coupes de la timeline...',
        deleteSilentTrackItems: 'Suppression des silences...',
      },
      zoom: {
        mainProcess: 'Ajout de Zooms...',
        getTimelineInfos: 'Obtention des infos de la timeline...',
        getWavData:
          'Rendu des pistes audio par Da Vinci\n(peut prendre quelques minutes)',
        computeBestZoomIntervals: 'Calcul des meilleurs intervalles de zoom...',
        createVideoTrack: 'Création des pistes vidéo...',
        createZoomFusionComps: 'Importation des compositions Fusion...',
      },
      captions: {
        transcript: {
          mainProcess: 'Génération de la transcription...',
          get_language: 'Obtenir la langue',
          render_wav:
            'Rendu des WAV dans DaVinci (peut prendre plusieurs minutes)',
          split_chunks: 'Division en morceaux',
          uploadFile: 'Téléversement du fichier...',
          getTranscript:
            "Génération de la transcription par l'IA\n(peut prendre quelques minutes)",
          writeFile: 'Écriture du fichier...',
        },
        generation: {
          mainProcess: 'Ajout de sous-titres...',
          initialization: 'Initialisation des sous-titres...',
          readTranscript: 'Lecture de la transcription...',
          getTimelineInfos: 'Obtention des infos de la timeline...',
          appendAdjustmentLayer: "Ajout d'une couche d'ajustement...",
          importFusionComp: 'Importation des compositions Fusion...',
        },
      },
    },
  },
  launchManager: {
    steps: {
      preload: {
        title: 'Préchargement',
      },
      python: {
        title: 'Installer Python',
      },
      ffmpeg: {
        title: 'Installer FFMPEG',
      },
      resources: {
        title: 'Installer les ressources',
      },
      computeIPC: {
        title: "Se connecter à l'IA",
      },
      daVinciIPC: {
        title: 'Se connecter à DaVinci Resolve',
      },
    },
    errors: {
      compute:
        "Impossible de se connecter à l'IA de calcul, veuillez contacter le support",
      davinci:
        "Impossible de se connecter à DaVinci, veuillez vous assurer que DaVinci Resolve est en cours d'exécution et que le script AutoCut a été exécuté",
      cta: 'Réessayer',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Essai',
        upgrade: 'Mettre à niveau',
        coming_soon: 'Bientôt disponible',
        version_not_supported: 'Version PPro non prise en charge',
        upgrade_to: 'Mettre à niveau vers {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title:
            'Sélectionnez une timeline avec au moins 2 pistes vidéo et audio',
          label: 'Sélectionner la timeline',
          descriptions: {
            '1': "Allez à l'onglet d'édition",
            '2': 'Naviguez vers la timeline que vous souhaitez éditer',
          },
          cta: 'Sélectionner la timeline active',
        },
        customization: {
          label: 'Paramètres du podcast',
          steps: {
            speakers: {
              title: 'Intervenants',
              description:
                'Ajoutez vos intervenants en attribuant à chacun une piste audio et en entrant leurs noms respectifs. Une seule piste audio peut être sélectionnée par intervenant. Si plusieurs intervenants partagent une seule piste audio, entrez les deux noms (par exemple, "Alice & Bob").',
              header: {
                audio: 'Piste audio',
                name: "Nom de l'intervenant",
              },
              textInputPlaceholder: "Entrez le nom de l'intervenant...",
              trackTemplate: 'Piste A{index}',
              cta: 'Ajouter un intervenant',
            },
            cameras: {
              title: 'Caméras',
              description:
                "Ajoutez vos caméras en attribuant à chacune une piste vidéo et en sélectionnant quels intervenants sont visibles à l'écran. Une seule piste vidéo peut être sélectionnée par caméra. Vous pouvez sélectionner n'importe quel nombre d'intervenants par caméra.",
              header: {
                audio: 'Piste vidéo',
                name: 'Intervenant(s)',
              },
              speakerSelectPlaceholder: "Sélectionner l'intervenant(e)(s)...",
              trackTemplate: 'Piste V{index}',
              cta: 'Ajouter une caméra',
            },
            cameraTime: {
              title: "Durées d'affichage des caméras",
              description:
                'Définissez la durée minimale et maximale pendant laquelle une caméra sera affichée avant de changer.',
              presets: {
                title: 'Préréglages par défaut',
                items: {
                  calm: 'Calme',
                  paced: 'Rythmée',
                  energetic: 'Énergique',
                  hyperactive: 'Hyperactive',
                },
              },
              minimumCamTime: {
                title: "Temps d'affichage minimum de la caméra",
                description:
                  'Durée minimale (en secondes) pendant laquelle une caméra sera affichée sans changer.',
                unit: 'secondes',
              },
              maximumCamTime: {
                title: "Temps d'affichage maximum de la caméra",
                description:
                  "Durée maximale (en secondes) pendant laquelle une caméra sera affichée avant d'être forcée à changer.",
                unit: 'secondes',
              },
            },
            silentVideoClips: {
              title: 'Clips vidéo silencieux',
              description:
                'Choisissez si vous souhaitez supprimer ou désactiver les parties inutilisées du podcast.',
              items: {
                remove: 'Supprimer',
                disable: 'Désactiver',
              },
            },
          },
          cta: {
            disable: 'Modifier et désactiver les clips inutilisés',
            delete: 'Modifier et supprimer les clips inutilisés',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: "Le temps de caméra minimum doit être d'au moins 0",
            higherThanMax:
              'Le temps de caméra minimum doit être inférieur au temps de caméra maximum',
          },
          maximumCamTime: {
            min: "Le temps de caméra maximum doit être d'au moins 0",
          },
          deleteUnusedClips: {
            required:
              'Vous devez choisir de supprimer ou désactiver les clips inutilisés',
          },
          speakers: {
            videoTracks: {
              min: 'Vous devez sélectionner au moins une piste vidéo par interlocuteur',
            },
            audioTrack: {
              min: 'Vous devez sélectionner une piste audio pour chaque interlocuteur',
            },
          },
        },
      },
      usageStats: {
        title: "Statistiques d'utilisation",
        select: {
          label: 'Sélectionner un mode',
        },
        stats: {
          thisMonth: 'Ce mois-ci',
          allTime: 'Tout le temps',
          mostUsed: 'Le plus utilisé',
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'Niveau de bruit',
          description:
            'Niveau sonore considéré comme silence. Vous pouvez voir les effets sur la visualisation ci-dessous.',
        },
        silenceSize: {
          title: 'Durée du silence',
          items: {
            silence: {
              title: 'Supprimer les silences plus longs que',
              description:
                "Durée minimale (en millisecondes) qu'un silence doit avoir pour être supprimé.",
              unit: 'millisecondes',
            },
            talk: {
              title: 'Supprimer les paroles plus courtes que',
              description:
                "Durée minimale (en millisecondes) qu'un segment de parole doit avoir pour être conservé.",
              unit: 'millisecondes',
            },
          },
        },
        paddingSize: {
          title: 'Amortissement',
          items: {
            before: {
              title: 'Amortissement avant',
              description:
                'Temps de silence avant un bruit - pour éviter une reprise soudaine de la parole',
              unit: 'millisecondes',
            },
            after: {
              title: 'Amortissement après',
              description:
                'Temps de silence après un bruit - pour éviter des interruptions brutales de la parole',
              unit: 'millisecondes',
            },
          },
        },
        transition: {
          params: {
            title: 'Transitions',
            warning:
              'Vous ne pouvez pas utiliser de transitions sans supprimer les silences.',
            default: {
              title: 'Par défaut',
              choices: {
                none: 'Aucun',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                "Cela ajoutera l'amortissement après et/ou avant chaque coupe. Créant une transition plus fluide entre les coupes. Si sélectionné, cela créera une nouvelle piste pour les transitions.",
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Les deux',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Puissance constante',
              },
            },
          },
          progress: {
            insert: 'Insertion de nouvelles pistes audio…',
            move: "Déplacement de l'audio vers de nouvelles pistes pour les transitions…",
            handling: 'Ajout des transitions : {transition}',
          },
        },
        preview: {
          title: 'Aperçu',
          description:
            "Aperçu des segments préservés et supprimés. Utilisez les flèches pour avancer ou reculer, ou centrez l'aperçu en fonction de la position du curseur de temps.",
          legend: {
            kept: 'Conservé',
            deleted: 'Supprimé',
            margin: 'Marges',
          },
          cta: "Générer l'aperçu",
          center: "Centrer sur l'indicateur",
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'Le niveau de bruit doit être un nombre négatif',
            int: 'Le niveau de bruit doit être un nombre',
          },
          minTimeOfSilence: {
            required: 'La durée minimale de silence est requise',
            min: "La durée minimale de silence doit être d'au moins 0",
          },
          minTimeOfTalk: {
            required: 'La durée minimale de parole est requise',
            min: "La durée minimale de parole doit être d'au moins 0",
          },
          marginBefore: {
            min: "La marge avant doit être d'au moins 0",
          },
          marginAfter: {
            min: "La marge après doit être d'au moins 0",
          },
        },
      },
    },
    zoom: {
      parameters: {
        preview: {
          title: 'Aperçu du zoom',
          description: "Définir le point d'ancrage",
          maximumZoom: 'Zoom maximum',
          errorMessage:
            "Une erreur s'est produite lors du chargement de l'aperçu",
        },
        zoomTypes: 'Types de zoom',
        defaultsPresets: {
          title: 'Préréglages par défaut',
          calm: 'Calme',
          paced: 'Rythmé',
          energetic: 'Énergétique',
          hyperactive: 'Hyperactif',
        },
        zoomCoverage: 'Couverture totale du zoom',
        constraint: {
          title: 'Contraindre les zooms à clip',
          helper:
            "Si oui, aucun zoom ne couvrira plus d'un clip. Si non, votre sélection entière sera traitée comme un seul fichier audio et les zooms ignoreront les clips individuels dans la timeline.",
        },
        maximumZoomDuration: 'Durée maximale du zoom',
        smoothZooms: {
          title: 'Zooms fluides',
          coverage: 'Couverture des zooms fluides',
          nervousness: 'Nervosité',
        },
        dynamicZooms: {
          title: 'Zooms dynamiques',
          coverage: 'Couverture des zooms dynamiques',
        },
      },
      types: {
        smooth: 'Fluide',
        dynamic: 'Dynamique',
      },
      footerButton: 'Zoom',
      infoText:
        'AutoZoom ajoutera automatiquement du zoom à chaque clip sélectionné en fonction des décibels.',
    },
    captions: {
      steps: {
        language: {
          button: "Aller à l'éditeur de sous-titres",
        },
        customization: {
          button: 'Ajouter des sous-titres',
          parts: {
            style: {
              title: 'Style des sous-titres',
              modal: {
                title: 'Modifier le style',
                apply: 'Appliquer le style',
                save: 'Appliquer le style et enregistrer comme préréglage',
                text: {
                  title: 'Police',
                  font: 'Nom de la police',
                  help: 'Comment ajouter une nouvelle famille de polices ?',
                  color: 'Couleur',
                },
                animation: {
                  title: 'Animation',
                  wordBox: 'Boîte avec texte',
                },
                outline: {
                  title: 'Contour',
                  width: 'Épaisseur',
                },
                box: {
                  title: 'Boîte de texte',
                  paddingX: 'Marge X',
                  paddingY: 'Marge Y',
                  opacity: 'Opacité',
                  radius: 'Rayon',
                  disabled:
                    'La police que vous avez choisie ne prend pas en charge la fonctionnalité de boîte pour la langue que vous avez sélectionnée.',
                  tips: 'Choisissez une police qui prend en charge la langue que vous avez sélectionnée si vous souhaitez utiliser cette fonctionnalité.',
                },
              },
            },
            preview: {
              title: 'Aperçu',
              modal: {
                title: 'Position & taille',
                apply: 'Appliquer le style',
                fontSize: 'Taille de police',
                nbLines: 'Nombre de lignes',
                maxWidth: 'Largeur maximale',
                help: "L'aperçu est encore en développement et peut ne pas être précis.",
              },
            },
            transcription: {
              title: 'Modifier la transcription',
              modal: {
                save: 'Sauvegarder',
                select:
                  'Sélectionner les sous-titres que vous souhaitez modifier',
                placeholder:
                  'Sélectionnez la partie de la transcription que vous souhaitez modifier/personnaliser',
                formatNumbers: 'Formater les numéros',
                checkTranscript: {
                  title: 'Avez-vous vérifié la transcription ?',
                  description:
                    "Nous avons remarqué que vous n'avez pas encore vérifié la transcription.",
                  dontShowAgain: 'Ne pas afficher ce message à nouveau',
                },
              },
            },
            presets: {
              delete: {
                title: 'Supprimer les préréglages',
                cancel: 'Annuler',
                confirm: 'Supprimer le préréglage',
              },
              search: {
                title: 'Préréglages de la communauté',
                input: 'Rechercher un préréglage',
                cancel: 'Annuler',
                confirm: 'Importer le préréglage',
                usage: 'Utilisé par {count} utilisateurs',
                error:
                  'Erreur lors de la copie du préréglage, veuillez nous contacter sur discord ou à contact@autocut.fr',
              },
              create: {
                title: 'Créer un nouveau préréglage',
                placeholder: 'Nom du préréglage (au moins deux mots)',
                public: 'Partager avec la communauté - public',
                cancel: 'Annuler',
                confirm: 'Créer le préréglage',
                alreadyExists:
                  'Ce préréglage existe déjà. Veuillez choisir un autre nom.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              "Les préréglages utilisateur sont manquants, veuillez réinitialiser vos paramètres dans le menu de l'en-tête.",
          },
          position: {
            xPercentage: {
              min: "La position x du texte doit être d'au moins 0",
              max: 'La position x du texte doit être au maximum 1',
            },
            yPercentage: {
              min: "La position y du texte doit être d'au moins 0",
              max: 'La position y du texte doit être au maximum 1',
            },
            emojiXPercentage: {
              min: "La position x de l'emoji doit être d'au moins 0",
              max: "La position x de l'emoji doit être au maximum 1",
            },
            emojiYPercentage: {
              min: "La position y de l'emoji doit être d'au moins 0",
              max: "La position y de l'emoji doit être au maximum 1",
            },
          },
          text: {
            fontSize: {
              int: 'La taille de la police doit être un nombre',
              min: "La taille de la police doit être d'au moins 1",
            },
            font: {
              missing:
                'Il manque quelque chose dans la police que vous avez sélectionnée... Veuillez la sélectionner à nouveau ou en choisir une autre.',
            },
          },
          outline: {
            width: {
              int: 'La largeur du contour doit être un nombre',
              min: "La largeur du contour doit être d'au moins 0",
            },
          },
          formating: {
            nbLines: {
              int: 'Le nombre de lignes doit être un nombre',
              min: "Le nombre de lignes doit être d'au moins 1",
            },
            maxWidth: {
              int: 'La largeur maximale doit être un nombre',
              min: "La largeur maximale doit être d'au moins 0",
            },
          },
          textBox: {
            xPadding: {
              int: 'Le padding x doit être un nombre',
              min: "Le padding x doit être d'au moins 0",
            },
            yPadding: {
              int: 'Le padding y doit être un nombre',
              min: "Le padding y doit être d'au moins 0",
            },
            opacity: {
              int: "L'opacité doit être un nombre",
              min: "L'opacité doit être d'au moins 0",
              max: "L'opacité doit être au maximum 100",
            },
            radius: {
              int: 'Le rayon doit être un nombre',
              min: "Le rayon doit être d'au moins 0",
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: "Définir les points d'entrée/sortie",
          descriptions: {
            '1': "Veuillez définir les points d'entrée et de sortie dans Davinci sur la portion de la timeline que vous souhaitez couper",
            '2': "Vous pouvez manuellement couper le son des clips ou des pistes si vous ne souhaitez pas qu'ils soient inclus dans l'analyse audio",
          },
          cta: "Confirmer les points d'entrée/sortie",
        },
        transcription: {
          title: 'Sélectionner le langage du clip audio',
        },
        customization: {
          title: 'Personnaliser les paramètres',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Entrez votre clé de licence pour activer AutoCut.',
      placeholder: 'Entrez votre clé de licence',
    },
    trial: {
      title: 'Obtenez un essai gratuit',
      steps: {
        email: {
          title: 'Entrez votre adresse e-mail',
          subtitle:
            'Entrez votre adresse e-mail pour recevoir une licence d’essai gratuite',
          placeholder: 'email@email.com',
          cta: 'Suivant',
        },
        sendingEmail: {
          title: 'Envoi de l’e-mail',
          success: 'E-mail envoyé avec succès à {email}',
        },
      },
    },
    errors: {
      no_key: 'Veuillez entrer une clé de licence',
      is_key_valid_error:
        'Erreur lors de la vérification de la clé, veuillez réessayer plus tard.\n',
      invalid_response: 'La réponse ne provient pas du serveur.',
      cant_read_host_error: "Impossible de lire le nom de l'ordinateur.",
      cant_read_uuid_error: "Impossible de lire l'UUID de l'ordinateur.",
    },
  },
  modals: {
    endProcess: {
      title: 'Traitement terminé',
      time: 'Traitement effectué en {minutes} minutes et {seconds} secondes !',
      timeSaved:
        'Vous avez économisé : <b>{minutesSaved}</b> minute(s) et <b>{secondsSaved}</b> seconde(s)',
      timeSavedAllTime:
        'Depuis que vous utilisez AutoCut, vous avez économisé : {hourSaved} heure(s) et {minutesSaved} minute(s) ! 🎉',
      feedback:
        "N'hésitez pas à nous faire part de vos retours sur la qualité du résultat :",
      review: 'Évaluez AutoCut !',
      captions: {
        title: 'Sous-titres ajoutés !',
        footer:
          "Si le style ou la transcription des sous-titres ne vous convient pas, vous pouvez revenir à l'éditeur de sous-titres.",
        button: "Retour à l'éditeur de transcription",
        text_1: 'Vos sous-titres animés ont été ajoutés ! 🎉',
        text_2: 'Si vous avez besoin de modifier des sous-titres :',
        list_1:
          'Placez le curseur de la timeline sur les sous-titres à mettre à jour',
        list_2: 'Allez sur la page fusion',
        list_3:
          'Cliquez sur le nœud TextPlus et mettez à jour manuellement les sous-titres',
        text_3:
          "Si vous voulez plus d'astuces, vous pouvez visiter ce <a>tutoriel</a>.",
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Version de DaVinci Resolve non prise en charge',
      currentVersion:
        'Nous avons détecté que vous utilisez DaVinci Resolve {version}',
      text: "AutoCut n'est compatible qu'avec DaVinci Resolve 18.6 et les versions supérieures.",
      update: 'Vous pouvez le mettre à jour ici : <a>Site web de DaVinci</a>',
    },
  },
  globals: {
    defaults: {
      components: {
        KebabMenu: {
          account: 'Espace client',
          bug: 'Rapport de bug',
          review: 'Noter AutoCut',
          tuto: 'Tutoriel vidéo',
          logout: 'Déconnexion',
          settings: 'Réinitialiser les paramètres',
          featureRequest: 'Demande de fonctionnalité',
          academy: 'Aide',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} jours restants',
          info: 'Licence {licenceType}',
          trialButton: 'Obtenir plus',
          paidButton: 'Gérer',
        },
        PromoBanner: {
          promo: "Jusqu'à -20% pour 24 heures",
          shop: 'Achetez maintenant',
        },
        FileInput: {
          upload_screenshot: "Téléchargez votre capture d'écran",
        },
        LinkToUserFileInput: {
          send_log_link_to_user_file: {
            title:
              'Veuillez fournir un lien vers votre fichier vidéo et audio.',
            description:
              '(WeTransfer, Google Drive ou tout autre service de partage de fichiers)',
            helper:
              "Pour télécharger votre fichier sur WeTransfer, allez sur wetransfer.com et cliquez sur 'Télécharger des fichiers'. Après cela, vous obtiendrez un lien, copiez-le ici.",
            end: 'Cela nous aidera à résoudre rapidement votre problème.',
          },
        },
        Select: {
          'no-more': "Plus d'options disponibles",
          'select-all': 'Tout sélectionner',
          placeholder: 'Sélectionnez une option',
        },
        PreviewFrame: {
          previewOnIndicator: 'Aperçu sur indicateur',
        },
      },
    },
    misc: {
      retry: 'Réessayer',
    },
    units: {
      second: 'secondes',
      millisecond: 'millisecondes',
      percentage: '%',
      px: 'px',
    },
  },
  old: {
    text: {
      errorSelection:
        "La sélection n'est pas valide.{br}Vous ne pouvez sélectionner que des pistes directement issues d'un fichier audio ou vidéo.{br}Si vous avez sélectionné une séquence, découpez les vidéos dans la séquence.",
      loading: 'Découpage en cours…',
      transition: {
        step: {
          title: 'Transitions',
          j_l_cut: 'J-Cut & L-Cut',
          default: 'Défaut',
          premiere: {
            pro: 'DaVinci Resolve',
          },
          none: 'Aucune',
          j_cut: 'J-Cut',
          l_cut: 'L-Cut',
          both: 'Les deux',
        },
        constant: {
          power: 'Puissance constante',
        },
        progress: {
          insert: 'Insertion des nouvelles pistes audio…',
          move: "Déplacement de l'audio vers de nouvelles pistes pour les transitions…",
          handling: 'Ajout des transitions : {transition}',
        },
      },
      Step4: {
        AutoZoom: {
          ratio: 'Coefficient maximum',
          step: 'Pourcentage de vidéo zoomé',
        },
        Title: 'AutoZoom (Bêta)',
      },
      Step5: {
        Backup: 'Activer la sauvegarde',
        Title: 'Prévisualisation',
      },
      autocutv2:
        "AutoCut v2 est la version originale, il y a beaucoup d'options à configurer pour une détection parfaite du silence.",
      autocutAi:
        "AutoCut AI vous permet de détecter automatiquement les silences avec un seul paramètre et de laisser l'IA faire le reste.",
      autocutPodcast:
        'AutoCut Podcast vous permet de monter des podcasts vidéo en quelques secondes. Il vous suffit de fournir la configuration de votre caméra et de profiter de votre vidéo finale fraîchement éditée.',
      autocutRepeat:
        'Laissez AutoCut Repeat supprimer toutes les mauvaises prises de votre vidéo et ne conserver que les meilleures.',
      autocutCaptions:
        'Générez des sous-titre dans le style de TikTok pour vos vidéos, à une vitesse fulgurante.',
      Step3: {
        Remove: 'Supprimé',
        Keep: 'Conservé',
        CenterButton: 'Centrer sur le curseur',
        Previsualisation: {
          __text__: 'Prévisualisation : ',
          loading: 'chargement…',
        },
        Noise: {
          unit: 'décibels',
        },
        DeleteSilence: 'Supprimer les silences',
        KeepSilence: {
          Yes: 'OUI',
          No: 'NON',
        },
        Title: 'Régler le niveau de bruit',
      },
      Step2: {
        After: 'Marge à la fin',
        Before: 'Marge au début',
        Title: 'Marges',
      },
      Step2Ai: {
        Title: 'Vérifier la prévisualisation',
      },
      Step1: {
        Talks: 'Supprimer les bruits de moins de',
        Silences: 'Supprimer les silences de plus de ',
        Title: 'Tailles des silences',
      },
      Step1Ai: {
        Silences: "Choisissez l'agressivité de l'IA",
        Title: "Agressivité de l'IA",
      },
      Step1Podcast: {
        Title: 'Participants',
      },
      step6: {
        selected: {
          clips: 'Clips choisis',
        },
      },
      NoInternet: "L'extension nécessite d'être connecté à internet.",
      noClip: {
        title: 'Selectionnez un ou plusieurs clips dans votre timeline',
        no_selection:
          'Si AutoCut ne réagit pas, essayez de redémarrer DaVinci Resolve.',
        tuto: {
          __text__: "Besoin d'aide ? N'hésitez pas à consulter notre",
          link: 'tutoriel vidéo',
        },
        loading: {
          bar: 'Chargement - Analyse de la séquence en cours...',
        },
      },
      needPProUpdate:
        'Votre version de DaVinci Resolve doit être la 15 ou plus récente.',
      No_Key: 'Veuillez entrer une clé',
      Login: {
        SubmitButton: 'Activer',
        SubmitButton2: 'Activer',
        PasteButton: 'Coller',
      },
      Welcome: {
        Title: 'Bienvenue',
        freeTrial: "Obtenir une licence d'essai",
        UseLicence: 'Utiliser une licence',
        BuyLicence: 'Acheter une licence',
      },
      UseLicence: {
        Title: 'Utiliser une licence',
        Text: 'Entrez votre clé de licence et commencez à utiliser AutoCut',
      },
      GetLicence: {
        Title: "Recevez une licence d'essai gratuite par email",
        Select: 'Comment avez-vous découvert Autocut ?',
        Text: 'Entrez votre email et recevez votre clé de licence gratuite',
        Button: 'Obtenir une licence gratuite',
      },
      UseNewLicence: {
        Title: "Votre licence vous a été envoyée par mail à {s} à l'instant !",
        Text: "Consultez vos e-mails, entrez votre clé de licence gratuite pour commencer à utiliser AutoCut. Si vous n'avez pas reçu la clé dans quelques minutes, pensez à vérifier vos spams ;)",
        Resend: "Vous n'avez pas reçu l'e-mail ?",
        ResendButton: "Renvoyer l'e-mail à {mail}",
        ResendSucces: 'E-mail renvoyé !',
      },
      NoFFMPEG:
        'Il y a eu une erreur avec FFMPEG, veuillez contacter le support',
      legend: {
        kept: 'Conservé',
        deleted: 'Supprimé',
        margin: 'Marges',
      },
      yes: 'Oui',
      no: 'Non',
      undo: {
        modal: "Vous n'êtes pas satisfait des découpes ?",
      },
      since: {
        your: {
          last: {
            visit: 'Depuis votre dernière visite',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted:
              'Nous sommes désolés, mais il semble que le fichier {filename} ait un format non supporté...',
            speed:
              'Nous sommes désolés, mais AutoCut ne permet pas de découper des clips si vous avez modifié leurs vitesses…',
            multi: {
              audio:
                "Nous sommes désolés, mais AutoCut risque de ne pas fonctionner correctement si vous ne sélectionnez pas toutes les pistes audio d'un fichier.",
              stream:
                "Nous sommes désolés, mais il semble qu'un de vos fichiers sélectionnés comporte plusieurs flux audio...",
            },
            '51_stereo':
              "Nous sommes désolés, mais il semblerait qu'un de vos fichiers sélectionnés possède une configuration audio 5.1 en stéréo dans DaVinci Resolve...",
            audio: {
              layout:
                "Nous sommes désolés, mais il semble que l'un de vos fichiers sélectionnés une disposition audio non supportée…",
            },
            remote: {
              file: 'Nous nous excusons, mais il semble que le fichier {filename} ne soit pas accessible...',
            },
          },
        },
        maintained: {
          modal:
            'Veuillez mettre à jour DaVinci Resolve pour utiliser AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted:
                "Veuillez sélectionner d'autres clips pour pouvoir les découper.",
              speed:
                "Veuillez réinitialiser la vitesse de vos clips (à 100%) ou sélectionner d'autres clips pour pouvoir les découper.",
              multi: {
                audio:
                  "Veillez à sélectionner tous les canaux audio et à les laisser dans l'ordre par défaut de Première Pro.",
                stream:
                  "Veuillez sélectionner d'autres clips pour pouvoir les couper.",
              },
              '51_stereo':
                "Veuillez sélectionner d'autres clips ou modifier la configuration des canaux audio de votre séquence pour pouvoir les couper.",
              audio: {
                layout:
                  "Veuillez sélectionner d'autres clips pour pouvoir les couper, si le problème persiste, veuillez nous envoyer une capture d'écran de votre séquence",
              },
              remote: {
                file: 'Veuillez vous assurer que les fichiers sont disponible sur votre ordinateur et non dans un cloud',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence:
            'Saisissez votre clé de licence et commencez à utiliser AutoCut !',
        },
      },
      no_audios:
        'Cet audio sera utilisé pour détecter, couper et supprimer les silences.',
      step1: {
        podcast: {
          title: 'Durées des caméras',
          minimum: 'Durée minimale de la caméra',
          maximum: 'Durée maximale de la caméra',
        },
      },
      silences: {
        step: {
          delete: {
            __text__: 'Supprimer les {mode}',
            keep: {
              spaces: 'Supprimer les {mode} mais conserver les espaces',
            },
          },
          keep: 'Garder les {mode}',
          mute: 'Rendre muets les {mode}',
        },
      },
      repetitions: 'répétitions',
      podcast: {
        add: {
          speaker: 'Ajouter un interlocuteur',
          camera: 'Ajouter une caméra',
        },
        track: 'Piste',
        camera: {
          title: 'Caméras',
          helper:
            "Ajoutez vos caméras et précisez quel interlocuteur est visible sur chaque caméra. Vous pouvez sélectionner un nombre quelconque d'interlocuteurs par caméra.",
        },
        speaker: 'Interlocuteur',
        all: {
          speakers: 'Tous les interlocuteurs',
        },
      },
    },
    help: {
      bug: 'Rapport de bug',
      review: 'Noter AutoCut',
      multipiste: 'Multipiste ? Consultez le tutoriel vidéo',
      tuto: 'Tutoriel vidéo',
      academy: 'Aide',
      buy: 'Acheter une licence',
      account: 'Espace client',
      logout: 'Déconnexion',
      reset: {
        settings: 'Réinitialiser les paramètres',
      },
      transition: {
        step: 'Cela ajoutera un espacement après et/ou avant chaque coupe. Créant une transition plus douce entre les coupes. Si sélectionné, cela créera une nouvelle piste pour les transitions.',
      },
      transcription: {
        provider: {
          step: 'La qualité et le temps de la transcription dépendent du modèle sélectionné.',
        },
      },
    },
    bugBtnDialog: 'Envoyez-nous un e-mail à : contact@autocut.fr',
    ButtonCutText: 'Découper et garder les silences',
    ButtonCutAndDeleteText: 'Découper et supprimer',
    OptionCutText: 'Découper et garder les silences',
    OptionCutAndDeleteText: 'Découper et supprimer les silences',
    first: {
      launch: {
        error:
          "Une erreur s'est produite lors du lancement d'AutoCut, veuillez redémarrer l'extension.",
      },
    },
    helper: {
      Step4: {
        '1': "Si vous utilisez l'option AutoZoom, après le découpage, les clips seront zoomés.",
        '2': 'Le coefficient de zoom est calculé en fonction des valeurs données ci-dessous et du volume maximum du sous-clip.',
        '3': 'Sélectionnez le pourcentage de vidéo qui sera zoomé.',
      },
      Step5:
        "La sauvegarde permet d'annuler toutes les modifications après les découpes",
      Step2: {
        '1': 'Temps de silence conservé avant un bruit.',
        '2': 'Temps de silence conservé après un bruit.',
      },
      Step1: {
        '1': "Temps minimum en seconde qu'un silence doit avoir pour être supprimé.",
        '2': "Temps minimum en seconde qu'un bruit doit avoir pour être conservé.",
        podcast: {
          minium:
            'Durée minimale (en secondes) pendant laquelle une caméra reste affichée sans changement.',
          maximum:
            "Durée maximale (en secondes) pendant laquelle une caméra reste affichée avant d'être forcée à changer.",
        },
      },
      Step1Ai:
        "L'agressivité déterminera la quantité de silence que l'IA retirera, le minimum est 1 et le maximum est 4.",
      Step3: {
        '1': 'Niveau sonore considéré comme du silence. Vous pouvez voir les effets sur la visualisation ci-dessous.',
        '2': 'Prévisualisation des morceaux conservés et supprimés. Vous pouvez avancer ou reculer grâce aux flèches ou centrer la prévisualisation par rapport à la position du curseur de temps.',
        '3': 'Si vous ne supprimez pas les silences, les clips seront coupés et les silences ne seront pas supprimés.',
      },
      Save: {
        Default:
          'Sauvegarde des paramètres. Vous pouvez sauvegarder les paramètres actuels, ils seront chargés lors du prochain démarrage.',
      },
      Undo: {
        Cut: 'Annuler la dernière découpe. Vous pouvez récupérer les clips que vous aviez juste avant de les découper.',
      },
      speakers: {
        podcast: {
          video: {
            tracks:
              'Sélectionnez les pistes vidéo où le locuteur apparaît. Vous pouvez sélectionner un nombre quelconque de pistes.',
          },
          audio: {
            tracks:
              "Sélectionnez la piste audio où l'on peut entendre l'interlocuteur, vous ne pouvez sélectionner qu'une seule piste par interlocuteur.",
          },
        },
      },
    },
    warn: {
      Step5:
        'Attention : si vous désactivez la sauvegarde, vous ne pourrez pas annuler vos découpes',
    },
    title: {
      Step5: {
        Backup: 'Activer la sauvegarde',
      },
      undo: {
        modal: 'Terminé !',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Un de vos clips a un format non pris en charge',
            speed:
              'AutoCut ne supporte pas les clips dont la vitesse a été changée',
            multi: {
              audio: 'AutoCut supporte mal les pistes audio multiples',
              stream: 'Un de vos clips contient plusieurs flux audio',
            },
            '51_stereo':
              'Un de vos clips présente une configuration audio 5.1 en stéréo',
            audio: {
              layout:
                'Un de vos clips comporte une disposition audio non supportée',
            },
            remote: {
              file: "L'un de vos clips ne se trouve pas sur votre ordinateur",
            },
          },
        },
        maintained: {
          modal:
            "AutoCut n'est pas pris en charge sur cette version de DaVinci Resolve",
        },
      },
      use: {
        trial: {
          licence: 'Consultez vos e-mails et saisissez votre clé de licence',
        },
        paid: {
          licence: 'Utilisez votre clé de licence',
        },
      },
      no_audios: "Il n'y a pas d'audio dans votre sélection",
      banner: {
        podcast: {
          beta: "Merci d'essayer notre nouveau mode Podcast.",
        },
      },
      silences: {
        step: 'Gérer les {mode}',
      },
      podcast: {
        silences: {
          step: 'Supprimer ou rendre muets les silences',
        },
      },
    },
    secondUnit: 'secondes',
    millisecondUnit: 'millisecondes',
    percentageUnit: '%',
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut et supprimer les silences',
          l_cut: 'L-Cut et supprimer les silences',
          both: 'J&L-Cut et supprimer les silences',
          constant: {
            power:
              'Couper, supprimer les silences et appliquer une transition audio',
          },
          none: 'Couper et supprimer les {mode}',
          keep: {
            spaces: {
              none: 'Couper les {mode} et conserver les espaces',
            },
          },
        },
        keep: {
          none: 'Couper et garder les {mode}',
        },
        mute: {
          none: 'Couper et rendre muets les {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Obtention des pistes...',
        silences: 'Calcul des silences...',
      },
      edit: {
        track: 'Montage de la piste {indexTrackInfo}: {current} sur {max}',
      },
      final: {
        message: {
          __text__: 'Succès ! Votre podcast a été monté',
          error: "Une erreur s'est produite lors du montage de votre podcast",
        },
      },
      button: {
        disable: 'Monter et désactiver les clips inutilisés',
        delete: 'Monter et supprimer les clips inutilisés',
      },
      minimum: {
        higher: {
          maximum:
            'Le temps de caméra minimal doit être inférieur au temps de caméra maximal.',
        },
      },
      empty: {
        audio: {
          track: 'Les pistes audio ne peuvent pas être vides.',
        },
        video: {
          track: 'Les pistes vidéo ne peuvent pas être vides.',
        },
      },
      not: {
        available: {
          title:
            'Sélectionner une séquence avec au moins 2 pistes vidéo et audio',
        },
      },
      preset: {
        title: 'Préréglages par défaut',
        calm: 'Calme',
        paced: 'Mesuré',
        energetic: 'Énergique',
        hyperactive: 'Hyperactif',
      },
    },
    error: {
      UseLicence: {
        __text__:
          'Vous pouvez dissocier votre clé de votre autre ordinateur grâce à',
        link: "l'espace client AutoCut.",
      },
      modal: {
        title: {
          subsequenceNotReachable:
            'La sous-séquence ne peut pas être atteinte.',
          unknown: 'Une erreur inconnue est survenue',
          noSpace: "Une erreur s'est produite : pas assez d'espace libre",
          exportUnknown:
            "DaVinci Resolve a rencontré une erreur lors de l'exportation",
          invalidInputData:
            "Une erreur s'est produite lors de la génération d'un fichier temporaire",
          fileNotFound:
            "Une erreur s'est produite lors de la lecture d'un de vos fichiers",
          scriptNotLoaded: "Une erreur s'est produite au lancement d'AutoCut",
          ffmpegError: "Nous n'avons pas pu lire un de vos fichier.",
          exportCancelled: 'Exportation annulée',
          timelineMuted: 'Aucune piste audible',
          noAudibleAudio: "Pas d'audio audible",
          offline: 'Il semble que vous soyez hors ligne',
          resourceTimeout: "Impossible d'obtenir la ressource",
          backupNotFound: 'Impossible de trouver votre sauvegarde',
          setCursorOutInterval: "Hors des points d'entrée/sortie",
          emptyPath:
            "Une erreur est survenue lors de l'analyse de la sélection",
          sequenceMismatch: "Impossible d'effectuer l'opération",
        },
        text: {
          subsequenceNotReachable:
            "Nous sommes désolés, mais nous n'avons pas pu atteindre l'une de vos sous-séquences sélectionnées.",
          unknown:
            "Une erreur est survenue lors de l'utilisation d'AutoCut, mais nous n'avons pas pu l'identifier.",
          noSpace:
            "Il semble que vous n'avez pas d'espace disponible sur votre appareil",
          exportUnknown:
            "Cette erreur est très probablement due à un manque d'espace de stockage sur le disque.",
          invalidInputData:
            "Nous sommes désolés, mais il semble qu'une erreur se soit produite lors de la génération d'un fichier temporaire",
          fileNotFound:
            "Veuillez vérifier que le fichier {filename} est toujours disponible à l'emplacement : {filepath}",
          scriptNotLoaded:
            'Veuillez fermer toutes vos extensions et DaVinci Resolve et ressayer.',
          ffmpegError:
            "Veuillez vérifier que le fichier {filepath} n'est pas corrompu.",
          exportCancelled:
            "AutoCut ne peut pas analyser l'audio si vous arrêtez l'encodage. Veuillez réessayer.",
          timelineMuted: 'Toutes vos pistes audio utilisées sont coupées.',
          noAudibleAudio: "Aucun audio audible n'a été détecté.",
          offline: 'Nous ne pouvons pas accéder à nos serveurs.',
          resourceTimeout:
            "La ressource en ligne nécessaire à ce processus n'a pas pu être téléchargée dans les 30 secondes.",
          backupNotFound:
            'Nous ne pouvons pas restaurer votre timeline car nous ne trouvons pas la séquence de sauvegarde',
          setCursorOutInterval:
            "Vous avez sélectionné une partie qui n'est pas contenue dans l'intervalle de vos points d'entrée/sortie. Vous devez retirer vos points d'entrée/sortie pour écouter cette partie de la transcription.",
          emptyPath:
            "Nous nous excusons, mais il semble que l'un de vos clips sélectionnés ne possède pas de chemin sur votre ordinateur.",
          sequenceMismatch:
            'Vous avez changé de séquence pendant que AutoCut était en cours de traitement. Veuillez réessayer sans changer de séquence.',
        },
        tips: {
          subsequenceNotReachable:
            'Veuillez vous assurer que chaque sous-séquence dans la timeline porte le même nom que la séquence originale correspondante dans le panneau du projet.',
          unknown:
            'Essayez de modifier votre sélection ou vos paramètres. Si le problème persiste, contactez-nous.',
          noSpace:
            'Essayez de supprimer certains fichiers avant de relancer AutoCut',
          exportUnknown:
            'Essayez de supprimer certains fichiers avant de relancer AutoCut.',
          invalidInputData:
            'Veuillez réessayer avec la même sélection. Si le problème persiste, veuillez nous contacter avec votre fichier',
          fileNotFound:
            'Veuillez réessayer avec la même sélection. Si le problème persiste, veuillez nous contacter avec votre fichier',
          scriptNotLoaded: 'Si le problème persiste, veuillez nous contacter.',
          ffmpegError: 'Si le problème persiste, veuillez nous contacter.',
          exportCancelled: ' ',
          timelineMuted: "Veuillez réactiver au moins l'une d'entre elles.",
          noAudibleAudio:
            "Veuillez vérifier qu'au moins un clip est actif et audible.",
          offline: 'Veuillez vérifier votre connexion internet et réessayer.',
          resourceTimeout:
            'Veuillez vérifier votre connexion internet et réessayer.',
          backupNotFound:
            'Veuillez vérifier le dossier "AutoCut-Backup" à la racine de votre projet pour voir si la sauvegarde est toujours présente.',
          setCursorOutInterval:
            "Vous devez retirer vos points d'entrée/sortie pour écouter cette partie de la transcription.",
          noSoundDetected:
            "AutoCut n'a pas pu détecter de son. Veuillez vérifier les points suivants : {br}{br} <li>Assurez-vous que la langue sélectionnée correspond à la langue de vos clips.</li><li>Assurez-vous que vos pistes ne sont pas muettes.</li><li>Confirmez que vos points d'entrée et de sortie incluent des clips avec du son.</li> {br}Si le problème persiste après avoir vérifié ces points, n'hésitez pas à nous contacter sur Discord ou à contact@autocut.fr.",
          emptyPath:
            'Veuillez vérifier que tous vos clips proviennent de médias atomiques ou de sous-séquences.',
          sequenceMismatch:
            "Lorsque vous utilisez AutoCut, veuillez ne pas changer de séquence, ni modifier quoi que ce soit dans la séquence actuelle, jusqu'à ce que le processus soit terminé.",
        },
        lost: {
          cep: {
            title: "Une erreur s'est produite : Connexion perdue",
            text: {
              '1': "Vous avez perdu la connexion avec Adobe DaVinci Resolve. Veuillez redémarrer l'extension et réessayer.",
              '2': "Si le redémarrage de l'extension ne résout pas le problème, essayez de redémarrer DaVinci Resolve.",
            },
          },
        },
      },
      language: {
        not: {
          supported: "La langue n'est pas prise en charge pour ce mode.",
        },
      },
    },
    retour: "Cette clé n'existe pas !",
    countdown: {
      days: "jours d'essai restants",
    },
    saveas: {
      default: {
        settings: 'Enregistrer les paramètres',
      },
    },
    button: {
      no_silences: 'Aucun silence détecté',
      no_audios: 'Aucun clip audio sélectionné',
      SavingBackup: 'Sauvegarde de votre projet…',
      RestoringBackup: 'Restauration de votre projet…',
      ExportingAudio: "Exportation de l'audio...",
      UploadingAudio: 'Téléchargement de fichiers audio...',
      SearchingSilences: 'Recherche de silences…',
      CuttingStep: {
        Audio: 'Découpe sur la piste audio {numTrack} : {current} sur {max}',
        Video: 'Découpe sur la piste vidéo {numTrack} : {current} sur {max}',
      },
      PreDeletingStep: 'Récupération des silences à supprimer…',
      DeletingStart: 'Suppression des silences…',
      finalizing: 'Finalisation en cours...',
      AssociatingStep: 'Association du clip {current} sur {max}',
      Success: "C'est fait ! Le processus s'est terminé avec succès",
      Failure: "Erreur… Quelque chose ne s'est pas passé comme prévu",
      NoSilences: 'Aucun silence trouvé',
      undo: {
        modal: 'Annuler',
      },
      not: {
        supported: {
          modal: 'Fermer',
        },
      },
      PreZoomingStep: 'Récupération des sous-clips à zoomer...',
      ZoomingStep: 'Ajout zoom : {current} sur {max}',
      AutozoomStep: "Préparation de l'Autozoom...",
      no_video: {
        to_zoom: 'Aucune vidéo à zoomer.',
      },
      getSubclip: {
        step: "Récupération des sous-clips pour l'Autozoom...",
      },
      checkSilenceSuppression: {
        step: 'Vérification de la suppression des silences...',
      },
      back: 'Retour',
      DeletingStep: {
        delete: {
          Audio:
            'Suppression du silence sur la piste audio {numTrack} : {current} sur {max}',
          Video:
            'Suppression du silence sur la piste vidéo {numTrack} : {current} sur {max}',
        },
        mute: {
          Audio:
            'Mise en sourdine du silence sur la piste audio {numTrack} : {current} sur {max}',
          Video:
            'Mise en sourdine du silence sur la piste vidéo {numTrack} : {current} sur {max}',
        },
      },
      skip: 'Passer',
      save: 'Sauvegarder',
    },
    warning: {
      DontTouchTimeline:
        'Veuillez ne pas toucher à votre timeline durant le processus',
      undo: {
        modal:
          "Nous avons remarqué que certaines méta-données n'était pas présentent lors de l'analyse de vos fichiers audio.",
      },
      modal: {
        title: {
          ffmpeg: 'AutoCut peut ne pas fonctionner comme prévu',
        },
        text: {
          ffmpeg:
            'Nous nous excusons, mais il semble que certaines métadonnées soient manquantes dans le fichier {filename}.',
        },
        tips: {
          ffmpeg:
            'Essayez de réencoder votre fichier si vous rencontrez des problèmes.',
        },
      },
      transition: {
        step: 'Vous ne pouvez pas utiliser de transitions sans supprimer les silences.',
      },
      beta: {
        version:
          'Il semble que vous utilisiez une version bêta de DaVinci Resolve. Vous pourriez rencontrer des problèmes avec AutoCut. Veuillez le tester avec la dernière version stable de DaVinci Resolve.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Vérification de la validité de votre sélection...',
          parsing: {
            step: 'Obtention des données de votre sélection : clip {current} sur {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Obtention du silence pour votre sélection : clip {current} sur {max}...',
          },
        },
        drawing: 'Génération de la prévisualisation...',
      },
      frame: {
        no_sequence: 'Ouvrez une séquence pour voir un aperçu de votre format',
      },
    },
    contact: {
      us_title: 'Nous sommes désolés que vous ayez rencontré un problème',
      us_text: {
        '2': "Si vous avez un problème avec votre compte, votre clé, votre paiement ou toute autre chose qui n'est pas liée à votre projet de montage vidéo :",
        __text__:
          'Si AutoCut ne fonctionne pas parfaitement avec votre projet et que vous souhaitez signaler le problème :',
      },
      us_button: 'Signaler un problème',
      us_contact: 'Contactez-nous :',
      us_copy: 'Contact copié dans le presse-papier !',
    },
    switch: {
      to_send: {
        log: {
          button: 'Ou envoyer le fichier de log',
        },
      },
    },
    send: {
      log: {
        title: 'Nous sommes désolés que vous rencontriez un bug',
        details:
          "Nous avons seulement accès aux évenements de l'algorithme avec ce fichier de log.",
        select:
          'Veuillez sélectionner un fichier de log journalier, cela nous aidera à résoudre votre problème.',
        text: {
          area: 'Veuillez décrire ici votre problème le plus précisément possible :',
        },
        button: 'Envoyer le fichier',
        success: {
          title: 'Fichier envoyé ! Nous allons étudier votre problème.',
        },
        thank: {
          text: 'Nous vous laissons continuer les découpes de vos vidéos.',
          text2:
            'Nous vous contacterons lorsque nous aurons résolu ce problème.',
        },
        message: {
          error: 'Veuillez entrer un message avec minimum 10 charactères',
        },
        link: {
          to_user: {
            file: {
              __text__:
                'Veuillez fournir un lien vers votre fichier vidéo et audio.',
              not: {
                valid:
                  'Veuillez fournir un lien valide vers votre fichier vidéo et audio.',
              },
              platform: {
                __text__:
                  '(WeTransfer, Google Drive ou tout autre service de partage de fichiers)',
                helper:
                  "Pour télécharger votre fichier sur WeTransfer, allez sur wetransfer.com et cliquez sur 'Upload files'. Après cela, vous obtiendrez un lien, copiez-le ici.",
              },
              end: 'Cela nous aidera à résoudre votre problème plus rapidement.',
            },
          },
        },
        screen: {
          timeline: {
            __text__:
              "Veuillez joindre une capture d'écran de votre sélection dans DaVinci Resolve",
            before:
              "Veuillez joindre une capture d'écran de votre sélection dans DaVinci Resolve avant la découpe",
            after:
              "Veuillez joindre une capture d'écran de votre sélection dans DaVinci Resolve après la découpe",
          },
          error: {
            message: "Veuillez joindre une capture d'écran du message d'erreur",
          },
        },
        trial: {
          mail: "Veuillez indiquer l'adresse électronique avec laquelle vous avez demandé un essai",
        },
        other:
          "Veuillez fournir toute information susceptible d'aider à résoudre votre problème",
      },
    },
    select: {
      issue: {
        title: 'Veuillez sélectionner votre problème dans la liste ci-dessous.',
        subtitle: 'Cela nous aidera à résoudre votre problème.',
      },
      one: {
        audios:
          'Veuillez sélectionner au moins un clip audio pour pouvoir utiliser AutoCut',
      },
    },
    upload: {
      screenshot: "Téléchargez votre capture d'écran",
    },
    modal: {
      feedback: {
        title: 'Avis',
        input: {
          feedback: {
            title: "Que pensez-vous d'AutoCut ?",
            placeholder: 'Donnez votre avis ici...',
          },
          improve: {
            title: 'Dites-nous ce qui peut être amélioré ?',
            placeholder: 'Écrivez ce qui pourrait être amélioré ici...',
          },
        },
        button: {
          text: 'Envoyer',
        },
        sent: {
          title: 'Merci pour votre avis !',
          text: "Si vous avez une idée de fonctionnalité à implémenter dans AutoCut, vous pouvez nous l'envoyer :",
          button: {
            text: 'Demande de fonctionnalité',
          },
        },
      },
      bug: {
        report: {
          title: 'Rapport de bug',
          description: {
            placeholder: 'Décrivez le bug ici...',
            error:
              "Veuillez fournir une description du bug d'au moins 20 caractères.",
          },
          reproduce: {
            placeholder: 'Marche à suivre pour reproduire le bug ici...',
            error:
              "Veuillez fournir une reproduction d'au moins 20 caractères.",
          },
          blocking: 'Le problème était bloquant.',
          button: {
            text: {
              __text__: 'Envoyer le rapport de bug',
              success: 'Merci pour votre rapport',
            },
          },
          link: {
            error: 'Veuillez fournir un lien valide vers votre fichier.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'Avez-vous rencontré un problème avec vos découpes ?',
      },
    },
    licence: {
      trial: "d'essai",
      paid: 'payante',
      info: 'Licence {licenceType}',
      time: {
        left: '{daysLeft} jours restants',
      },
    },
    star: {
      review: {
        title: 'Laissez un témoignage à AutoCut !',
        captions: {
          title: 'Notez AutoCaptions !',
        },
        text: "Dites-nous ce que vous pensez d'AutoCut !",
      },
    },
    review: {
      title: 'Dites-nous en plus',
      text: "Est-ce que les découpes sont bonnes ? Avez-vous un avis sur AutoCut à nous partager pour que nous puissions l'améliorer ? Nous sommes à votre écoute :",
      form: {
        placeholder: 'Entrez votre message…',
      },
      button: 'Envoyer',
      thank: {
        title: 'Merci pour votre retour !',
        text: 'Nous vous laissons continuer les découpes de vos vidéos.',
      },
    },
    exchange: {
      title: "Obtenez 7 jours d'essai supplémentaires !",
      text: "Rendez-vous sur Adobe Exchange et notez AutoCut pour obtenir 7 jours d'essai supplémentaires !",
      button: 'Noter AutoCut sur Adobe Exchange',
      info: 'Si vous avez installé AutoCut depuis notre site, pas besoin d\'installer à nouveau l\'extension. Cliquez simplement sur "Free" ce qui lancera le téléchargement et donnera accès à la notation.',
    },
    trial: {
      promo: {
        text: "Jusqu'à -20% pour 24 heures",
      },
      resend: {
        error:
          "Erreur lors du renvoi de la clé d'essai, veuillez réessayer plus tard.\n",
      },
      expired: {
        modal: {
          title: "Votre clé d'essai a expiré.",
          text1:
            "Votre essai gratuit a expiré. Abonnez-vous à AutoCut pour continuer à utiliser l'extension.",
          text2: 'Visitez votre dashboard AutoCut sur :',
          text3: 'Choisissez votre abonnement.',
          text4:
            "Contactez notre équipe de support si vous avez besoin d'aide : {supportEmail}",
          button: 'Souscrire un abonnement (-20%)',
        },
      },
      title: 'Essai gratuit',
      stepper: {
        onboarding: 'Aidez-nous à améliorer AutoCut',
        email: 'Entrez votre email',
        sending: {
          email: "Envoi de l'email",
        },
        login: 'Utilisez votre clé de licence',
      },
      onboarding: {
        subtitle:
          "Pour améliorer l'extension et imaginer de nouvelles fonctionnalités, aidez-nous à comprendre votre utilisation de DaVinci Resolve",
        discover: 'Où avez-vous découvert AutoCut ?',
        job: {
          status: 'Quel est votre statut professionnel ?',
        },
        type: {
          creation: 'Type de créations que vous réalisez sur DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Email envoyé avec succès à {email}',
        },
      },
      task: {
        captions: 'Utilisez AutoCaptions : <link>en savoir plus</link>',
        podcast: 'Utilisez AutoCut Podcast : <link>en savoir plus</link>',
        zoom: 'Utilisez AutoZoom : <link>en savoir plus</link>',
        repeat: 'Utilisez AutoCut Repeat : <link>en savoir plus</link>',
        broll: 'Utilisez AutoB-Roll : <link>en savoir plus</link>',
        swear: {
          word: 'Utilisez AutoProfanity Filter : <link>en savoir plus</link>',
        },
        preset:
          'Créez un nouveau preset pour AutoCaptions, AutoZoom ou AutoResize',
        testimonial:
          'Laissez-nous un témoignage (en utilisant le même e-mail) : <link>témoignage</link>',
      },
    },
    shop: {
      now: 'Achetez maintenant',
    },
    back: {
      to_autocut: 'Retourner sur AutoCut',
    },
    'discover-option-youtube-video': 'Vidéo YouTube',
    'discover-option-youtube-comment': 'Commentaire YouTube',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Amis',
    'discover-option-school': 'École',
    'discover-option-other': 'Autre',
    link: {
      not: {
        supported: {
          modal: {
            __text__:
              'Pour mieux comprendre cette erreur et comment la résoudre, rendez vous sur ',
            no_solution: 'Pour mieux comprendre cette erreur, rendez vous sur ',
            end: ', notre site documentation et de tutoriels.',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'Vous venez de recevoir un mail sur votre email ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence: "Si vous ne voyez pas l'e-mail, vérifiez vos spams.",
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Saisissez votre clé de licence',
      },
    },
    "can't": {
      reach: {
        server: {
          error:
            "Impossible d'atteindre le serveur. Veuillez réessayer plus tard.",
        },
      },
      read: {
        uuid: {
          error: "Impossible de lire l'UUID de l'ordinateur",
        },
        host: {
          error: "Impossible de lire le nom de l'ordinateur",
        },
      },
    },
    asking: {
      key: {
        error:
          'Erreur lors de la demande de clé au serveur, veuillez réessayer plus tard.\n',
      },
    },
    sending: {
      log: {
        file: {
          error:
            "Impossible d'envoyer le fichier de log, veuillez réessayer plus tard..\n",
        },
      },
    },
    is_key: {
      valid: {
        error:
          'Erreur lors de la vérification de la clé, veuillez réessayer plus tard.\n',
      },
    },
    base: {
      error: {
        message: "Une erreur s'est produite",
      },
    },
    invalid: {
      response: {
        error: 'Le réponse ne vient pas du serveur.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'Il est encore en cours de développement, si vous rencontrez des bugs ou si vous avez des suggestions, veuillez nous contacter à contact@autocut.fr',
        },
      },
    },
    header: {
      trial: {
        button: 'Obtenir plus',
      },
      paid: {
        button: 'Gérer',
      },
      speakers: {
        name: "Nom de l'interlocuteur",
        track: "Piste audio de l'interlocuteur",
      },
      cameras: {
        name: {
          __text__: 'Piste vidéo de la caméra',
          helper:
            "Sélectionnez la piste vidéo où se trouve la caméra, vous ne pouvez sélectionner qu'une seule piste par caméra.",
        },
        speakers: {
          __text__: 'Interlocuteur(s) sur la caméra',
          helper:
            "Sélectionnez les interlocuteurs qui sont visibles sur cette caméra, vous pouvez choisir n'importe quel nombre d'interlocuteurs.",
        },
      },
    },
    game: {
      saved: {
        hours: 'Vous venez de gagner {hourSavedText} heure(s) de plus,',
      },
      have: {
        a_break: 'faites une pause, vous le méritez bien!',
      },
    },
    generate: {
      preview: "Générer l'aperçu",
    },
    onboarding: {
      skip: "Ignorer l'onboarding",
      close: 'Fermer',
      previous: 'Précédent',
      next: 'Suivant ({current}/{max})',
      onboarding: {
        step1: {
          title: 'Bienvenue sur AutoCut !',
          body: "Lors de votre première utilisation, les différentes fonctions d'AutoCut vous seront présentées étape par étape, vous guidant tout au long du processus. Alors, commençons le voyage !",
        },
        step2: {
          title: "Clé d'essai gratuite.",
          body: "Si vous souhaitez tester les fonctionnalités d'AutoCut par vous-même, vous pouvez demander une clé d'évaluation gratuite à tout moment. Veuillez noter que vous ne pourrez demander qu'une seule clé d'essai pour une durée maximale de 14 jours.",
        },
        step3: {
          title: 'Clé de licence.',
          body: "Si vous disposez déjà d'une clé de licence, qu'elle soit d'essai ou payante, vous pouvez directement l'utiliser pour accéder aux outils AutoCut.",
        },
        step4: {
          title: 'Acheter une clé de licence.',
          body: "Si vous souhaitez acheter une clé de licence, vous pouvez cliquer sur le bouton prévu à cet effet pour être redirigé vers le site web d'AutoCut, où vous trouverez toutes les informations utiles à l'achat d'AutoCut.",
        },
      },
      modeSelection: {
        step1: {
          title: "Modes d'AutoCut.",
          body: "AutoCut propose trois modes différents pour répondre aux différents besoins de montage. Vous pouvez sélectionner le mode qui vous convient en cliquant simplement sur l'un des modes.",
        },
        step2: {
          title: "Besoin d'aide ?",
          body: "Chaque élément de l'interface est doté d'une infobulle qui fournit des informations supplémentaires sur sa fonction. Vous pouvez en savoir plus sur un élément particulier en passant le pointeur de la souris sur son infobulle.",
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2.',
          body: "La fonction AutoCut V2 s'appuie sur le niveau de bruit de votre fichier audio pour détecter les silences avec précision. Cependant, vous devez la configurer avant de l'utiliser.",
        },
        step2: {
          title: 'Niveau de bruit.',
          body: "Réglez le niveau de bruit minimum détecté comme un silence à l'aide du curseur.",
        },
        step3: {
          title: 'Quelle est la prochaine étape ?',
          body: "Naviguez à travers les étapes en cliquant sur les flèches pour en savoir plus sur les fonctionnalités d'AutoCut.",
        },
        step4: {
          title: "Génération de l'aperçu.",
          body: "L'audio de votre timeline doit être exporté avant le traitement. En cliquant sur 'Générer l'aperçu', vous pouvez pré-exporter l'audio et visualiser le résultat avec vos paramètres actuels.",
        },
        step5: {
          title: 'Prévisualisation audio.',
          body: 'Les boutons de navigation vous permettent de passer aux 10 secondes précédentes ou suivantes du fichier ou de centrer la prévisualisation avec votre curseur DaVinci Resolve.',
        },
        step6: {
          title: 'Il est temps de découper.',
          body: 'Une fois que vous avez tout vérifié et que tout vous semble correct, il est temps de découper !',
        },
        step7: {
          title: 'Il est temps de découper.',
          body: "Cliquez sur la flèche pour choisir l'option de découpe que vous préférez.",
        },
        step8: {
          title: 'Il est temps de découper.',
          body: "Asseyez-vous, détendez-vous et laissez AutoCut s'occuper du reste en cliquant sur le bouton !",
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast.',
          body: "AutoCut Podcast est une fonctionnalité puissante qui vous permet d'éditer rapidement vos podcasts vidéo.",
        },
        step2: {
          title: 'Participants.',
          body: 'Vous pouvez créer un nouvel interlocuteur en cliquant sur "Ajouter un interlocuteur". Pour chaque personne s\'exprimant dans votre podcast, vous devez ajouter un interlocuteur.',
        },
        step3: {
          title: 'Participants.',
          body: 'Une fois que vous avez créé un interlocuteur, vous pouvez le configurer en fonction de vos besoins.',
        },
        step4: {
          title: "Nom de l'interlocuteur.",
          body: "Dans cette section, vous pouvez attribuer à l'interlocuteur un nom qui n'apparaîtra que visuellement pour vous permettre de l'identifier.",
        },
        step5: {
          title: 'Sélection des pistes vidéo.',
          body: 'Le menu déroulant vous permet de sélectionner toutes les pistes vidéo dans lesquelles le locuteur apparaît.',
        },
        step6: {
          title: 'Sélection de la piste audio.',
          body: "Vous pouvez sélectionner la piste audio du locuteur en utilisant le menu déroulant. Chaque locuteur ne peut avoir qu'une seule piste audio.",
        },
        step7: {
          title: 'Il est temps de découper.',
          body: 'Une fois que vous avez défini tous les locuteurs et les pistes où ils apparaissent, vous êtes prêt à partir !',
        },
        step8: {
          title: 'Il est temps de découper.',
          body: 'Utilisez la flèche pour sélectionner les options de montage que vous préférez.',
        },
        step9: {
          title: 'Il est temps de découper.',
          body: "Cliquez sur le bouton, détendez-vous et laissez AutoCut s'occuper du reste pendant que vous savourez une tasse de café.",
        },
      },
      title: 'Bienvenue sur AutoCut',
      subtitle: 'Votre extension ultime pour DaVinci Resolve',
    },
    no_more: {
      credits: 'Plus de crédits disponibles',
    },
    file: {
      too: {
        long: 'Le fichier audio est trop long',
      },
      sent: 'Fichier envoyé, en attente de la transcription ...',
    },
    start: {
      transcription: 'Débuter la transcription',
    },
    please: {
      wait1: 'Veuillez patienter pendant la transcription de votre clip audio.',
      wait2:
        "Ce processus peut prendre jusqu'à plusieurs minutes en fonction de la longueur de ce dernier.",
      wait3: 'Envoie du fichier : ',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: "Langage de l'audio",
            helper: 'Sélectionner le langage du clip audio',
          },
        },
        french: {
          __text__: 'Français',
          beta: 'Français (beta)',
        },
        danish: 'Danois',
        dutch: 'Néerlandais',
        english: {
          __text__: 'Anglais',
          united: {
            states: 'Anglais - Etats Unis',
          },
        },
        flemish: 'Flamand',
        german: {
          __text__: 'Allemand',
          beta: 'Allemand (beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (beta)',
        },
        italian: 'Italien',
        japanese: 'Japonais',
        korean: 'Coréen',
        norwegian: {
          __text__: 'Norvégien',
          nynorsk: 'Norvégien nynorsk',
        },
        polish: 'Polonais',
        portuguese: {
          __text__: 'Portugais',
          beta: 'Portugais (beta)',
          brazil: {
            __text__: 'Portugais - Brésil',
            beta: 'Portugais - Brésil (beta)',
          },
        },
        spanish: {
          __text__: 'Espagnol',
          beta: 'Espagnol (beta)',
          latin: {
            america: {
              __text__: 'Espagnol - Amérique Latine',
              beta: 'Espagnol - Amérique Latine (beta)',
            },
          },
        },
        swedish: 'Suédois',
        tamil: 'Tamil',
        chinese: 'Chinois',
        russian: 'Russe',
        turkish: 'Turc',
        catalan: 'Catalan',
        arabic: {
          __text__: 'Arabe',
          algeria: 'Arabe - Algérie',
          bahrain: 'Arabe - Bahreïn',
          egypt: 'Arabe - Égypte',
          iraq: 'Arabe - Irak',
          israel: 'Arabe - Israël',
          jordan: 'Arabe - Jordanie',
          kuwait: 'Arabe - Koweït',
          lebanon: 'Arabe - Liban',
          mauritania: 'Arabe - Mauritanie',
          morocco: 'Arabe - Maroc',
          oman: 'Arabe - Oman',
          qatar: 'Arabe - Qatar',
          saudi: {
            arabia: 'Arabe - Arabie saoudite',
          },
          palestine: 'Arabe - Palestine',
          syria: 'Arabe - Syrie',
          tunisia: 'Arabe - Tunisie',
          united: {
            arab: {
              emirates: 'Arabe - Émirats arabes unis',
            },
          },
          yemen: 'Arabe - Yémen',
        },
        indonesian: 'Indonésien',
        finnish: 'Finnois',
        vietnamese: 'Vietnamien',
        hebrew: 'Hébreu',
        ukrainian: 'Ukrainien',
        greek: 'Grec',
        malay: 'Malais',
        czech: 'Tchèque',
        romanian: 'Roumain',
        hungarian: 'Hongrois',
        thai: 'Thaï',
        urdu: 'Ourdou',
        croatian: 'Croate',
        bulgarian: 'Bulgare',
        lithuanian: 'Lituanien',
        latin: 'Latin',
        maori: 'Maori',
        malayalam: 'Malayalam',
        welsh: 'Gallois',
        slovak: 'Slovaque',
        telugu: 'Télougou',
        persian: 'Persan',
        latvian: 'Letton',
        bengali: 'Bengali',
        serbian: 'Serbe',
        azerbaijani: 'Azéri',
        slovenian: 'Slovène',
        kannada: 'Kannada',
        estonian: 'Estonien',
        macedonian: 'Macédonien',
        breton: 'Breton',
        basque: 'Basque',
        icelandic: 'Islandais',
        armenian: 'Arménien',
        nepali: 'Népalais',
        mongolian: 'Mongol',
        bosnian: 'Bosniaque',
        kazakh: 'Kazakh',
        albanian: 'Albanais',
        swahili: 'Swahili',
        galician: 'Galicien',
        marathi: 'Marathi',
        punjabi: 'Pendjabi',
        sinhala: 'Sinhala',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Yoruba',
        somali: 'Somali',
        afrikaans: 'Afrikaans',
        occitan: 'Occitan',
        georgian: 'Géorgien',
        belarusian: 'Biélorusse',
        tajik: 'Tadjik',
        sindhi: 'Sindhi',
        gujarati: 'Gujarati',
        amharic: 'Amharique',
        yiddish: 'Yiddish',
        lao: 'Laotien',
        uzbek: 'Ouzbek',
        faroese: 'Féroïen',
        haitian: {
          creole: 'Créole haïtien',
        },
        pashto: 'Pachto',
        turkmen: 'Turkmène',
        maltese: 'Maltais',
        sanskrit: 'Sanskrit',
        luxembourgish: 'Luxembourgeois',
        burmese: 'Birman',
        tibetan: 'Tibétain',
        tagalog: 'Tagalog',
        malagasy: 'Malgache',
        assamese: 'Assamais',
        tatar: 'Tatar',
        hawaiian: 'Hawaïen',
        lingala: 'Lingala',
        hausa: 'Haoussa',
        bashkir: 'Bachkir',
        javanese: 'Javanais',
        sundanese: 'Soundanais',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            "Vous avez beaucoup de clip dans votre séquence ! Cela va prendre du temps pour charger. Si vous n'avez pas besoin de couper tout ces clips, veuillez selectionner ceux que vous voulez découper et les découper dans une séquence vide.",
        },
      },
    },
    homepage: {
      title: "Page d'accueil",
      comming: {
        soon: 'Bientôt disponible !',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dynamisme',
        normal: 'Normal',
        high: 'Elevé',
        extreme: 'Extrême',
        custom: 'Personalisée :',
      },
      button: {
        __text__: 'Zoomer',
        message: {
          step1: "Préparation d'Autozoom ...",
          step2: 'Extraction des données audio ...',
          step3: 'Calcul des intervalles de zoom ...',
          step4: 'Application du zoom {current} sur {max} ...',
        },
      },
      success: 'Succès ! Votre sélection a été zoomée',
      info: {
        text: 'AutoZoom ajoutera automatiquement un zoom sur chaque clip, basé sur les décibels',
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Selectionnez au moins un clip audio et un clip vidéo dans votre timeline',
            text1:
              'Pour utiliser AutoZoom, sélectionner tout les clips qui composent votre vidéo dans votre timeline.',
          },
        },
      },
      anchor: {
        title: "Definissez le point d'ancrage",
        helper:
          "Le point d'ancrage correspond à l'endroit où sera appliqué le zoom. Vous pouvez visualiser l'effet des zooms maximum et minimum grâce aux encadrés de couleurs.",
      },
      dual: {
        range: {
          title: 'Coefficient de zoom minimum et maximum utilisé',
          helper:
            'Le coefficient de zoom est calculé en fonction des valeurs données ci-dessous et du volume maximum du sous-clip.',
        },
      },
      preview: {
        on_indicator: "Previsualisation sur l'indicateur",
        error: {
          message: 'Une erreur est survenu en chargeant la prévisualisation',
          button: 'Réessayer',
        },
      },
      min: {
        zoom: {
          title: "Durée minimum d'un zoom",
          helper: 'Chaque zoom sera au moins aussi long.',
        },
      },
      max: {
        zoom: {
          title: "Durée maximum d'un zoom",
          helper: 'Chaque zoom sera au maximum aussi long.',
        },
      },
      constraint: {
        zoom: {
          title: 'Contraindre le zoom aux clips',
          helper:
            "Si oui, aucun zoom ne s'étendra sur plus d'un clip. Si non, toute votre sélection sera traitée comme un seul fichier audio et les zooms ignoreront les clips individuels sur la timeline.",
        },
      },
      zoom: {
        types: {
          __text__: 'Types de zoom',
          helper:
            'Détermine le type de zoom à utiliser. Doux : Zoom progressif dans le temps. Dynamique : Zoom instantané, net.',
        },
        coverage: {
          __text__: 'Couverture de zoom totale',
          helper: 'Pourcentage de la vidéo à zoomer',
        },
      },
      smooth: {
        __text__: 'Doux',
        zoom: {
          __text__: 'Zooms doux',
          coverage: 'Couverture des zooms doux',
          nervousness: {
            __text__: 'Nervosité',
            helper:
              'Une faible nervosité signifie des zooms lents, faibles. Une nervosité élevée signifie des zooms rapides, intenses.',
          },
        },
      },
      dynamic: {
        __text__: 'Dynamique',
        zoom: {
          __text__: 'Zooms dynamiques',
          coverage: 'Couverture des zooms dynamiques',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Préréglages par défaut',
        calm: 'Calme',
        paced: 'Mesuré',
        energetic: 'Énergique',
        hyperactive: 'Hyperactif',
      },
    },
    repeat: {
      info: {
        banner:
          "Vous pouvez maintenant corriger quelles phrases seront coupées ou non.{br}Les phrases grisées sont considérés comme des répétitions.{br}Les ciseaux permettent de définir l'état d'une phrase (répétition ou non). Vous pouvez aussi surligner une portion de phrase et cibler précisément les mots qui seront coupés",
      },
      transcript: {
        editor: {
          title: "Corriger l'analyse",
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Selectionnez un clip dans votre timeline',
            text1:
              'Pour utiliser AutoCut Repeat, sélectionner un clip audio et optionnellement un clip video dans votre timeline.',
          },
        },
      },
      select: {
        algorithm: "Sélectionner l'algorithme",
      },
      precise: {
        algorithm: {
          info: "L'algorithme précis est notre plus récent et le plus efficace mais nécessite environ 1 minute de traitement pour 6 minutes de vidéo.",
        },
      },
      fast: {
        algorithm: 'Rapide',
      },
      toggle: {
        cut: {
          button: {
            text: 'Découper/Conserver',
          },
        },
      },
      play: {
        button: {
          text: 'Lire',
        },
      },
      text: {
        step: {
          edit: {
            transcript: 'Corriger la transcription',
          },
        },
      },
    },
    captions: {
      step: {
        customization: 'Personnalisation',
        script: 'Langue et selection',
        transcription: 'Transcription',
      },
      script: {
        transcript: {
          part: 'Transcription',
        },
        sequence: {
          part: 'Séquence',
          name: 'Nom',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} clips | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Ajouter les sous-titres',
        coming: {
          soon: "L'editeur de transcript arrive !{br}En attendant, Vous pouvez éditer les sous-titres une fois importés dans le panneau Objets graphiques essentiels.{br}Selectionnez simplement le clip correspondant dans la séquence et modifier la valeur dans l'onglet Modifier du panneau.",
        },
        transcription: {
          part: 'Transcription',
        },
        info: "Bienvenue dans l'éditeur de sous-titres - vous pourrez bien sûr également éditer vos sous-titres dans la timeline DaVinci Resolve après les avoir ajoutés.",
        add: {
          emojis: {
            cta: 'Générer des emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Selectionnez un ou plusieurs clips dans votre timeline',
          no_selection:
            'Si AutoCut ne réagit pas, essayez de redémarrer DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Analyser',
        },
      },
      customization: {
        info: 'AutoCaption est actuellement en Bêta, nous prévoyons que la première version stable soit disponible le 10 novembre',
        back: {
          button: 'Supprimer le transcript et revenir à la sélection',
        },
        example: {
          __text__:
            "Aperçu <opacity50><i>Vous pouvez faire glisser la légende ou l'emoji pour changer leur position </i></opacity50>",
          text: "Ajouter des sous-titres avec AutoCaptions. Ceci est une preview d'AutoCaptions et vous permet de paramétrer AutoCaptions super facilement. Vous pourrez ensuite lancer la transcription et la modifier si besoin, puis AutoCaptions ajoutera automatiquement vos captions dans votre timeline.",
        },
        animation: {
          __text__: 'Animation',
          helper: "Personnalisez l'animation",
          color: {
            __text__: "Surligner avec <color>l'audio</color>",
            text: {
              color: 'Couleur du texte',
            },
            before: {
              highlight: {
                color: 'Couleur avant la surbrillance',
              },
            },
            after: {
              highlight: {
                color: 'Couleur après la surbrillance',
              },
            },
          },
          advanced: {
            color: {
              options: 'Options de couleur avancées',
            },
          },
          word: {
            box: 'Texte avec <color>surlignage</color>',
          },
          bold: {
            with: {
              text: 'Texte en <b>gras</b>',
            },
          },
          floating: {
            text: 'Texte flottant',
          },
        },
        transition: {
          __text__: 'Transition',
          helper:
            "Gérez les transitions des sous-titres. Le timing peut ne pas être le même que dans l'exemple.",
          effect: {
            zoomIn: 'Zoom entrant',
            blurIn: 'Flou entrant',
          },
        },
        outline: {
          __text__: 'Bordure',
          helper: 'Personnalisez la bordure',
          color: 'Couleur',
          width: 'Épaisseur',
        },
        font: {
          __text__: 'Police',
          helper: 'Personnalisez le texte',
          font: 'Police',
          help: 'Comment ajouter une nouvelle famille de polices ?',
          size: 'Taille',
          weight: 'Épaisseur',
          color: 'Couleur',
        },
        formating: {
          __text__: 'Formatage du texte',
          helper: 'Modifiez le format du texte',
          ponctuation: 'Retirer la ponctuation',
          uppercase: 'Majuscule',
          bold: 'Gras',
          italic: 'Italique',
          glow: {
            __text__: 'Brillance',
            color: 'Couleur',
            intensity: 'Intensité',
          },
        },
        transform: {
          __text__: 'Positionnement',
          x: 'x',
          y: 'y',
          helper: 'Choisissez la position de vos sous-titres',
          position: {
            __text__: 'Position verticale',
            emoji: "Position verticale de l'emoji",
            info: 'Vous pouvez également bouger le texte depuis la prévisualisation avec votre souris',
          },
          rotation: 'Rotation',
        },
        multiple: {
          lines: 'Lignes multiples',
        },
        box: {
          __text__: 'Surlignage',
          helper: 'Style du surlignage',
        },
        boxes: {
          padding: {
            x: 'Marge horizontale',
            y: 'Marge verticale',
          },
          opacity: 'Opacité',
          radius: 'Arrondis',
          color: 'Couleur',
        },
        emojis: {
          __text__: 'Emojis',
          helper: "Générez les emojis avec l'IA",
          size: 'Taille',
        },
        shadow: 'Ombre',
        transcript: 'Transcription',
        action: 'Étape suivante',
        select: {
          chunk:
            'Sélectionnez la partie du transcript que vous souhaitez éditer/personnaliser',
        },
        delete: {
          caption: {
            modal: {
              title: 'Êtes-vous sûr de vouloir supprimer ce sous-titre ?',
              cancel: 'Non - le garder',
              confirm: 'Oui - le supprimer',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Erreur lors de la récupération du nombre de préréglages, veuillez nous contacter sur discord ou à contact@autocut.fr',
            },
            error:
              'Erreur lors de la récupération des préréglages, veuillez nous contacter sur discord ou à contact@autocut.fr',
          },
          public: {
            presets: {
              error:
                'Erreur lors de la récupération des préréglages publics, veuillez nous contacter sur discord ou à contact@autocut.fr',
            },
          },
        },
        presets: {
          title: 'Préréglages',
          save: {
            new: {
              preset: 'Enregistrer paramètres actuels',
            },
          },
          view: {
            more: 'Voir plus',
            less: 'Voir moins',
          },
        },
        save: {
          preset: {
            info: {
              text: "Vous avez modifié vos paramètres, vous pouvez les enregistrer comme préréglage ci-dessous et les réutiliser à l'infini !",
            },
          },
        },
      },
      editor: {
        title: 'Editeur de sous-titres',
        description: {
          title: 'Sélectionnez les sous-titres que vous souhaitez éditer',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Mots incertains',
            },
          },
          merge: {
            tooltip: 'Fusionner les chunks',
          },
          split: {
            tooltip: 'Diviser les chunks',
          },
        },
        sound: {
          play: 'Activer la lecture du son',
        },
      },
      presetCustomization: {
        createPresetModal: {
          title: {
            length: {
              error:
                'Le nom de votre préréglage doit contenir au moins 2 mots.',
            },
          },
          error:
            'Erreur lors de la création du préréglage, veuillez nous contacter sur discord ou à contact@autocut.fr',
          placeholder: 'Nom du préréglage (au moins 2 mots)',
          create: 'Créer le préréglage',
          isPublic: 'Partager à la communauté - public',
        },
        searchPresetModal: {
          title: 'Préréglage de la communauté',
        },
        error: {
          preset: {
            already: {
              exists:
                'Ce préréglage existe déjà. Veuillez choisir un autre nom.',
            },
          },
        },
        copyPreset: {
          error:
            'Erreur lors de la copie du préréglage, veuillez nous contacter sur discord ou à contact@autocut.fr',
        },
        no_preset: {
          selected: "Sélectionnez un préréglage pour voir l'aperçu",
        },
        no_preview: {
          available: 'Aucun aperçu disponible',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Police non trouvée',
          message:
            "La police <i><b>{fontLabel}</b></i> n'a pas été trouvée sur votre système, la police par défaut sera utilisée.",
          helper:
            'Vous pouvez ajouter une nouvelle famille de polices en suivant ce tutoriel.',
        },
      },
    },
    version: {
      not: {
        supported: 'Version de DaVinci Resolve non supportée',
      },
    },
    transcript: {
      usage: {
        unit: 'minutes',
      },
      upgrade: {
        button:
          "Mettre à niveau vers le plan AI pour continuer d'utiliser {mode}",
      },
      too: {
        short: {
          error:
            'Malheureusement, votre transcription peut être trop courte ou la langue que vous utilisez est mal prise en charge. Si le problème persiste, veuillez nous contacter à contact@autocut.fr.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Demande de fonctionnalité',
        form: {
          placeholder:
            "Entrez votre demande de fonctionnalité. N'hésitez pas à expliquer en détail votre demande pour que nous puissions l'étudier plus facilement.",
        },
        screenshot:
          'Vous pouvez ajouter des photos/vidéos pour nous aider à mieux comprendre votre demande :',
        button: 'Envoyer la demande',
        success: {
          title: 'Demande envoyée ! 🥳',
          body: "Merci d'aider AutoCut à s'améliorer ! Nous allons étudier votre demande avec la plus grande attention.",
        },
        error: {
          title: 'Oups, une erreur est survenue... 🤕',
          body: 'Nous sommes vraiment désolés pour la gêne occasionnée. Veuillez réessayer plus tard ou nous contacter à contact@autocut.fr.',
        },
      },
    },
    with: {
      emojis: 'Avec emojis',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title:
              "Ajoutez des points d'entrée et de sortie dans votre timeline",
            text1:
              "Pour utiliser AutoResize, vous devez ajouter des points d'entrée et de sortie.",
            cta: "Régler les points d'entrée et de sortie sur la séquence complète",
          },
        },
      },
      text: {
        info: 'AutoResize redimensionne et recadre automatiquement votre séquence pour les meilleures dimensions des médias sociaux.',
      },
      in_out: {
        step: {
          title: "Points d'entrée / de sortie",
          helper:
            "Veuillez vérifier vos points d'entrée et de sortie dans votre timeline. Ils devraient correspondre aux valeurs ci-dessous.",
        },
      },
      preset: {
        step: {
          title: 'Préréglages',
          helper:
            'Sélectionnez un préréglage pour redimensionner votre séquence. Vous pouvez également créer votre propre préréglage. Les dimensions de votre nouvelle séquence sont affichées ci-dessous.',
        },
        undefined: 'Veuillez sélectionner un préréglage.',
      },
      watermark: {
        step: {
          title: 'Filigrane',
          helper:
            'Vous pouvez ajouter un filigrane à votre séquence. Il sera ajouté dans le coin inférieur droit de votre séquence.',
          text: 'Téléchargez votre filigrane',
        },
      },
      button: 'Générer le clip pour réseaux sociaux',
      reframe: {
        undefined: 'Veuillez sélectionner la vitesse "Suivre le sujet".',
        step: {
          title: 'Suivre le sujet',
          helper:
            'Vous pouvez choisir la vitesse à laquelle la caméra suivra le sujet.',
          slower: 'Lente',
          default: 'Normale',
          faster: 'Rapide',
        },
      },
      backup: 'Sauvegarde de votre séquence en cours...',
      create: {
        new: {
          sequence: "Création d'une nouvelle séquence...",
        },
      },
      resizing: {
        sequence: 'Redimensionnement de la nouvelle séquence...',
      },
      adding: {
        watermark: 'Ajout du filigrane...',
      },
      cleaning: {
        sequence: 'Nettoyage de la séquence...',
      },
    },
    endprocess: {
      modal: {
        title: 'Processus terminé !',
        text: {
          '1': 'Processus terminé avec succès !',
          '2': 'Votre avis est précieux – dites-nous ce que nous avons bien fait et comment nous pouvons rendre votre expérience encore meilleure !',
        },
        next: {
          mode: {
            silence: {
              text: 'Prêt pour une expérience audio sans accroc ? Utilisez AutoCut Silences pour éliminer sans effort les silences gênants de votre contenu.',
              button: 'Passer à AutoCut Silences',
            },
            podcast: {
              text: "Rehaussez votre production de podcast avec AutoCut Podcast. Profitez d'un montage simplifié et de transitions de caméra automatiques.",
              button: 'Passer à AutoCut Podcast',
            },
            repeat: {
              text: 'Gardez votre audience en haleine ! Utilisez AutoCut Repeat pour éditer votre vidéo via un éditeur de texte, en conservant uniquement les parties les plus captivantes de votre contenu.',
              button: 'Passer à AutoCut Repeat',
            },
            zoom: {
              text: 'Donnez vie à vos vidéos avec AutoZoom ! Ajoutez des effets de zoom fluides qui captivent les spectateurs et retiennent leur attention.',
              button: 'Passer à AutoZoom',
            },
            caption: {
              text: 'Connectez-vous mieux avec votre public grâce à AutoCaptions. Intégrez des sous-titres élégants et synchronisés qui renforcent le récit de vos vidéos.',
              button: 'Passer à AutoCaptions',
            },
            resize: {
              text: 'Maîtrisez le partage cross-plateforme avec AutoResize. Formatez vos vidéos à la perfection sans perdre de vue votre sujet.',
              button: 'Passer à AutoResize',
            },
            broll: {
              text: "Amplifiez votre récit visuel avec AutoB-Roll, où l'IA intègre de manière transparente des B-Rolls pertinents pour compléter votre histoire.",
              button: 'Passer à AutoB-Roll',
            },
            swear: {
              word: {
                text: 'Maintenez un dialogue soigné avec le filtre AutoProfanity. Masquez automatiquement le langage indésirable pour préserver le professionnalisme de votre contenu.',
                button: 'Passer à AutoProfanity Filter',
              },
            },
          },
        },
      },
      captions: {
        modal: {
          text: {
            '1': 'Vos sous-titres ont été ajoutés ! 🎉 {br} Si vous avez besoin de modifier vos sous-titres :',
            '2': 'Il y a un <a>tutoriel détaillé si besoin.</a>',
          },
          bullet: {
            point: {
              '1': 'Double-cliquez sur la sous-séquence : "Double click to edit"',
              '2': 'Sélectionnez le sous-titre que vous souhaitez modifier',
              '3': 'Ouvrez les objets graphiques essentiels et cliquez sur l\'onglet "Modifier"',
            },
          },
        },
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title:
              'Sélectionnez les clips sur votre timeline sur lesquels vous souhaitez baser le B-Roll',
            text1:
              'Pour utiliser le B-Roll automatique, vous devez sélectionner au moins un clip audio dans votre timeline.',
          },
        },
      },
      text: {
        info: "Le B-Roll automatique, grâce à l'IA, analyse votre audio et ajoute automatiquement des {br} @Pexels B-Rolls liés au contenu de votre vidéo.",
      },
      duration: {
        step: {
          title: 'Durées des B-Rolls',
          minimum: {
            title: 'Durée minimale du B-Roll',
            helper: 'Chaque B-Roll durera au moins aussi longtemps.',
          },
          maximum: {
            title: 'Durée maximale du B-Roll',
            helper: 'Chaque B-Roll durera au plus aussi longtemps.',
          },
        },
      },
      choice: {
        step: {
          title: 'Sélection de B-Roll',
        },
      },
      no_broll: {
        screen: {
          title:
            "Le traitement du transcript est terminé, mais rien n'a été trouvé.",
          text1:
            "Pour utiliser l'Auto B-Roll, vous devez sélectionner au moins un clip audio avec de la parole dans votre timeline.",
          text2:
            "Si vous l'avez déjà fait, vérifiez la langue de votre transcript et assurez-vous que vos pistes audio ne sont pas en sourdine.",
        },
      },
      button: {
        __text__: 'Ajouter des B-Rolls',
        next: 'Suivant',
      },
      exporting: 'Exportation des clips audio sélectionnés...',
      analyse: "Analyse de l'audio...",
      getting: {
        broll:
          'Récupération des B-Rolls... (cela peut prendre quelques minutes)',
      },
      transcript: {
        finished: 'Transcription générée',
      },
      step: {
        parameters: 'Paramètres du B-Roll',
        language: 'Sélectionnez les clips et la langue',
        brolls: 'Modifier la sélection de B-Roll',
      },
      adding: {
        broll: 'Ajout des B-Rolls...',
      },
      restore: 'Restauration de votre séquence...',
      backup: 'Sauvegarde de votre séquence...',
      credit: {
        modal: {
          title:
            'Voici les crédits pour les B-Rolls utilisés dans votre vidéo : ',
          provided: {
            by: 'fournis par Pexels',
          },
          link: 'lien',
          button: 'Copier dans le presse-papier',
        },
      },
      search: {
        modal: {
          title: 'Choisir un B-Roll',
          input: {
            placeholder: 'Tapez pour rechercher des B-Rolls',
          },
          searching: 'Recherche de B-Rolls en cours...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Double-cliquez pour éditer',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title:
                'Sélectionnez les clips sur votre timeline où vous souhaitez biper les jurons',
              text1:
                "Pour utiliser AutoProfanity Filter, vous devez sélectionner au moins un clip avec de l'audio dans votre timeline.",
            },
          },
        },
        text: {
          info: "AutoProfanity Filter, grâce à l'IA, analyse votre audio et ajoute automatiquement des sons de bipage sur les jurons.",
        },
        mute: 'Couper le son',
        button: 'Supprimer les jurons',
        audio: {
          export: "Exportation de l'audio...",
          analyse: "Analyse de l'audio...",
          insert: 'Insertion des bips...',
          filter: 'Application des filtres de volume...',
        },
        script: {
          transcript: {
            part: 'Transcription',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: {
            helper: "Sélectionnez l'effet sonore de bipage",
          },
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Créer un nouveau format',
          button: {
            __text__: 'Créer',
            disabled: 'Nom déjà utilisé',
          },
          name: 'Nom',
          width: 'Largeur',
          height: 'Hauteur',
        },
      },
    },
    process: {
      modal: {
        warning:
          'Ne faites rien sur DaVinci Resolve, cela causerait des problèmes.',
        generating: {
          emojis: "Génération d'emojis à partir de la transcription...",
        },
        formatting: {
          numbers: 'Formatage des nombres à partir de la transcription...',
        },
      },
    },
    backup: {
      modal: {
        title: 'Sauvegarde AutoCut',
        text: {
          '1': 'Avant tout processus AutoCut, une sauvegarde est effectuée. Vous pouvez trouver ces sauvegardes dans le dossier "AutoCut-Backup".',
          '2': 'Vous pouvez les utiliser comme vous le souhaitez.',
        },
      },
    },
    confirm: {
      modal: {
        title: 'Êtes-vous sûr ?',
        confirm: {
          label: 'Confirmer',
        },
        cancel: {
          label: 'Annuler',
        },
      },
    },
    login: {
      resend: {
        email: "Vous ne l'avez pas reçu ? Renvoyer l'email à {trialEmail}",
      },
    },
    typeofcreation: {
      option: {
        interview: 'Interviews',
        podcast: 'Podcasts',
        youtube: 'Vidéos Youtube',
        short: 'TikTok/Shorts/Reels',
        internal: 'Vidéos internes',
        other: 'Autre',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Créateur de contenu',
        },
        video: {
          editor: {
            professional: 'Monteur vidéo professionnel',
            semi: {
              professional: 'Monteur vidéo semi-professionnel',
            },
            beginner: 'Monteur vidéo débutant',
          },
        },
        student: 'Étudiant',
        other: 'Autre',
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: "Entrez le nom de l'interlocuteur...",
        },
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Création des sous-titres...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal:
          'Fantastique ! Vous avez terminé le processus en seulement {minutes} minutes et {seconds} secondes.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Temps économisé : <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b> et <b>{secondsSaved} {secondsSaved, plural, one {seconde} other {secondes}}</b>.',
        },
        all: {
          cuts: 'Temps total économisé avec AutoCut : Un incroyable <b>{hourSaved} {hourSaved, plural, one {heure} other {heures}}</b> et <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b> ! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Excluez certaines pistes audio de la détection de silence en les désactivant. Cela est idéal pour conserver la musique de fond ou les sons de jeu. Pour garder les silences dans un clip spécifique inchangés, il suffit de ne pas sélectionner ce clip.',
        video: 'Pistes vidéo sélectionnées :',
        audio: 'Pistes audio sélectionnées :',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: "Limite d'essai gratuit atteinte",
            cta: "S'abonner",
            text: "Vous avez utilisé plus de 1000 minutes de fonctionnalités d'IA. {br} Abonnez-vous au plan IA pour continuer à utiliser les fonctionnalités AutoCut AI.",
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Limite de transcription atteinte',
          text: "Vous avez utilisé plus de 6000 minutes de fonctionnalités d'IA. {br} Veuillez nous contacter pour débloquer la situation.",
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Veuillez choisir un type de transition valide (Voir le champ <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>)',
        ai_silences:
          'Veuillez choisir un traitement de silence valide (Voir le champ <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>)',
        broll: {
          min: {
            max: {
              duration:
                'La durée maximale de B-Roll ne peut pas être inférieure à la durée minimale de B-Roll (Voir le champ <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>)',
            },
          },
          minimumBRollTime:
            'La durée minimale de B-Roll doit être un nombre positif (Voir le champ <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>)',
          maximumBRollTime:
            'La durée maximale de B-Roll doit être un nombre positif (Voir le champ <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>)',
        },
        caption: {
          position:
            "Les légendes doivent être à l'intérieur de l'aperçu (Voir le champ <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a>)",
          emoji: {
            position:
              "Les emojis doivent être à l'intérieur de l'aperçu (Voir le champ <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a>)",
          },
          text: {
            fontSize:
              'La taille de la police doit être un nombre positif (Voir le champ <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>)',
          },
          outline: {
            width:
              'La largeur du contour doit être un nombre positif (Voir le champ <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a>)',
          },
          transition: {
            effects:
              'Veuillez sélectionner un effet de transition de légende valide (Voir le champ <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>)',
          },
          emojis: {
            size: 'La taille des emojis doit être un nombre positif (Voir le champ <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>)',
          },
          formating: {
            nbLines:
              'Veuillez sélectionner au moins une ligne pour le formatage des légendes (Voir le champ <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>)',
          },
          title: {
            too: {
              short: 'Le titre doit contenir au moins 5 caractères',
              long: 'Le titre doit contenir au plus 25 caractères',
            },
            no_space:
              'Le titre doit contenir au moins deux mots, séparés par un espace',
          },
        },
        font: {
          fontFamily:
            'La famille de polices est requise (Voir le champ <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>)',
          label:
            'Veuillez sélectionner une police (Voir le champ <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>)',
        },
        languageOfTranscription:
          'Veuillez sélectionner une langue de transcription (Voir le champ <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>)',
        cameras: {
          speakerIds:
            'Au moins un intervenant est requis pour chaque caméra (Voir le champ <a><b><embedTranslation>header_cameras_speakers</embedTranslation></b></a>)',
          label:
            "Une des caméras n'a pas de piste (Voir le champ <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)",
        },
        podcast: {
          minimum: {
            cam: {
              time: 'La durée minimale de la caméra doit être un nombre positif (Voir le champ <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>)',
            },
          },
          maximum: {
            cam: {
              time: 'La durée maximale de la caméra doit être un nombre positif (Voir le champ <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>)',
            },
          },
          deleteUnusedClips:
            'Veuillez choisir entre la suppression ou le silence des silences (Voir le champ <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>)',
          speakers: {
            cameras:
              'Au moins une caméra est requise pour chaque intervenant (Voir le champ <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
            audio:
              'Une piste audio est requise pour chaque intervenant (Voir le champ <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>)',
          },
          cameras: {
            __text__:
              'Les caméras ne peuvent pas avoir la même piste (Voir le champ <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
            length:
              'Doit avoir configuré au moins 2 caméras différentes (Voir le champ <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Le préréglage de recadrage automatique est requis (Voir le champ <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>)',
            invalid:
              'Le préréglage de recadrage automatique doit être plus lent, par défaut ou plus rapide (Voir le champ <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>)',
          },
          watermark:
            "Le filigrane doit être un format d'image valide : JPEG, PNG ou WebP (Voir le champ <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>)",
          preset: {
            name: 'Veuillez entrer un nom pour le préréglage',
            width: 'La largeur doit être supérieure à 1 pixel',
            height: 'La hauteur doit être supérieure à 1 pixel',
          },
        },
        rgb: 'Les valeurs RVB doivent être comprises entre 0 et 255',
        silence: {
          noiseLevel: {
            type: 'Le niveau de bruit doit être un nombre (Voir le champ <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
            nonpositive:
              'Le niveau de bruit ne doit pas être un nombre positif (Voir le champ <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
            integer:
              'Le niveau de bruit doit être un nombre entier (Voir le champ <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
          },
          minTimeOfSilence: {
            type: 'La durée minimale de silence doit être un nombre (Voir le champ <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>)',
          },
          maxTimeOfSilence: {
            type: 'La durée maximale de silence doit être un nombre (Voir le champ <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>)',
          },
          marginBefore: {
            type: 'Le rembourrage avant doit être un nombre (Voir le champ <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>)',
          },
          marginAfter: {
            type: 'Le rembourrage après doit être un nombre (Voir le champ <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>)',
          },
          transitions:
            'Veuillez choisir un type de transition valide (Voir le champ <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>)',
          silences:
            'Veuillez choisir un traitement de silence valide (Voir le champ <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>)',
        },
        swearWord: {
          bleepFile:
            'Veuillez sélectionner un effet sonore de bip (Voir le champ <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>)',
        },
        textBox: {
          xPadding:
            'La marge horizontale doit être un nombre positif (Voir le champ <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>)',
          yPadding:
            'La marge verticale doit être un nombre positif (Voir le champ <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>)',
          opacity: {
            min: "L'opacité doit être supérieure à 0% (Voir le champ <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>)",
            max: "L'opacité doit être inférieure à 100% (Voir le champ <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>)",
          },
        },
        anchor:
          "Le point d'ancrage doit être à l'intérieur de l'aperçu (Voir le champ <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>)",
        zoom: {
          autoZoomMinCoef: {
            min: 'Le coefficient de zoom minimal doit être supérieur à 1 (Voir le champ <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
            max: 'Le coefficient de zoom minimal doit être inférieur à 2 (Voir le champ <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          },
          autoZoomMaxCoef: {
            min: 'Le coefficient de zoom maximal doit être supérieur à 1 (Voir le champ <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
            max: 'Le coefficient de zoom maximal doit être inférieur à 2 (Voir le champ <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          },
          minZoomTime:
            'La durée minimale de zoom doit être supérieure à 0 secondes',
          maxZoomTime:
            'La durée maximale de zoom doit être supérieure à 0 secondes',
          dynamicZoomPercentage: {
            min: 'Le pourcentage de zoom doit être supérieur à 0%',
            max: 'Le pourcentage de zoom doit être inférieur à 100%',
          },
          coef: 'Le coefficient de zoom maximal ne peut pas être inférieur au coefficient de zoom minimal (Voir le champ <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          time: 'La durée maximale de zoom ne peut pas être inférieure à la durée minimale de zoom',
          dynamicMinZoomIntensity:
            "L'intensité de zoom dynamique doit être comprise entre 0% et 100% (Voir le champ <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>)",
          smoothZoomPercentage:
            'Le pourcentage de zoom fluide doit être compris entre 0% et 100% (Voir le champ <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>)',
          nervousness:
            'La nervosité doit être comprise entre 0% et 100% (Voir le champ <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>)',
          totalZoomPercentage:
            "La couverture totale du zoom doit être supérieure à 0% et jusqu'à 100% (Voir le champ <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>)",
          types: {
            at_least: {
              one: {
                true: 'Au moins un type de zoom doit être sélectionné (Voir le champ <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>)',
              },
            },
          },
        },
        repeat: {
          algorithm: "L'algorithme sélectionné n'est pas valide",
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Absence de paiement',
          button: {
            recover: "Récupérer l'abonnement",
          },
          to_continue:
            "Votre abonnement actuel a été <b>{status}</b> en raison d'un problème de paiement. Pour continuer à utiliser AutoCut, veuillez :",
          deactivated: 'désactivé',
          deleted: 'supprimé',
          recover: {
            list: {
              click:
                "<b>Cliquez sur le bouton ci-dessous</b> pour récupérer votre abonnement ou vérifiez l'envoi de facture par courriel de notre part ou de Stripe vous demandant de payer votre dernière facture",
              re_activated:
                'Votre clé sera <b>réactivée immédiatement</b> après la mise à jour du paiement',
            },
          },
          new: {
            list: {
              website:
                "Visitez le site internet d'AutoCut : <span>https://www.autocut.fr/#pricing</span>",
              subscribe: 'Souscrire à une nouvelle clé de licence',
            },
          },
          support:
            "Contactez notre équipe d'assistance si vous avez besoin d'aide : <span>contact@autocut.fr</span>",
          thanks: 'Merci !',
        },
        moda: {
          button: {
            new: 'Obtenir un nouvel abonnement',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Commencez votre aventure AutoCut',
          header: {
            days: {
              left: "Votre voyage créatif commence avec un essai gratuit de 14 jours d'AutoCut, vous avez débloqué {completedElements} jours de plus et il vous reste <b>{daysLeft} jours</b> pour explorer l'extension !",
            },
            more: {
              days: "Mais pourquoi s'arrêter là ? Lancez-vous dans une quête pour débloquer <b>{bonusDays} jours supplémentaires</b> et libérer pleinement la puissance d'AutoCut.",
            },
          },
          timeline: {
            left: 'Essai gratuit de 14 jours',
            right: 'Essai gratuit de 24 jours',
          },
          task: {
            list: {
              title: 'Achèvement des tâches : {completed}/{maximum}',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'AutoCut doit être rechargé',
          cta: 'Recharger AutoCut',
          text: "Il s'est écoulé plus de 30 minutes depuis la dernière ouverture d'AutoCut. {br} Pour des performances optimales, nous recommandons de recharger AutoCut maintenant.{br}Cliquez simplement sur le bouton ci-dessous pour recharger AutoCut",
        },
      },
    },
    'key-already-activated':
      'Cette clé a déjà été activée sur un autre ordinateur. Veuillez la dissocier dans votre espace client AutoCut (https://www.autocut.fr/fr/) avant de réessayer.',
    'key-does-not-exist':
      "Cette clé n'existe pas. Veuillez vérifier votre clé et réessayer.",
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Version de DaVinci Resolve non prise en charge',
            '24.4.0':
              'Veuillez rétrograder DaVinci Resolve à 24.3 ou mettre à niveau vers 24.5',
          },
          cta: 'Je comprends',
          text: {
            __text__:
              'Vous utilisez actuellement une version non prise en charge de DaVinci Resolve ({version}). Vous <b>ne pourrez pas</b> utiliser AutoCut de manière optimale avec cette version.{br}{br}Veuillez utiliser la <b>dernière version stable</b> pour bénéficier de la meilleure expérience avec AutoCut.',
            '24.4.0':
              'Vous utilisez actuellement une version non prise en charge de DaVinci Resolve ({version}). Il y a eu des changements majeurs dans cette version. Veuillez rétrograder à la version 24.3 ou mettre à niveau vers la version 24.5 pour utiliser AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Moteur de rendu non pris en charge',
          modal: {
            text: 'Vous utilisez actuellement un moteur de rendu non pris en charge. Vous <b>ne pourrez pas</b> utiliser AutoCaptions ni AutoZoom.{br}{br}Veuillez sélectionner un moteur de rendu accéléré par GPU dans les paramètres de votre projet.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Réinitialisation nécessaire',
          cta: 'Fermer toutes mes extensions',
          text: "Nous avons détecté plusieurs instances d'AutoCut en cours d'exécution. Cliquez sur le bouton ci-dessous pour les fermer toutes et éviter tout problème.",
        },
      },
    },
    disabled: {
      features: {
        info: 'Vous avez sélectionné une combinaison de langue/police qui ne prend pas en charge les fonctionnalités suivantes : <b>{features}</b> Ces fonctionnalités seront désactivées dans les sous-titres importés.',
        tips: 'Cela peut être lié aux caractères pris en charge par la police que vous avez choisie. Modifiez la police ou la langue pour activer ces fonctionnalités.',
      },
    },
    upgrade: {
      to: 'Passer à {plan}',
    },
    deepgram: {
      error: {
        modal: {
          title: 'Une erreur de traduction est survenue',
          cta: 'Rejoindre Discord',
          text: "Une erreur s'est produite lors de la traduction de l'audio. Cela peut être lié à la qualité de l'audio ou à la langue parlée, mais nous n'avons pas plus d'informations pour le moment. {br} Veuillez signaler votre problème sur Discord ou nous envoyer un e-mail à contact@autocut.fr.",
        },
      },
    },
    completing: {
      preset: {
        preview:
          'Finalisation de la prévisualisation... Cela peut prendre quelques secondes.',
      },
    },
    chapters: {
      preview: {
        error:
          "Une erreur s'est produite lors de la génération de l'aperçu. Essayez de mettre à jour les timings ou de redémarrer AutoCut si le problème persiste.",
      },
      step: {
        language: 'Sélectionnez les clips et la langue',
        editor: 'Éditeur de chapitres',
      },
      button: {
        chapters: 'Ajouter des chapitres',
      },
      editor: {
        chapters: {
          title: 'Chapitres détectés',
        },
        parameters: {
          title: 'Paramètres des chapitres',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Télécharger le fichier des chapitres YouTube',
                success: 'chapter.txt a été enregistré dans votre projet PPRO',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Sélectionnez des extraits sur votre timeline sur lesquels vous souhaitez baser les chapitres',
            text1:
              'Pour utiliser les chapitres, vous devez sélectionner au moins un extrait audio dans votre timeline.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Générer des chapitres',
        },
      },
      adding: {
        chapters: 'Ajout des chapitres...',
      },
      backup: 'Sauvegarde de votre séquence...',
      analyse: 'Analyse de votre audio...',
      getting: {
        chapters: 'Génération des chapitres...',
      },
      transcript: {
        finished: 'Les chapitres ont été générés',
      },
      disabled: {
        language:
          "La langue sélectionnée n'est pas encore supportée pour AutoChapters",
        file: {
          too: {
            long: 'Les clips sélectionnés sont trop longs. Veuillez sélectionner un maximum de {limit} minutes.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: 'Format incorrect. Veuillez utiliser le format HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animé',
        },
        static: {
          option: 'Statique',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Sélectionnez les clips de votre chronologie sur lesquels vous souhaitez baser le détecteur automatique de clips viraux',
              text1:
                'Pour utiliser le détecteur automatique de clips viraux, vous devez sélectionner au moins un clip audio dans votre chronologie.',
            },
          },
        },
        disabled: {
          language:
            "Le détecteur automatique de clips viraux n'est pas disponible pour cette langue pour le moment",
          file: {
            too: {
              long: 'Vos clips sélectionnés sont trop longs. Veuillez sélectionner des clips de moins de {limit} minutes.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Générer des clips viraux',
          },
        },
        button: {
          process: 'Créer des clips',
        },
        selector: {
          title: 'Détecté {number} clips potentiellement viraux',
        },
        virality: {
          score: 'Score de potentiel viral',
        },
        step: {
          language: 'Sélectionner les clips et la langue',
          selector: 'Sélectionner les clips viraux',
        },
        backup: 'Sauvegarde de votre séquence en cours...',
        analyse: 'Analyse de votre audio en cours...',
        getting: {
          clips: 'Génération de clips viraux en cours...',
        },
        transcript: {
          finished: 'Des clips viraux ont été générés',
        },
        adding: {
          creating: {
            clips: 'Création de clips en cours...',
          },
        },
      },
      score: 'Score viral',
    },
    mode: {
      description: {
        silence: 'Supprime les silences dans votre timeline',
        ai: 'Supprime les silences dans votre timeline',
        podcast: 'Édite instantanément les podcasts',
        repeat: "Élimine les prises de mauvaise qualité avec l'IA",
        zoom: 'Ajoute automatiquement des zooms',
        captions: "Ajoute des légendes animées avec l'IA",
        resize: "Redimensionne votre séquence avec l'IA",
        broll: "Ajoute automatiquement du B-Roll avec l'IA",
        profanity: 'Censure les gros mots',
        chapter: "Détecte les chapitres et ajoute des marqueurs avec l'IA",
        viral:
          'Identifiez des courts métrages viraux dans des vidéos longues en un clic',
      },
    },
    formatted: {
      numbers: {
        title: 'Les nombres ont été formattés',
        modal: {
          cta: 'Fermer la modale',
          text: '<b>{number} mots</b> ont été remplacés par des chiffres dans votre transcription.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Sélectionnez un modèle de transcription',
          model: {
            '1': 'Modèle 1 (par défaut)',
            '2': 'Modèle 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
