import {Button} from '@autocut/components/Button/Button';
import {DiscordIcon} from '@autocut/components/DiscordCta/DiscordIcon/DiscordIcon';
import {LoaderAutocut} from '@autocut/components/LoaderAutocut/LoaderAutocut';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {colors} from '@autocut/designSystem/colors';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {preload} from '@autocut/types/ElectronPreload';
import {ffmpegSetup} from '@autocut/utils/setup/ffmpeg.setup';
import {preloadWait} from '@autocut/utils/setup/preload.setup';
import {resourcesSetup} from '@autocut/utils/setup/resources.setup';
import {useEffect, useState} from 'react';
import {HiOutlineAcademicCap} from 'react-icons/hi';
import {useIntl} from 'react-intl';

import daVinciScreenshotPath from '@autocut/assets/images/DaVinciScript.png';
import {checkForUpdates} from '@autocut/utils/setup/update.setup';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {SpacingPx} from '@autocut/designSystem/enums/spacing.enum';
import {setAutocutStore} from '@autocut/utils/zustand';
import {daVinci} from '@autocut/utils/davinci';
import {StatType} from '@autocut/enums/statType.enum';
import {sendStats} from '@autocut/utils/stats.utils';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
const daVinciScreenshotUrl = new URL(daVinciScreenshotPath, import.meta.url)
  .href;

export type LaunchStepHandler = (
  setProgress?: (progress: number) => Promise<void>,
) => Promise<boolean>;

type LaunchStep = {
  title: TranslationKeys;
  handler: LaunchStepHandler;
  failHandler?: () => void;
};

const getLaunchSteps = (showUpdateModal: () => void): LaunchStep[] => [
  {
    title: 'launchManager_steps_preload_title',
    handler: preloadWait,
  },
  {
    title: 'launchManager_steps_update_title',
    handler: checkForUpdates,
    failHandler: () => {
      showUpdateModal();
    },
  },
  {
    title: 'launchManager_steps_ffmpeg_title',
    handler: ffmpegSetup,
  },
  {
    title: 'launchManager_steps_resources_title',
    handler: resourcesSetup,
  },
  {
    title: 'launchManager_steps_computeIPC_title',
    handler: async setProgress => {
      // Fin install
      try {
        await preload().computeNodeIpc.connect();
        await setProgress?.(50);
        const computeVersion = await preload().computeNodeIpc.startTask<string>(
          {
            domain: {
              slug: 'version',
              target: 'common',
            },
            priority: 0,
          },
        ).promise;
        setAutocutStore('ui.versions.compute', computeVersion);
        await setProgress?.(95);
        await sendStats({
          type: StatType.SETUP_COMPLETED,
          value: 1,
        });
        await setProgress?.(100);
        return true;
      } catch {
        throw new Error('launchManager_errors_compute');
      }
    },
  },
  {
    title: 'launchManager_steps_daVinciIPC_title',
    handler: async setProgress => {
      // Fin install
      try {
        await preload().daVinciNodeIpc.connect();
        console.log('Connected to lua server');
        await setProgress?.(33);
        await new Promise(async resolve => {
          console.log('waiting for daVinci');
          let isConnected = (await daVinci.misc.isConnected()).isConnected;
          while (!isConnected) {
            await new Promise(resolve => setTimeout(resolve, 2000));
            isConnected = (await daVinci.misc.isConnected()).isConnected;
          }
          resolve(true);
        });
        await setProgress?.(66);
        const daVinciVersion = await daVinci.misc.version();
        setAutocutStore('ui.versions.lua', daVinciVersion.luaVersion);
        setAutocutStore('ui.versions.luaServer', daVinciVersion.tsVersion);

        if (
          daVinciVersion.luaVersion !== daVinciVersion.tsVersion &&
          CURRENT_ENV != Env.Development
        ) {
          preload().electron.reload();
        }
        await setProgress?.(100);
        return true;
      } catch {
        throw new Error('launchManager_errors_davinci');
      }
    },
  },
];

export const LaunchManager = ({onComplete}: {onComplete: () => void}) => {
  const intl = useIntl();
  const [stepIndex, setStepIndex] = useState(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [error, setError] = useState<TranslationKeys | null>(null);

  const launchSteps = getLaunchSteps(() => setShowUpdateModal(true));

  useEffect(() => {
    const process = async () => {
      await sendStats({
        type: StatType.SETUP_STARTED,
        value: 1,
      });
      for (const [indexStr, step] of Object.entries(launchSteps)) {
        const index = parseInt(indexStr);
        const handlerResult = await new Promise(async resolve =>
          resolve(await step.handler().catch(e => setError(e.message))),
        );

        if (handlerResult) {
          if (index + 1 === launchSteps.length) {
            onComplete();
          }

          setStepIndex(index + 1);

          // Wait for the page to be updated
          await new Promise(resolve => setTimeout(() => resolve(true), 50));
        } else {
          if (handlerResult === false) {
            step.failHandler?.();
          }

          break;
        }
      }
    };

    void process();
  }, []);

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="space-between"
      gap={SpacingPx.p16}
      style={{height: '100%'}}
    >
      {showUpdateModal ? (
        <Modal
          noHeader
          footer={
            <Button onClickFunction={() => preload().updater.quitAndInstall()}>
              <TranslatedMessage id="launchManager_steps_update_modal_cta" />
            </Button>
          }
        >
          <FlexContainer
            flexDirection="column"
            justifyContent="space-between"
            gap={8}
            style={{padding: `0px ${SpacingPx.p16}`}}
          >
            <h1 style={{textAlign: 'center', marginBottom: SpacingPx.p24}}>
              <TranslatedMessage id="launchManager_steps_update_modal_title" />
            </h1>
            <h2>
              <TranslatedMessage id="launchManager_steps_update_modal_subtitle" />
            </h2>
            <p>
              <TranslatedMessage id="launchManager_steps_update_modal_body" />
            </p>
          </FlexContainer>
        </Modal>
      ) : null}
      {stepIndex === launchSteps.length - 1 ? (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={SpacingPx.p16}
          style={{margin: SpacingPx.p16}}
        >
          <h2>
            <TranslatedMessage
              id="launchManager_steps_daVinciIPC_title"
              defaultMessage="Installation / Update"
            />
          </h2>
          <div
            style={{
              padding: `${SpacingPx.p8} ${SpacingPx.p16}`,
            }}
          >
            <img
              src={daVinciScreenshotUrl}
              alt="davinci_screenshot"
              style={{
                height: 240,
                objectFit: 'cover',
                boxShadow: '0px 0px 10px 0px rgba(75,75,75,0.35)',
                borderRadius: 8,
              }}
            />
          </div>
          <h3 style={{textAlign: 'center'}}>
            <TranslatedMessage id={'launchManager_steps_daVinciIPC_subtitle'} />
          </h3>
          <p style={{textAlign: 'center'}}>
            <TranslatedMessage
              id={'launchManager_steps_daVinciIPC_stillNot'}
              valuePamareters={{
                copyText: intl.formatMessage({id: 'globals_contactMail'}),
              }}
            />
          </p>
          <p style={{textAlign: 'center'}}>
            <TranslatedMessage
              id={'launchManager_steps_daVinciIPC_ppro'}
              valuePamareters={{
                href: 'https://www.autocut.com/download',
              }}
            />
          </p>
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={SpacingPx.p16}
            style={{margin: `${SpacingPx.p32} ${SpacingPx.p16} 0`}}
          >
            <h1>
              <TranslatedMessage
                id="launchManager_title"
                defaultMessage="Installation / Update"
              />
            </h1>
            <h3 style={{textAlign: 'center'}}>
              {stepIndex}/{launchSteps.length} -
              <TranslatedMessage id={launchSteps[stepIndex].title} />
            </h3>
            <LoaderAutocut height={150} />
            {error ? (
              <>
                <p style={{color: 'red', textAlign: 'center'}}>
                  <TranslatedMessage id={error} />
                </p>
                <Button
                  color="red"
                  onClickFunction={() => preload().electron.reload()}
                  size={250}
                >
                  <TranslatedMessage id="launchManager_errors_cta" />
                </Button>
              </>
            ) : null}
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={SpacingPx.p16}
            style={{margin: `0 ${SpacingPx.p16} ${SpacingPx.p16}`}}
          >
            <Button
              buttonType="tertiary"
              color={colors.gray300}
              weight="bold"
              onClickFunction={() =>
                preload().electron.openLinkInBrowser(
                  'https://discord.gg/autocut',
                )
              }
              style={{display: 'flex', alignItems: 'center', gap: SpacingPx.p8}}
              compact
            >
              <DiscordIcon
                size={24}
                color="white"
              />
              <TranslatedMessage
                id="launchManager_footer_discord"
                defaultMessage="Click here to join the AutoCut Discord!"
              />
            </Button>
            <Button
              buttonType="tertiary"
              color={colors.gray300}
              weight="bold"
              onClickFunction={() =>
                preload().electron.openLinkInBrowser(
                  'https://www.autocut.com/help-center/',
                )
              }
              style={{display: 'flex', alignItems: 'center', gap: 8}}
              compact
            >
              <HiOutlineAcademicCap
                size={24}
                color="white"
              />
              <TranslatedMessage
                id="launchManager_footer_learnMore"
                defaultMessage="Click here to discover tips on mastering all AutoCut features!"
              />
            </Button>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};
