const translations = {
  progress: {
    footer: {
      warning:
        'Please do not do anything on DaVinci, it will cause some issues',
    },
    steps: {
      global: {
        starting: 'Starting...',
        processing: 'Processing...',
        ending: 'Ending...',
      },
      dev: {
        ping: 'Pinging DaVinci Resolve...',
        pong: 'Ponging DaVinci Resolve...',
      },
      common: {
        getSilences: 'Getting silences...',
        getAudioInfos: 'Getting audio infos...',
        readAudioFile: 'Reading audio file...',
        computeRMS: 'Analysing audio file...',
        convertRMSToDB: 'Analysing audio file...',
        exportDrt: 'Reading timeline...',
        extractDrt: 'Reading timeline...',
        getXmlTimelineFolderPath: 'Reading timeline...',
        getXmlTimelineFullpath: 'Reading timeline...',
        loadXmlTimeline: 'Reading timeline...',
        writeFile: 'Writing file...',
        writeXml: 'Importing modified timeline...',
        updateDrt: 'Importing modified timeline...',
        importXml: 'Importing modified timeline...',
      },
      podcast: {
        mainProcess: 'Podcast editing...',
        getVideoTracksWeight: 'Recording of speakers',
        getAudioTracks: 'Recording of audio tracks',
        getPodcastInterval: 'Calculating the duration of the podcast',
        getPodcastDuration: 'Calculating the duration of the podcast',
        renders:
          'Rendering of audio tracks by Da Vinci\n(may take a few minutes)',
        getTalkingTimelines: 'Distribution of speaking times',
        getTransitions: 'Calculating camera changes',
        getCutTimecodes: 'Preparing the cuts',
        cutAtTimecodes: 'Cuts of the timeline',
      },
      silences: {
        mainProcess: 'Silences editing...',
        getTimelineInfos: 'Getting timeline infos...',
        renders:
          'Rendering of audio tracks by Da Vinci\n(may take a few minutes)',
        convertAudioToMono: 'Processing audio file...',
        getCutTimecodes: 'Preparing the cuts...',
        cutAtTimecodes: 'Cuts of the timeline...',
        deleteSilentTrackItems: 'Deleting silences...',
      },
      zoom: {
        mainProcess: 'Adding Zooms...',
        getTimelineInfos: 'Getting timeline infos...',
        getWavData:
          'Rendering of audio tracks by Da Vinci\n(may take a few minutes)',
        computeBestZoomIntervals: 'Computing best zoom intervals...',
        createVideoTrack: 'Creating video tracks...',
        createZoomFusionComps: 'Importing Fusion compositions...',
      },
      captions: {
        transcript: {
          mainProcess: 'Generating transcript...',
          get_language: 'Get language',
          render_wav: 'Rendering WAVs in DaVinci (might take several minutes)',
          split_chunks: 'Splitting chunks',
          uploadFile: 'Uploading file...',
          getTranscript:
            'Generation of the transcription by AI\n(may take a few minutes)',
          writeFile: 'Writing file...',
        },
        generation: {
          mainProcess: 'Adding Captions...',
          initialization: 'Initializing Captions...',
          readTranscript: 'Reading transcript...',
          getTimelineInfos: 'Getting timeline infos...',
          appendAdjustmentLayer: 'Appending adjustment layer...',
          importFusionComp: 'Importing Fusion compositions...',
        },
      },
    },
  },
  launchManager: {
    title: 'Installation / Update',
    steps: {
      preload: {
        title: 'Preload',
      },
      update: {
        title: 'Checking for updates',
        modal: {
          title: 'AutoCut updated',
          subtitle: 'We just updated AutoCut',
          body: 'To provide you the best user experience, we just updated AutoCut.{br}Please relaunch it in clicking on the button below.',
          cta: 'Restart AutoCut',
        },
      },
      python: {
        title: 'Install Python',
      },
      ffmpeg: {
        title: 'Install FFMPEG',
      },
      resources: {
        title: 'Install resources',
      },
      computeIPC: {
        title: 'Connect to AI',
      },
      daVinciIPC: {
        title: 'Launch AutoCut on DaVinci',
        subtitle: "You don't find it? Relaunch DaVinci",
        stillNot:
          "You still don't find it after relaunching DaVinci & AutoCut?{br}Please contact use on <copy><embedTranslation>globals_contactMail</embedTranslation></copy>",
        ppro: 'You want to use AutoCut on Premiere Pro? <a>Download it here</a>',
      },
    },
    footer: {
      discord: 'Click here to join the AutoCut Discord!',
      learnMore:
        'Click here to discover tips on mastering all AutoCut features!',
    },
    errors: {
      compute: 'Cannot connect to compute AI, please contact support',
      davinci:
        'Cannot connect to DaVinci, please ensure DaVinci Resolve is running and the AutoCut script has been runned',
      cta: 'Retry',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Trial',
        upgrade: 'Upgrade',
        coming_soon: 'Coming soon',
        version_not_supported: 'DaVinci version not supported',
        upgrade_to: 'Upgrade to {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title: 'Select a timeline with at least 2 video and audio tracks',
          label: 'Select timeline',
          descriptions: {
            '1': 'Go to the edit tab',
            '2': 'Navigate to the timeline you want to edit',
          },
          cta: 'Select active timeline',
        },
        customization: {
          label: 'Podcast parameters',
          steps: {
            speakers: {
              title: 'Speakers',
              description:
                'Add your speakers by assigning each an audio track and entering their respective names. Only one audio track can be selected per speaker. If multiple speakers share a single audio track, enter both names (e.g., "Alice & Bob").',
              header: {
                audio: 'Audio track',
                name: 'Speaker’s name',
              },
              textInputPlaceholder: "Enter speaker's name...",
              trackTemplate: 'Track A{index}',
              cta: 'Add a speaker',
            },
            cameras: {
              title: 'Cameras',
              description:
                'Add your cameras by assigning each a video track and selecting wich speakers are visible on screen. Only one video track can be selected per camera. You can select any number of speakers per camera.',
              header: {
                audio: 'Video track',
                name: 'Speaker(s)',
              },
              speakerSelectPlaceholder: 'Select speaker(s)...',
              trackTemplate: 'Track V{index}',
              cta: 'Add a camera',
            },
            cameraTime: {
              title: 'Camera time durations',
              description:
                'Define the minimum and maximum duration a camera will be displayed before changing.',
              presets: {
                title: 'Default presets',
                items: {
                  calm: 'Calm',
                  paced: 'Paced',
                  energetic: 'Energetic',
                  hyperactive: 'Hyperactive',
                },
              },
              minimumCamTime: {
                title: 'Minimum camera time',
                description:
                  'Minimum duration (in seconds) a camera will be displayed without changing.',
                unit: 'seconds',
              },
              maximumCamTime: {
                title: 'Maximum camera time',
                description:
                  'Maximum duration (in seconds) a camera will be displayed before being forced to change.',
                unit: 'seconds',
              },
            },
            silentVideoClips: {
              title: 'Silent video clips',
              description:
                'Choose whether you want to remove or to disable the unused parts of the podcast.',
              items: {
                remove: 'Remove',
                disable: 'Disable',
              },
            },
          },
          cta: {
            disable: 'Edit and disable unused clips',
            delete: 'Edit and delete unused clips',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: 'The minimum camera time must be at least 0',
            higherThanMax:
              'The minimum camera time must be lower than the maximum camera time',
          },
          maximumCamTime: {
            min: 'The maximum camera time must be at least 0',
          },
          deleteUnusedClips: {
            required: 'You must choose to delete or disable unused clips',
          },
          speakers: {
            videoTracks: {
              min: 'You must select at least one video track per speaker',
            },
            audioTrack: {
              min: 'You must select an audio track for each speaker',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'Noise level',
          description:
            'Sound level considered as silence. You can see the effects on the visualization below.',
        },
        silenceSize: {
          title: 'Silence duration',
          items: {
            silence: {
              title: 'Remove silences longer than',
              description:
                'Minimum duration (in milliseconds) a silence must have to be removed.',
              unit: 'milliseconds',
            },
            talk: {
              title: 'Remove talks shorter than',
              description:
                'Minimum duration (in milliseconds) a talk segment must have to be kept.',
              unit: 'milliseconds',
            },
          },
        },
        paddingSize: {
          title: 'Padding',
          items: {
            before: {
              title: 'Before padding',
              description:
                'Silent time before a noise - to avoid sudden resumption of speech',
              unit: 'milliseconds',
            },
            after: {
              title: 'After padding',
              description:
                'Silent time after a noise - to avoid harsh speech interruptions',
              unit: 'milliseconds',
            },
          },
        },
        transition: {
          params: {
            title: 'Transitions',
            warning: 'You cannot use transitions without deleting silences.',
            default: {
              title: 'Default',
              choices: {
                none: 'None',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'This will add the padding after and/or before each cut. Creating a smoother transition between the cuts. If selected, it will create a new track for the transitions.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Both',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Constant Power',
              },
            },
          },
        },
        preview: {
          title: 'Preview',
          description:
            'Preview of preserved and removed segments. Use the arrows to move forward or backward, or center the preview based on the time cursor’s position.',
          legend: {
            kept: 'Kept',
            deleted: 'Deleted',
            margin: 'Margins',
          },
          cta: 'Generate the preview',
          center: 'Center on indicator',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'The noise level must be a negative number',
            int: 'The noise level must be a number',
          },
          minTimeOfSilence: {
            required: 'The minimum duration of silence is required',
            min: 'The minimum duration of silence must be at least 0',
          },
          minTimeOfTalk: {
            required: 'The minimum duration of talk is required',
            min: 'The minimum duration of talk must be at least 0',
          },
          marginBefore: {
            min: 'The margin before must be at least 0',
          },
          marginAfter: {
            min: 'The margin after must be at least 0',
          },
        },
      },
      transition: {
        params: {
          title: 'Transitions',
          warning: 'You cannot use transitions without deleting silences.',
          default: {
            title: 'Default',
            choices: {
              none: 'None',
            },
          },
          progress: {
            insert: 'Inserting new audio tracks…',
            move: 'Moving audio to new tracks for transitions…',
            handling: 'Adding transitions: {transition}',
          },
        },
      },
    },
    zoom: {
      title: 'AutoZoom',
      parameters: {
        preview: {
          title: 'Zoom preview',
          description: 'Define the anchor point',
          maximumZoom: 'Maximum zoom',
          errorMessage: 'An error occurred while loading the preview',
        },
        zoomTypes: 'Zoom types',
        defaultsPresets: {
          title: 'Default presets',
          calm: 'Calm',
          paced: 'Paced',
          energetic: 'Energetic',
          hyperactive: 'Hyperactive',
        },
        zoomCoverage: 'Total zoom coverage',
        constraint: {
          title: 'Constraint zooms to clip',
          helper:
            'If yes, no zoom will span over more than one clip. If no, your whole selection will be processed as one single audio file and zooms will ignore individuals clips in the timeline.',
        },
        maximumZoomDuration: 'Maximum zoom duration',
        smoothZooms: {
          title: 'Smooth zooms',
          coverage: 'Smooth zooms coverage',
          nervousness: 'Nervousness',
        },
        dynamicZooms: {
          title: 'Dynamic zooms',
          coverage: 'Dynamic zooms coverage',
        },
      },
      types: {
        smooth: 'Smooth',
        dynamic: 'Dynamic',
      },
      footerButton: 'Zoom',
      infoText:
        'AutoZoom will automatically add zoom to each clip selected based on decibels.',
    },
    captions: {
      title: 'AutoCaptions',
      steps: {
        language: {
          button: 'Go to captions editor',
        },
        customization: {
          button: 'Add captions',
          parts: {
            style: {
              title: 'Captions style',
              modal: {
                title: 'Edit style',
                apply: 'Apply style',
                save: 'Apply style and save as preset',
                text: {
                  title: 'Font',
                  font: 'Font name',
                  help: 'How to add a new font family?',
                  color: 'Color',
                },
                animation: {
                  title: 'Animation',
                  wordBox: 'Box with text',
                },
                outline: {
                  title: 'Outline',
                  width: 'Thickness',
                },
                box: {
                  title: 'Text box',
                  paddingX: 'Padding X',
                  paddingY: 'Padding Y',
                  opacity: 'Opacity',
                  radius: 'Radius',
                  disabled:
                    'The font you have chosen does not support the box feature for the language you have selected.',
                  tips: 'Pick a font that supports the language you have selected if you want to use this feature.',
                },
              },
            },
            preview: {
              title: 'Preview',
              modal: {
                title: 'Position & size',
                apply: 'Apply style',
                fontSize: 'Font size',
                nbLines: 'Number of lines',
                maxWidth: 'Maximum width',
                help: 'The preview is still in development and may not be accurate.',
              },
            },
            transcription: {
              title: 'Edit transcription',
              modal: {
                save: 'Save',
                select: 'Select captions you want to edit',
                placeholder:
                  'Select the part of the transcript you want to edit/customize',
                formatNumbers: 'Format numbers',
                checkTranscript: {
                  title: 'Have you checked the transcript?',
                  description:
                    "We noticed that you haven't checked the transcript yet.",
                  dontShowAgain: "Don't show this message again",
                },
              },
            },
            presets: {
              delete: {
                title: 'Delete presets',
                cancel: 'Cancel',
                confirm: 'Delete preset',
              },
              search: {
                title: 'Community presets',
                input: 'Search preset',
                cancel: 'Cancel',
                confirm: 'Import preset',
                usage: 'Used by {count} users',
                error:
                  'Error while copying the preset, please contact us on discord or at contact@autocut.fr',
              },
              create: {
                title: 'Create new preset',
                placeholder: 'Preset name (at least two words)',
                public: 'Share to community - public',
                cancel: 'Cancel',
                confirm: 'Create preset',
                alreadyExists:
                  'This preset already exists. Please choose another name.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'The user presets are missing, please reset your settings in the header menu.',
          },
          position: {
            xPercentage: {
              min: 'The x position of the text must be at least 0',
              max: 'The x position of the text must be at most 1',
            },
            yPercentage: {
              min: 'The y position of the text must be at least 0',
              max: 'The y position of the text must be at most 1',
            },
            emojiXPercentage: {
              min: 'The x position of the emoji must be at least 0',
              max: 'The x position of the emoji must be at most 1',
            },
            emojiYPercentage: {
              min: 'The y position of the emoji must be at least 0',
              max: 'The y position of the emoji must be at most 1',
            },
          },
          text: {
            fontSize: {
              int: 'The font size must be a number',
              min: 'The font size must be at least 1',
            },
            font: {
              missing:
                'Something is missing in the font you selected... Please select it again or choose another one.',
            },
          },
          outline: {
            width: {
              int: 'The outline width must be a number',
              min: 'The outline width must be at least 0',
            },
          },
          formating: {
            nbLines: {
              int: 'The number of lines must be a number',
              min: 'The number of lines must be at least 1',
            },
            maxWidth: {
              int: 'The maximum width must be a number',
              min: 'The maximum width must be at least 0',
            },
          },
          textBox: {
            xPadding: {
              int: 'The x padding must be a number',
              min: 'The x padding must be at least 0',
            },
            yPadding: {
              int: 'The y padding must be a number',
              min: 'The y padding must be at least 0',
            },
            opacity: {
              int: 'The opacity must be a number',
              min: 'The opacity must be at least 0',
              max: 'The opacity must be at most 100',
            },
            radius: {
              int: 'The radius must be a number',
              min: 'The radius must be at least 0',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'Set In/Out points',
          descriptions: {
            '1': 'Please define the entry and exit points in Davinci on the portion of the timeline you wish to cut',
            '2': "You can manually mute clips or tracks if you don't want them to be included in audio analysis",
          },
          cta: 'Confirm In/Out points',
        },
        transcription: {
          title: 'Select audio clip language',
        },
        customization: {
          title: 'Customize parameters',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Enter your license key to activate AutoCut.',
      placeholder: 'Enter your license key',
    },
    trial: {
      title: 'Get a free trial',
      steps: {
        email: {
          title: 'Enter your email address',
          subtitle: 'Enter your email address to receive a free trial license',
          placeholder: 'email@email.com',
          cta: 'Next',
        },
        sendingEmail: {
          title: 'Sending the email',
          success: 'Email sent successfully to {email}',
        },
      },
    },
    errors: {
      no_key: 'Please enter a license key',
      is_key_valid_error: 'Error while checking key, please try again later.\n',
      invalid_response: "Response doesn't come from server.",
      cant_read_host_error: "Can't read the computer's name.",
      cant_read_uuid_error: "Can't read the computer's UUID.",
    },
  },
  modals: {
    usageStats: {
      title: 'Usage statistics',
      select: {
        label: 'Select a mode',
      },
      stats: {
        thisMonth: 'This month',
        allTime: 'All time',
        mostUsed: 'Most used',
      },
    },
    endProcess: {
      title: 'Process completed',
      time: 'Process completed in {minutes} minutes and {seconds} seconds !',
      timeSaved:
        'You saved: <b>{minutesSaved}</b> minute(s) and <b>{secondsSaved}</b> second(s)',
      timeSavedAllTime:
        'Since using AutoCut, you saved : {hourSaved} hour(s) and {minutesSaved} minute(s) ! 🎉',
      feedback: 'Feel free to give us feedback on the quality of the result:',
      review: 'Grade AutoCut!',
      captions: {
        title: 'Captions added!',
        footer:
          'If your subtitle style or transcription is not ok, you can go back to the captions editor.',
        button: 'Back to the transcript editor',
        text_1: 'Your animated captions have been added! 🎉',
        text_2: 'If you need to modify captions:',
        list_1: 'Put the timeline cursor on the captions you want to update',
        list_2: 'Go on the fusion page',
        list_3: 'Click on the TextPlus node and update manually the captions',
        text_3: 'If you want more tips, you can visit this <a>tutorial</a>.',
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Unsupported DaVinci Resolve version',
      currentVersion:
        'We detected that you are using DaVinci Resolve {version}',
      text: 'AutoCut is only compatible with DaVinci Resolve with 18.6 and higher.',
      update: 'You can update it there: <a>DaVinci Website</a>',
    },
  },
  globals: {
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: 'Processing',
        },
        KebabMenu: {
          account: 'Manage licence key',
          usageStatistics: 'Usage statistics',
          bug: 'Bug report',
          review: 'Review AutoCut',
          tuto: 'Video tutorial',
          logout: 'Logout',
          settings: 'Reset settings',
          featureRequest: 'Feature request',
          academy: 'Help',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} days left',
          info: '{licenceType} license',
          trialButton: 'Get more',
          paidButton: 'Manage',
        },
        PromoBanner: {
          promo: 'Up to 20% OFF in the next 24 hours',
          shop: 'Shop now',
        },
        FileInput: {
          upload_screenshot: 'Upload your screenshot',
        },
        LinkToUserFileInput: {
          send_log_link_to_user_file: {
            title: 'Please provide a link to your video & audio file.',
            description:
              '(WeTransfer, Google Drive or any other file sharing service)',
            helper:
              "To upload your file on WeTransfer, go to wetransfer.com and click on 'Upload files'. After that, you will get a link, copy it here.",
            end: 'This will assist us in quickly resolving your issue.',
          },
        },
        Select: {
          'no-more': 'No more options available',
          'select-all': 'Select all',
          placeholder: 'Select an option',
        },
        PreviewFrame: {
          previewOnIndicator: 'Preview on indicator',
        },
      },
    },
    misc: {
      retry: 'Retry',
    },
    units: {
      second: 'seconds',
      millisecond: 'milliseconds',
      percentage: '%',
      px: 'px',
    },
  },
  old: {
    text: {
      errorSelection:
        'Invalid selection.{br}Please select tracks directly from an audio or video file.{br}If a sequence is selected, split the videos within the sequence.',
      loading: 'Cutting...',
      Step4: {
        AutoZoom: {
          ratio: 'Maximum zoom coefficient',
          step: 'Percentage of zoomed video',
        },
        Title: 'AutoZoom (Beta)',
      },
      Step5: {
        Backup: 'Enable backup',
        Title: 'Preview',
      },
      autocutv2:
        'AutoCut v2 is the expert version with many configurable options for optimal silence detection.',
      autocutAi:
        'AutoCut AI automatically detects silences with just one parameter, letting the AI handle the rest.',
      autocutPodcast:
        'AutoCut Podcast allows you to edit podcast videos in a matter of seconds. Simply provide your camera setup and enjoy your final video freshly edited.',
      autocutRepeat:
        'Sit back and let AutoCut Repeat remove every bad takes of your video and leave you with only the best ones.',
      autocutCaptions:
        'Generate tiktok style captions for your videos blazing fast.',
      Step3: {
        Remove: 'Remove',
        Keep: 'Keep',
        CenterButton: 'Center on indicator',
        Previsualisation: {
          __text__: 'Preview:',
          loading: 'loading...',
        },
        Noise: {
          __text__: 'Noise level',
          helper:
            'Sound level considered as silence. You can see the effects on the visualization below.',
          unit: 'decibels',
        },
        DeleteSilence: 'Remove silences',
        KeepSilence: {
          Yes: 'YES',
          No: 'NO',
        },
        Title: 'Adjust noise level',
      },
      Step2: {
        After: 'After padding',
        Before: 'Before padding',
        Title: 'Padding',
      },
      Step2Ai: {
        Title: 'Verify preview',
      },
      Step1: {
        Talks: 'Remove talks shorter than',
        Silences: 'Remove silences longer than',
        Title: 'Silence durations',
      },
      Step1Ai: {
        Silences: 'Choose AI aggressiveness',
        Title: 'AI aggressiveness',
      },
      Step1Podcast: {
        Title: 'Speakers',
      },
      step6: {
        selected: {
          clips: 'Selected clips',
        },
      },
      NoInternet: 'Internet connection is required.',
      noClip: {
        title: 'Select clip(s) to cut in your timeline',
        no_selection:
          "If AutoCut doesn't respond, try restarting DaVinci Resolve.",
        tuto: {
          __text__: 'Need help? Feel free to watch our',
          link: ' video tutorial',
        },
        loading: {
          bar: 'Loading - Sequence analysis in progress...',
        },
      },
      needPProUpdate: 'Your DaVinci Resolve version must be 15 or later.',
      No_Key: 'Please enter a license key',
      Login: {
        SubmitButton: 'Activate',
        SubmitButton2: 'Activate',
        PasteButton: 'Paste',
      },
      Welcome: {
        Title: 'Welcome',
        freeTrial: 'Get a free trial',
        UseLicence: 'Use a license key',
        BuyLicence: 'Buy a license key',
      },
      UseLicence: {
        Title: 'Use a license key',
        Text: 'Enter your license key and start using AutoCut!',
      },
      GetLicence: {
        Title: 'Receive a free trial license by email',
        Select: 'Where did you discover Autocut?',
        Text: 'Enter your email address and receive a free trial license',
        Button: 'Get a free license',
      },
      UseNewLicence: {
        Title: 'Your license key has been sent to {s}!',
        Text: "Check your email, enter your license key, and start using AutoCut! If you don't receive the key in the next few minutes, please check your spam.",
        Resend: "Didn't receive the email?",
        ResendButton: 'Resend the key to {mail}',
        ResendSucces: 'Email sent!',
      },
      NoFFMPEG: 'There has been an issue with FFMPEG, please contact support',
      legend: {
        kept: 'Kept',
        deleted: 'Deleted',
        margin: 'Margins',
      },
      yes: 'Yes',
      no: 'No',
      undo: {
        modal: 'Not satisfied with the cuts?',
      },
      since: {
        your: {
          last: {
            visit: 'Since your last visit',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted:
              'We apologize, but it seems that the file {filename} has an unsupported format...',
            speed:
              'We apologize, but AutoCut does not support cutting clips with altered speeds...',
            multi: {
              audio:
                'We apologize, but AutoCut may not function properly if all audio tracks in a file are not selected.',
              stream:
                'We apologize, but it seems that one of your selected files has multiple audio streams...',
            },
            '51_stereo':
              'We apologize, but it seems that one of your selected files has a 5.1 audio layout displayed in stereo by DaVinci Resolve...',
            audio: {
              layout:
                "We're sorry, but it seems that one of your selected files has an unsupported audio layout...",
            },
            remote: {
              file: "We apologize, but it seems that we couldn't access the file {filename}...",
            },
          },
        },
        maintained: {
          modal:
            'Please upgrade to a newer version of DaVinci Resolve to use AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: 'Please select different clips to cut.',
              speed:
                'Please reset clip speeds (to 100%) or select different clips to cut.',
              multi: {
                audio:
                  'Ensure all audio channels are selected and remain in the default DaVinci Resolve order.',
                stream: 'Please select different clips to cut.',
              },
              '51_stereo':
                'Please select different clips or change the channel layout of your sequence to cut.',
              audio: {
                layout:
                  'Please select other clips to be able to cut them, if the problem persists, please send us a screenshot of your sequence.',
              },
              remote: {
                file: 'Please make sur that the files are available on your computer and not in a cloud.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'Enter your license key to activate AutoCut.',
        },
      },
      no_audios: 'This audio will be used to detect, cut and remove silences.',
      step1: {
        podcast: {
          title: 'Camera time durations',
          minimum: 'Minimum camera time',
          maximum: 'Maximum camera time',
        },
      },
      silences: {
        __text__: 'silences',
        step: {
          delete: {
            __text__: 'Remove {mode}',
            keep: {
              spaces: 'Remove {mode} but keep spaces',
            },
          },
          keep: 'Keep {mode}',
          mute: 'Mute {mode}',
        },
      },
      repetitions: 'repetitions',
      podcast: {
        add: {
          speaker: 'Add a speaker',
          camera: 'Add a camera',
        },
        track: 'Track',
        camera: {
          title: 'Cameras',
          helper:
            'Add your cameras and specify wich speaker is visible on each camera. You can select any number of speakers per camera.',
        },
        speaker: 'Speaker',
        all: {
          speakers: 'All speakers',
        },
      },
    },
    help: {
      bug: 'Bug report',
      review: 'Review AutoCut',
      multipiste: 'Need help with multitrack? View our video tutorial',
      tuto: 'Video tutorial',
      academy: 'Help',
      buy: 'Purchase a license key',
      account: 'Manage licence key',
      logout: 'Logout',
      reset: {
        settings: 'Reset settings',
      },
      transcription: {
        provider: {
          step: 'Quality and duration of the transcription may vary depending on the model selected.',
        },
      },
    },
    bugBtnDialog: 'Send an email to: contact@autocut.fr',
    first: {
      launch: {
        error:
          "An error occurred during AutoCut's launch. Please restart the plugin.",
      },
    },
    helper: {
      Step4: {
        '1': 'If you enable the AutoZoom option, the clips will be zoomed after cutting.',
        '2': 'The zoom coefficient is calculated based on the values provided below and the maximum volume of the sub-clip.',
        '3': 'Choose the percentage of video to zoom.',
      },
      Step5: 'Backup allows you to undo all modification after cuts.',
      Step1: {
        '1': 'Minimum duration (in seconds) a silence must have to be removed.',
        '2': 'Minimum duration (in seconds) a talk segment must have to be kept.',
        podcast: {
          minium:
            'Minimum duration (in seconds) a camera will be displayed without changing.',
          maximum:
            'Maximum duration (in seconds) a camera will be displayed before being forced to change.',
        },
      },
      Step1Ai:
        'Aggressiveness determines how much silence the AI will remove, with a minimum of 1 and a maximum of 4.',
      Step2: {
        '1': 'Silence duration before the talk segment.',
        '2': 'Silence duration after the talk segment.',
      },
      Step3: {
        '1': 'Sound level considered as silence. You can see the effects on the visualization below.',
        '2': "Preview of preserved and removed segments. Use the arrows to move forward or backward, or center the preview based on the time cursor's position.",
        '3': "If you choose to don't delete the silences, the clip will be cut, but silences will not be removed.",
      },
      Save: {
        Default:
          'Save settings. Save the current settings and they will be loaded the next time you start.',
      },
      Undo: {
        Cut: 'Undo the last cut. Restore the clips to their state before the last cut.',
      },
      speakers: {
        podcast: {
          __text__:
            'Add your speakers, give them a name and select the corresponding audio track.',
          audio: {
            tracks:
              'Select the audio track where we can hear the speaker, you can only select one track per speaker.',
          },
          video: {
            tracks:
              'Select the video tracks where the speaker appears, you can select any number of tracks.',
          },
        },
      },
    },
    warn: {
      Step5:
        'Warning : if you disable backup, you will not be able to undo your cuts.',
    },
    title: {
      Step5: {
        Backup: 'Enable backup',
      },
      undo: {
        modal: 'Done!',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'One of your clips has an unsupported format',
            speed: 'AutoCut only supports clips with default speed',
            multi: {
              audio: 'AutoCut may not work well with multiple audio tracks',
              stream: 'One of your clips has multiple audio streams',
            },
            '51_stereo':
              'One of your clips has a 5.1 audio layout displayed in stereo',
            audio: {
              layout: 'One of your clips has an unsupported audio layout',
            },
            remote: {
              file: 'One of your clips is not on your computer',
            },
          },
        },
        maintained: {
          modal:
            'AutoCut is not maintained on versions of DaVinci Resolve older than the 2021 edition.',
        },
      },
      use: {
        trial: {
          licence: 'Check your email and use your license key',
        },
        paid: {
          licence: 'Use your license key',
        },
      },
      no_audios: 'There is no audio in your selection',
      banner: {
        podcast: {
          beta: 'Thank you for trying our new Podcast mode.',
        },
      },
      silences: {
        step: 'Manage {mode}',
      },
      podcast: {
        silences: {
          step: 'Remove or mute silences',
        },
      },
    },
    generate: {
      preview: 'Generate the preview',
    },
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut and delete silences',
          l_cut: 'L-Cut and delete silences',
          both: 'J&L-Cut and delete silences',
          constant: {
            power: 'Cut, delete silences and apply audio transition',
          },
          none: 'Cut and delete {mode}',
          keep: {
            spaces: {
              none: 'Cut {mode} and keep spaces',
            },
          },
        },
        keep: {
          none: 'Cut and keep {mode}',
        },
        mute: {
          none: 'Cut and mute {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Getting tracks...',
        silences: 'Getting silences...',
      },
      edit: {
        track: 'Editing track {indexTrackInfo}: {current} out of {max}',
      },
      final: {
        message: {
          __text__: 'Success! Your podcast has been edited',
          error: 'An error occurred while editing your podcast',
        },
      },
      button: {
        disable: 'Edit and disable unused clips',
        delete: 'Edit and delete unused clips',
      },
      not: {
        available: {
          title: 'Select a sequence with at least 2 video and audio tracks',
        },
      },
      minimum: {
        higher: {
          maximum:
            'Minimum camera time must be lower than maximum camera time.',
        },
      },
      empty: {
        audio: {
          track: 'Audio tracks cannot be empty.',
        },
        video: {
          track: 'Video tracks cannot be empty.',
        },
      },
      preset: {
        title: 'Default presets',
        calm: 'Calm',
        paced: 'Paced',
        energetic: 'Energetic',
        hyperactive: 'Hyperactive',
      },
    },
    error: {
      UseLicence: {
        __text__:
          'You can unlink your key from your other computer through the',
        link: 'AutoCut client area.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'Subsequence cannot be reached',
          unknown: 'An unknown error occurred',
          noSpace: 'An error has occurred : not enough free space',
          exportUnknown: 'DaVinci Resolve encountered an error during export',
          invalidInputData:
            'An error has occurred during temporary files generation',
          fileNotFound: 'An error occurred while reading one of your files',
          scriptNotLoaded: 'An error occurred while loading AutoCut',
          ffmpegError: "We couldn't read one of your files.",
          exportCancelled: 'Export cancelled',
          timelineMuted: 'No audible tracks',
          noAudibleAudio: 'No audible audio',
          offline: 'Seems like you are offline',
          resourceTimeout: "Can't get resource",
          backupNotFound: "Can't find your backup",
          setCursorOutInterval: 'Out of In/Out points',
          emptyPath: 'An error has occurred during selection analysis',
          sequenceMismatch: 'Unable to perform the operation',
        },
        text: {
          subsequenceNotReachable:
            "We are sorry, but we couldn't reach one of your selected subsequences.",
          unknown:
            'An error occurred while using AutoCut, but we were unable to identify it.',
          noSpace: "It seems you don't have enough free space on your device.",
          exportUnknown:
            'This error is most likely caused by a lack of storage space on the disk.',
          invalidInputData:
            'We apologies, but it seems that an error occurred during temporary file generation.',
          fileNotFound:
            'Please check that file {filename} is still available at location: {filepath}',
          scriptNotLoaded:
            'Please close all others extensions and DaVinci Resolve and retry.',
          ffmpegError:
            'Please check that the file {filepath} is not corrupted.',
          exportCancelled:
            "AutoCut can't analyze the audio if you stop the encoding. Please try again.",
          timelineMuted: 'All of your used audio tracks are muted.',
          noAudibleAudio: 'No audible audio has been detected.',
          offline: "We can't reach our servers.",
          resourceTimeout:
            'The online resource needed for this process could not be downloaded within 30 seconds',
          backupNotFound:
            "We cannot restore your timeline as we can't find the backup sequence",
          setCursorOutInterval:
            'You selected a chunk that is not contained in your In/Out points.',
          emptyPath:
            'We apologies, but it seems that one of your selected clips does not have a path on your computer.',
          sequenceMismatch:
            'You changed sequence while AutoCut was processing. Please try again without changing sequence.',
        },
        tips: {
          subsequenceNotReachable:
            'Please make sure that each subsequence in the timeline share the same name with the original, corresponding sequence in the project panel.',
          unknown:
            'Try changing your selection or settings. If the problem persists, please contact us.',
          noSpace: 'Try removing some files before relaunching AutoCut.',
          exportUnknown: 'Try removing some files before relaunching AutoCut.',
          invalidInputData:
            'Please try again with the same selection. If the problem persists, please contact us with your file.',
          fileNotFound:
            'Please try again with the same selection. If the problem persists, please contact us with your file',
          scriptNotLoaded: 'If the problem persists, please contact us.',
          ffmpegError: 'If the problem persists, please contact us.',
          exportCancelled: ' ',
          timelineMuted: 'Please unmute at least one of them.',
          noAudibleAudio:
            'Please check that at least one clip is active and is audible.',
          offline: 'Please check your internet connection and try again.',
          resourceTimeout:
            'Please check your internet connection and try again.',
          backupNotFound:
            'Please check the "AutoCut-Backup" bin at the root of your project to see if the backup is still there.',
          setCursorOutInterval:
            'You must remove the In/Out points to listen this part of the transcript.',
          noSoundDetected:
            "AutoCut couldn't detect any sound. Please check the following: {br}{br} <li>Ensure the selected language matches the language of your clips.</li><li>Make sure your tracks are not muted.</li><li>Confirm that your In and Out points include clips with sound.</li> {br}If the issue persists after checking these points, feel free to contact us on Discord or at contact@autocut.fr.",
          emptyPath:
            'Please make sure that all your clips are from atomic medias or subsequences.',
          sequenceMismatch:
            'When you use AutoCut, please do not change sequence, or change anything in the current sequence, until the process is finished.',
        },
        lost: {
          cep: {
            title: 'An error has occured : Lost connection',
            text: {
              '1': 'You have lost the connection to Adobe DaVinci Resolve. Please restart the extension and try again.',
              '2': "If restarting the extension doesn't fix the issue, try restarting DaVinci Resolve.",
            },
          },
        },
      },
      language: {
        not: {
          supported: 'The language is not supported for this mode.',
        },
      },
    },
    retour: "This key doesn't exist!",
    countdown: {
      days: 'trial days remaining',
    },
    saveas: {
      default: {
        settings: 'Save current settings',
      },
    },
    button: {
      no_silences: 'No silence detected',
      no_audios: 'No audio clip selected',
      SavingBackup: 'Backing up your project...',
      RestoringBackup: 'Restoring your project...',
      ExportingAudio: 'Exporting audio...',
      UploadingAudio: 'Uploading audio...',
      SearchingSilences: 'Searching for silences...',
      CuttingStep: {
        Audio:
          'Performing cuts on audio track {numTrack} : {current} out of {max}',
        Video:
          'Performing cuts on video track {numTrack} : {current} out of {max}',
      },
      PostCuttingStep: 'Cuts done...',
      PreDeletingStep: 'Searching for silence clips to delete...',
      PostDeletingStep: 'Silences deleted...',
      finalizing: 'Finalizing...',
      DeletingStart: 'Deleting silences...',
      AssociatingStep: 'Performing association {current} out of {max}',
      Success: 'Done ! Process ended successfully',
      Failure: 'Failure... Something went wrong',
      NoSilences: 'No silences found',
      undo: {
        modal: 'Undo',
      },
      not: {
        supported: {
          modal: 'Close',
        },
      },
      PreZoomingStep: 'Obtaining sub-clips for zooming...',
      ZoomingStep: 'Adding zoom : {current} out of {max}',
      Autozoom: 'Preparing AutoZoom...',
      no_video: {
        to_zoom: 'No video to zoom.',
      },
      getSubclip: {
        step: 'Retrieving sub-clip for AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Checking if silences are correctly removed...',
      },
      back: 'Back',
      DeletingStep: {
        delete: {
          Audio:
            'Deleting silence on audio track {numTrack}: {current} out of {max}',
          Video:
            'Deleting silence on video track {numTrack}: {current} out of {max}',
        },
        mute: {
          Audio:
            'Muting silence on audio track {numTrack}: {current} out of {max}',
          Video:
            'Muting silence on video track {numTrack}: {current} out of {max}',
        },
      },
      skip: 'Skip',
      save: 'Save',
    },
    warning: {
      DontTouchTimeline:
        'Please do not modify your timeline during this process',
      undo: {
        modal:
          'We noticed that some metadata was not present when analyzing your audio files.',
      },
      modal: {
        title: {
          ffmpeg: 'AutoCut may not work as expected',
        },
        text: {
          ffmpeg:
            'We apologize, but it seems that some metadata are missing in the file {filename}.',
        },
        tips: {
          ffmpeg: 'Try to re-encode your file if you encounter any issues.',
        },
      },
      beta: {
        version:
          'It seems you are running a beta version of DaVinci Resolve. You might encounter some issues with AutoCut. Please test it out on the latest stable version of DaVinci Resolve.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Checking if your selection is valid...',
          parsing: {
            step: 'Getting data of your selection : clip {current} out of {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Getting silence of your selection : clip {current} out of {max}...',
          },
        },
        drawing: 'Generating preview...',
      },
      frame: {
        no_sequence: 'Open a sequence to preview your format',
      },
    },
    contact: {
      us_title: 'We are sorry that you encountered a bug',
      us_text: {
        '2': 'If you have an issue with your account, your key, your payment or anything else not link to your video editing project :',
        __text__:
          "If AutoCut doesn't works perfectly with your project and you want to report the issue :",
      },
      us_button: 'Report an issue',
      us_contact: 'Contact us :',
      us_copy: 'Contact copied to clipboard !',
    },
    switch: {
      to_send: {
        log: {
          button: 'Or send a log file',
        },
      },
    },
    send: {
      log: {
        title: 'We apologize for the inconvenience caused by this bug',
        details:
          "We only have access to information about the algorithms' work with this log file.",
        select:
          'Please select a daily log file, it will help us resolve your issue.',
        text: {
          area: 'Please, describe your issue in as much detail as possible:',
        },
        button: 'Send log file',
        success: {
          title: 'File sent! We will investigate your issue.',
        },
        thank: {
          text: 'You may continue cutting your videos.',
          text2: 'We will contact you when the issue has been resolved.',
        },
        message: {
          error: 'Please enter a message with at least 10 characters',
        },
        screen: {
          timeline: {
            __text__:
              'Please attach a screenshot of your selection in DaVinci Resolve.',
            before:
              'Please attach a screenshot of your selection in DaVinci Resolve before cuts.',
            after:
              'Please attach a screenshot of your selection in DaVinci Resolve after cuts.',
          },
          error: {
            message: 'Please attach a screenshot of the error message.',
          },
        },
        trial: {
          mail: 'Please provide the email you ask a trial with.',
        },
        other:
          'Please provide any information that could help to solve your problem.',
      },
    },
    select: {
      issue: {
        title: 'Please select your issue in the list below.',
        subtitle: 'It will help us to resolve your issue.',
      },
      one: {
        audios: 'Please select at least one audio clip to be able use AutoCut.',
      },
    },
    upload: {
      screenshot: 'Upload your screenshot',
    },
    modal: {
      feedback: {
        title: 'Feedback',
        input: {
          feedback: {
            title: 'What do you think of AutoCut?',
            placeholder: 'Write your feedback here...',
          },
          improve: {
            title: 'Tell us what can be improved?',
            placeholder: 'Write what could be improved here...',
          },
        },
        button: {
          text: 'Send',
        },
        sent: {
          title: 'Thank you for your feedback!',
          text: 'If you have any feature idea to implement in AutoCut, you can send it to us:',
          button: {
            text: 'Request feature',
          },
        },
      },
      bug: {
        report: {
          title: 'Bug report',
          description: {
            placeholder: 'Describe the bug here...',
            error:
              'Please provide a bug description of at least 20 characters.',
          },
          reproduce: {
            placeholder: 'Steps to reproduce the bug here...',
            error: 'Please provide a reproduction of at least 20 characters.',
          },
          blocking: 'The issue was blocking',
          button: {
            text: {
              __text__: 'Send bug report',
              success: 'Thank you for your report',
            },
          },
          link: {
            error: 'Please provide a valid link to your file.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'Have you encountered an issue with your cuts?',
      },
    },
    star: {
      review: {
        title: 'Leave AutoCut a testimonial!',
        captions: {
          title: 'Rate AutoCaptions!',
        },
        text: 'Share your thoughts about AutoCut!',
      },
    },
    review: {
      title: 'Tell us more',
      text: "Were the cuts satisfactory? Do you have any feedback to help us improve AutoCut? We're all ears:",
      form: {
        placeholder: 'Enter your message...',
      },
      button: 'Send',
      thank: {
        title: 'Thank you for your feedback!',
        text: 'You may continue cutting your videos.',
      },
    },
    exchange: {
      title: 'Get an extra 7 days of trial!',
      text: 'Head to Adobe Exchange and rate AutoCut to get an extra 7 days of trial!',
      button: 'Rate AutoCut on Adobe Exchange',
      info: "If you installed AutoCut from our website, you don't need to install the extension again. Simply click on “Free” to start the download and access the rating.",
    },
    licence: {
      trial: 'Trial',
      paid: 'Paid',
      info: '{licenceType} license',
      time: {
        left: '{daysLeft} days left',
      },
    },
    trial: {
      promo: {
        text: 'Up to 20% OFF in the next 24 hours',
      },
      resend: {
        error: 'Error while resending trial key, please try again later.\n',
      },
      expired: {
        modal: {
          title: 'Your trial key has expired.',
          text1:
            'Your free trial has expired. Subscribe to AutoCut to keep using the extension.',
          text2: 'Visit your AutoCut dashboard at:',
          text3: 'Subscribe to a license key.',
          text4:
            'Reach out to our support team if you need help: {supportEmail}',
          button: 'Get a subscription (-20%)',
        },
      },
      title: 'Free trial',
      stepper: {
        onboarding: 'Help us to improve AutoCut',
        email: 'Enter your email',
        sending: {
          email: 'Sending the email',
        },
        login: 'Use your licence key',
      },
      onboarding: {
        subtitle:
          'To improve the extension and imagine new features, please help us to understand your usage of DaVinci Resolve',
        discover: 'Where did you discover AutoCut?',
        job: {
          status: 'What is your job status?',
        },
        type: {
          creation: 'Type of creations you do on DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Email sent successfully to {email}',
        },
      },
      task: {
        captions: 'Use AutoCaptions: <link>learn more</link>',
        podcast: 'Use AutoCut Podcast: <link>learn more</link>',
        zoom: 'Use AutoZoom: <link>learn more</link>',
        repeat: 'Use AutoCut Repeat: <link>learn more</link>',
        broll: 'Use AutoB-Roll: <link>learn more</link>',
        swear: {
          word: 'Use AutoProfanity Filter: <link>learn more</link>',
        },
        preset: 'Create a new preset for AutoCaptions, AutoZoom or AutoResize',
        testimonial:
          'Let us a testimonial (using the same email): <link>testimonial</link>',
      },
    },
    shop: {
      now: 'Shop now',
    },
    back: {
      to_autocut: 'Go Back to AutoCut',
    },
    'discover-option-youtube-video': 'YouTube video',
    'discover-option-youtube-comment': 'YouTube comment',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Friend',
    'discover-option-school': 'School',
    'discover-option-other': 'Other',
    link: {
      not: {
        supported: {
          modal: {
            __text__:
              'To better understand this error and how to fix it, go to ',
            no_solution: 'To better understand this error, please go to ',
            end: ', our documentation and tutorials site',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'You just received a mail on your email ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            "If you don't see the email, check your spam or promotional folder.",
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Enter your license key',
      },
    },
    "can't": {
      reach: {
        server: {
          error: "Can't reach the server. Please try again later.",
        },
      },
      read: {
        uuid: {
          error: "Can't read the computer's UUID.",
        },
        host: {
          error: "Can't read the computer's name.",
        },
      },
    },
    asking: {
      key: {
        error: 'Error while asking server for key, please try again later.\n',
      },
    },
    sending: {
      log: {
        file: {
          error: "Can't send daily log, please try again later.\n",
        },
      },
    },
    is_key: {
      valid: {
        error: 'Error while checking key, please try again later.\n',
      },
    },
    base: {
      error: {
        message: 'An error has occurred ',
      },
    },
    invalid: {
      response: {
        error: "Response doesn't come from server.",
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'It is still under heavy development, if you have any bugs or have any suggestions, please contact us at contact@autocut.fr',
        },
      },
    },
    header: {
      trial: {
        button: 'Get more',
      },
      paid: {
        button: 'Manage',
      },
      speakers: {
        name: "Speaker's name",
        track: 'Audio track of the speaker',
      },
      cameras: {
        name: {
          __text__: 'Video track of the camera',
          helper:
            'Select the video track where the camera is, you can only select one track per camera.',
        },
        speakers: {
          __text__: 'Speaker(s) on camera',
          helper:
            'Select the speakers that are visible on this camera, you can select any number of speakers.',
        },
      },
    },
    game: {
      saved: {
        hours: 'You just saved {hourSavedText} hour(s),',
      },
      have: {
        a_break: 'have a break you deserve it!',
      },
    },
    onboarding: {
      skip: 'Skip onboarding',
      close: 'Close',
      previous: 'Previous',
      next: 'Next ({current}/{max})',
      onboarding: {
        step1: {
          title: 'Welcome to AutoCut!',
          body: "During your first use, various AutoCut features will be introduced step-by-step, guiding you through the process. So, let's start on the journey!",
        },
        step2: {
          title: 'Free trial key.',
          body: "If you're interested in testing AutoCut's features for yourself, you can request a free trial key at any time. Please note that you will only be able to request one trial key for up to 14 days.",
        },
        step3: {
          title: 'License key.',
          body: 'If you already have a licensed key - either trial or paid, you can use it directly to access AutoCut tools.',
        },
        step4: {
          title: 'Buying a license key.',
          body: "If you'd like to purchase a licensed key, you can click on the button provided to be redirected to the AutoCut website, where you'll find all the information you need to buy AutoCut.",
        },
      },
      modeSelection: {
        step1: {
          title: 'AutoCut Modes.',
          body: 'AutoCut offers three different modes to cater to different editing needs. You can select the mode that fits your requirements by simply clicking on one of the modes.',
        },
        step2: {
          title: 'Help needed?',
          body: 'Every element on the interface comes equipped with a tooltip, providing additional information about its function. You can learn more about a particular element by hovering the mouse pointer over its tooltip.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2.',
          body: 'The AutoCut V2 feature relies on the noise level of your audio file to detect silences with precision. However, you need to set it up before using it.',
        },
        step2: {
          title: 'Noise level.',
          body: 'Adjust the minimum noise level that will be detected as a silence by using the slider.',
        },
        step3: {
          title: 'What is next?',
          body: 'Navigate through the steps by clicking on the arrows to learn more about AutoCut functionality.',
        },
        step4: {
          title: 'Generating preview.',
          body: "Your timeline's audio needs to be exported before being processed. By clicking 'Generate the preview' you can pre-export the audio and visualize the result with your current parameters.",
        },
        step6: {
          title: 'Time to Cut.',
          body: "Once you've reviewed everything and it looks good, it's time to cut!",
        },
        step7: {
          title: 'Time to Cut.',
          body: 'Click on the arrow to choose the cutting options that you prefer.',
        },
        step8: {
          title: 'Time to Cut.',
          body: 'Sit back, relax, and let AutoCut handle the rest by clicking on the button!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast.',
          body: 'AutoCut Podcast is a powerful feature that enables you to edit your video podcasts quickly.',
        },
        step2: {
          title: 'Speakers.',
          body: 'You can create a new speaker by clicking on "Add a speaker". For each person speaking in your podcast, you need to add one speaker.',
        },
        step3: {
          title: 'Speakers.',
          body: "Once you've created a speaker, you can set it up to meet your needs.",
        },
        step4: {
          title: "Speaker's name.",
          body: 'In this section, you can assign a name to the speaker that will only appear visually for you to identify them.',
        },
        step5: {
          title: 'Video tracks selection.',
          body: 'The dropdown menu allows you to select all the video tracks in which the speaker appears.',
        },
        step6: {
          title: 'Audio track selection.',
          body: "You can select the speaker's audio track by using the dropdown menu. Each speaker can only have one audio track.",
        },
        step7: {
          title: 'Time to edit.',
          body: "Once you've defined all the speakers and where they appear, you're all set to go!",
        },
        step8: {
          title: 'Time to edit.',
          body: 'Use the arrow to select the editing options that you prefer.',
        },
        step9: {
          title: 'Time to edit.',
          body: 'Click the button, kick back, relax, and let AutoCut take care of the rest while you enjoy a cup of coffee.',
        },
      },
      title: 'Welcome on AutoCut',
      subtitle: 'Your ultimate DaVinci Resolve extension',
    },
    start: {
      transcription: 'Start transcription',
    },
    no_more: {
      credits: 'No more credits available',
    },
    file: {
      too: {
        long: 'Audio file is too long',
      },
      sent: 'File uploaded, waiting for transcription ...',
    },
    please: {
      wait1: 'Please wait while your audio clip is being transcribed.',
      wait2:
        'This process can take up to several minutes, depending on the length.',
      wait3: 'Uploading file : ',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Audio language',
            helper: 'Select audio clip language',
          },
        },
        french: {
          __text__: 'French',
          beta: 'French (beta)',
        },
        danish: 'Danish',
        dutch: 'Dutch',
        english: {
          __text__: 'English',
          united: {
            states: 'English - United States',
          },
        },
        flemish: 'Flemish',
        german: {
          __text__: 'German',
          beta: 'German (beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (beta)',
        },
        italian: 'Italian',
        japanese: 'Japanese',
        korean: 'Korean',
        norwegian: {
          __text__: 'Norwegian',
          nynorsk: 'Norwegian Nynorsk',
        },
        polish: 'Polish',
        portuguese: {
          __text__: 'Portuguese',
          brazil: 'Portuguese - Brazil',
          'brazil—beta': 'Portuguese - Brazil (beta)',
        },
        'portuguese—beta': 'Portuguese (beta)',
        spanish: {
          __text__: 'Spanish',
          latin: {
            america: 'Spanish - Latin America',
            'america—beta': 'Spanish - Latin America (beta)',
          },
        },
        'spanish—beta': 'Spanish (beta)',
        swedish: 'Swedish',
        tamil: 'Tamil',
        chinese: 'Chinese',
        russian: 'Russian',
        turkish: 'Turkish',
        catalan: 'Catalan',
        arabic: {
          __text__: 'Arabic',
          algeria: 'Arabic - Algeria',
          bahrain: 'Arabic - Bahrain',
          egypt: 'Arabic - Egypt',
          iraq: 'Arabic - Iraq',
          israel: 'Arabic - Israel',
          jordan: 'Arabic - Jordan',
          kuwait: 'Arabic - Kuwait',
          lebanon: 'Arabic - Lebanon',
          mauritania: 'Arabic - Mauritania',
          morocco: 'Arabic - Morocco',
          oman: 'Arabic - Oman',
          qatar: 'Arabic - Qatar',
          saudi: {
            arabia: 'Arabic - Saudi Arabia',
          },
          palestine: 'Arabic - Palestine',
          syria: 'Arabic - Syria',
          tunisia: 'Arabic - Tunisia',
          united: {
            arab: {
              emirates: 'Arabic - United Arab Emirates',
            },
          },
          yemen: 'Arabic - Yemen',
        },
        indonesian: 'Indonesian',
        finnish: 'Finnish',
        vietnamese: 'Vietnamese',
        hebrew: 'Hebrew',
        ukrainian: 'Ukrainian',
        greek: 'Greek',
        malay: 'Malay',
        czech: 'Czech',
        romanian: 'Romanian',
        hungarian: 'Hungarian',
        thai: 'Thai',
        urdu: 'Urdu',
        croatian: 'Croatian',
        bulgarian: 'Bulgarian',
        lithuanian: 'Lithuanian',
        latin: 'Latin',
        maori: 'Maori',
        malayalam: 'Malayalam',
        welsh: 'Welsh',
        slovak: 'Slovak',
        telugu: 'Telugu',
        persian: 'Persian',
        latvian: 'Latvian',
        bengali: 'Bengali',
        serbian: 'Serbian',
        azerbaijani: 'Azerbaijani',
        slovenian: 'Slovenian',
        kannada: 'Kannada',
        estonian: 'Estonian',
        macedonian: 'Macedonian',
        breton: 'Breton',
        basque: 'Basque',
        icelandic: 'Icelandic',
        armenian: 'Armenian',
        nepali: 'Nepali',
        mongolian: 'Mongolian',
        bosnian: 'Bosnian',
        kazakh: 'Kazakh',
        albanian: 'Albanian',
        swahili: 'Swahili',
        galician: 'Galician',
        marathi: 'Marathi',
        punjabi: 'Punjabi',
        sinhala: 'Sinhala',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Yoruba',
        somali: 'Somali',
        afrikaans: 'Afrikaans',
        occitan: 'Occitan',
        georgian: 'Georgian',
        belarusian: 'Belarusian',
        tajik: 'Tajik',
        sindhi: 'Sindhi',
        gujarati: 'Gujarati',
        amharic: 'Amharic',
        yiddish: 'Yiddish',
        lao: 'Lao',
        uzbek: 'Uzbek',
        faroese: 'Faroese',
        haitian: {
          creole: 'Haitian Creole',
        },
        pashto: 'Pashto',
        turkmen: 'Turkmen',
        maltese: 'Maltese',
        sanskrit: 'Sanskrit',
        luxembourgish: 'Luxembourgish',
        burmese: 'Burmese',
        tibetan: 'Tibetan',
        tagalog: 'Tagalog',
        malagasy: 'Malagasy',
        assamese: 'Assamese',
        tatar: 'Tatar',
        hawaiian: 'Hawaiian',
        lingala: 'Lingala',
        hausa: 'Hausa',
        bashkir: 'Bashkir',
        javanese: 'Javanese',
        sundanese: 'Sundanese',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            'It looks like you have a lot of clips in your sequence ! It may take a while to load. If you do not need to cut all these clips, please select only the ones you need to cut and cut them in a separate empty sequence.',
        },
      },
    },
    homepage: {
      title: 'Homepage',
      comming: {
        soon: 'Coming soon !',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dynamism',
        normal: 'Normal',
        high: 'High',
        extreme: 'Extreme',
        custom: 'Custom :',
      },
      button: {
        __text__: 'Zoom',
        message: {
          step1: 'Autozoom preparation ...',
          step2: 'Extracting audio data ...',
          step3: 'Computing zoom intervals ...',
          step4: 'Applying zoom {current} out of {max} ...',
        },
      },
      success: 'Success ! Your selection has been zoomed',
      info: {
        text: 'AutoZoom will automatically add zoom to each clip selected based on decibels.',
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Select at least one audio clip and one video clip in your timeline',
            text1:
              'To use AutoZoom, select all the clips that make up your video in your timeline.',
          },
        },
      },
      anchor: {
        title: 'Define the anchor point',
        helper:
          'The anchor point is where the zoom will be applied. You can visualize the effect of maximum and minimum zoom using the colored boxes.',
      },
      dual: {
        range: {
          title: 'Minimum and maximum zoom coefficient applied',
          helper:
            'The zoom coefficient is calculated based on the values provided below and the maximum volume of the sub-clip.',
        },
      },
      preview: {
        on_indicator: 'Preview on indicator',
        error: {
          message: 'An error occurred while loading the preview',
          button: 'Retry',
        },
      },
      min: {
        zoom: {
          title: 'Minimum zoom duration',
          helper: 'Each zoom will be at least this long.',
        },
      },
      max: {
        zoom: {
          title: 'Maximum zoom duration',
          helper: 'Each zoom will be at most this long.',
        },
      },
      constraint: {
        zoom: {
          title: 'Constraint zooms to clips',
          helper:
            'If yes, no zoom will span over more than one clip. If no, your whole selection will be processed as one single audio file and zooms will ignore individuals clips in the timeline.',
        },
      },
      zoom: {
        types: {
          __text__: 'Zoom types',
          helper:
            'Determines the type of zoom to be used. Smooth: Progressive zoom over time. Dynamic: Instant, sharp zoom.',
        },
        coverage: {
          __text__: 'Total zoom coverage',
          helper: 'Percentage of video to be zoomed',
        },
      },
      smooth: {
        __text__: 'Smooth',
        zoom: {
          __text__: 'Smooth zooms',
          coverage: 'Smooth zooms coverage',
          nervousness: {
            __text__: 'Nervousness',
            helper:
              'A low nervousness means slow, weak zooms. A high nervousness means fast, intense zooms.',
          },
        },
      },
      dynamic: {
        __text__: 'Dynamic',
        zoom: {
          __text__: 'Dynamic zooms',
          coverage: 'Dynamic zooms coverage',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Default presets',
        calm: 'Calm',
        paced: 'Paced',
        energetic: 'Energetic',
        hyperactive: 'Hyperactive',
      },
    },
    repeat: {
      info: {
        banner:
          'You can now correct which sentences will be cut or not.{br}The grayed-out sentences are considered repetitions.{br}The scissors allow you to define the status of a sentence (repetition or not). You can also highlight a portion of a sentence and precisely target the words that will be cut',
      },
      text: {
        step: {
          edit: {
            transcript: 'Fix transcription',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Select a clip in your timeline',
            text1:
              'To use AutoCut Repeat, select an audio clip and optionally a video clip in your timeline.',
          },
        },
      },
      select: {
        algorithm: 'Select algorithm',
      },
      precise: {
        algorithm: {
          info: 'The precise algorithm is our most recent and efficient one but require approximately 1 minute of processing per 6 minutes of video.',
        },
      },
      fast: {
        algorithm: 'Fast',
      },
      toggle: {
        cut: {
          button: {
            text: 'Cut/Uncut',
          },
        },
      },
      play: {
        button: {
          text: 'Play',
        },
      },
    },
    captions: {
      step: {
        customization: 'Captions parameters',
        script: 'Select clips and language',
        transcription: 'Edit transcription',
      },
      script: {
        transcript: {
          part: 'Transcription',
        },
        sequence: {
          part: 'Sequence',
          name: 'Name',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} clips | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Add captions',
        coming: {
          soon: 'Transcript editor is coming !{br}For now, you can edit your transcript once imported in the Essential Graphics panel.{br}Just select a caption in the timeline and edit it in the modify tab of the pannel.',
        },
        transcription: {
          part: 'Transcription',
        },
        info: 'Welcome to the caption editor - you will of course also be able to edit your captions in the DaVinci Resolve timeline after adding them.',
        add: {
          emojis: {
            cta: 'Generate emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Select clip(s) to subtitle in your timeline',
          no_selection:
            "If AutoCut doesn't respond, try restarting DaVinci Resolve.",
        },
      },
      process: {
        transcript: {
          button: 'Process',
        },
      },
      customization: {
        info: 'AutoCaption is currently in Beta, we plan the first stable version to be available on November 10th',
        back: {
          button: 'Delete transcript and go back',
        },
        example: {
          __text__:
            'Preview <opacity50><i>You can drag the caption or the emoji to change their position </i></opacity50>',
          text: 'Add subtitles with AutoCaptions. This is a preview of AutoCaptions and allows you to easily customize AutoCaptions. You can then start the transcription and edit it if necessary, and AutoCaptions will automatically add your captions to your timeline.',
        },
        animation: {
          __text__: 'Animation',
          helper: 'Manage text animations',
          color: {
            __text__: 'Highlight with <color>audio</color>',
            text: {
              color: 'Text color',
            },
            before: {
              highlight: {
                color: 'Color before highlight',
              },
            },
            after: {
              highlight: {
                color: 'Color after highlight',
              },
            },
          },
          advanced: {
            color: {
              options: 'Advanced color options',
            },
          },
          word: {
            box: 'Text with <color>box</color>',
          },
          bold: {
            with: {
              text: 'Bold with <b>text</b>',
            },
          },
          floating: {
            text: 'Floating text',
          },
        },
        transition: {
          __text__: 'Transition',
          helper:
            'Manage subtitles transitions. The timing may not be the same as in the example.',
          effect: {
            zoomIn: 'Zoom-in',
            blurIn: 'Blur-in',
          },
        },
        outline: {
          __text__: 'Outline',
          helper: 'Manage text outline style',
          color: 'Color',
          width: 'Width',
        },
        font: {
          __text__: 'Font',
          helper: 'Manage text style',
          font: 'Font name',
          help: 'How to add a new font family?',
          size: 'Font size',
          weight: 'Font weight',
          color: 'Color',
        },
        formating: {
          __text__: 'Text formating',
          helper: 'Manage text formating',
          ponctuation: 'Remove ponctuation',
          uppercase: 'Uppercase',
          bold: 'Bold',
          italic: 'Italic',
          glow: {
            __text__: 'Glow',
            color: 'Color',
            intensity: 'Intensity',
          },
        },
        transform: {
          __text__: 'Transform',
          x: 'x',
          y: 'y',
          helper: 'Manage captions position and rotation',
          position: {
            __text__: 'Vertical position',
            emoji: 'Emoji vertical position',
            info: 'You can also move the text on the preview',
          },
          rotation: 'Rotation',
        },
        multiple: {
          lines: 'Multiples lines',
        },
        box: {
          __text__: 'Boxes',
          helper: 'Manage box style',
        },
        boxes: {
          padding: {
            x: 'Horizontal padding',
            y: 'Vertical padding',
          },
          opacity: 'Box opacity',
          radius: 'Radius',
          color: 'Box color',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'Generate emojis with AI',
          size: 'Emojis size',
        },
        shadow: 'Shadow',
        transcript: 'Transcript',
        action: 'Next step',
        select: {
          chunk: 'Select the part of the transcript you want to edit/customize',
        },
        delete: {
          caption: {
            modal: {
              title: 'Are you sure you want to remove this caption?',
              cancel: 'No - keep it',
              confirm: 'Yes - remove it',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Error while getting the presets number, please contact us on discord or at contac@autocut.fr',
            },
            error:
              'Error while getting the presets, please contact us on discord or at contac@autocut.fr',
          },
          public: {
            presets: {
              error:
                'Error while getting the public presets, please contact us on discord or at contact@autocut.fr',
            },
          },
        },
        presets: {
          title: 'Presets',
          save: {
            new: {
              preset: 'Save current parameters',
            },
          },
          view: {
            more: 'View more',
            less: 'View less',
          },
        },
        save: {
          preset: {
            info: {
              text: 'You have changed your parameters, you can save them as a preset below and reuse them infinitely!',
            },
          },
        },
      },
      editor: {
        title: 'Captions editor',
        description: {
          title: 'Select captions you want to edit',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Low confidence words',
            },
          },
          merge: {
            tooltip: 'Merge chunks',
          },
          split: {
            tooltip: 'Split chunks',
          },
        },
        sound: {
          play: 'Enable sound play',
        },
      },
      presetCustomization: {
        createPresetModal: {
          title: {
            length: {
              error: 'Your preset name must contain at least 2 words.',
            },
          },
          error:
            'Error while creating the preset, please contact us on discord or at contact@autocut.fr',
          placeholder: 'Preset name (at least 2 words)',
          create: 'Create preset',
          isPublic: 'Share to community - public',
        },
        searchPresetModal: {
          title: 'Search community preset',
        },
        error: {
          preset: {
            already: {
              exists: 'This preset already exists. Please choose another name.',
            },
          },
        },
        copyPreset: {
          error:
            'Error while copying the preset, please contact us on discord or at contact@autocut.fr',
        },
        no_preset: {
          selected: 'Select a preset to see the preview',
        },
        no_preview: {
          available: 'No preview available',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Font not found',
          message:
            'The font <i><b>{fontLabel}</b></i> was not found on your system, the default font will be used.',
          helper: 'You can add a new font family by following this tutorial.',
        },
      },
    },
    version: {
      not: {
        supported: 'Unsupported DaVinci Resolve version',
      },
    },
    transcript: {
      usage: {
        unit: 'minutes',
      },
      upgrade: {
        button: 'Upgrade to AI to continue with {mode}',
      },
      too: {
        short: {
          error:
            'Unfortunately, your transcript may be too short or the language you are using is badly supported. If the problem persists, please contact us at contact@autocut.fr.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Feature request',
        form: {
          placeholder:
            'Enter your feature request. Feel free to explain in detail your request so that we can study it more easily.',
        },
        screenshot:
          'You can attach screenshots/videos to help us better understand your feature request:',
        button: 'Send feature request',
        success: {
          title: 'Feature request sent! 🥳',
          body: 'Thank you for helping AutoCut grow! We will review your request with the most attention.',
        },
        error: {
          title: 'Oops, something went wrong... 🤕',
          body: 'We are deeply sorry for the inconvenience. Please trya again later or contact us at contact@autocut.fr.',
        },
      },
    },
    with: {
      emojis: 'With emojis',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Add In & Out points in your timeline',
            text1: 'To use AutoResize, your need to add in & out points.',
            cta: 'Set In & Out points on full sequence',
          },
        },
      },
      text: {
        info: 'AutoResize resize and reframe automatically your sequence for all Social media best dimensions.',
      },
      in_out: {
        step: {
          title: 'In / Out Points',
          helper:
            'Please check your in & out points in your timeline. They should be the same as the values below.',
        },
      },
      preset: {
        step: {
          title: 'Presets',
          helper:
            'Select a preset to resize your sequence. You can also create your own preset. The dimensions of your new sequence are displayed below.',
        },
        undefined: 'Please select a preset.',
      },
      watermark: {
        step: {
          title: 'Watermark',
          helper:
            'You can add a watermark to your sequence. It will be added to the bottom right corner of your sequence.',
          text: 'Upload your watermark',
        },
      },
      button: 'Generate social clip',
      reframe: {
        undefined: 'Please select "Follow subject" speed.',
        step: {
          title: 'Follow subject',
          helper:
            'You can choose the speed at which the camera will follow the subject.',
          slower: 'Slow',
          default: 'Normal',
          faster: 'Fast',
        },
      },
      backup: 'Backing up your sequence...',
      create: {
        new: {
          sequence: 'Creating a new sequence...',
        },
      },
      resizing: {
        sequence: 'Resizing the new sequence...',
      },
      adding: {
        watermark: 'Adding watermark...',
      },
      cleaning: {
        sequence: 'Cleaning the sequence...',
      },
    },
    endprocess: {
      modal: {
        title: 'Process completed!',
        text: {
          '1': 'Process finished successfully!',
          '2': 'Your feedback is invaluable – let us know how we did and how we can make your experience even better!',
        },
        next: {
          mode: {
            silence: {
              text: 'Ready for a seamless audio experience? Use AutoCut Silences to effortlessly eliminate awkward silences from your content.',
              button: 'Go to AutoCut Silences',
            },
            podcast: {
              text: 'Elevate your podcast production with AutoCut Podcast. Enjoy streamlined editing and automatic camera transitions.',
              button: 'Go to AutoCut Podcast',
            },
            repeat: {
              text: 'Keep your audience hooked! Use AutoCut Repeat to edit your video via a text editor, keeping only the most engaging parts of your content.',
              button: 'Go to AutoCut Repeat',
            },
            zoom: {
              text: 'Bring your videos to life with AutoZoom! Add flowing zoom effects that draw viewers in and hold their undivided attention.',
              button: 'Go to AutoZoom',
            },
            caption: {
              text: 'Connect with your audience better using AutoCaptions. Integrate stylish, synchronized subtitles that enhance the storytelling of your videos.',
              button: 'Go to AutoCaptions',
            },
            resize: {
              text: 'Master cross-platform sharing with AutoResize. Format your videos to perfection across various channels without losing focus on your subject.',
              button: 'Go to AutoResize',
            },
            broll: {
              text: 'Amplify your visual narrative with AutoB-Roll, where AI seamlessly weaves in relevant stock footage to complement your story.',
              button: 'Go to AutoB-Roll',
            },
            swear: {
              word: {
                text: 'Maintain a polished dialogue with AutoProfanity Filter. Automatically mask unwanted language to uphold the professionalism of your content.',
                button: 'Go to AutoProfanity Filter',
              },
            },
          },
        },
      },
      captions: {
        modal: {
          title: 'Done',
          text: {
            '1': 'Your animated captions have been added! 🎉 {br} If you need to modify captions:',
            '2': 'There is a <a>detailed tutorial if needed.</a>',
          },
          bullet: {
            point: {
              '1': 'Double click on the subsequence: "Double click to edit"',
              '2': 'Select the caption you want to edit',
              '3': 'Open the essential graphics and click on "edit" tab',
            },
          },
        },
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title:
              'Select clips on your timeline on which you want to base B-Roll',
            text1:
              'To use Auto B-Roll, you need to select at least one clip with audio in your timeline.',
          },
        },
      },
      text: {
        info: 'Auto B-Roll, thanks to AI, analyzes your audio and automatically adds {br} @Pexels B-Rolls related to what your video is about.',
      },
      duration: {
        step: {
          title: 'B-Roll durations',
          minimum: {
            title: 'Minimum B-Roll duration',
            helper: 'Each B-Roll will be at least this long.',
          },
          maximum: {
            title: 'Maximum B-Roll duration',
            helper: 'Each B-Roll will be at most this long.',
          },
        },
      },
      choice: {
        step: {
          title: 'B-Roll selection',
        },
      },
      no_broll: {
        screen: {
          title: 'Transcript processing is over, but nothing was found.',
          text1:
            'In order to use Auto B-Roll, you need to select at least one audio clip with speech in your timeline.',
          text2:
            'If you did that already, verify the language of your transcript and check that you audio tracks are not muted.',
        },
      },
      button: {
        __text__: 'Add B-Rolls',
        next: 'Next',
      },
      exporting: 'Exporting selected audio clips...',
      analyse: 'Analysing audio...',
      getting: {
        broll: 'Getting B-Rolls... (it may take a few minutes)',
      },
      transcript: {
        finished: 'Transcript generated',
      },
      step: {
        parameters: 'B-Roll Parameters',
        language: 'Select clips and language',
        brolls: 'Edit B-Roll selection',
      },
      adding: {
        broll: 'Adding B-Rolls...',
      },
      restore: 'Restoring your sequence...',
      backup: 'Backing up your sequence...',
      credit: {
        modal: {
          title: 'Here are the credits for the B-Rolls used in your video : ',
          provided: {
            by: 'provided by Pexels',
          },
          link: 'link',
          button: 'Copy to clipboard',
        },
      },
      search: {
        modal: {
          title: 'Choose B-Roll',
          input: {
            placeholder: 'Type to search for B-Rolls',
          },
          searching: 'Searching for B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Double click to edit',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title:
                'Select clips on your timeline on which you want to bleep profanities',
              text1:
                'To use AutoProfanity Filter, you need to select at least one clip with audio in your timeline.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter, thanks to AI, analyzes your audio and automatically adds {br} bleeping sounds on top of profanities.',
        },
        mute: 'Mute',
        button: 'Remove profanities',
        audio: {
          export: 'Exporting audio...',
          analyse: 'Analysing audio...',
          insert: 'Inserting bleeps...',
          filter: 'Applying volume filters...',
        },
        script: {
          transcript: {
            part: 'Transcription',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: {
            helper: 'Select bleep sound effect',
          },
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Create a new format',
          button: {
            __text__: 'Create',
            disabled: 'Name already taken',
          },
          name: 'Name',
          width: 'Width',
          height: 'Height',
        },
      },
    },
    process: {
      modal: {
        warning:
          'Please do not do anything on DaVinci Resolve, it will cause some issues',
        generating: {
          emojis: 'Generating emojis from transcription...',
        },
        formatting: {
          numbers: 'Formatting numbers from transcription...',
        },
      },
    },
    backup: {
      modal: {
        title: 'AutoCut Backup',
        text: {
          '1': 'Before any AutoCut process, a backup is made. You can find these backups in the "AutoCut-Backup" folder.',
          '2': 'You can use them as you wish.',
        },
      },
    },
    confirm: {
      modal: {
        title: 'Are you sure?',
        confirm: {
          label: 'Confirm',
        },
        cancel: {
          label: 'Cancel',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: "Enter speaker's name…",
        },
      },
    },
    login: {
      resend: {
        email: "You didn't receive it? Resend email to {trialEmail}",
      },
    },
    typeofcreation: {
      option: {
        interview: 'Interviews',
        podcast: 'Podcasts',
        youtube: 'Youtube videos',
        short: 'TikTok/Shorts/Reels',
        internal: 'Internal videos',
        other: 'Other',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Content creator',
        },
        video: {
          editor: {
            professional: 'Professional Video editor',
            semi: {
              professional: 'Semi-Professional Video editor',
            },
            beginner: 'Beginner Video editor',
          },
        },
        student: 'Student',
        other: 'Other',
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Creating captions...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal:
          'Fantastic work! You completed the process in just {minutes} minutes and {seconds} seconds.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Time saved: <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b> and <b>{secondsSaved} {secondsSaved, plural, one {second} other {seconds}}</b>.',
        },
        all: {
          cuts: 'Total time saved with AutoCut: A whopping <b>{hourSaved} {hourSaved, plural, one {hour} other {hours}}</b> and <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          "Exclude certain audio tracks from silence detection by deactivating them. This is ideal for retaining background music or game sounds. To keep silences in a specific clip unaltered, simply don't select that clip.",
        video: 'Video tracks selected:',
        audio: 'Audio tracks selected:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: 'Free trial limitation reached',
            cta: 'Subscribe',
            text: 'You have used more than 1000 minutes of AI feature. {br} Subscribe to the AI plan to continue using AutoCut AI features.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Limit of transcription reached',
          text: 'You used more than 6000 minutes of AI features. {br} Please contact us to unlock the situation.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Please choose a valid transition type (See <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a> field)',
        ai_silences:
          'Please choose a valid silence treatment (See <a><b><embedTranslation>title_silences_step</embedTranslation></b></a> field)',
        broll: {
          min: {
            max: {
              duration:
                'Maximum B-Roll time cannot be smaller than minimum B-Roll time. (See <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a> field)',
            },
          },
          minimumBRollTime:
            'Minimum B-Roll time must be a positive number (See <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a> field)',
          maximumBRollTime:
            'Maximum B-Roll time must be a positive number (See <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a> field)',
        },
        caption: {
          position:
            'Captions must be inside the preview (See <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a> field)',
          emoji: {
            position:
              'Emojis must be inside the preview (See <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a> field)',
          },
          text: {
            fontSize:
              'Font size must be a positive number (See <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a> field)',
          },
          outline: {
            width:
              'Outline width must be a positive number (See <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a> field)',
          },
          transition: {
            effects:
              'Please select a valid caption transition effect (See <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a> field)',
          },
          emojis: {
            size: 'Emojis size must be a positive number (See <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a> field)',
          },
          formating: {
            nbLines:
              'Please select at least one line for caption formatting. (See <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a> field)',
          },
          title: {
            too: {
              short: 'Title must contain at least 5 characters',
              long: 'Title must contain at most 25 characters',
            },
            no_space:
              'Title must contain at least two words, separated by a space',
          },
        },
        font: {
          fontFamily:
            'Font family is required (See <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a> field)',
          label:
            'Please select a font (See <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a> field)',
        },
        languageOfTranscription:
          'Please select a language of transcription (See <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a> field)',
        cameras: {
          speakerIds:
            'At least one speaker is required for each camera (See <a><b><embedTranslation>header_cameras_speakers</embedTranslation></b></a> field)',
          label:
            'One of the cameras is missing its track (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a> field)',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'Minimum camera time must be a positive number (See <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a> field)',
            },
          },
          maximum: {
            cam: {
              time: 'Maximum camera time must be a positive number (See <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a> field)',
            },
          },
          deleteUnusedClips:
            'Please choose either removing or muting silences (See <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a> field)',
          speakers: {
            cameras:
              'At least one camera is required for each speaker (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a> field)',
            audio:
              'One audio track is required for each speaker (See <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a> field)',
          },
          cameras: {
            __text__:
              'Cameras cannot have the same track (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a> field)',
            length:
              'Must have set at least 2 differents cameras (See <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a> field)',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Auto Reframe Preset is required (See <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a> field)',
            invalid:
              'Auto Reframe Preset must be slower, default, or faster (See <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a> field)',
          },
          watermark:
            'The watermark must be a valid image file format: JPEG, PNG, or WebP. (See <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a> field)',
          preset: {
            name: 'Please enter a name for the preset',
            width: 'Width must be over 1px',
            height: 'Height must be over 1px',
          },
        },
        rgb: 'RGB values must be between 0 and 255',
        silence: {
          noiseLevel: {
            type: 'Noise Level must be a number (See <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a> field)',
            nonpositive:
              'Noise Level must not be a positive number (See <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a> field)',
            integer:
              'Noise Level must be an integer (See <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a> field)',
          },
          minTimeOfSilence: {
            type: 'Minimum time of silence must be a number (See <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a> field)',
          },
          maxTimeOfSilence: {
            type: 'Maximum time of silence must be a number (See <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a> field)',
          },
          marginBefore: {
            type: 'Before padding must be a number (See <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a> field)',
          },
          marginAfter: {
            type: 'After padding must be a number (See <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a> field)',
          },
          transitions:
            'Please choose a valid transition type (See <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a> field)',
          silences:
            'Please choose a valid silence treatment (See <a><b><embedTranslation>title_silences_step</embedTranslation></b></a> field)',
        },
        swearWord: {
          bleepFile:
            'Please select a bleep sound effect (See <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a> field)',
        },
        textBox: {
          xPadding:
            'Horizontal padding must be a positive number (See <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a> field)',
          yPadding:
            'Vertical padding must be a positive number (See <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a> field)',
          opacity: {
            min: 'Opacity must be over 0% (See <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a> field)',
            max: 'Opacity must be under 100% (See <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a> field)',
          },
        },
        anchor:
          'Anchor point must be inside the preview (See <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a> field)',
        zoom: {
          autoZoomMinCoef: {
            min: 'Minimum zoom coefficient must over 1 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a> field)',
            max: 'Minimum zoom coefficient must under 2 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a> field)',
          },
          autoZoomMaxCoef: {
            min: 'Maximum zoom coefficient must over 1 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a> field)',
            max: 'Maximum zoom coefficient must under 2 (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a> field)',
          },
          minZoomTime: 'Minimum zoom duration must be over 0 seconds',
          maxZoomTime: 'Maximum zoom duration must be over 0 seconds',
          dynamicZoomPercentage: {
            min: 'Zoom percentage must be over 0%',
            max: 'Zoom percentage must be under 100%',
          },
          coef: 'Maximum zoom coefficient cannot be smaller than minimum zoom coefficient. (See <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a> field)',
          time: 'Maximum zoom duration cannot be smaller than minimum zoom duration.',
          dynamicMinZoomIntensity:
            'Dynamic zoom intensity must be between 0% and 100%. (See <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a> field)',
          smoothZoomPercentage:
            'Smooth zoom percentage must be between 0% and 100%. (See <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a> field)',
          nervousness:
            'Nervousness must be between 0% and 100%. (See <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a> field)',
          totalZoomPercentage:
            'Total zoom coverage must be greater than 0% and up to 100%. (See <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a> field)',
          types: {
            at_least: {
              one: {
                true: 'At least one zoom type must be selected. (See <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a> field)',
              },
            },
          },
        },
        repeat: {
          algorithm: 'Selected algorithm is not valid',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Lack of payment',
          button: {
            recover: 'Recover subscription',
          },
          to_continue:
            'Your current subscription has been <b>{status}</b> due to a payment issue. To continue using AutoCut, please:',
          deactivated: 'deactivated',
          deleted: 'deleted',
          recover: {
            list: {
              click:
                '<b>Click on the button bellow</b> to recover your subscription or check your email invoice for an email from us or stripe asking to pay your latest invoice',
              re_activated:
                'Your key will be <b>re-activated immediately</b> after the payment update',
            },
          },
          new: {
            list: {
              website:
                'Visit the AutoCut website: <span>https://www.autocut.fr/#pricing</span>',
              subscribe: 'Subscribe to a new license key',
            },
          },
          support:
            'Reach out to our support team if you need help: <span>contact@autocut.fr</span>',
          thanks: 'Thank you!',
        },
        moda: {
          button: {
            new: 'Get a new subscription',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Start your AutoCut Adventure',
          header: {
            days: {
              left: "Your creative journey begins with a 14-day free trial of AutoCut, you unlocked {completedElements} more and you've got <b>{daysLeft} days</b> left to explore!",
            },
            more: {
              days: 'But why stop there? Embark on a quest to unlock <b>an additional {bonusDays} days</b> and fully unleash the power of AutoCut.',
            },
          },
          timeline: {
            left: '14 days free trial',
            right: '24 days free trial',
          },
          task: {
            list: {
              title: 'Task Mastery: {completed}/{maximum} Completed',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'AutoCut needs to be reloaded',
          cta: 'Reload AutoCut',
          text: "It's been over 30 minutes since AutoCut was last opened. {br} For optimal performances, we recommend to reload AutoCut now.{br}Simply click the button bellow to reload AutoCut",
        },
      },
    },
    'key-already-activated':
      'This key has already been activated on another computer. Please dissociate your key on your AutoCut dashboard (https://www.autocut.fr/en/) before retrying it.',
    'key-does-not-exist':
      'This key does not exist. Please check your key and try again.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Unsupported DaVinci Resolve version',
            '24.4.0':
              'Please downgrade DaVinci Resolve to 24.3 or upgrade to 24.5',
          },
          cta: 'I understand',
          text: {
            __text__:
              'You are currently using an unsupported version of DaVinci Resolve ({version}). You <b>will</b> not be able to use AutoCut at its fullest with this version.{br}{br}Please use the <b>latest stable release</b> to have the best experience with AutoCut.',
            '24.4.0':
              'You are currently using an unsupported version of DaVinci Resolve ({version}). There has been some breaking changes in this version. Please downgrade to version 24.3 or upgrade to version 24.5 to use AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Unsupported rendering engine',
          modal: {
            text: 'You are currently using an unsupported rendereing engine. You <b>will</b> not be able to use AutoCaptions nor AutoZoom.{br}{br}Please select a GPU accelerated rendering engine in your project settings.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Hard reset needed',
          cta: 'Close all my extensions',
          text: 'We detected multiple AutoCut instances running. Click on the button bellow to close them all and avoid any problem.',
        },
      },
    },
    disabled: {
      features: {
        info: 'You have selected a combination language/font that does not support the following features: <b>{features}</b> These features will be disabled in the imported captions.',
        tips: 'It may be related to the characters supported by the font you have chosen. Change the font or the language to enable these features.',
      },
    },
    upgrade: {
      to: 'Upgrade to {plan}',
    },
    deepgram: {
      error: {
        retry: 'You can also retry the transcription with one of our other AI.',
        modal: {
          title: 'A translation error has occurred',
          cta: 'Join discord',
          text: 'An error occurred while translating the audio. It may be related to the quality of the audio or the language spoken but we have no further information for now. {br} Please report your issue on discord or email us at contact@autocut.fr.',
        },
      },
    },
    completing: {
      preset: {
        preview: 'Completing preset preview... It may take a few seconds',
      },
    },
    chapters: {
      preview: {
        error:
          'An error has occured generating the preview. Try to update the timestamps or to restart AutoCut if the problems persists.',
      },
      step: {
        language: 'Select clips and language',
        editor: 'Chapters editor',
      },
      button: {
        chapters: 'Add chapters',
      },
      editor: {
        chapters: {
          title: 'Detected chapters',
        },
        parameters: {
          title: 'Chapters parameters',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Download YouTube chapters file',
                success: 'chapter.txt has been saved in your PPRO project',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Select clips on your timeline on which you want base Chapters',
            text1:
              'To use Chapters, you need to select at least one clip with audio in your timeline.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Generate chapters',
        },
      },
      adding: {
        chapters: 'Adding chapters...',
      },
      backup: 'Backing up your sequence...',
      analyse: 'Analysing your audio...',
      getting: {
        chapters: 'Generating chapters...',
      },
      transcript: {
        finished: 'Chapters have been generated',
      },
      disabled: {
        language: 'Chapters are not available for this language yet',
        file: {
          too: {
            long: 'Your selected clips are too long. Please select clips that are less than {limit} minutes long.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: 'Wrong time format. Please use HH:MM:SS format.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animated',
        },
        static: {
          option: 'Static',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Select clips on your timeline on which you want to base Auto Viral Clips Detector',
              text1:
                'To use Auto Viral Clips Detector, you need to select at least one clip with audio in your timeline.',
            },
          },
        },
        disabled: {
          language:
            'Auto Viral Clips Detector is not available for this language yet',
          file: {
            too: {
              long: 'Your selected clips are too long. Please select clips that are less than {limit} minutes long.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Generate viral clips',
          },
        },
        button: {
          process: 'Create clips',
        },
        selector: {
          title: 'Detected {number} potential viral clips',
        },
        virality: {
          score: 'Viral potential score',
        },
        step: {
          language: 'Select clips and language',
          selector: 'Select viral clips',
        },
        backup: 'Backing up your sequence...',
        analyse: 'Analysing your audio...',
        getting: {
          clips: 'Generating viral clips...',
        },
        transcript: {
          finished: 'Viral clips have been generated',
        },
        adding: {
          creating: {
            clips: 'Creating clips...',
          },
        },
      },
      score: 'Viral score',
    },
    mode: {
      description: {
        silence: 'Remove silences in your timeline',
        ai: 'Remove silences in your timeline',
        podcast: 'Edits podcast instantly',
        repeat: 'Removes bad takes with AI',
        zoom: 'Automatically add zooms',
        captions: 'Add animated captions with AI',
        resize: 'Resizes your sequence with AI',
        broll: 'Automatically adds B-Roll with AI',
        profanity: 'Bleeps out swear words',
        chapter: 'Detect chapters and add markers with AI',
        viral: 'Identify viral shorts in long videos in one click',
      },
    },
    formatted: {
      numbers: {
        title: 'Numbers have been formatted',
        modal: {
          cta: 'Close modal',
          text: '<b>{number} words</b> have been replaced by numbers in your transcription.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Select a transcription model',
          model: {
            '1': 'Model 1 (default)',
            '2': 'Model 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
