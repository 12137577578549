import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Navigate, useNavigate} from 'react-router-dom';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {addModalToQueue} from '@autocut/utils/modal/modals.utils';
import {ModalType} from '@autocut/enums/modals.enum';
import {getLocalStorage} from '@autocut/utils/localStorage.utils';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {colors} from '@autocut/designSystem/colors';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {CheckTranscriptModal} from './parts/CheckTranscriptModal';
import {CaptionsTranscriptStepContainer} from './parts/CaptionsTranscriptStepContainer';
import {CaptionsStyleStep} from './parts/CaptionsStyleStep/CaptionsStyleStep';
import {CaptionsTranscriptEditor} from './parts/CaptionsTranscriptEditorStep/CaptionsTranscriptEditorStep';
import {handleCaptionsProcess} from '@autocut/utils/process/captions/handleCaptionsProcess';
import {manageError} from '@autocut/utils/manageError';

const CaptionsCustomization = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {captionsChunks} = useAutoCutStore(state => ({
    captionsChunks: state.onGoingProcess.captionChunks,
  }));
  const [isCheckTranscriptModalOpen, setIsCheckTranscriptModalOpen] =
    useState(false);
  const [hasCheckedTranscript, setHasCheckedTranscript] = useState(
    getLocalStorage('dontShowCheckTranscriptModal'),
  );

  if (!captionsChunks) {
    return null;
  }

  const hasChunks = (captionsChunks ?? []).length > 0;

  if (!hasChunks) {
    navigate('../script');
    manageError({
      error: new Error('NO_SOUND_DETECTED'),
    });
  }

  const openTranscriptEditorModal = () => {
    addModalToQueue(ModalType.EditTranscript);
    setHasCheckedTranscript(true);
  };

  const processCaptions = async () => {
    await handleCaptionsProcess(intl);
    await handleTrialTask('captions');
  };

  return captionsChunks.length > 0 ? (
    <div>
      <CheckTranscriptModal
        isOpen={isCheckTranscriptModalOpen}
        onClose={() => setIsCheckTranscriptModalOpen(false)}
        onValidateTranscript={async () => {
          await processCaptions();
        }}
        onOpenModal={() => {
          setHasCheckedTranscript(true);
        }}
      />
      <ModeLayout
        footer={
          <ModeFooter
            renderButton={({buttonRef, isLoading, isDisabled}) => (
              <Button
                ref={buttonRef}
                disabled={isDisabled || isLoading || !hasChunks}
                isLoading={isLoading}
                onClick={async () => {
                  if (hasCheckedTranscript) {
                    await processCaptions();
                  } else {
                    setIsCheckTranscriptModalOpen(true);
                  }
                }}
                color={colors.primary600}
              >
                <TranslatedMessage
                  id={'modes_captions_steps_customization_button'}
                  defaultMessage="Add captions"
                />
              </Button>
            )}
          />
        }
      >
        <FlexContainer
          flexDirection="column"
          gap={8}
        >
          <CaptionsTranscriptStepContainer
            title={
              <TranslatedMessage
                id={'modes_captions_steps_customization_parts_style_title'}
                defaultMessage="Captions style"
              />
            }
          >
            <CaptionsStyleStep />
          </CaptionsTranscriptStepContainer>

          <Divider />

          <CaptionsTranscriptStepContainer
            title={
              <TranslatedMessage
                id={
                  'modes_captions_steps_customization_parts_transcription_title'
                }
                defaultMessage="Transcription"
              />
            }
            onClick={openTranscriptEditorModal}
          >
            <CaptionsTranscriptEditor />
          </CaptionsTranscriptStepContainer>
        </FlexContainer>
      </ModeLayout>
    </div>
  ) : (
    <Navigate
      to="../language"
      replace={true}
    />
  );
};

export default CaptionsCustomization;
