import {PreviewFrame} from '@autocut/components/PreviewFrame/PreviewFrame';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {daVinci} from '@autocut/utils/davinci';
import {autocutStoreVanilla} from '@autocut/utils/zustand';
import React, {useEffect, useState} from 'react';

const CaptionsPreview = ({
  withPreviewButton,
  maxHeight = '200px',
  disableInteractions = false,
}: {
  withPreviewButton?: boolean;
  maxHeight?: `${number}px`;
  disableInteractions?: boolean;
}) => {
  const [initialPosition, setInitialPosition] = useState<
    {x: number; y: number} | undefined
  >(undefined);

  useEffect(() => {
    const init = async () => {
      return await daVinci.timeline.getTimelineFormat();
    };

    void init().then(res => {
      const sequenceWidth = parseInt(res.width);
      const sequenceHeight = parseInt(res.height);

      const autocutState = autocutStoreVanilla();
      const {xPercentage, yPercentage} =
        autocutState.ui.parameters.caption.position;

      const x = xPercentage * sequenceWidth;
      const y = yPercentage * sequenceHeight;

      setInitialPosition({x, y});
    });
  }, []);

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{
        alignSelf: 'center',
        height: '100%',

        pointerEvents: disableInteractions ? 'none' : 'auto',
        userSelect: disableInteractions ? 'none' : 'auto',
      }}
    >
      <PreviewFrame
        interfaces={[
          {
            type: 'captions',
          },
        ]}
        initialPosition={initialPosition}
        anchors={false}
        anchorsDisplay={false}
        // cursorBounds={
        //   (minVideoTime || Infinity) < 10000000 && (maxVideoTime || 0) > 0
        //     ? ([minVideoTime, maxVideoTime] as [number, number])
        //     : undefined
        // }
        maxHeight={maxHeight}
        withPreviewButton={withPreviewButton}
      />
    </FlexContainer>
  );
};

export default CaptionsPreview;
