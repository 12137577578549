import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect, useMemo} from 'react';
import {FeedbackModal} from './FeedbackModal/FeedbackModal';
import {TrialExpiredModal} from './TrialExpiredModal/TrialExpiredModal';
import {StartUpModals} from './StartUpModals';
import {ProcessModal} from './ProcessModal/ProcessModal';
import {OnboardingStatModal} from './OnboardingStatModal/OnboardingStatModal';
import {KeyExpiredModal} from './KeyExpiredModal/KeyExpiredModal';
import ContactUsModal from './ContactUsModal/ContactUsModal';
import FeatureRequestModal from './FeatureRequestModal/FeatureRequestModal';
import {ReportIssueModal} from './ReportIssueModal/ReportIssueModal';
import ReviewModal from './ReviewModal/ReviewModal';
import ExchangeRateModal from './ExchangeRateModal/ExchangeRateModal';
import {EndProcessModal} from './EndProcessModal/EndProcessModal';
import ErrorModal from './ErrorModal/ErrorModal';
import {UsageModal} from './UsageModal/UsageModal';
import {DeepgramErrorModal} from './DeepgramErrorModal/DeepgramErrorModal';
import {CaptionsCustomizationModal} from '@autocut/pages/modes/captions/customization/parts/CustomizationModal/CaptionsCustomizationModal';
import {CaptionsPreviewModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPreviewModal/CaptionsPreviewModal';
import {SearchPublicPresetModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPresets/SearchPublicPresetModal/SearchPublicPresetModal';
import {DeletePresetsModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPresets/DeletePresetsModal/DeletePresetsModal';
import {CreatePresetModal} from '@autocut/pages/modes/captions/customization/parts/CreatePresetModal/CreatePresetModal';
import {EditTranscriptModal} from '@autocut/pages/modes/captions/customization/parts/EditTranscriptModal/EditTranscriptModal';
import {EndCaptionsProcessModal} from './EndProcessModal/captions/EndCaptionsProcessModal';
import UsageStatsModal from '@autocut/modals/UsageStatsModal/UsageStatsModal';

const ModalManager = () => {
  const {openedModalName, modalQueue} = useAutoCutStore(state => ({
    openedModalName: state.ui.openedModalName,
    modalQueue: state.ui.modalQueue,
  }));

  useEffect(() => {
    if (!openedModalName && modalQueue.length > 0) {
      const newModal = modalQueue.splice(0, 1);
      setAutocutStore('ui.openedModalName', newModal[0]);
      setAutocutStore('ui.modalQueue', modalQueue);
    }
  }, [openedModalName, modalQueue]);

  const ModalComponent = useMemo(() => {
    switch (openedModalName) {
      // Global modals
      case ModalType.Feedback:
        return FeedbackModal;
      case ModalType.TrialExpired:
        return TrialExpiredModal;
      case ModalType.StartUp:
        return StartUpModals;
      case ModalType.Processing:
        return ProcessModal;
      case ModalType.Onboarding:
        return OnboardingStatModal;
      case ModalType.KeyExpired:
        return KeyExpiredModal;
      case ModalType.UsageStats:
        return UsageStatsModal;
      // TODO: uncomment when GamingTrialModal is ready
      // case ModalType.GamingTrial:
      //   return GamingTrialModal;

      // Header modals
      case ModalType.ContactUs:
        return ContactUsModal;
      case ModalType.FeatureRequest:
        return FeatureRequestModal;
      case ModalType.ReportIssue:
        return ReportIssueModal;

      // Process modals
      case ModalType.Review:
        return ReviewModal;
      case ModalType.ExchangeRate:
        return ExchangeRateModal;
      case ModalType.EndProcess:
        return EndProcessModal;

      // TODO : autre modes, resize captions broll
      // Modes modals
      // case ModalType.CreateResizeFormat:
      //   return CreateResizeFormatModal;
      case ModalType.EndCaptionsProcess:
        return EndCaptionsProcessModal;
      // case ModalType.BRollCredits:
      //   return BRollCreditsModal;
      // case ModalType.FormattedNumber:
      //   return FormattedNumberModal;

      // Captions modals
      case ModalType.CaptionsCustomization:
        return CaptionsCustomizationModal;
      case ModalType.CaptionsPreview:
        return CaptionsPreviewModal;
      case ModalType.CreatePresetModal:
        return CreatePresetModal;
      case ModalType.SearchPreset:
        return SearchPublicPresetModal;
      case ModalType.DeletePresets:
        return DeletePresetsModal;
      case ModalType.EditTranscript:
        return EditTranscriptModal;
      case ModalType.GenerateEmojis:
        return null;

      // Errors modals
      case ModalType.Error:
        return ErrorModal;
      case ModalType.UsageLimit:
        return UsageModal;
      case ModalType.DeepgramError:
        return DeepgramErrorModal;

      default:
        return null;
    }
  }, [openedModalName]);

  return ModalComponent ? <ModalComponent /> : null;
};

export default ModalManager;
