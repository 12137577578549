import {PiWarningCircleBold} from 'react-icons/pi';
import {useOldIntl} from '@autocut/hooks/useOldIntl';

import './ErrorModal.css';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {
  OldTranslatedMessage,
  OnlyOldTranslations,
} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {
  IdentifiedErrorKeys,
  IdentifiedErrorType,
} from '@autocut/enums/errors.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {getErrorInfosById} from '@autocut/utils/errors/errors.util';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

const ErrorModal = () => {
  const intl = useOldIntl();

  const {errorId} = useAutoCutStore(state => ({
    errorId: state.ui.currentErrorId,
  }));
  const {intlId, link} = getErrorInfosById(
    errorId as IdentifiedErrorKeys,
  ) as IdentifiedErrorType;

  // If a fixable error pop up again, change this
  const isFixable = false;

  const linkTextID = isFixable
    ? 'link_not_supported_modal'
    : 'link_not_supported_modal_no_solution';
  const linkTextDefaultMessage = `To better understand this error${
    isFixable ? 'and how to fix it' : ''
  }, go to `;

  return (
    <Modal
      title={intl.formatMessage({
        id: `error_modal_title_${intlId}` as OnlyOldTranslations,
        defaultMessage: 'An error has occured.',
      })}
      icon={
        <PiWarningCircleBold
          fill="red"
          size="24"
        />
      }
    >
      <FlexContainer justifyContent="space-between">
        <FlexContainer
          flexDirection="column"
          alignItems="flex-start"
        >
          <p className="error-modal-text">
            {!intlId && (
              <OldTranslatedMessage
                id={`error_modal_text_${intlId}` as OnlyOldTranslations}
                defaultMessage={'An error has occured.'}
              />
            )}
            <br />
            <OldTranslatedMessage
              id={`error_modal_tips_${intlId}` as OnlyOldTranslations}
              defaultMessage={'Try to change your selection or your settings.'}
            />
          </p>
          {link && (
            <p>
              <OldTranslatedMessage
                id={linkTextID as OnlyOldTranslations}
                defaultMessage={linkTextDefaultMessage}
              />
              <a
                className={'tuto_link'}
                onClick={() => {
                  // TODO : openLinkInBrowser
                  // openLinkInBrowser(
                  //   AUTOCUT_CONSTANTS[CURRENT_ENV].ACADEMY_ADDRESS[
                  //     intl.locale.toUpperCase() as 'FR' | 'EN'
                  //   ] + link
                  // );
                }}
              >
                AutoCut Academy
              </a>
              <OldTranslatedMessage
                id={'link_not_supported_modal_end'}
                defaultMessage={' our documentation and tutorials site'}
              />
            </p>
          )}
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export default ErrorModal;
