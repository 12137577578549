import logLevel from '@autocut/enums/logLevel.enum';
import {resourcesConfigs} from '@autocut/enums/resources.enum';
import {
  ResourceManagerElement,
  ResourceManagerElementConfig,
  ResourceManagerState,
} from '@autocut/types/ResourceManager';
import {logger} from '@autocut/utils/logger';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
// import { getEncodedProjectName } from '@autocut/utils/sequence.utils';
import {config} from '@autocut/constants/configs';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {isPreloadReady, preload} from '@autocut/types/ElectronPreload';

const id = config.id;

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('resourceManager', level, message, objects);
};

export type ResourceManagerKeys = keyof typeof resourcesConfigs;

export const getResourcesFolder = (subFolder?: string) => {
  const homeDir = preload().os.homedir();
  const platform = preload().os.platform();
  let resourcesPath;

  // const projectName = getEncodedProjectName();
  const projectName = 'TEMP_DATA'; // TODO : Remplacer fonctions spécifique ppro

  const subFolderName = subFolder ?? projectName ?? 'global';
  switch (platform) {
    case 'darwin': {
      resourcesPath = preload().path.join(
        homeDir || '',
        'Library',
        'Caches',
        id,
        'Resources',
        subFolderName,
      );
      break;
    }

    case 'win32': {
      resourcesPath = preload().path.join(
        homeDir || '',
        'AppData',
        'Roaming',
        id,
        'Resources',
        subFolderName,
      );
      break;
    }

    case 'linux': {
      resourcesPath = preload().path.join(
        homeDir || '',
        `.${id}`,
        'Resources',
        subFolderName,
      );
      break;
    }

    default:
      resourcesPath = preload().path.join(
        homeDir || '',
        `.${id}`,
        'Resources',
        subFolderName,
      );
      break;
  }

  try {
    if (!preload().fs.existsSync(resourcesPath || '')) {
      preload().fs.mkdirSync(resourcesPath || '', {recursive: true});
    }
  } catch (err: any) {
    logMessage(
      logLevel.crit,
      `Can't get or create resources directory path. Failed command:  + fs.mkdirSync(${resourcesPath})`,
    );
    return '';
  }

  return resourcesPath;
};

export const getResourcePath = (
  resource: Pick<ResourceManagerElementConfig, 'fileName'>,
  subFolder?: string,
) =>
  preload().path.join(getResourcesFolder(subFolder) || '', resource.fileName);

export const checkResourceExist = (resource: ResourceManagerElementConfig) => {
  if (!isPreloadReady()) {
    return false;
  }
  try {
    return !!preload().fs.statSync(getResourcePath(resource) || '');
  } catch (_) {
    return false;
  }
};

export const getInitialResourceState = () => {
  const baseState = resourcesConfigs;
  const resources: Partial<ResourceManagerState<ResourceManagerKeys>> = {};

  Object.keys(baseState).forEach(key => {
    const resource = baseState[key as ResourceManagerKeys];
    const isExist = checkResourceExist(resource);
    resources[key as ResourceManagerKeys] = {
      ...resource,
      loading: false,
      required: resource.requestOnInit ? true : false,
      filePath: isExist ? getResourcePath(resource) : undefined,
    };
  });

  return resources as ResourceManagerState<ResourceManagerKeys>;
};

export const getResourceKey = (config: ResourceManagerElementConfig) => {
  const resources = autocutStoreVanilla().resources;
  const key = Object.keys(resources).find(
    key => resources[key as ResourceManagerKeys].fileName === config.fileName,
  );
  return key as ResourceManagerKeys;
};

export function getResource(
  config: ResourceManagerElementConfig,
): ResourceManagerElement;
export function getResource(key: ResourceManagerKeys): ResourceManagerElement;
export function getResource(
  configOrKey: ResourceManagerElementConfig | ResourceManagerKeys,
) {
  const resources = autocutStoreVanilla().resources;

  if (typeof configOrKey === 'string') {
    return resources[configOrKey];
  }

  return resources[getResourceKey(configOrKey)];
}

export const waitForResource = async (
  resourceConfig: ResourceManagerElementConfig,
  timeout = 30000,
) => {
  const resourceKey = getResourceKey(resourceConfig);
  setAutocutStore(`resources.${resourceKey}.required`, true);

  await new Promise(async (resolve, reject) => {
    const startTime = Date.now();
    let finished = false;
    do {
      const resource = getResource(resourceKey);
      if (resource.filePath) {
        resolve(resource.filePath);
        finished = true;
      } else if (resource.error) {
        reject(resource.error);
        finished = true;
      } else {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
    } while (Date.now() - startTime < timeout && !finished);
    reject(`Timeout while waiting for resource "${resourceConfig.fileName}"`);
  }).catch(err => {
    throw new IncrementalError(err, 'waitForResource');
  });
};
