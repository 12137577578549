import React from 'react';

import css from './EndCaptionsProcessModal.module.css';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useNavigate} from 'react-router-dom';
import {setAutocutStore} from '@autocut/utils/zustand';
import {FormattedMessage, useIntl} from 'react-intl';
import {BiUndo} from 'react-icons/bi';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {StatType} from '@autocut/enums/statType.enum';
import {sendStats} from '@autocut/utils/stats.utils';
import {EndProcessModal} from '../EndProcessModal';
import {processTranscriptFunctions} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {preload} from '@autocut/types/ElectronPreload';

export const EndCaptionsProcessModal = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <EndProcessModal
      fullWidth={false}
      title={
        <TranslatedMessage
          id={'modals_endProcess_captions_title'}
          defaultMessage="Captions added!"
        />
      }
      icon={'🎉'}
      footer={
        <FlexContainer
          flexDirection="column"
          gap={4}
        >
          <p>
            <TranslatedMessage
              id={'modals_endProcess_captions_footer'}
              defaultMessage="If your subtitle style or transcription is not ok, you can go back to the captions editor."
            />
          </p>
          <Button
            onClick={async () => {
              await sendStats({
                type: StatType.CAPTIONS_BACK_TO_EDITOR,
                value: 1,
              });
              navigate(processTranscriptFunctions.caption.navigateTo);
              setAutocutStore('ui.openedModalName', '');
            }}
            variant="secondary"
          >
            <FlexContainer
              flexDirection="row"
              gap={12}
              justifyContent="center"
              alignItems="center"
            >
              <BiUndo size={18} />
              <FormattedMessage
                id={'modals_endProcess_captions_button'}
                defaultMessage="Back to the transcript editor"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      }
    >
      <div className={css.modal}>
        <FlexContainer
          flexDirection="column"
          gap={0}
        >
          <p>
            <TranslatedMessage
              id={'modals_endProcess_captions_text_1'}
              defaultMessage="Your animated captions have been added! 🎉"
            />
          </p>
          <p>
            <TranslatedMessage
              id={'modals_endProcess_captions_text_2'}
              defaultMessage="If you need to modify captions:"
            />
          </p>
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          gap={6}
          style={{
            paddingBlock: 6,
          }}
        >
          <li>
            {intl.formatMessage({
              id: 'modals_endProcess_captions_list_1',
              defaultMessage:
                'Double click on the subsequence: "Double click to edit"',
            })}
          </li>

          <li>
            {intl.formatMessage({
              id: 'modals_endProcess_captions_list_2',
              defaultMessage: 'Select the caption you want to edit',
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: 'modals_endProcess_captions_list_3',
              defaultMessage:
                'Open the essential graphics and click on "edit" tab',
            })}
          </li>
        </FlexContainer>

        <p>
          <TranslatedMessage
            id={'modals_endProcess_captions_text_3'}
            defaultMessage="There is a <a>detailed tutorial if needed.</a>"
            values={{
              a: text => (
                <a
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    void preload().electron.openLinkInBrowser(
                      'https://www.autocut.com/fr/help-center/autocaptions/become-an-expert/',
                    );
                  }}
                >
                  {text}
                </a>
              ),
            }}
          />
        </p>
      </div>
    </EndProcessModal>
  );
};
