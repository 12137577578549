import {DiscordCta} from '@autocut/components/DiscordCta/DiscordCta';
import {
  HomepageCardAutoZoom,
  HomepageCardBRoll,
  HomepageCardCaptions,
  HomepageCardChapter,
  HomepageCardLegacy,
  HomepageCardPodcast,
  HomepageCardRepeat,
  HomepageCardResize,
  HomepageCardSwearWords,
  HomepageCardViralClips,
} from '@autocut/components/HomepageCard/HomepageCards';
import {ModeTitle} from '@autocut/components/ModeTitle/ModeTitle';
import {useEffect, useRef} from 'react';
import {AiOutlineHome} from 'react-icons/ai';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import css from './Homepage.module.scss';
import {
  CARD_SIZE,
  GRID_GAP,
  calculateColumnNumbers,
  computeAndApplyBackgroundMask,
} from './utils';
import {Button} from '@autocut/components/Button/Button';
import {useNavigate} from 'react-router-dom';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';

export const Homepage = () => {
  // TODO: zustand
  // const { additionalInformations, openedTimestamp } = useAutoCutStore(
  //   state => ({
  //     additionalInformations: state.user.additionalInformations,
  //     openedTimestamp: state.utils.openedTimestamp,
  //   })
  // );

  // useEffect(() => {
  //   if (additionalInformations && additionalInformations.displayOnboarding)
  //     setAutocutStore('ui.openedModalName', ModalType.Onboarding);
  // }, [additionalInformations]);

  // useEffect(() => {
  //   setAutocutStore('ui.process.isPaused', true);
  //   setAutocutStore('sequence.infos', undefined);

  //   return () => {
  //     setAutocutStore('ui.process.isPaused', false);
  //     void manuallyRefreshSequence();
  //   };
  // }, []);

  // TODO: zustand & modal
  // useEffect(() => {
  //   const openReloadAutoCutModal = () => {
  //     setAutocutStore('ui.openedModalName', ModalType.RestartAutoCut);
  //   };
  //   const timeout = setTimeout(
  //     openReloadAutoCutModal,
  //     Math.max(0, openedTimestamp + RELOAD_MODAL_TIMEOUT - Date.now())
  //   );

  //   return () => clearTimeout(timeout);
  // }, [openedTimestamp]);

  return (
    // <TourContainer
    //   steps={modeSelectionSteps}
    //   beforeClose={() => {
    //     setLocalStorage(onboardingLocalStorageKeys.ModeSelection, true);
    //     enableBodyScroll(document as unknown as HTMLElement);
    //   }}
    //   afterOpen={async () => {
    //     await sendStats({
    //       type: StatType.ON_BOARDING_STARTED,
    //       value: 1,
    //       traits: {
    //         page: 'homepage',
    //       },
    //     });
    //   }}
    // >
    <HomepageContent />
    // </TourContainer>
  );
};

// in pixel

const HomepageCards = [
  HomepageCardLegacy,
  HomepageCardPodcast,
  HomepageCardCaptions,
  HomepageCardAutoZoom,
  // HomepageCardRepeat,
  // HomepageCardSwearWords,
  // HomepageCardResize,
  // HomepageCardBRoll,
  // HomepageCardChapter,
  // HomepageCardViralClips,
];

const HomepageContent = () => {
  const intl = useOldIntl();
  // const { isBeta } = useAutoCutStore(state => ({
  //   isBeta: state.ppro.isBeta,
  // }));
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);

  // const { setIsOpen: setIsTourOpen } = useTour();
  // useEffect(() => {
  //   if (!getLocalStorage(onboardingLocalStorageKeys.ModeSelection)) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  useEffect(() => {
    const cardsContainerElement = cardsContainerRef.current;
    const backgroundElement = backgroundRef.current;

    const columnNumbers = calculateColumnNumbers({
      cardsContainerElement: cardsContainerElement,
      cardsNumber: HomepageCards.length,
    });
    cardsContainerElement?.style.setProperty(
      '--column-numbers',
      columnNumbers.toString(),
    );

    computeAndApplyBackgroundMask({
      cardsContainerElement: cardsContainerElement,
      backgroundElement: backgroundElement,
    });

    window.addEventListener('resize', () => {
      const columnNumbers = calculateColumnNumbers({
        cardsContainerElement: cardsContainerElement,
        cardsNumber: HomepageCards.length,
      });
      cardsContainerElement?.style.setProperty(
        '--column-numbers',
        columnNumbers.toString(),
      );

      computeAndApplyBackgroundMask({
        cardsContainerElement: cardsContainerElement,
        backgroundElement: backgroundElement,
      });
    });

    return () =>
      window.removeEventListener('resize', () => {
        const columnNumbers = calculateColumnNumbers({
          cardsContainerElement: cardsContainerElement,
          cardsNumber: HomepageCards.length,
        });
        cardsContainerElement?.style.setProperty(
          '--column-numbers',
          columnNumbers.toString(),
        );

        computeAndApplyBackgroundMask({
          cardsContainerElement: cardsContainerElement,
          backgroundElement: backgroundElement,
        });
      });
  });

  return (
    <>
      <DiscordCta />
      <ModeTitle
        Icon={AiOutlineHome}
        text={intl.formatMessage({
          id: 'homepage_title',
          defaultMessage: 'Homepage',
        })}
        withHomeButton={false}
      />
      <div className={`dashboardContainer ${css.mainContainer}`}>
        {/* {isBeta && (
          <InfoText style="warning">
            <OldTranslatedMessage
              id="warning_beta_version"
              defaultMessage="It seems you are running a beta version of DaVinci Resolve. You might encounter some issues with AutoCut. Please test it out on the latest stable version of DaVinci Resolve."
            />
          </InfoText>
        )} */}
        <div
          ref={cardsContainerRef}
          id="modeSelection-step1"
          className={css.cardsContainer}
          style={{
            gridTemplateColumns: `repeat(var(--column-numbers), ${CARD_SIZE}px)`,
            gridAutoRows: CARD_SIZE,
            gap: GRID_GAP,
          }}
        >
          {HomepageCards.map((Card, index) => {
            return <Card key={index} />;
          })}
          <div
            ref={backgroundRef}
            className={css.background}
          />
          {/* If you are adding new cards, you can modify the background (add blobs) to make it suit the changes */}
          <div className={css.blobContainer}>
            <div className={css.blob} />
            <div className={css.blob} />
            <div className={css.blob} />
            <div className={css.blob} />
          </div>
        </div>
      </div>
    </>
  );
};
