import {daVinci} from './davinci';

export const isShorterThanXFrames = ({
  startSecond,
  endSecond,
  nbFrame,
  framerate,
}: {
  startSecond: number;
  endSecond: number;
  nbFrame: number;
  framerate: number;
}) => {
  const endFrame = Math.floor(endSecond * framerate);
  const startFrame = Math.floor(startSecond * framerate);

  if (endFrame - startFrame < 10 || (startSecond > 290 && endSecond < 310)) {
    console.log(
      endFrame,
      startFrame,
      endFrame - startFrame,
      nbFrame,
      endFrame - startFrame < nbFrame,
    );
  }

  return endFrame - startFrame < nbFrame;
};

export const convertSecondsToFrames = async (
  secondes: number[][],
  relative = false,
  params?: {
    framerate?: number;
    offset?: number;
  },
): Promise<number[][]> => {
  const framerate =
    params?.framerate ?? (await daVinci.timeline.getFrameRate());
  const offset = relative
    ? 0
    : (params?.offset ?? (await daVinci.timeline.getStartFrame()));

  return secondes.map(([start, end]) => {
    return [
      Math.floor(start * framerate) + offset,
      Math.floor(end * framerate) + offset,
    ];
  });
};

export const convertFramesToSeconds = async (
  frames: number[][],
  params?: {
    framerate?: number;
    offset?: number;
  },
): Promise<number[][]> => {
  const framerate =
    params?.framerate ?? (await daVinci.timeline.getFrameRate());
  const offset = params?.offset ?? (await daVinci.timeline.getStartFrame());

  return frames.map(([start, end]) => {
    return [(start - offset) / framerate, (end - offset) / framerate];
  });
};
