const translations = {
  progress: {
    footer: {
      warning: 'DaVinci에서 아무것도 하지 마세요. 문제가 발생할 수 있습니다.',
    },
    steps: {
      global: {
        starting: '시작 중...',
        processing: '처리 중...',
        ending: '종료 중...',
      },
      dev: {
        ping: 'DaVinci Resolve에 핑 보내는 중...',
        pong: 'DaVinci Resolve에 퐁 보내는 중...',
      },
      common: {
        getSilences: '침묵 가져오는 중...',
        getAudioInfos: '오디오 정보 가져오는 중...',
        readAudioFile: '오디오 파일 읽는 중...',
        computeRMS: '오디오 파일 분석 중...',
        convertRMSToDB: '오디오 파일 분석 중...',
        exportDrt: '타임라인 읽는 중...',
        extractDrt: '타임라인 읽는 중...',
        getXmlTimelineFolderPath: '타임라인 읽는 중...',
        getXmlTimelineFullpath: '타임라인 읽는 중...',
        loadXmlTimeline: '타임라인 읽는 중...',
        writeFile: '파일 쓰는 중...',
        writeXml: '수정된 타임라인 가져오는 중...',
        updateDrt: '수정된 타임라인 가져오는 중...',
        importXml: '수정된 타임라인 가져오는 중...',
      },
      podcast: {
        mainProcess: '팟캐스트 편집 중...',
        getVideoTracksWeight: '화자 기록',
        getAudioTracks: '오디오 트랙 기록',
        getPodcastInterval: '팟캐스트의 길이 계산 중',
        getPodcastDuration: '팟캐스트의 길이 계산 중',
        renders: '다빈치에서 오디오 트랙 렌더링 중\n(몇 분 걸릴 수 있습니다)',
        getTalkingTimelines: '발언 시간 분배',
        getTransitions: '카메라 변경 계산 중',
        getCutTimecodes: '컷 준비 중',
        cutAtTimecodes: '타임라인 컷',
      },
      silences: {
        mainProcess: '침묵 편집 중...',
        getTimelineInfos: '타임라인 정보 가져오는 중...',
        renders: '다빈치에서 오디오 트랙 렌더링 중\n(몇 분 걸릴 수 있습니다)',
        convertAudioToMono: '오디오 파일 처리 중...',
        getCutTimecodes: '컷 준비 중...',
        cutAtTimecodes: '타임라인 컷...',
        deleteSilentTrackItems: '침묵 삭제 중...',
      },
      zoom: {
        mainProcess: '줌 추가 중...',
        getTimelineInfos: '타임라인 정보 가져오는 중...',
        getWavData:
          '다빈치에서 오디오 트랙 렌더링 중\n(몇 분 걸릴 수 있습니다)',
        computeBestZoomIntervals: '최적의 줌 간격 계산 중...',
        createVideoTrack: '비디오 트랙 생성 중...',
        createZoomFusionComps: '퓨전 구성 가져오는 중...',
      },
      captions: {
        transcript: {
          mainProcess: '전사 생성 중...',
          get_language: '언어 가져오기',
          render_wav: '다빈치에서 WAV 렌더링 중 (몇 분 걸릴 수 있습니다)',
          split_chunks: '청크 분할 중',
          uploadFile: '파일 업로드 중...',
          getTranscript: 'AI에 의한 전사 생성\n(몇 분 걸릴 수 있습니다)',
          writeFile: '파일 쓰는 중...',
        },
        generation: {
          mainProcess: '자막 추가 중...',
          initialization: '자막 초기화 중...',
          readTranscript: '전사 읽는 중...',
          getTimelineInfos: '타임라인 정보 가져오는 중...',
          appendAdjustmentLayer: '조정 레이어 추가하는 중...',
          importFusionComp: '퓨전 구성 가져오는 중...',
        },
      },
    },
  },
  launchManager: {
    steps: {
      preload: {
        title: '프리로드',
      },
      python: {
        title: 'Python 설치',
      },
      ffmpeg: {
        title: 'FFMPEG 설치',
      },
      resources: {
        title: '리소스 설치',
      },
      computeIPC: {
        title: 'AI에 연결',
      },
      daVinciIPC: {
        title: 'DaVinci Resolve에 연결',
      },
    },
    errors: {
      compute: '계산 AI에 연결할 수 없습니다. 지원팀에 문의하세요.',
      davinci:
        'DaVinci에 연결할 수 없습니다. DaVinci Resolve가 실행 중인지 그리고 AutoCut 스크립트가 실행되었는지 확인하세요.',
      cta: '다시 시도',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: '체험판',
        upgrade: '업그레이드',
        coming_soon: '곧 출시 예정',
        version_not_supported: 'PPro 버전은 지원되지 않습니다.',
        upgrade_to: '업그레이드하기 {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: '오토 팟캐스트',
      steps: {
        select: {
          title: '최소 2개의 비디오 및 오디오 트랙이 있는 타임라인 선택',
          label: '타임라인 선택',
          descriptions: {
            '1': '편집 탭으로 이동',
            '2': '편집할 타임라인으로 이동',
            ctа: '활성 타임라인 선택',
          },
          customization: {
            label: '팟캐스트 매개변수',
            steps: {
              speakers: {
                title: '스피커',
                description:
                  "각 스피커에 오디오 트랙을 할당하고 해당 이름을 입력하여 스피커를 추가하세요. 각 스피커당 하나의 오디오 트랙만 선택할 수 있습니다. 여러 스피커가 하나의 오디오 트랙을 공유하는 경우, 두 이름을 입력하세요 (예: '앨리스 & 밥').",
                header: {
                  audio: '오디오 트랙',
                  name: '스피커 이름',
                },
                textInputPlaceholder: '스피커 이름 입력...',
                trackTemplate: '트랙 A{index}',
                cta: '스피커 추가',
              },
              cameras: {
                title: '카메라',
                description:
                  '각 카메라에 비디오 트랙을 할당하고 화면에서 어떤 스피커가 보일지 선택하여 카메라를 추가하세요. 각 카메라당 하나의 비디오 트랙만 선택할 수 있습니다. 카메라당 여러 스피커를 선택할 수 있습니다.',
                header: {
                  audio: '비디오 트랙',
                  name: '스피커(들)',
                },
                speakerSelectPlaceholder: '스피커 선택...',
                trackTemplate: '트랙 V{index}',
                cta: '카메라 추가',
              },
              cameraTime: {
                title: '카메라 시간 지속',
                description:
                  '변경되기 전에 카메라가 표시될 최소 및 최대 지속 시간을 정의하세요.',
                presets: {
                  title: '기본 프리셋',
                  items: {
                    calm: '차분함',
                    paced: '페이스 조절',
                    energetic: '에너지 넘침',
                    hyperactive: '과다 활동',
                  },
                },
                minimumCamTime: {
                  title: '최소 카메라 시간',
                  description: '변경 없이 카메라가 표시될 최소 시간(초 단위)',
                  unit: '초',
                },
                maximumCamTime: {
                  title: '최대 카메라 시간',
                  description:
                    '강제로 변경되기 전에 카메라가 표시될 최대 시간(초 단위)',
                  unit: '초',
                },
              },
              silentVideoClips: {
                title: '무음 비디오 클립',
                description:
                  '사용하지 않는 팟캐스트 부분을 제거할 것인지 비활성화할 것인지를 선택하세요.',
                items: {
                  remove: '제거',
                  disable: '비활성화',
                },
              },
            },
            cta: {
              disable: '편집 및 사용하지 않는 클립 비활성화',
              delete: '편집 및 사용하지 않는 클립 삭제',
            },
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: '최소 카메라 시간은 최소 0이어야 합니다',
            higherThanMax:
              '최소 카메라 시간은 최대 카메라 시간보다 작아야 합니다',
          },
          maximumCamTime: {
            min: '최대 카메라 시간은 최소 0이어야 합니다',
          },
          deleteUnusedClips: {
            required: '사용하지 않는 클립을 삭제하거나 비활성화해야 합니다',
          },
          speakers: {
            videoTracks: {
              min: '각 화자당 최소한 하나의 비디오 트랙을 선택해야 합니다',
            },
            audioTrack: {
              min: '각 화자당 오디오 트랙을 선택해야 합니다',
            },
          },
        },
      },
      usageStats: {
        title: '사용 통계',
        select: {
          label: '모드 선택',
        },
        stats: {
          thisMonth: '이번 달',
          allTime: '전체 기간',
          mostUsed: '가장 많이 사용된',
        },
      },
    },
    silence: {
      title: 'AutoCut Silence',
      steps: {
        noiseLevel: {
          title: '소음 수준',
          description:
            '침묵으로 간주되는 소리 수준. 아래 시각화에서 효과를 볼 수 있습니다.',
        },
        silenceSize: {
          title: '침묵의 지속시간',
          items: {
            silence: {
              title: '보다 긴 침묵 제거',
              description:
                '침묵이 제거되기 위해 필요한 최소 지속시간(밀리초 단위)。',
              unit: '밀리초',
            },
            talk: {
              title: '보다 짧은 대화 제거',
              description:
                '대화 섹션이 유지되기 위해 필요한 최소 지속시간(밀리초 단위)。',
              unit: '밀리초',
            },
          },
        },
        paddingSize: {
          title: '패딩',
          items: {
            before: {
              title: '이전 패딩',
              description:
                '소음 전의 침묵 시간 - 갑작스런 말하기 재개를 피하기 위해',
              unit: '밀리초',
            },
            after: {
              title: '이후 패딩',
              description:
                '소음 후의 침묵 시간 - 말하기의 거친 중단을 피하기 위해',
              unit: '밀리초',
            },
          },
        },
        transition: {
          params: {
            title: '전환',
            warning: '정적 없이 전환을 사용할 수 없습니다.',
            default: {
              title: '기본',
              choices: {
                none: '없음',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                '이것은 각 컷의 앞과/또는 뒤에 패딩을 추가합니다. 컷 간의 부드러운 전환을 생성합니다. 선택하면 전환을 위한 새로운 트랙이 생성됩니다.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: '둘 다',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: '상수 전력',
              },
            },
          },
          progress: {
            insert: '새로운 오디오 트랙 삽입 중…',
            move: '전환을 위해 새로운 트랙으로 오디오 이동 중…',
            handling: '전환 추가 중: {transition}',
          },
        },
        preview: {
          title: '미리보기',
          description:
            '보관 및 제거된 세그먼트의 미리보기입니다. 화살표를 사용하여 앞으로 또는 뒤로 이동하거나 시간 커서의 위치를 기준으로 미리보기를 중심에 배치하세요.',
          legend: {
            kept: '보관',
            deleted: '삭제',
            margin: '여백',
            cta: '미리보기 생성',
            center: '지표 중심',
          },
          cta: '미리보기 생성',
          center: '지표 중심',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: '소음 수준은 음수여야 합니다',
            int: '소음 수준은 숫자여야 합니다',
          },
          minTimeOfSilence: {
            required: '최소 침묵 시간은 필수입니다',
            min: '최소 침묵 시간은 최소 0이어야 합니다',
          },
          minTimeOfTalk: {
            required: '최소 말하기 시간은 필수입니다',
            min: '최소 말하기 시간은 최소 0이어야 합니다',
          },
          marginBefore: {
            min: '이전 여백은 최소 0이어야 합니다',
          },
          marginAfter: {
            min: '이후 여백은 최소 0이어야 합니다',
          },
        },
      },
    },
    zoom: {
      parameters: {
        preview: {
          title: '줌 미리보기',
          description: '앵커 포인트 정의',
          maximumZoom: '최대 줌',
          errorMessage: '미리로드 중 오류가 발생했습니다.',
        },
        zoomTypes: '줌 유형',
        defaultsPresets: {
          title: '기본 사전 설정',
          calm: '차분함',
          paced: '절제됨',
          energetic: '에너제틱',
          hyperactive: '과잉활동',
        },
        zoomCoverage: '총 줌 범위',
        constraint: {
          title: '클립의 줌 제약',
          helper:
            '예인 경우, 어떤 줌도 하나의 클립을 초과하지 않습니다. 아니오인 경우, 모든 선택은 하나의 오디오 파일로 처리되며 줌은 타임라인의 개별 클립을 무시합니다.',
        },
        maximumZoomDuration: '최대 줌 지속시간',
        smoothZooms: {
          title: '부드러운 줌',
          coverage: '부드러운 줌 범위',
          nervousness: '신경질',
        },
        dynamicZooms: {
          title: '다이나믹 줌',
          coverage: '다이나믹 줌 범위',
        },
      },
      types: {
        smooth: '부드러운',
        dynamic: '다이나믹',
      },
      footerButton: '줌',
      infoText:
        'AutoZoom은 선택된 각 클립에 데시벨을 기반으로 자동으로 줌을 추가할 것입니다.',
    },
    captions: {
      steps: {
        language: {
          button: '캡션 편집기로 이동',
        },
        customization: {
          button: '캡션 추가',
          parts: {
            style: {
              title: '캡션 스타일',
              modal: {
                title: '스타일 편집',
                apply: '스타일 적용',
                save: '스타일 적용 및 프리셋으로 저장',
                text: {
                  title: '글꼴',
                  font: '글꼴 이름',
                  help: '새 글꼴 패밀리를 추가하는 방법은?',
                  color: '색상',
                },
                animation: {
                  title: '애니메이션',
                  wordBox: '텍스트 상자',
                },
                outline: {
                  title: '윤곽선',
                  width: '두께',
                },
                box: {
                  title: '텍스트 박스',
                  paddingX: 'X 패딩',
                  paddingY: 'Y 패딩',
                  opacity: '불투명도',
                  radius: '반경',
                  disabled:
                    '선택한 언어에 대해 선택한 글꼴은 박스 기능을 지원하지 않습니다.',
                  tips: '이 기능을 사용하려면 선택한 언어를 지원하는 글꼴을 선택하세요.',
                },
              },
            },
            preview: {
              title: '미리보기',
              modal: {
                title: '위치 및 크기',
                apply: '스타일 적용',
                fontSize: '글꼴 크기',
                nbLines: '줄 수',
                maxWidth: '최대 너비',
                help: '미리보기가 아직 개발 중이며 정확하지 않을 수 있습니다.',
              },
            },
            transcription: {
              title: '전사 편집',
              modal: {
                save: '저장',
                select: '편집할 캡션 선택',
                placeholder: '편집/사용자 지정할 전사 부분 선택',
                formatNumbers: '숫자 포맷',
                checkTranscript: {
                  title: '전사를 확인하셨습니까?',
                  description: '아직 전사를 확인하지 않으신 것 같습니다.',
                  dontShowAgain: '이 메시지를 다시 표시하지 않기',
                },
              },
            },
            presets: {
              delete: {
                title: '프리셋 삭제',
                cancel: '취소',
                confirm: '프리셋 삭제',
              },
              search: {
                title: '커뮤니티 프리셋',
                input: '프리셋 검색',
                cancel: '취소',
                confirm: '프리셋 가져오기',
                usage: '{count}명의 사용자에 의해 사용됨',
                error:
                  '프리셋 복사 중 오류가 발생했습니다. Discord 또는 contact@autocut.fr로 문의해 주세요.',
              },
              create: {
                title: '새 프리셋 만들기',
                placeholder: '프리셋 이름 (두 단어 이상)',
                public: '커뮤니티와 공유 - 공개',
                cancel: '취소',
                confirm: '프리셋 만들기',
                alreadyExists:
                  '이 프리셋은 이미 존재합니다. 다른 이름을 선택해 주세요.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              '사용자 프리셋이 누락되었습니다. 헤더 메뉴에서 설정을 초기화하십시오.',
          },
          position: {
            xPercentage: {
              min: '텍스트의 x 위치는 최소 0이어야 합니다',
              max: '텍스트의 x 위치는 최대 1이어야 합니다',
            },
            yPercentage: {
              min: '텍스트의 y 위치는 최소 0이어야 합니다',
              max: '텍스트의 y 위치는 최대 1이어야 합니다',
            },
            emojiXPercentage: {
              min: '이모티콘의 x 위치는 최소 0이어야 합니다',
              max: '이모티콘의 x 위치는 최대 1이어야 합니다',
            },
            emojiYPercentage: {
              min: '이모티콘의 y 위치는 최소 0이어야 합니다',
              max: '이모티콘의 y 위치는 최대 1이어야 합니다',
            },
          },
          text: {
            fontSize: {
              int: '글꼴 크기는 숫자여야 합니다',
              min: '글꼴 크기는 최소 1이어야 합니다',
            },
            font: {
              missing:
                '선택한 글꼴에 누락된 부분이 있습니다... 다시 선택하거나 다른 것을 선택하십시오.',
            },
          },
          outline: {
            width: {
              int: '윤곽선 폭은 숫자여야 합니다',
              min: '윤곽선 폭은 최소 0이어야 합니다',
            },
          },
          formating: {
            nbLines: {
              int: '줄 수는 숫자여야 합니다',
              min: '줄 수는 최소 1이어야 합니다',
            },
            maxWidth: {
              int: '최대 너비는 숫자여야 합니다',
              min: '최대 너비는 최소 0이어야 합니다',
            },
          },
          textBox: {
            xPadding: {
              int: 'x 패딩은 숫자여야 합니다',
              min: 'x 패딩은 최소 0이어야 합니다',
            },
            yPadding: {
              int: 'y 패딩은 숫자여야 합니다',
              min: 'y 패딩은 최소 0이어야 합니다',
            },
            opacity: {
              int: '불투명도는 숫자여야 합니다',
              min: '불투명도는 최소 0이어야 합니다',
              max: '불투명도는 최대 100이어야 합니다',
            },
            radius: {
              int: '반지름은 숫자여야 합니다',
              min: '반지름은 최소 0이어야 합니다',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'In/Out 포인트 설정',
          descriptions: {
            '1': '컷할 타임라인의 부분에서 Davinci에서 진입 및 탈출 포인트를 정의해 주세요.',
            '2': '오디오 분석에 포함되지 않기 원하신다면 클립이나 트랙을 수동으로 음소거 할 수 있습니다.',
          },
          cta: 'In/Out 포인트 확인',
        },
        transcription: {
          title: '오디오클립 언어 선택',
        },
        customization: {
          title: '매개변수 사용자 정의',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'AutoCut을 활성화하려면 라이센스 키를 입력하세요.',
      placeholder: '라이센스 키를 입력하세요',
    },
    trial: {
      title: '무료 체험하기',
      steps: {
        email: {
          title: '이메일 주소 입력',
          subtitle: '무료 체험 라이선스를 받기 위해 이메일 주소를 입력하세요',
          placeholder: 'email@email.com',
          cta: '다음',
        },
        sendingEmail: {
          title: '이메일 전송 중',
          success: '{email}로 이메일이 성공적으로 전송되었습니다',
        },
      },
    },
    errors: {
      no_key: '라이센스 키를 입력하세요.',
      is_key_valid_error:
        '키 확인 중 오류가 발생했습니다. 나중에 다시 시도하세요.\n',
      invalid_response: '응답이 서버에서 오지 않았습니다.',
      cant_read_host_error: '컴퓨터 이름을 읽을 수 없습니다.',
      cant_read_uuid_error: '컴퓨터의 UUID를 읽을 수 없습니다.',
    },
  },
  modals: {
    endProcess: {
      title: '프로세스 완료',
      time: '{minutes}분 {seconds}초 만에 프로세스 완료!',
      timeSaved:
        '절약된 시간: <b>{minutesSaved}</b> 분 및 <b>{secondsSaved}</b> 초',
      timeSavedAllTime:
        'AutoCut을 사용하면서 절약한 총 시간: {hourSaved} 시간 및 {minutesSaved} 분! 🎉',
      feedback: '결과물의 품질에 대한 피드백을 남겨주세요:',
      review: 'AutoCut 평가하기!',
      captions: {
        title: '자막이 추가되었습니다!',
        footer:
          '자막 스타일이나 전사 내용이 마음에 들지 않으면 자막 편집기로 돌아갈 수 있습니다.',
        button: '전사 편집기로 돌아가기',
        text_1: '애니메이션 자막이 추가되었습니다! 🎉',
        text_2: '자막을 수정하려면:',
        list_1: '수정하려는 자막에 타임라인 커서를 맞춥니다',
        list_2: '퓨전 페이지로 이동합니다',
        list_3: 'TextPlus 노드를 클릭하고 자막을 수동으로 업데이트합니다',
        text_3: '추가 팁을 원하신다면, 이 <a>튜토리얼</a>을 방문하세요.',
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: '지원되지 않는 DaVinci Resolve 버전',
      currentVersion: '현재 사용 중인 DaVinci Resolve 버전은 {version}입니다',
      text: 'AutoCut은 DaVinci Resolve 18.6 이상과만 호환됩니다.',
      update: '여기에서 업데이트 할 수 있습니다: <a>DaVinci 웹사이트</a>',
    },
  },
  globals: {
    defaults: {
      components: {
        KebabMenu: {
          account: '라이센스 키 관리',
          bug: '버그 신고',
          review: 'AutoCut 검토',
          tuto: '영상 튜토리얼',
          logout: '로그아웃',
          settings: '설정 초기화',
          featureRequest: '機能リクエスト',
          academy: '기능 요청',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft}일 남음',
          info: '{licenceType} 라이센스',
          trialButton: '더 보기',
          paidButton: '관리하기',
        },
        PromoBanner: {
          promo: '24시간 이내에 최대 20% 할인',
          shop: '지금 쇼핑하기',
        },
        FileInput: {
          upload_screenshot: '스크린샷 업로드',
        },
        LinkToUserFileInput: {
          send_log_link_to_user_file: {
            title: '비디오 및 오디오 파일 링크를 제공하세요.',
            description:
              '(WeTransfer, Google Drive 또는 기타 파일 공유 서비스)',
            helper:
              "WeTransfer에 파일을 업로드하려면 wetransfer.com으로 가서 '파일 업로드'를 클릭하세요. 그 후 링크를 받아서 여기에 복사하세요.",
            end: '이것은 문제를 신속하게 해결하는 데 도움이 될 것입니다.',
          },
        },
        Select: {
          'no-more': '더 이상 옵션 사용 불가',
          'select-all': '모두 선택',
          placeholder: '옵션을 선택하세요',
        },
        PreviewFrame: {
          previewOnIndicator: '인디케이터에서 미리보기',
        },
      },
    },
    misc: {
      retry: '재시도',
    },
    units: {
      second: '초',
      millisecond: '밀리초',
      percentage: '%',
      px: 'px',
    },
  },
  old: {
    text: {
      errorSelection:
        '잘못된 선택입니다.{br}오디오 또는 영상 파일에서 직접 트랙을 선택하십시오.{br}시퀀스가 선택된 경우, 시퀀스 내의 동영상을 분할하십시오.',
      loading: '자르는 중...',
      transition: {
        step: {
          title: '전환',
          j_l_cut: 'J-컷 & L-컷',
          default: '기본값',
          premiere: {
            pro: 'DaVinci Resolve',
          },
          none: '없음',
          j_cut: 'J-컷',
          l_cut: 'L-컷',
          both: '둘 다',
        },
        constant: {
          power: '지속 가감속',
        },
        progress: {
          insert: '새 오디오 트랙 삽입 중...',
          move: '전환을 위해 오디오를 새 트랙으로 이동 중...',
          handling: '전환 추가 중: {transition}',
        },
      },
      Step4: {
        AutoZoom: {
          ratio: '최대 줌 계수',
          step: '확대된 영상의 백분율',
        },
        Title: 'AutoZoom (베타)',
      },
      Step5: {
        Backup: '백업 활성화',
        Title: '미리 보기',
      },
      autocutv2:
        'AutoCut v2는 최적의 침묵 탐지를 위한 다양한 설정 옵션을 제공하는 전문가용 버전입니다.',
      autocutAi:
        'AutoCut AI는 침묵을 자동으로 탐지하여 AI가 나머지를 처리하게 합니다.',
      autocutPodcast:
        'AutoCut Podcast를 사용하면 컴퓨터 설정을 제공하기만 하면 수 초 만에 팟캐스트 영상를 편집할 수 있습니다. 편집된 최종 영상를 바로 즐길 수 있습니다.',
      autocutRepeat:
        'AutoCut Repeat으로 동영상의 모든 나쁜 테이크를 제거하고 최고의 테이크만 남겨두세요.',
      autocutCaptions: '틱톡 스타일의 캡션을 빠르고 쉽게 생성하세요.',
      Step3: {
        Remove: '제거',
        Keep: '유지',
        CenterButton: '지시기에 중앙 맞춤',
        Previsualisation: {
          __text__: '미리 보기:',
          loading: '로딩 중...',
        },
        Noise: {
          unit: '데시벨',
        },
        DeleteSilence: '침묵 제거',
        KeepSilence: {
          Yes: '네',
          No: '아니요',
        },
        Title: '소음 수준 조정',
      },
      Step2: {
        After: '이후 패딩',
        Before: '이전 패딩',
        Title: '패딩',
      },
      Step2Ai: {
        Title: '미리 보기 검증',
      },
      Step1: {
        Talks: '다음보다 짧은 대화 제거',
        Silences: '다음보다 긴 침묵 제거',
        Title: '침묵 지속 시간',
      },
      Step1Ai: {
        Silences: 'AI 공격성 선택',
        Title: 'AI 공격성',
      },
      Step1Podcast: {
        Title: '대화자',
      },
      step6: {
        selected: {
          clips: '선택된 클립',
        },
      },
      NoInternet: '인터넷 연결이 필요합니다.',
      noClip: {
        title: '타임라인에서 자를 클립을 선택하십시오',
        no_selection:
          'AutoCut이 응답하지 않으면 DaVinci Resolve를 다시 시작해보세요.',
        tuto: {
          __text__: '도움이 필요하십니까? 저희의',
          link: ' 영상 튜토리얼을 시청하세요',
        },
        loading: {
          bar: '로딩 중 - 시퀀스 분석 진행 중...',
        },
      },
      needPProUpdate: 'DaVinci Resolve 버전이 15 이상이어야 합니다.',
      No_Key: '라이센스 키를 입력하세요',
      Login: {
        SubmitButton: '활성화',
        SubmitButton2: '활성화',
        PasteButton: '붙여넣기',
      },
      Welcome: {
        Title: '환영합니다',
        freeTrial: '무료 평가판 받기',
        UseLicence: '라이센스 키 사용',
        BuyLicence: '라이센스 키 구매',
      },
      UseLicence: {
        Title: '라이센스 키 사용',
        Text: '라이센스 키를 입력하고 AutoCut을 사용 시작하세요!',
      },
      GetLicence: {
        Title: '이메일로 무료 평가판 라이센스 받기',
        Select: 'Autocut을 어디에서 발견하셨나요?',
        Text: '이메일 주소를 입력하고 무료 평가판 라이센스 받기',
        Button: '무료 라이센스 받기',
      },
      UseNewLicence: {
        Title: '{s}로 라이센스 키를 보냈습니다!',
        Text: '이메일을 확인하고 라이센스 키를 입력한 다음 AutoCut을 사용 시작하세요! 몇 분 안에 키를 받지 못하면 스팸을 확인하세요.',
        Resend: '이메일을 받지 못하셨나요?',
        ResendButton: '{mail}로 키 다시 보내기',
        ResendSucces: '이메일 발송!',
      },
      NoFFMPEG: 'FFMPEG에 문제가 발생했습니다. 고객 지원에 문의하십시오',
      legend: {
        kept: '유지함',
        deleted: '삭제함',
      },
      yes: '예',
      no: '아니요',
      undo: {
        modal: '컷 결과에 만족하지 않으십니까?',
      },
      since: {
        your: {
          last: {
            visit: '마지막 방문 이후로',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted:
              '죄송하지만 {filename} 파일이 지원되지 않는 형식인 것 같습니다...',
            speed:
              '죄송하지만 AutoCut은 변경된 속도의 클립을 자르는 것을 지원하지 않습니다...',
            multi: {
              audio:
                '죄송하지만 파일에 포함된 모든 오디오 트랙이 선택되지 않은 경우 AutoCut이 제대로 작동하지 않을 수 있습니다.',
              stream:
                '죄송하지만 선택한 파일 중 하나에 여러 오디오 스트림이 있는 것 같습니다...',
            },
            '51_stereo':
              '죄송하지만 DaVinci Resolve에서 스테레오로 표시된 5.1 오디오 레이아웃이 있는 선택한 파일이 있는 것 같습니다...',
            audio: {
              layout:
                '죄송하지만 선택한 파일 중 하나가 지원되지 않는 오디오 레이아웃인 것 같습니다...',
            },
            repeat: {
              multi: {
                audio:
                  '죄송하지만 Autocut Repeat은 현재 여러 오디오 클립을 지원하지 않습니다.',
              },
              no_audio:
                'Autocut Repeat을 사용하려면 오디오 클립을 선택해야 합니다.',
            },
            remote: {
              file: '죄송하지만 {filename} 파일에 접근할 수 없는 것 같습니다...',
            },
          },
        },
        maintained: {
          modal:
            'AutoCut을 사용하려면 DaVinci Resolve를 더 최신 버전으로 업그레이드하세요.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: '다른 클립을 선택하여 자르십시오.',
              speed:
                '클립 속도를 리셋(100%로) 하시거나 다른 클립을 선택해주세요.',
              multi: {
                audio:
                  '모든 오디오 채널이 선택되어 있고 DaVinci Resolve의 기본 순서에 있다는 것을 확인해 주세요.',
                stream: '다른 클립을 선택하여 자르십시오.',
              },
              '51_stereo':
                '다른 클립을 선택하거나 잘라보십시오. 문제가 계속되면 시퀀스의 채널 레이아웃을 변경하세요.',
              audio: {
                layout:
                  '다른 클립을 선택하여 자르십시오. 문제가 계속될 경우 시퀀스의 스크린샷을 보내주세요.',
              },
              repeat: {
                multi: {
                  audio: '자르기 위해 하나의 오디오 클립만 선택하십시오.',
                },
              },
              remote: {
                file: '파일이 컴퓨터에 있고 클라우드에 없는지 확인하세요.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: '라이센스 키를 입력하고 AutoCut을 사용 시작하세요!',
        },
      },
      no_audios: '이 오디오를 사용하여 침묵을 탐지, 자르기 및 제거합니다.',
      step1: {
        podcast: {
          title: '카메라 시간 지속 기간',
          minimum: '최소 카메라 시간',
          maximum: '최대 카메라 시간',
        },
      },
      silences: {
        step: {
          delete: {
            __text__: '{mode} 제거',
            keep: {
              spaces: '{mode} 제거하되 공간 유지',
            },
          },
          keep: '{mode} 유지',
          mute: '{mode} 음소거',
        },
      },
      repetitions: '반복',
      podcast: {
        add: {
          speaker: '연사 추가',
          camera: '카메라 추가',
        },
        track: '트랙',
        camera: {
          title: '카메라',
          helper:
            '카메라를 추가하고 각 카메라에서 보이는 연사를 명시하세요. 카메라마다 여러 연사를 선택할 수 있습니다.',
        },
        speaker: '연사',
        all: {
          speakers: '모든 스피커',
        },
      },
    },
    help: {
      bug: '버그 신고',
      review: 'AutoCut 검토',
      multipiste: '멀티트랙에 대한 도움이 필요하세요? 영상 튜토리얼을 보세요',
      tuto: '영상 튜토리얼',
      academy: '도움말',
      buy: '라이센스 키 구매',
      account: '라이센스 키 관리',
      logout: '로그아웃',
      reset: {
        settings: '설정 초기화',
      },
      transition: {
        step: '이 기능은 각 잘라낸 부분의 앞과 뒤에 여백을 추가하여 잘라낸 부분 사이의 전환을 부드럽게 만듭니다. 선택하면, 전환을 위한 새로운 트랙을 만들게 됩니다.',
      },
      transcription: {
        provider: {
          step: '선택한 모델에 따라 전사의 품질과 지속 시간이 달라질 수 있습니다.',
        },
      },
    },
    bugBtnDialog: '이메일 보내기: contact@autocut.fr',
    ButtonCutText: '자르기',
    ButtonCutAndDeleteText: '자르고 삭제하기',
    OptionCutText: '침묵 유지하며 자르기',
    OptionCutAndDeleteText: '침묵 삭제하며 자르기',
    first: {
      launch: {
        error:
          'AutoCut 실행 중 오류가 발생했습니다. 플러그인을 다시 시작하십시오.',
      },
    },
    helper: {
      Step4: {
        '1': 'AutoZoom 옵션을 활성화하면, 컷 뒤에 클립이 확대됩니다.',
        '2': '줌 계수는 아래 제공된 값과 부클립의 최대 볼륨을 기준으로 계산됩니다.',
        '3': '영상를 확대할 비율을 선택하십시오.',
      },
      Step5: '백업 기능을 사용하면 컷 이후의 모든 수정을 되돌릴 수 있습니다.',
      Step1: {
        '1': '침묵이 제거되어야 하는 최소 지속 시간(초).',
        '2': '유지되어야 할 대화 부분의 최소 지속 시간(초).',
        podcast: {
          minium: '카메라가 변경되지 않고 표시되는 최소 지속 시간(초).',
          maximum: '변경되기 전에 카메라가 표시되는 최대 지속 시간(초).',
        },
      },
      Step1Ai:
        '공격성은 AI가 제거할 침묵의 양을 결정합니다. 최소 1, 최대 4입니다.',
      Step2: {
        '1': '대화 부분 이전의 침묵 지속 시간.',
        '2': '대화 부분 이후의 침묵 지속 시간.',
      },
      Step3: {
        '1': '침묵으로 간주되는 소리 수준. 아래 시각화에서 효과를 확인할 수 있습니다.',
        '2': '유지 및 제거된 세그먼트의 미리 보기. 화살표를 사용하여 앞뒤로 이동하거나 타임 커서 위치를 기준으로 미리 보기를 중앙에 맞출 수 있습니다.',
        '3': '침묵을 삭제하지 않도록 선택하면 클립이 자르지만 침묵은 제거되지 않습니다.',
      },
      Save: {
        Default:
          '설정 저장하기. 현재 설정을 저장하면 다음에 시작할 때 로드됩니다.',
      },
      Undo: {
        Cut: '최근 컷 취소하기. 마지막 컷 이전 상태로 클립 복원하기.',
      },
      speakers: {
        podcast: {
          video: {
            tracks:
              '대화자가 나타나는 영상 트랙을 선택하십시오. 원하는 만큼의 트랙을 선택할 수 있습니다.',
          },
          audio: {
            tracks:
              '연사의 목소리가 들리는 오디오 트랙을 선택하세요, 연사마다 트랙을 하나만 선택할 수 있습니다.',
          },
        },
      },
    },
    warn: {
      Step5: '경고: 백업을 비활성화하면 컷을 취소할 수 없습니다.',
    },
    title: {
      Step5: {
        Backup: '백업 활성화',
      },
      undo: {
        modal: '완료!',
      },
      not: {
        supported: {
          modal: {
            corrupted: '클립 중 하나가 지원되지 않는 형식입니다',
            speed: 'AutoCut은 기본 속도로만 클립을 지원합니다',
            multi: {
              audio:
                'AutoCut은 여러 오디오 트랙을 잘 처리하지 못할 수 있습니다',
              stream: '클립 중 하나가 여러 오디오 스트림을 가지고 있습니다',
            },
            '51_stereo':
              '클립 중 하나에 5.1 오디오 레이아웃이 스테레오로 표시됩니다',
            audio: {
              layout: '클립 중 하나에 지원되지 않는 오디오 레이아웃이 있습니다',
            },
            repeat: {
              multi: {
                audio: '선택한 것에 여러 오디오 클립이 포함되어 있습니다',
              },
              no_audio: '선택한 것에 오디오 클립이 없습니다',
            },
            remote: {
              file: '클립 중 하나가 컴퓨터에 없습니다',
            },
          },
        },
        maintained: {
          modal:
            '2021년판 이전의 DaVinci Resolve 버전에서는 AutoCut이 유지되지 않습니다.',
        },
      },
      use: {
        trial: {
          licence: '이메일을 확인하고 라이센스 키 사용',
        },
        paid: {
          licence: '라이센스 키 사용',
        },
      },
      no_audios: '선택한 것에 오디오가 없습니다',
      banner: {
        podcast: {
          beta: '새로운 Podcast 모드를 시도해 주셔서 감사합니다.',
        },
      },
      silences: {
        step: '{mode} 관리',
      },
      podcast: {
        silences: {
          step: '침묵 제거 또는 음소거',
        },
      },
    },
    secondUnit: '초',
    millisecondUnit: '밀리초',
    percentageUnit: '%',
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut 및 침묵 삭제',
          l_cut: 'L-Cut 및 침묵 삭제',
          both: 'J&L-Cut 및 침묵 삭제',
          constant: {
            power: '자르기, 공백 삭제 및 오디오 전환 적용',
          },
          none: '{mode} 자르기 및 삭제',
          keep: {
            spaces: {
              none: '{mode}를 자르고 공간을 유지하다',
            },
          },
        },
        keep: {
          none: '{mode} 자르기 및 유지',
        },
        mute: {
          none: '{mode} 자르기 및 음소거',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: '트랙 가져오는 중...',
        silences: '침묵 가져오는 중...',
      },
      edit: {
        track: '트랙 {indexTrackInfo} 편집: {current} / {max}',
      },
      final: {
        message: {
          __text__: '성공! 팟캐스트가 편집되었습니다',
          error: '팟캐스트 편집 중 오류가 발생했습니다',
        },
      },
      button: {
        disable: '편집하고 사용하지 않는 클립 비활성화',
        delete: '편집하고 사용하지 않는 클립 삭제',
      },
      not: {
        available: {
          title: '최소 2개의 영상 및 오디오 트랙이 있는 시퀀스를 선택하십시오',
        },
      },
      minimum: {
        higher: {
          maximum: '최소 카메라 시간은 최대 카메라 시간보다 짧아야 합니다.',
        },
      },
      empty: {
        audio: {
          track: '오디오 트랙은 비워둘 수 없습니다.',
        },
        video: {
          track: '영상 트랙은 비워둘 수 없습니다.',
        },
      },
      preset: {
        title: '기본 프리셋',
        calm: '차분함',
        paced: '적당한 속도',
        energetic: '에너지 넘치는',
        hyperactive: '과잉 활동적인',
      },
    },
    error: {
      UseLicence: {
        __text__: '다른 컴퓨터에서 키의 연결을 해제하려면',
        link: 'AutoCut 클라이언트 영역',
      },
      modal: {
        title: {
          subsequenceNotReachable: '하위 시퀀스에 도달할 수 없습니다',
          unknown: '알 수 없는 오류가 발생했습니다',
          noSpace: '오류가 발생했습니다: 충분한 여유 공간이 없습니다',
          exportUnknown: '프리미어 프로가 내보내기 중에 오류를 만났습니다',
          invalidInputData: '임시 파일 생성 중 오류가 발생했습니다',
          fileNotFound: '파일 읽기 중 오류가 발생했습니다',
          scriptNotLoaded: 'AutoCut 로딩 중 오류가 발생했습니다',
          ffmpegError: '파일을 읽을 수 없습니다.',
          exportCancelled: '내보내기 취소됨',
          timelineMuted: '들을 수 있는 트랙 없음',
          noAudibleAudio: '들을 수 있는 오디오 없음',
          offline: '오프라인 상태인 것 같습니다',
          resourceTimeout: '리소스를 가져올 수 없습니다',
          backupNotFound: '백업을 찾을 수 없습니다',
          setCursorOutInterval: '인/아웃 포인트를 벗어남',
          emptyPath: '선택 분석 중 오류가 발생했습니다',
          sequenceMismatch: '작업을 수행할 수 없습니다',
        },
        text: {
          subsequenceNotReachable:
            '죄송하지만, 선택하신 하위 시퀀스 중 하나에 도달할 수 없었습니다.',
          unknown:
            'AutoCut을 사용하는 동안 오류가 발생했지만 정확한 원인을 파악할 수 없습니다.',
          noSpace: '디바이스에 충분한 여유 공간이 없는 것 같습니다.',
          exportUnknown:
            '이 오류는 디스크의 저장 공간 부족으로 인해 가장 가능성이 높습니다.',
          invalidInputData:
            '죄송하지만 임시 파일 생성 중 오류가 발생한 것 같습니다.',
          fileNotFound:
            '파일 {filename}이 여전히 다음 위치에 있는지 확인하세요: {filepath}',
          scriptNotLoaded:
            '모든 다른 확장 프로그램과 DaVinci Resolve를 닫고 다시 시도하세요.',
          ffmpegError: '파일 {filepath}이 손상되지 않았는지 확인하세요.',
          exportCancelled:
            '인코딩을 중지하면 AutoCut는 오디오를 분석할 수 없습니다. 다시 시도해 주세요.',
          timelineMuted: '사용한 모든 오디오 트랙이 음소거되었습니다.',
          noAudibleAudio: '들을 수 있는 오디오가 감지되지 않았습니다.',
          offline: '서버에 연결할 수 없습니다.',
          resourceTimeout:
            '이 작업에 필요한 온라인 리소스를 30초 내에 다운로드할 수 없습니다.',
          backupNotFound:
            '백업 시퀀스를 찾을 수 없어 타임라인을 복원할 수 없습니다.',
          setCursorOutInterval:
            '인/아웃 포인트에 포함되지 않은 청크를 선택했습니다.',
          emptyPath:
            '죄송하지만 선택하신 클립 중 컴퓨터에 경로가 없는 것 같습니다.',
          sequenceMismatch:
            'AutoCut이 처리 중일 때 시퀀스가 변경되었습니다. 시퀀스를 변경하지 않고 다시 시도해 주세요.',
        },
        tips: {
          subsequenceNotReachable:
            '타임라인의 각 하위 시퀀스가 프로젝트 패널의 원본 시퀀스와 동일한 이름을 공유하는지 확인해 주세요.',
          unknown:
            '선택 사항이나 설정을 바꿔보세요. 문제가 계속되면 저희에게 문의하세요.',
          noSpace: 'AutoCut을 다시 시작하기 전에 일부 파일을 제거해 보세요.',
          exportUnknown:
            'AutoCut을(를) 다시 시작하기 전에 일부 파일을 삭제해 보십시오.',
          invalidInputData:
            '동일한 선택 항목으로 다시 시도해 보십시오. 계속 문제가 발생하면 파일과 함께 저희에게 연락주세요.',
          fileNotFound:
            '동일한 선택 항목으로 다시 시도해 보세요. 문제가 계속되면 파일과 함께 문의 주시기 바랍니다',
          scriptNotLoaded: '문제가 계속되면 저희에게 연락주세요.',
          ffmpegError: '문제가 지속되면 저희에게 문의하세요.',
          exportCancelled: ' ',
          timelineMuted: '적어도 하나를 음소거 해제해 주세요.',
          noAudibleAudio:
            '적어도 하나의 클립이 활성화되어 있고 들을 수 있는지 확인해주세요.',
          offline: '인터넷 연결을 확인하고 다시 시도하세요.',
          resourceTimeout: '인터넷 연결을 확인하고 다시 시도하세요.',
          backupNotFound:
            '프로젝트의 루트에 있는 "AutoCut-Backup" 폴더를 확인하여 백업이 여전히 있는지 확인하세요.',
          setCursorOutInterval:
            '이 부분의 트랜스크립트를 듣기 위해서는 인/아웃 포인트를 제거해야 합니다.',
          emptyPath:
            '모든 클립이 원자 미디어 또는 하위 시퀀스에서 가져온 것인지 확인해 주세요.',
          sequenceMismatch:
            'AutoCut을 사용할 때는 처리 과정이 끝날 때까지 시퀀스를 변경하거나 현재 시퀀스의 어떤 것도 변경하지 말아 주세요.',
          noSoundDetected:
            'AutoCut이 소리를 감지할 수 없습니다. 다음을 확인해 주세요: {br}{br} <li>선택한 언어가 클립의 언어와 일치하는지 확인하세요.</li><li>트랙이 음소거되지 않았는지 확인하세요.</li><li>입력 및 출력 지점에 소리가 있는 클립이 포함되어 있는지 확인하세요.</li> {br}이 문제를 확인한 후에도 계속 발생하면 Discord 또는 contact@autocut.fr로 문의해 주세요.',
        },
        lost: {
          cep: {
            title: '오류가 발생했습니다: 연결 끊김',
            text: {
              '1': 'Adobe DaVinci Resolve와의 연결이 끊겼습니다. 확장 프로그램을 다시 시작하고 다시 시도해 보세요.',
              '2': '확장 프로그램을 다시 시작해도 문제가 해결되지 않는 경우 DaVinci Resolve를 다시 시작해 보세요.',
            },
          },
        },
      },
      language: {
        not: {
          supported: '이 모드에서는 지원되지 않는 언어입니다.',
        },
      },
    },
    retour: '이 키는 존재하지 않습니다!',
    countdown: {
      days: '시험 사용 일수가 남았습니다',
    },
    saveas: {
      default: {
        settings: '현재 설정 저장',
      },
    },
    button: {
      no_silences: '감지된 침묵이 없습니다',
      no_audios: '선택된 오디오 클립이 없습니다',
      SavingBackup: '프로젝트 백업 중...',
      RestoringBackup: '프로젝트 복원 중...',
      ExportingAudio: '오디오 내보내는 중...',
      UploadingAudio: '오디오 업로드 중...',
      SearchingSilences: '침묵 검색 중...',
      Cuttingtrack: '트랙 자르기 {current}',
      CuttingStep: {
        __text__: '컷 작업 수행 중 {current} / {max}',
        Audio: '오디오 트랙 {numTrack} 자르기 진행 중: {current} / {max}',
        Video: '비디오 트랙 {numTrack} 자르기 진행 중: {current} / {max}',
      },
      PreDeletingStep: '삭제할 침묵 클립 검색 중...',
      DeletingStart: '침묵 삭제 중...',
      finalizing: '완료 중...',
      AssociatingStep: '연결 작업 수행 중 {current} / {max}',
      Success: '완료! 과정이 성공적으로 끝났습니다',
      Failure: '실패... 문제가 발생했습니다',
      NoSilences: '침묵을 찾을 수 없습니다',
      undo: {
        modal: '실행 취소',
      },
      not: {
        supported: {
          modal: '닫기',
        },
      },
      PreZoomingStep: '줌을 위한 부클립 가져오는 중...',
      ZoomingStep: '줌 추가 중: {current} / {max}',
      Autozoom: 'AutoZoom 준비중...',
      no_video: {
        to_zoom: '확대할 영상가 없습니다.',
      },
      getSubclip: {
        step: 'AutoZoom을 위한 부클립 가져오는 중...',
      },
      checkSilenceSuppression: {
        step: '침묵이 제대로 제거되었는지 확인 중...',
      },
      back: '뒤로',
      DeletingStep: {
        delete: {
          Audio: '오디오 트랙 {numTrack}에서 침묵 삭제 중: {current}/{max}',
          Video: '비디오 트랙 {numTrack}에서 침묵 삭제 중: {current}/{max}',
        },
        mute: {
          Audio: '오디오 트랙 {numTrack}에서 침묵 음소거 중: {current}/{max}',
          Video: '비디오 트랙 {numTrack}에서 침묵 음소거 중: {current}/{max}',
        },
      },
      skip: '건너뛰기',
      save: '저장',
    },
    warning: {
      DontTouchTimeline: '이 프로세스 중에 타임라인을 수정하지 마세요',
      undo: {
        modal:
          '오디오 파일을 분석하는 동안 일부 메타데이터가 없는 것으로 보입니다.',
      },
      modal: {
        title: {
          ffmpeg: 'AutoCut이 예상대로 작동하지 않을 수 있습니다',
        },
        text: {
          ffmpeg:
            '죄송하지만 {filename} 파일에 일부 메타데이터가 누락된 것 같습니다.',
        },
        tips: {
          ffmpeg: '문제가 발생하면 파일을 다시 인코딩해 보세요.',
        },
      },
      transition: {
        step: '침묵을 삭제하지 않고 전환을 사용할 수 없습니다.',
      },
      beta: {
        version:
          '현재 사용 중인 프리미어 프로가 베타 버전인 것 같습니다. AutoCut에서 일부 문제가 발생할 수 있습니다. 최신 안정 버전 프리미어 프로에서 테스트해 보시기 바랍니다.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: '선택 사항이 유효한지 확인하는 중...',
          parsing: {
            step: '선택 항목 데이터 가져오기 중: 클립 {current} / {max}...',
          },
        },
        silence: {
          detection: {
            step: '선택 항목의 침묵 검출 중: 클립 {current} / {max}...',
          },
        },
        drawing: '미리 보기 생성 중...',
      },
      frame: {
        no_sequence: '포맷을 미리 보려면 시퀀스를 열어주세요',
      },
    },
    contact: {
      us_title: '버그를 만나 심려를 끼쳐 드려 죄송합니다',
      us_text: {
        '2': '계정, 키, 결제 또는 영상 편집 프로젝트와 관련이 없는 다른 문제가 있으면:',
        __text__:
          'AutoCut이 프로젝트와 완벽하게 작동하지 않고 문제를 신고하고 싶다면:',
      },
      us_button: '문제 신고하기',
      us_contact: '문의하기:',
      us_copy: '클립보드에 연락처 복사!',
    },
    switch: {
      to_send: {
        log: {
          button: '또는 로그 파일 보내기',
        },
      },
    },
    send: {
      log: {
        title: '이 버그로 인한 불편함에 대해 사과드립니다',
        details:
          '이 로그 파일로 알고리즘 작업에 관한 정보만 접근할 수 있습니다.',
        select:
          '일기 로그 파일을 선택하십시오, 문제 해결에 도움이 될 것입니다.',
        text: {
          area: '문제를 가능한 자세히 설명해주십시오:',
        },
        button: '로그 파일 보내기',
        success: {
          title: '파일 전송 완료! 귀하의 문제를 검토하겠습니다.',
        },
        thank: {
          text: '동영상 편집을 계속하실 수 있습니다.',
          text2: '문제가 해결되면 연락드리겠습니다.',
        },
        message: {
          error: '최소 10자 이상의 메시지를 입력하세요',
        },
        link: {
          to_user: {
            file: {
              __text__: '영상 및 오디오 파일에 대한 링크를 제공해주십시오.',
              not: {
                valid:
                  '영상 및 오디오 파일에 대한 유효한 링크를 제공해주십시오.',
              },
              platform: {
                __text__:
                  '(WeTransfer, 구글 드라이브 또는 기타 파일 공유 서비스)',
                helper:
                  "WeTransfer에 파일을 업로드하려면 wetransfer.com으로 가서 '파일 업로드'를 클릭하세요. 그 후 링크를 받게 되면 여기에 복사하세요.",
              },
              end: '이로 인해 문제를 신속히 해결할 수 있습니다.',
            },
          },
        },
        screen: {
          timeline: {
            __text__: 'DaVinci Resolve에서 선택한 스크린샷을 첨부해 주십시오.',
            before:
              '컷 전에 DaVinci Resolve에서 선택한 스크린샷을 첨부하십시오.',
            after:
              '컷 후에 DaVinci Resolve에서 선택한 스크린샷을 첨부하십시오.',
          },
          error: {
            message: '오류 메시지 스크린샷을 첨부하십시오.',
          },
        },
        trial: {
          mail: '시험판으로 요청한 이메일을 제공해 주세요.',
        },
        other: '문제를 해결하는 데 도움이 될 수 있는 정보를 제공해 주세요.',
      },
    },
    select: {
      issue: {
        title: '아래 목록에서 문제를 선택해 주십시오.',
        subtitle: '문제를 해결하는 데 도움이 될 것입니다.',
      },
      one: {
        audios:
          'AutoCut을 사용하려면 적어도 하나의 오디오 클립을 선택해야 합니다.',
      },
    },
    upload: {
      screenshot: '스크린샷 업로드',
    },
    modal: {
      feedback: {
        title: '피드백',
        input: {
          feedback: {
            title: 'AutoCut에 대해 어떻게 생각하십니까?',
            placeholder: '여기에 피드백을 작성하세요...',
          },
          improve: {
            title: '어떤 점을 개선하면 좋을지 알려주세요?',
            placeholder: '여기에 개선될 점을 작성하세요...',
          },
        },
        button: {
          text: '보내기',
        },
        sent: {
          title: '피드백을 주셔서 감사합니다!',
          text: 'AutoCut에 구현할 기능 아이디어가 있으면 저희에게 보낼 수 있습니다:',
          button: {
            text: '기능 요청하기',
          },
        },
      },
      bug: {
        report: {
          title: '버그 신고',
          description: {
            placeholder: '여기에 버그를 설명하세요...',
            error: '최소 20자 이상의 버그 설명을 제공해 주세요.',
          },
          reproduce: {
            placeholder: '여기에 버그를 재현하는 단계를 작성하세요...',
            error: '최소 20자 이상의 재현 단계를 제공해 주세요.',
          },
          blocking: '문제가 차단되었습니다',
          button: {
            text: '버그 신고 보내기',
          },
          link: {
            error: '파일에 대한 유효한 링크를 제공해 주세요.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: '컷에 문제가 있었나요?',
      },
    },
    star: {
      review: {
        title: 'AutoCut에 후기를 남겨주세요!',
        captions: {
          title: 'AutoCaptions 평가하기！',
        },
        text: 'AutoCut에 대한 생각을 공유해 주세요!',
      },
    },
    review: {
      title: '더 자세한 의견을 알려주세요',
      text: '컷이 만족스러웠나요? AutoCut을 개선하기 위한 피드백이 있으신가요? 저희는 항상 귀 기울일 준비가 되어 있습니다:',
      form: {
        placeholder: '메시지를 입력하세요...',
      },
      button: '보내기',
      thank: {
        title: '피드백을 주셔서 감사합니다!',
        text: '동영상 편집을 계속하실 수 있습니다.',
      },
    },
    exchange: {
      title: '시험 사용 기간을 7일 더 추가하세요!',
      text: 'Adobe Exchange에 가서 AutoCut을 평가하면 7일간 시험 사용 기간을 추가로 받을 수 있습니다!',
      button: 'Adobe Exchange에서 AutoCut 평가하기',
      info: "웹사이트에서 AutoCut을 설치한 경우, 확장 프로그램을 다시 설치할 필요가 없습니다. '무료'를 클릭하여 다운로드를 시작하고 평가에 접근하세요.",
    },
    licence: {
      trial: '시험판',
      paid: '유료',
      info: '{licenceType} 라이센스',
      time: {
        left: '{daysLeft}일 남음',
      },
    },
    trial: {
      promo: {
        text: '24시간 이내에 최대 20% 할인',
      },
      resend: {
        error:
          '시험 사용 키를 다시 보내는 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.\n',
      },
      expired: {
        modal: {
          title: '체험 기간이 만료되었습니다.',
          text1:
            '무료 체험이 만료되었습니다. 확장 기능을 계속 사용하려면 AutoCut에 가입하십시오.',
          text2: 'AutoCut 대시보드를 방문하세요 :',
          text3: '라이선스 키를 구독하십시오.',
          text4: '도움이 필요하시면 지원팀에 문의하십시오 : {supportEmail}',
          button: '구독하기 (-20%)',
        },
      },
      title: '무료 체험판',
      stepper: {
        onboarding: '자동 컷 개선에 도움을 주세요.',
        email: '이메일 입력',
        sending: {
          email: '이메일 보내기',
        },
        login: '라이선스 키 사용',
      },
      onboarding: {
        subtitle:
          '확장 프로그램을 개선하고 새로운 기능을 상상하기 위해, 프리미어 프로 사용에 대한 이해를 돕기 위해 당신의 사용을 알려주십시오',
        discover: '어디에서 AutoCut를 발견하셨나요?',
        job: {
          status: '당신의 직업 상태는 무엇입니까?',
        },
        type: {
          creation: '프리미어 프로에서 하시는 창작물 유형',
        },
      },
      sending: {
        email: {
          success: '이메일이 {email}로 성공적으로 전송되었습니다.',
        },
      },
      task: {
        captions: 'AutoCaptions 사용하기: <link>더 알아보기</link>',
        podcast: 'AutoCut Podcast 사용하기: <link>더 알아보기</link>',
        zoom: 'AutoZoom 사용하기: <link>더 알아보기</link>',
        repeat: 'AutoCut Repeat 사용하기: <link>더 알아보기</link>',
        broll: 'AutoB-Roll 사용하기: <link>더 알아보기</link>',
        swear: {
          word: 'AutoProfanity Filter 사용하기: <link>더 알아보기</link>',
        },
        preset:
          'AutoCaptions, AutoZoom 또는 AutoResize를 위한 새 프리셋 만들기',
        testimonial:
          '사용 후기를 남겨주세요(동일한 이메일 사용): <link>추천글</link>',
      },
    },
    shop: {
      now: '지금 쇼핑하기',
    },
    back: {
      to_autocut: 'AutoCut으로 돌아가기',
    },
    'discover-option-youtube-video': '유튜브 영상',
    'discover-option-youtube-comment': '유튜브 댓글',
    'discover-option-reddit': '레딧',
    'discover-option-google': '구글',
    'discover-option-facebook': '페이스북',
    'discover-option-friend': '친구',
    'discover-option-school': '학교',
    'discover-option-other': '기타',
    link: {
      not: {
        supported: {
          modal: {
            __text__:
              '이 오류를 이해하고 해결 방법을 알아보려면 다음으로 이동하세요:',
            no_solution: '이 오류를 더 잘 이해하려면 다음으로 이동하세요:',
            end: ' 문서 및 튜토리얼 사이트로',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: '방금 이메일({email})로 메일을 받았습니다.',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            '이메일을 보지 못한다면 스팸 또는 프로모션 폴더를 확인하세요.',
        },
      },
    },
    placeholder: {
      use: {
        licence: '라이센스 키 입력',
      },
    },
    "can't": {
      reach: {
        server: {
          error: '서버에 연결할 수 없습니다. 나중에 다시 시도해 보세요.',
        },
      },
      read: {
        uuid: {
          error: '컴퓨터의 UUID를 읽을 수 없습니다.',
        },
        host: {
          error: '컴퓨터의 이름을 읽을 수 없습니다.',
        },
      },
    },
    asking: {
      key: {
        error:
          '서버에서 키를 요청하는 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.\n',
      },
    },
    sending: {
      log: {
        file: {
          error: '일일 로그를 보낼 수 없습니다. 나중에 다시 시도해 주세요.\n',
        },
      },
    },
    is_key: {
      valid: {
        error:
          '키를 확인하는 중 오류가 발생했습니다. 나중에 다시 시도해 주세요.\n',
      },
    },
    base: {
      error: {
        message: '오류가 발생했습니다 ',
      },
    },
    invalid: {
      response: {
        error: '응답이 서버에서 오지 않았습니다.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: '아직 개발 중이므로 버그가 있거나 제안 사항이 있으면 contact@autocut.fr로 연락주세요.',
        },
      },
    },
    header: {
      trial: {
        button: '더 보기',
      },
      paid: {
        button: '관리하기',
      },
      speakers: {
        name: '연사의 이름',
        track: '연사의 오디오 트랙',
      },
      cameras: {
        name: {
          __text__: '카메라의 비디오 트랙',
          helper:
            '카메라가 있는 비디오 트랙을 선택하세요, 카메라마다 트랙을 하나만 선택할 수 있습니다.',
        },
        speakers: {
          __text__: '카메라에 보이는 연사(들)',
          helper:
            '이 카메라에 보이는 연사들을 선택하세요, 연사의 수에 제한이 없습니다.',
        },
      },
    },
    game: {
      saved: {
        hours: '{hourSavedText} 시간을 절약했습니다,',
      },
      have: {
        a_break: '쉬세요. 당신은 그것을 획득했습니다!',
      },
    },
    generate: {
      preview: '미리보기 생성하기',
    },
    onboarding: {
      skip: '온보딩 건너뛰기',
      close: '닫기',
      previous: '이전',
      next: '다음 ({current}/{max})',
      onboarding: {
        step1: {
          title: 'AutoCut에 오신 것을 환영합니다!',
          body: '첫 사용 시 다양한 AutoCut 기능에 대한 단계별 소개가 진행됩니다. 이를 통해 프로세스를 가이드 하므로, 함께 시작해 봅시다!',
        },
        step2: {
          title: '무료 시험 키.',
          body: 'AutoCut 기능을 직접 테스트하고 싶다면, 언제든지 무료 시험 키를 요청할 수 있습니다. 최대 14일 동안 한 번 시험 키를 요청할 수 있음을 유의하세요.',
        },
        step3: {
          title: '라이선스 키.',
          body: '이미 시험 또는 유료 라이센스 키를 보유하고 있으면, 직접 사용하여 AutoCut 도구에 접근할 수 있습니다.',
        },
        step4: {
          title: '라이선스 키 구매.',
          body: '라이선스 키를 구매하고 싶다면, 제공된 버튼을 클릭하여 AutoCut 웹사이트로 리디렉션되어 AutoCut을 구매하기 위한 모든 정보를 찾을 수 있습니다.',
        },
      },
      modeSelection: {
        step1: {
          title: 'AutoCut 모드.',
          body: 'AutoCut은 다양한 편집 요구 사항에 맞춰 여러 가지 모드를 제공합니다. 간단히 모드 중 하나를 클릭하여 필요한 모드를 선택할 수 있습니다.',
        },
        step2: {
          title: '도움이 필요하신가요?',
          body: '인터페이스의 모든 요소에는 해당 기능에 대한 추가 정보를 제공하는 툴팁이 제공됩니다. 특정 요소에 대해 더 알아보려면 툴팁 위로 마우스 포인터를 올려놓으십시오.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2.',
          body: 'AutoCut V2 기능은 오디오 파일의 소음 수준에 기반하여 침묵을 정밀하게 감지합니다. 하지만, 사용하기 전에 설정이 필요합니다.',
        },
        step2: {
          title: '소음 수준.',
          body: '슬라이더를 사용하여 침묵으로 감지될 최소 소음 수준을 조정합니다.',
        },
        step3: {
          title: '다음은 무엇이 있을가요?',
          body: '화살표를 클릭하여 AutoCut 기능에 대해 자세히 알아보세요.',
        },
        step4: {
          title: '미리보기 생성 중.',
          body: "타임라인의 오디오는 처리되기 전에 내보내야 합니다. '미리보기 생성하기'를 클릭하면 오디오를 사전에 내보낼 수 있고, 현재 설정된 매개변수로 결과를 시각화할 수 있습니다.",
        },
        step5: {
          title: '오디오 미리보기.',
          body: '탐색 버튼을 사용하면 이전 또는 다음 10초로 이동하거나 DaVinci Resolve 커서와 함께 미리보기를 중앙에 맞출 수 있습니다.',
        },
        step6: {
          title: '자르기 시간.',
          body: '모든 것을 검토하고 괜찮다고 생각되면, 자르기 시간입니다!',
        },
        step7: {
          title: '자르기 시간.',
          body: '선호하는 절삭 옵션을 선택하려면 화살표를 클릭하세요.',
        },
        step8: {
          title: '자르기 시간.',
          body: '버튼을 클릭하면 AutoCut이 나머지를 처리하니 편안히 쉬세요!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut 팟캐스트.',
          body: 'AutoCut 팟캐스트는 영상 팟캐스트를 빠르게 편집할 수 있는 강력한 기능입니다.',
        },
        step2: {
          title: '대화자.',
          body: '"대화자 추가하기"를 클릭하여 새로운 대화자를 생성할 수 있습니다. 팟캐스트에 말하는 각 사람마다 하나의 대화자를 추가해야 합니다.',
        },
        step3: {
          title: '대화자.',
          body: '대화자를 생성한 후에는 필요에 맞게 설정할 수 있습니다.',
        },
        step4: {
          title: '대화자 이름.',
          body: '이 섹션에서는 대화자에게 할당할 이름을 지정할 수 있으며, 이 이름은 대화자를 식별하기 위해 시각적으로만 나타납니다.',
        },
        step5: {
          title: '영상 트랙 선택.',
          body: '드롭다운 메뉴를 사용하여 대화자가 나타나는 모든 영상 트랙을 선택할 수 있습니다.',
        },
        step6: {
          title: '오디오 트랙 선택.',
          body: '드롭다운 메뉴를 사용하여 대화자의 오디오 트랙을 선택할 수 있습니다. 각 대화자는 하나의 오디오 트랙만 가질 수 있습니다.',
        },
        step7: {
          title: '편집 시간.',
          body: '모든 대화자를 정의하고 위치를 설정하면 바로 시작할 준비가 됩니다!',
        },
        step8: {
          title: '편집 시간.',
          body: '화살표를 사용하여 선호하는 편집 옵션을 선택하세요.',
        },
        step9: {
          title: '편집 시간.',
          body: '버튼을 클릭하고 커피 한 잔을 즐기며 AutoCut이 나머지 작업을 수행하도록 하세요.',
        },
      },
      title: 'AutoCut에 오신 것을 환영합니다',
      subtitle: '귀하의 최고의 프리미어 프로 확장 프로그램',
    },
    start: {
      transcription: '전사 시작',
    },
    no_more: {
      credits: '이용 가능한 크레딧이 더 이상 없습니다',
    },
    file: {
      too: {
        long: '오디오 파일이 너무 깁니다',
      },
      sent: '파일 업로드 완료, 전사를 기다리는 중...',
    },
    please: {
      wait1: '오디오 클립이 전사되는 동안 기다려 주세요.',
      wait2: '이 프로세스는 파일 길이에 따라 몇 분 정도 걸릴 수 있습니다.',
      wait3: '파일 업로드 중: ',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: '오디오 언어',
            helper: '오디오클립 언어 선택',
          },
        },
        french: {
          __text__: '프랑스어',
          beta: '프랑스어 (베타)',
        },
        danish: '덴마크어',
        dutch: '네덜란드어',
        english: {
          __text__: '영어',
          united: {
            states: '영어 - 미국',
          },
        },
        flemish: '플라망어',
        german: {
          __text__: '독일어',
          beta: '독일어 (베타)',
        },
        hindi: {
          __text__: '힌디어',
          beta: '힌디어 (베타)',
        },
        italian: '이탈리아어',
        japanese: '일본어',
        korean: '한국어',
        norwegian: {
          __text__: '노르웨이어',
          nynorsk: '노르웨이어 니노르스크',
        },
        polish: '폴란드어',
        portuguese: {
          __text__: '포르투갈어',
          beta: '포르투갈어 (베타)',
          brazil: {
            __text__: '포르투갈어 - 브라질',
            beta: '포르투갈어 - 브라질 (베타)',
          },
        },
        spanish: {
          __text__: '스페인어',
          beta: '스페인어 (베타)',
          latin: {
            america: {
              __text__: '스페인어 - 라틴 아메리카',
              beta: '스페인어 - 라틴 아메리카 (베타)',
            },
          },
        },
        swedish: '스웨덴어',
        tamil: '타밀어',
        chinese: '중국어',
        russian: '러시아어',
        turkish: '터키어',
        catalan: '카탈로니아어',
        arabic: {
          __text__: '아랍어',
          algeria: '아랍어 - 알제리',
          bahrain: '아랍어 - 바레인',
          egypt: '아랍어 - 이집트',
          iraq: '아랍어 - 이라크',
          israel: '아랍어 - 이스라엘',
          jordan: '아랍어 - 요르단',
          kuwait: '아랍어 - 쿠웨이트',
          lebanon: '아랍어 - 레바논',
          mauritania: '아랍어 - 모리타니',
          morocco: '아랍어 - 모로코',
          oman: '아랍어 - 오만',
          qatar: '아랍어 - 카타르',
          saudi: {
            arabia: '아랍어 - 사우디 아라비아',
          },
          palestine: '아랍어 - 팔레스타인',
          syria: '아랍어 - 시리아',
          tunisia: '아랍어 - 튀니지',
          united: {
            arab: {
              emirates: '아랍어 - 아랍 에미리트 연합',
            },
          },
          yemen: '아랍어 - 예멘',
        },
        indonesian: '인도네시아어',
        finnish: '핀란드어',
        vietnamese: '베트남어',
        hebrew: '히브리어',
        ukrainian: '우크라이나어',
        greek: '그리스어',
        malay: '말레이어',
        czech: '체코어',
        romanian: '루마니아어',
        hungarian: '헝가리어',
        thai: '태국어',
        urdu: '우르두어',
        croatian: '크로아티아어',
        bulgarian: '불가리아어',
        lithuanian: '리투아니아어',
        latin: '라틴어',
        maori: '마오리어',
        malayalam: '말라얄람어',
        welsh: '웨일스어',
        slovak: '슬로바키아어',
        telugu: '텔루구어',
        persian: '페르시아어',
        latvian: '라트비아어',
        bengali: '벵골어',
        serbian: '세르비아어',
        azerbaijani: '아제르바이잔어',
        slovenian: '슬로베니아어',
        kannada: '칸나다어',
        estonian: '에스토니아어',
        macedonian: '마케도니아어',
        breton: '브르타뉴어',
        basque: '바스크어',
        icelandic: '아이슬란드어',
        armenian: '아르메니아어',
        nepali: '네팔어',
        mongolian: '몽골어',
        bosnian: '보스니아어',
        kazakh: '카자흐어',
        albanian: '알바니아어',
        swahili: '스와힐리어',
        galician: '갈리시아어',
        marathi: '마라티어',
        punjabi: '펀자브어',
        sinhala: '신할라어',
        khmer: '크메르어',
        shona: '쇼나어',
        yoruba: '요루바어',
        somali: '소말리어',
        afrikaans: '아프리칸스어',
        occitan: '오크어',
        georgian: '조지아어',
        belarusian: '벨라루스어',
        tajik: '타지크어',
        sindhi: '신디어',
        gujarati: '구자라트어',
        amharic: '암하라어',
        yiddish: '이디시어',
        lao: '라오어',
        uzbek: '우즈베크어',
        faroese: '페로어',
        haitian: {
          creole: '아이티 크리올어',
        },
        pashto: '파슈토어',
        turkmen: '투르크멘어',
        maltese: '몰타어',
        sanskrit: '산스크리트어',
        luxembourgish: '룩셈부르크어',
        burmese: '버마어',
        tibetan: '티베트어',
        tagalog: '타갈로그어',
        malagasy: '마다가스카르어',
        assamese: '아샘어',
        tatar: '타타르어',
        hawaiian: '하와이어',
        lingala: '링갈라어',
        hausa: '하우사어',
        bashkir: '바슈키르어',
        javanese: '자바어',
        sundanese: '순다어',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            '시퀀스에 클립이 많아보입니다! 로딩에 시간이 걸릴 수 있습니다. 모든 클립을 자를 필요가 없다면, 필요한 클립만 선택하여 별도의 빈 시퀀스에서 자르십시오.',
        },
      },
    },
    homepage: {
      title: '홈페이지',
      comming: {
        soon: '곧 올 예정입니다!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: '다이내믹함',
        normal: '보통',
        high: '높음',
        extreme: '극단적',
        custom: '사용자 설정:',
      },
      button: {
        __text__: '줌',
        message: {
          step1: 'Autozoom 준비 중...',
          step2: '오디오 데이터 추출 중...',
          step3: '줌 간격 계산 중...',
          step4: '줌 적용 중 {current} / {max}...',
        },
      },
      success: '성공! 선택한 부분이 확대되었습니다',
      info: {
        text: 'AutoZoom은 각 클립마다 최대 볼륨을 기반으로 자동 줌을 추가합니다.',
      },
      invalid: {
        selection: {
          screen: {
            title:
              '타임라인에서 적어도 하나의 오디오 클립과 영상 클립을 선택하세요',
            text1:
              'AutoZoom을 사용하려면 타임라인에서 영상를 구성하는 모든 클립을 선택하세요.',
          },
        },
      },
      anchor: {
        title: '앵커 포인트 정의',
        helper:
          '앵커 포인트는 줌이 적용될 위치입니다. 색상 박스를 사용하여 최대 및 최소 줌 효과를 시각화할 수 있습니다.',
      },
      dual: {
        range: {
          title: '최소 및 최대 줌 계수 적용',
          helper:
            '줌 계수는 아래 제공된 값과 부클립의 최대 볼륨에 기반하여 계산됩니다.',
        },
      },
      preview: {
        on_indicator: '지시기에 미리 보기',
        error: {
          message: '미리 보기를 로드하는 동안 오류가 발생했습니다',
          button: '재시도',
        },
      },
      min: {
        zoom: {
          title: '최소 줌 지속 시간',
          helper: '각 줌은 적어도 이 시간 동안 지속됩니다.',
        },
      },
      max: {
        zoom: {
          title: '최대 줌 지속 시간',
          helper: '각 줌은 최대 이 시간 동안 지속됩니다.',
        },
      },
      constraint: {
        zoom: {
          title: '클립에 제한된 줌',
          helper:
            '예의 경우, 줌이 둘 이상의 클립에 걸쳐 확대되지 않습니다. 아니요의 경우, 선택한 전체가 하나의 오디오 파일로 처리되며 줌은 타임라인에서 개별 클립을 무시합니다.',
        },
      },
      zoom: {
        types: {
          __text__: '줌 유형',
          helper:
            '사용할 줌 유형을 결정합니다. 부드러운: 시간이 지남에 따라 점진적인 줌. 동적: 즉각적이고 날카로운 줌.',
        },
        coverage: {
          __text__: '총 줌 커버리지',
          helper: '줌 될 비디오의 비율',
        },
      },
      smooth: {
        __text__: '부드러운',
        zoom: {
          __text__: '부드러운 줌',
          coverage: '부드러운 줌 커버리지',
          nervousness: {
            __text__: '긴장감',
            helper:
              '낮은 긴장감은 느리고 약한 줌을 의미합니다. 높은 긴장감은 빠르고 강한 줌을 의미합니다.',
          },
        },
      },
      dynamic: {
        __text__: '동적',
        zoom: {
          __text__: '동적 줌',
          coverage: '동적 줌 커버리지',
        },
      },
    },
    zoom: {
      preset: {
        title: '기본 프리셋',
        calm: '차분함',
        paced: '적당한 속도',
        energetic: '에너지 넘치는',
        hyperactive: '과잉 활동적인',
      },
    },
    repeat: {
      info: {
        banner:
          '이제 어떤 문장을 잘라낼지 말지 수정할 수 있습니다.{br}흐릿하게 표시된 문장은 반복으로 간주됩니다.{br}가위는 문장의 상태(반복 여부)를 정의하는 데 사용할 수 있습니다. 문장의 일부를 하이라이트하여 정확히 어떤 단어들이 잘려질지 목표로 할 수도 있습니다',
      },
      text: {
        step: {
          edit: {
            transcript: '전사 수정',
          },
        },
      },
      toggle: {
        cut: {
          button: {
            text: '자르기/자르지 않기',
          },
        },
      },
      play: {
        button: {
          text: '재생',
        },
      },
      invalid: {
        selection: {
          screen: {
            title: '타임라인에서 클립을 선택하세요',
            text1:
              'AutoCut 반복을 사용하려면 타임라인에서 오디오 클립과 선택적으로 영상 클립을 선택하십시오.',
          },
        },
      },
      select: {
        algorithm: '알고리즘 선택',
      },
      precise: {
        algorithm: {
          info: '정확한 알고리즘은 우리의 가장 최근이고 효율적인 것이지만, 영상 6분당 처리 시간이 약 1분이 필요합니다.',
        },
      },
      fast: {
        algorithm: '빠른',
      },
    },
    captions: {
      step: {
        customization: '자막 매개변수',
        script: '클립과 언어 선택',
        transcription: '전사 편집',
      },
      script: {
        transcript: {
          part: '전사',
        },
        sequence: {
          part: '시퀀스',
          name: '이름',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} 클립 | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: '자막 추가',
        coming: {
          soon: '전사 편집기가 준비 중입니다!{br}현재는 Essential Graphics 패널에서 전사를 가져온 후 수정할 수 있습니다.{br}타임라인에서 자막을 선택하고 패널의 수정 탭에서 편집하세요.',
        },
        transcription: {
          part: '전사 편집',
        },
        info: '자막 편집기에 오신 것을 환영합니다. 자막을 추가한 후 DaVinci Resolve 타임라인에서도 편집할 수 있습니다.',
        add: {
          emojis: {
            cta: '이모지 생성',
          },
        },
      },
      text: {
        noClip: {
          title: '타임라인에서 자막을 적용할 클립을 선택하세요',
          no_selection:
            'AutoCut이 응답하지 않으면 DaVinci Resolve를 다시 시작해보세요.',
        },
      },
      process: {
        transcript: {
          button: '처리',
        },
      },
      customization: {
        info: 'AutoCaption은 현재 베타 버전입니다. 첫 번째 안정 버전은 11월 10일에 제공될 예정입니다',
        back: {
          button: '전사 삭제하고 돌아가기',
        },
        example: {
          __text__:
            '미리 보기 <opacity50><i>캡션 또는 이모티콘을 드래그하여 위치를 변경할 수 있습니다 </i></opacity50>',
          text: 'AutoCaptions를 사용하여 자막을 추가하세요. 이것은 AutoCaptions의 미리 보기로, AutoCaptions를 쉽게 사용자 정의할 수 있습니다. 그런 다음 전사를 시작하고 필요한 경우 편집할 수 있으며, AutoCaptions가 자동으로 자막을 타임라인에 추가합니다.',
        },
        animation: {
          __text__: '애니메이션',
          helper: '텍스트 애니메이션 관리',
          color: {
            __text__: '<p>색상</p> 텍스트',
            text: {
              color: '색상',
            },
            before: {
              highlight: {
                color: '하이라이트 전 색상',
              },
            },
            after: {
              highlight: {
                color: '하이라이트 후 색상',
              },
            },
          },
          advanced: {
            color: {
              options: '고급 색상 옵션',
            },
          },
          word: {
            box: '<color>강조표시</color>가 있는 텍스트',
          },
          bold: {
            with: {
              text: '<b>굵게</b> 텍스트',
            },
          },
          floating: {
            text: '플로팅 텍스트',
          },
        },
        outline: {
          __text__: '윤곽선',
          helper: '텍스트 윤곽선 스타일 관리',
          color: '색상',
          width: '너비',
        },
        font: {
          __text__: '글꼴',
          helper: '텍스트 스타일 관리',
          font: '글꼴 이름',
          help: '새 글꼴 패밀리를 추가하는 방법은 무엇인가요?',
          size: '글꼴 크기',
          weight: '글꼴 두께',
          color: '색상',
        },
        formating: {
          __text__: '텍스트 포맷팅',
          helper: '텍스트 포맷팅 관리',
          ponctuation: '구두점 제거',
          uppercase: '대문자',
          bold: '굵게',
          italic: '기울임꼴',
          glow: {
            __text__: '글로우',
            color: '색상',
            intensity: '강도',
          },
        },
        transform: {
          __text__: '변환',
          x: 'x',
          y: 'y',
          helper: '자막 위치 및 회전 관리',
          position: {
            __text__: '수직 위치',
            emoji: '이모티콘 수직 위치',
            info: '미리 보기에서 텍스트를 직접 이동할 수도 있습니다',
          },
          rotation: '회전',
        },
        multiple: {
          lines: '여러 줄',
        },
        box: {
          __text__: '박스',
          helper: '박스 스타일 관리',
        },
        boxes: {
          padding: {
            x: '수평 패딩',
            y: '수직 패딩',
          },
          opacity: '박스 투명도',
          radius: '반지름',
          color: '박스 색상',
        },
        emojis: {
          __text__: '이모티콘',
          helper: 'AI로 이모티콘 생성',
          size: '이모티콘 크기',
        },
        shadow: '그림자',
        transcript: '전사',
        action: '다음 단계',
        select: {
          chunk: '편집/사용자 정의할 전사 부분을 선택하세요',
        },
        delete: {
          caption: {
            modal: {
              title: '이 자막을 삭제하시겠습니까?',
              cancel: '아니요 - 유지',
              confirm: '예 - 삭제',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                '프리셋 수를 가져오는 중 오류가 발생했습니다. Discord 또는 contact@autocut.fr로 문의해주세요.',
            },
            error:
              '프리셋을 가져오는 중 오류가 발생했습니다. Discord 또는 contact@autocut.fr로 문의해주세요.',
          },
          public: {
            presets: {
              error:
                '공개 프리셋을 가져오는 중 오류가 발생했습니다. 문의 사항은 discord 또는 contact@autocut.fr로 연락 주시기 바랍니다.',
            },
          },
        },
        presets: {
          title: '프리셋',
          save: {
            new: {
              preset: '현재 매개 변수 저장',
            },
          },
          view: {
            more: '더 보기',
            less: '덜 보기',
          },
        },
        save: {
          preset: {
            info: {
              text: '매개 변수를 변경했습니다. 아래의 프리셋으로 저장하여 무한히 재사용할 수 있습니다!',
            },
          },
        },
      },
      editor: {
        title: '자막 편집기',
        description: {
          title: '편집할 자막을 선택하세요',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: '의심되는 단어',
            },
          },
          merge: {
            tooltip: '덩어리 병합',
          },
          split: {
            tooltip: '덩어리 분할',
          },
        },
        sound: {
          play: '소리 재생 활성화',
        },
      },
      presetCustomization: {
        createPresetModal: {
          title: {
            length: {
              error: '프리셋 이름은 최소 2단어를 포함해야 합니다.',
            },
          },
          error:
            '프리셋 생성 중 오류가 발생했습니다. Discord 또는 contact@autocut.fr로 문의해주세요.',
          placeholder: '프리셋 이름 (최소 2단어)',
          create: '프리셋 생성',
          isPublic: '커뮤니티에 공유 - 공개',
        },
        searchPresetModal: {
          title: '커뮤니티 프리셋 검색',
        },
        error: {
          preset: {
            already: {
              exists: '이 프리셋은 이미 존재합니다. 다른 이름을 선택하십시오.',
            },
          },
        },
        copyPreset: {
          error:
            '프리셋을 복사하는 중 오류가 발생했습니다. 문의 사항은 discord 또는 contact@autocut.fr로 연락 주시기 바랍니다.',
        },
        no_preset: {
          selected: '프리셋을 선택하여 미리보기를 확인하십시오',
        },
        no_preview: {
          available: '미리보기 없음',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: '폰트를 찾을 수 없음',
          message:
            '<i><b>{fontLabel}</b></i> 폰트를 시스템에서 찾을 수 없습니다. 기본 폰트가 사용됩니다.',
          helper: '새로운 폰트 패밀리를 추가하려면 이 튜토리얼을 따르세요.',
        },
      },
    },
    version: {
      not: {
        supported: '지원되지 않는 프리미어 프로 버전',
      },
    },
    transcript: {
      usage: {
        unit: '분',
      },
      upgrade: {
        button: '{mode}을(를) 계속하려면 AI로 업그레이드하세요',
      },
      too: {
        short: {
          error:
            '죄송합니다. 귀하의 트랜스크립트가 너무 짧거나 사용하는 언어가 제대로 지원되지 않을 수 있습니다. 문제가 지속되면 contact@autocut.fr 로 문의하십시오.',
        },
      },
    },
    feature: {
      request: {
        __text__: '기능 요청',
        form: {
          placeholder:
            '기능 요청을 입력하세요. 요청을 더 쉽게 검토할 수 있도록 요청 내용을 자세히 설명해 주세요.',
        },
        screenshot:
          '기능 요청을 더 잘 이해할 수 있도록 스크린샷/동영상을 첨부할 수 있습니다:',
        button: '기능 요청 보내기',
        success: {
          title: '기능 요청이 성공적으로 전송되었습니다! 🥳',
          body: 'AutoCut의 성장에 도움을 주셔서 감사합니다! 요청한 내용은 가장 세심하게 검토할 것입니다.',
        },
        error: {
          title: '이런, 문제가 발생했네요... 🤕',
          body: '불편을 드려 죄송합니다. 나중에 다시 시도하거나 contact@autocut.fr로 문의해 주세요.',
        },
      },
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: '타임라인에 들어가기 및 나가기 지점을 추가하세요',
            text1:
              '자동 크기 조정 기능을 사용하려면 들어가기 및 나가기 지점을 추가해야 합니다.',
            cta: '전체 시퀀스에 인/아웃 포인트 설정',
          },
        },
      },
      text: {
        info: '자동 크기 조정은 모든 소셜 미디어에 가장 적합한 크기로 시퀀스의 크기를 조정하고 자동으로 재구성합니다.',
      },
      in_out: {
        step: {
          title: '들어가기 / 나가기 지점',
          helper:
            '타임라인에서 들어가기 및 나가기 지점을 확인하세요. 아래 값과 동일해야 합니다.',
        },
      },
      preset: {
        step: {
          title: '프리셋',
          helper:
            '시퀀스의 크기를 조정하기 위해 프리셋을 선택하세요. 사용자 고유의 프리셋을 만들 수도 있습니다. 새 시퀀스의 치수는 아래에 표시됩니다.',
        },
        undefined: '프리셋을 선택하세요.',
      },
      watermark: {
        step: {
          title: '워터마크',
          helper:
            '시퀀스에 워터마크를 추가할 수 있습니다. 워터마크는 시퀀스의 오른쪽 하단 모서리에 추가됩니다.',
          text: '워터마크 업로드하기',
        },
      },
      button: '소셜 클립 생성하기',
      reframe: {
        undefined: "'주제 팔로우' 속도를 선택하세요.",
        step: {
          title: '주제 따라가기',
          helper: '카메라가 주제를 따라가는 속도를 선택할 수 있습니다.',
          slower: '느린',
          default: '보통',
          faster: '빠른',
        },
      },
      backup: '시퀀스 백업 중...',
      create: {
        new: {
          sequence: '새로운 시퀀스 생성 중...',
        },
      },
      resizing: {
        sequence: '새로운 시퀀스 크기 조정 중...',
      },
      adding: {
        watermark: '워터마크 추가 중...',
      },
      cleaning: {
        sequence: '시퀀스 정리 중...',
      },
    },
    endprocess: {
      modal: {
        title: '처리 완료!',
        text: {
          '1': '처리가 성공적으로 완료되었습니다!',
          '2': '귀하의 피드백은 매우 중요합니다 – 우리가 어떻게 했는지, 그리고 어떻게 해야 귀하의 경험을 더욱 향상시킬 수 있는지 알려주세요!',
        },
        next: {
          mode: {
            silence: {
              text: "원활한 오디오 경험을 준비하셨나요? 자동으로 불편한 침묵을 없애주는 'AutoCut Silences' 사용으로 콘텐츠를 손쉽게 편집하세요.",
              button: 'AutoCut Silences로 이동하기',
            },
            podcast: {
              text: 'AutoCut Podcast로 팟캐스트 제작을 한 단계 높이세요. 체계적인 편집과 자동 카메라 전환을 즐길 수 있습니다.',
              button: 'AutoCut Podcast로 이동하기',
            },
            repeat: {
              text: "관객의 관심을 끌어라! 'AutoCut Repeat'를 사용해서 텍스트 편집기로 비디오를 편집하고 콘텐츠의 가장 매력적인 부분만 남겨보세요.",
              button: 'AutoCut Repeat로 이동하기',
            },
            zoom: {
              text: 'AutoZoom으로 비디오에 생명을 불어넣으세요! 관객의 주목을 끌고 유지하는 흐르는 줌 효과를 추가하세요.',
              button: 'AutoZoom으로 이동하기',
            },
            caption: {
              text: 'AutoCaptions을 사용하여 관객과 더 잘 연결하세요. 비디오의 스토리텔링을 강화하는 스타일리시하고 동기화된 자막을 통합합니다.',
              button: 'AutoCaptions으로 이동하기',
            },
            resize: {
              text: 'AutoResize로 크로스-플랫폼 공유를 마스터하세요. 주제에 집중을 잃지 않으면서 비디오 형식을 완벽하게 조정합니다.',
              button: 'AutoResize로 이동하기',
            },
            broll: {
              text: 'AI가 관련 스톡 영상을 끊임없이 포함시켜 주는 AutoB-Roll로 시각적 서사를 강화하세요.',
              button: 'AutoB-Roll로 이동하기',
            },
            swear: {
              word: {
                text: 'AutoProfanity Filter로 깔끔한 대화를 유지하세요. 원치 않는 언어를 자동으로 가려서 콘텐츠의 전문성을 유지합니다.',
                button: 'AutoProfanity Filter로 이동하기',
              },
            },
          },
        },
      },
      captions: {
        modal: {
          text: {
            '1': '자막이 추가되었습니다! 🎉 {br} 자막을 수정해야 하는 경우:',
            '2': '필요한 경우 <a>자세한 튜토리얼</a>이 있습니다.',
          },
          bullet: {
            point: {
              '1': '서브 시퀀스를 두 번 클릭하세요: "Double click to edit"',
              '2': '편집하려는 자막을 선택하세요',
              '3': '필수 그래픽을 열고 "편집" 탭을 클릭하세요',
            },
          },
        },
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: '베이스 B롤로 사용할 타임라인 클립을 선택하세요',
            text1:
              '자동 B롤 기능을 사용하려면 타임라인에서 오디오가 있는 최소 하나의 클립을 선택해야 합니다.',
          },
        },
      },
      text: {
        info: 'AI 덕분에 자동 B롤 기능은 당신의 오디오를 분석하고 {br} @Pexels B롤을 자동으로 추가하여 비디오의 내용과 관련된 내용을 보여줍니다.',
      },
      duration: {
        step: {
          title: 'B롤 지속 시간',
          minimum: {
            title: '최소 B롤 지속 시간',
            helper: '각 B롤은 적어도 이 길이만큼 지속됩니다.',
          },
          maximum: {
            title: '최대 B롤 지속 시간',
            helper: '각 B롤은 이 길이보다 길지 않게 지속됩니다.',
          },
        },
      },
      choice: {
        step: {
          title: 'B-롤 선택',
        },
      },
      no_broll: {
        screen: {
          title: '전사 처리가 완료되었지만, 발견된 내용이 없습니다.',
          text1:
            '자동 B-롤을 사용하려면 타임라인에 최소한 하나의 음성 클립을 선택해야 합니다.',
          text2:
            '이미 선택한 경우, 전사의 언어를 확인하고 오디오 트랙이 음소거되지 않았는지 확인하세요.',
        },
      },
      button: {
        __text__: 'B롤 추가',
        next: '다음',
      },
      exporting: '선택한 오디오 클립 내보내는 중...',
      analyse: '오디오 분석 중...',
      getting: {
        broll: 'B롤 가져오는 중... (몇 분 정도 소요될 수 있습니다)',
      },
      transcript: {
        finished: '전사 생성 완료',
      },
      step: {
        parameters: 'B-롤 매개변수',
        language: '클립 및 언어 선택',
        brolls: 'B-롤 선택 편집',
      },
      adding: {
        broll: 'B롤 추가 중...',
      },
      restore: '시퀀스 복원 중...',
      backup: '시퀀스 백업 중...',
      credit: {
        modal: {
          title: '귀하의 비디오에 사용된 B-롤의 크레딧은 다음과 같습니다 : ',
          provided: {
            by: 'Pexels 제공',
          },
          link: '링크',
          button: '클립보드에 복사',
        },
      },
      search: {
        modal: {
          title: 'B-롤 선택',
          input: {
            placeholder: '입력하여 B-롤 검색',
          },
          searching: 'B-롤 검색 중...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: '편집하려면 두 번 클릭하세요',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: '욕설을 차단하고 싶은 타임라인의 클립을 선택하세요',
              text1:
                'AutoProfanity Filter를 사용하려면 타임라인에서 오디오가 포함된 최소 하나의 클립을 선택해야 합니다.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter는 AI를 통해 오디오를 분석하고 욕설이 들어간 부분에 자동으로 {br} 차단음을 추가합니다.',
        },
        mute: '음소거',
        button: '욕설 제거',
        audio: {
          export: '오디오 내보내는 중...',
          analyse: '오디오 분석 중...',
          insert: '차단음 삽입 중...',
          filter: '볼륨 필터 적용 중...',
        },
        script: {
          transcript: {
            part: '전사',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: {
            helper: '차단음 효과 선택',
          },
        },
      },
    },
    create: {
      format: {
        modal: {
          title: '새로운 형식 만들기',
          button: {
            __text__: '생성',
            disabled: '이미 사용 중인 이름',
          },
          name: '이름',
          width: '너비',
          height: '높이',
        },
      },
    },
    process: {
      modal: {
        warning:
          '문제가 발생할 수 있으므로 DaVinci Resolve에서 아무 작업도 하지 마세요.',
        generating: {
          emojis: '대본으로부터 이모지 생성 중...',
        },
        formatting: {
          numbers: '전사에서 숫자 형식 지정 중...',
        },
      },
    },
    backup: {
      modal: {
        title: 'AutoCut 백업',
        text: {
          '1': 'AutoCut 작업을 실행하기 전에 백업이 생성됩니다. 이 백업 파일들은 "AutoCut-Backup" 폴더에서 찾을 수 있습니다.',
          '2': '원하는 대로 사용하실 수 있습니다.',
        },
      },
    },
    confirm: {
      modal: {
        title: '확인하시겠습니까?',
        confirm: {
          label: '확인',
        },
        cancel: {
          label: '취소',
        },
      },
    },
    login: {
      resend: {
        email: '이메일을 받지 못하셨나요? {trialEmail}(으)로 이메일 재발송',
      },
    },
    typeofcreation: {
      option: {
        interview: '인터뷰',
        podcast: '팟캐스트',
        youtube: '유튜브 비디오',
        short: 'TikTok/Shorts/Reels',
        internal: '내부 비디오',
        other: '기타',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: '콘텐츠 제작자',
        },
        video: {
          editor: {
            professional: '전문 비디오 편집자',
            semi: {
              professional: '준전문 비디오 편집자',
            },
            beginner: '초보 비디오 편집자',
          },
        },
        student: '학생',
        other: '기타',
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: '연사의 이름을 입력하세요…',
        },
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: '자막 생성 중...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal:
          '정말 멋진 작업이었습니다! 과정을 단 {minutes} 분과 {seconds} 초 만에 완료했습니다.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: '절약된 시간: <b>{minutesSaved} 분</b>과 <b>{secondsSaved} 초</b>.',
        },
        all: {
          cuts: 'AutoCut으로 절약한 전체 시간: 무려 <b>{hourSaved} 시간</b>과 <b>{minutesSaved} 분</b>입니다! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          '특정 오디오 트랙을 침묵 감지에서 제외하려면 비활성화하세요. 이는 배경 음악이나 게임 소리를 유지하는 데 이상적입니다. 특정 클립의 침묵을 변경하지 않으려면 해당 클립을 선택하지 않으면 됩니다.',
        video: '선택된 비디오 트랙:',
        audio: '선택된 오디오 트랙:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: '무료 체험 한도 초과',
            cta: '구독하기',
            text: 'AI 기능을 1000분 이상 사용했습니다. {br} AutoCut AI 기능을 계속 사용하려면 AI 플랜에 가입하십시오.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: '전사 제한 도달',
          text: 'AI 기능을 6000분 이상 사용했습니다. {br} 상황을 해제하려면 저희에게 연락해 주세요.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          '유효한 전환 유형을 선택해주세요 (<a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a> 필드 참조)',
        ai_silences:
          '유효한 묵음 처리를 선택해주세요 (<a><b><embedTranslation>title_silences_step</embedTranslation></b></a> 필드 참조)',
        broll: {
          min: {
            max: {
              duration:
                '최대 B-Roll 시간은 최소 B-Roll 시간보다 작을 수 없습니다. (<a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a> 필드 참조)',
            },
          },
          minimumBRollTime:
            '최소 B-Roll 시간은 양수여야 합니다. (<a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a> 필드 참조)',
          maximumBRollTime:
            '최대 B-Roll 시간은 양수여야 합니다. (<a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a> 필드 참조)',
        },
        caption: {
          position:
            '자막은 미리보기 안에 있어야 합니다. (<a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a> 필드 참조)',
          emoji: {
            position:
              '이모지는 미리보기 안에 있어야 합니다. (<a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a> 필드 참조)',
          },
          text: {
            fontSize:
              '폰트 크기는 양수여야 합니다. (<a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a> 필드 참조)',
          },
          outline: {
            width:
              '외곽선 너비는 양수여야 합니다. (<a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a> 필드 참조)',
          },
          transition: {
            effects:
              '유효한 자막 전환 효과를 선택해주세요 (자막 사용자 정의 전환 필드 참조)',
          },
          emojis: {
            size: '이모지 크기는 양수여야 합니다 (자막 사용자 정의 폰트 크기 필드 참조)',
          },
          formating: {
            nbLines:
              '자막 서식을 위해 최소한 한 줄을 선택해주세요 (자막 사용자 정의 서식 줄 수 필드 참조)',
          },
          title: {
            too: {
              short: '제목은 최소 5자 이상이어야 합니다',
              long: '제목은 최대 25자 이하여야 합니다',
            },
            no_space:
              '제목에는 공백으로 구분된 두 개 이상의 단어가 포함되어야 합니다',
          },
        },
        font: {
          fontFamily:
            '폰트 패밀리가 필요합니다 (자막 사용자 정의 폰트 패밀리 필드 참조)',
          label: '폰트를 선택해주세요 (자막 사용자 정의 폰트 패밀리 필드 참조)',
        },
        languageOfTranscription:
          '전사 언어를 선택해주세요 (언어 선택 단계 제목 도움말 필드 참조)',
        cameras: {
          speakerIds:
            '각 카메라에는 최소한 한 명의 스피커가 필요합니다 (카메라 스피커 필드 참조)',
          label:
            '카메라 중 하나가 트랙이 누락되었습니다 (카메라 이름 필드 참조)',
        },
        podcast: {
          minimum: {
            cam: {
              time: '최소 카메라 시간은 양수여야 합니다 (팟캐스트 최소 필드 참조)',
            },
          },
          maximum: {
            cam: {
              time: '최대 카메라 시간은 양수여야 합니다 (팟캐스트 최대 필드 참조)',
            },
          },
          deleteUnusedClips:
            '삭제 또는 음소거 중 하나를 선택해주세요 (묵음 제거 단계 제목 필드 참조)',
          speakers: {
            cameras:
              '각 스피커에는 최소한 한 대의 카메라가 필요합니다 (카메라 이름 필드 참조)',
            audio:
              '각 스피커에는 하나의 오디오 트랙이 필요합니다 (스피커 트랙 필드 참조)',
          },
          cameras: {
            __text__:
              '카메라는 동일한 트랙을 가질 수 없습니다 (카메라 이름 필드 참조)',
            length:
              '최소 2개의 다른 카메라를 설정해야 합니다 (<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a> 필드 참조)',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              '자동 리프레임 프리셋이 필요합니다 (리사이즈 리프레임 단계 제목 필드 참조)',
            invalid:
              '자동 리프레임 프리셋은 slower, default, 또는 faster여야 합니다 (리사이즈 리프레임 단계 제목 필드 참조)',
          },
          watermark:
            '워터마크는 유효한 이미지 파일 형식이어야 합니다: JPEG, PNG, 또는 WebP (리사이즈 워터마크 단계 제목 필드 참조)',
          preset: {
            name: '프리셋의 이름을 입력해주세요',
            width: '너비는 1px보다 커야 합니다',
            height: '높이는 1px보다 커야 합니다',
          },
        },
        rgb: 'RGB 값은 0과 255 사이여야 합니다',
        silence: {
          noiseLevel: {
            type: '노이즈 레벨은 숫자여야 합니다 (노이즈 단계 필드 참조)',
            nonpositive:
              '노이즈 레벨은 양수가 아니어야 합니다 (노이즈 단계 필드 참조)',
            integer: '노이즈 레벨은 정수여야 합니다 (노이즈 단계 필드 참조)',
          },
          minTimeOfSilence: {
            type: '최소 묵음 시간은 숫자여야 합니다 (묵음 단계 필드 참조)',
          },
          maxTimeOfSilence: {
            type: '최대 묵음 시간은 숫자여야 합니다 (대화 단계 필드 참조)',
          },
          marginBefore: {
            type: '앞쪽 여백은 숫자여야 합니다 (앞쪽 여백 필드 참조)',
          },
          marginAfter: {
            type: '뒤쪽 여백은 숫자여야 합니다 (뒤쪽 여백 필드 참조)',
          },
          transitions:
            '유효한 전환 유형을 선택해주세요 (전환 단계 제목 필드 참조)',
          silences:
            '유효한 묵음 처리를 선택해주세요 (묵음 단계 제목 필드 참조)',
        },
        swearWord: {
          bleepFile:
            '욕설 사운드 효과를 선택해주세요 (욕설 선택 단계 제목 도움말 필드 참조)',
        },
        textBox: {
          xPadding:
            '수평 여백은 양수여야 합니다 (자막 사용자 정의 상자 여백 X 필드 참조)',
          yPadding:
            '수직 여백은 양수여야 합니다 (자막 사용자 정의 상자 여백 Y 필드 참조)',
          opacity: {
            min: '불투명도는 0%보다 커야 합니다 (자막 사용자 정의 상자 불투명도 필드 참조)',
            max: '불투명도는 100%보다 작아야 합니다 (자막 사용자 정의 상자 불투명도 필드 참조)',
          },
        },
        anchor:
          '앵커 포인트는 미리보기 내에 있어야 합니다 (자동 확대 앵커 제목 필드 참조)',
        zoom: {
          autoZoomMinCoef: {
            min: '최소 확대 계수는 1보다 커야 합니다 (자동 확대 최대 확대 필드 참조)',
            max: '최소 확대 계수는 2보다 작아야 합니다 (자동 확대 최대 확대 필드 참조)',
          },
          autoZoomMaxCoef: {
            min: '최대 확대 계수는 1보다 커야 합니다 (자동 확대 최대 확대 필드 참조)',
            max: '최대 확대 계수는 2보다 작아야 합니다 (자동 확대 최대 확대 필드 참조)',
          },
          minZoomTime: '최소 확대 지속 시간은 0초보다 커야 합니다',
          maxZoomTime: '최대 확대 지속 시간은 0초보다 커야 합니다',
          dynamicZoomPercentage: {
            min: '확대 백분율은 0%보다 커야 합니다',
            max: '확대 백분율은 100%보다 작아야 합니다',
          },
          coef: '최대 확대 계수는 최소 확대 계수보다 작을 수 없습니다 (자동 확대 최대 확대 필드 참조)',
          time: '최대 확대 지속 시간은 최소 확대 지속 시간보다 작을 수 없습니다',
          dynamicMinZoomIntensity:
            '동적 확대 강도는 0%에서 100% 사이여야 합니다 (자동 확대 동적 확대 범위 필드 참조)',
          smoothZoomPercentage:
            '부드러운 확대 백분율은 0%에서 100% 사이여야 합니다 (자동 확대 부드러운 확대 범위 필드 참조)',
          nervousness:
            '긴장도는 0%에서 100% 사이여야 합니다 (자동 확대 부드러운 확대 긴장도 필드 참조)',
          totalZoomPercentage:
            '전체 확대 범위는 0%보다 크고 100%까지여야 합니다 (자동 확대 확대 범위 필드 참조)',
          types: {
            at_least: {
              one: {
                true: '최소한 하나의 확대 유형을 선택해주세요 (자동 확대 확대 유형 필드 참조)',
              },
            },
          },
        },
        repeat: {
          algorithm: '선택된 알고리즘이 유효하지 않습니다',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: '결제 부족',
          button: {
            recover: '구독 복구',
          },
          to_continue:
            '결제 문제로 인해 현재 구독이 <b>{status}</b>되었습니다. AutoCut을 계속 사용하려면 다음을 수행하십시오:',
          deactivated: '비활성화됨',
          deleted: '삭제됨',
          recover: {
            list: {
              click:
                '<b>아래 버튼을 클릭하여</b> 구독을 복구하거나 저희 또는 Stripe으로부터의 이메일 송장에 있는 이메일에서 최신 송장 납부를 위한 이메일을 확인하세요',
              re_activated:
                '결제 정보가 업데이트된 후에 바로 <b>다시 활성화됩니다</b>',
            },
          },
          new: {
            list: {
              website:
                'AutoCut 웹사이트 방문: <span>https://www.autocut.fr/#pricing</span>',
              subscribe: '새 라이선스 키에 가입하기',
            },
          },
          support:
            '도움이 필요하시면 저희 지원팀에 문의하세요: <span>contact@autocut.fr</span>',
          thanks: '감사합니다!',
        },
        moda: {
          button: {
            new: '새 구독 받기',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'AutoCut 모험을 시작하세요',
          header: {
            days: {
              left: 'AutoCut의 14일 무료 체험으로 창작 여정을 시작하세요, 여러분은 {completedElements} 일을 더 얻었고 탐험할 수 있는 <b>{daysLeft} 일</b>이 남았습니다!',
            },
            more: {
              days: '하지만 여기서 멈출 필요 없죠? <b>추가 {bonusDays} 일</b>을 해금하고 AutoCut의 힘을 완전히 발휘해 보세요.',
            },
          },
          timeline: {
            left: '14일 무료 체험',
            right: '24일 무료 체험',
          },
          task: {
            list: {
              title: '작업 완료: {completed}/{maximum}',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'AutoCut를 다시로드해야 합니다',
          cta: 'AutoCut 다시로드',
          text: 'AutoCut을 마지막으로 열고 지난 30분이 지났습니다. {br} 최적의 성능을 위해 지금 AutoCut을 다시로드하는 것을 권장합니다.{br}AutoCut을 다시로드하려면 아래 버튼을 클릭하세요.',
        },
      },
    },
    'key-already-activated':
      '이 키는 이미 다른 컴퓨터에서 활성화되었습니다. 다시 시도하기 전에 AutoCut 대시 보드 (https://www.autocut.fr/kr/) 에서 키를 분리하십시오.',
    'key-does-not-exist':
      '이 키는 존재하지 않습니다. 키를 확인하고 다시 시도하십시오.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: '지원되지 않는 프리미어 프로 버전',
            '24.4.0':
              'DaVinci Resolve를 24.3으로 다운그레이드하거나 24.5로 업그레이드해 주세요',
          },
          cta: '이해했습니다',
          text: {
            __text__:
              '현재 지원되지 않는 프리미어 프로 버전({version})을 사용하고 계십니다. 이 버전으로는 AutoCut을 완전히 사용할 수 <b>없을 것입니다</b>.{br}{br}AutoCut을 최상으로 경험하시려면 <b>최신 안정 버전</b>을 사용해 주세요.',
            '24.4.0':
              '현재 지원되지 않는 버전의 DaVinci Resolve({version})를 사용 중입니다. 이 버전에서 중요한 변경사항이 있었습니다. AutoCut를 사용하려면 버전을 24.3으로 다운그레이드하거나 24.5로 업그레이드해 주세요.',
          },
        },
      },
      rendering: {
        engine: {
          title: '지원되지 않는 렌더링 엔진',
          modal: {
            text: '현재 지원되지 않는 렌더링 엔진을 사용하고 있습니다. AutoCaptions 또는 AutoZoom을 사용할 <b>수 없습니다</b>.{br}{br}프로젝트 설정에서 GPU 가속 렌더링 엔진을 선택하십시오.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: '하드 리셋이 필요합니다',
          cta: '모든 확장 프로그램 닫기',
          text: '여러 개의 AutoCut 인스턴스가 실행되고 있습니다. 모두 닫고 문제를 피하려면 아래 버튼을 클릭하세요.',
        },
      },
    },
    disabled: {
      features: {
        info: '다음 기능을 지원하지 않는 언어/글꼴 조합을 선택했습니다: <b>{features}</b> 이러한 기능은 가져온 자막에서 비활성화됩니다.',
        tips: '선택한 글꼴이 지원하는 문자와 관련이 있을 수 있습니다. 이러한 기능을 활성화하려면 글꼴이나 언어를 변경하십시오.',
      },
    },
    upgrade: {
      to: '{plan}로 업그레이드',
    },
    deepgram: {
      error: {
        modal: {
          title: '번역 오류가 발생했습니다',
          cta: 'Discord 가입하기',
          text: '오디오 번역 중 오류가 발생했습니다. 오디오 품질이나 사용 언어와 관련이 있을 수 있지만, 현재는 더 이상의 정보가 없습니다. {br} 문제를 Discord에서 보고하거나 contact@autocut.fr로 이메일을 보내주십시오.',
        },
      },
    },
    completing: {
      preset: {
        preview:
          '프리셋 미리보기를 완료 중입니다... 몇 초 정도 걸릴 수 있습니다',
      },
    },
    chapters: {
      preview: {
        error:
          '미리보기 생성 중 오류가 발생했습니다. 타임 스탬프를 업데이트하거나 문제가 지속되면 AutoCut을 다시 시작해보세요.',
      },
      step: {
        language: '클립과 언어 선택',
        editor: '챕터 편집기',
      },
      button: {
        chapters: '챕터 추가',
      },
      editor: {
        chapters: {
          title: '검출된 챕터',
        },
        parameters: {
          title: '챕터 매개변수',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'YouTube 챕터 파일 다운로드',
                success: 'chapter.txt가 PPRO 프로젝트에 저장되었습니다',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: '챕터를 기준으로 할 타임라인의 클립을 선택하세요',
            text1:
              '챕터를 사용하려면 타임라인에서 오디오가 포함된 적어도 하나의 클립을 선택해야 합니다.',
          },
        },
      },
      process: {
        transcript: {
          button: '챕터 생성',
        },
      },
      adding: {
        chapters: '챕터 추가 중...',
      },
      backup: '시퀀스를 백업 중...',
      analyse: '오디오를 분석 중...',
      getting: {
        chapters: '챕터 생성 중...',
      },
      transcript: {
        finished: '챕터가 생성되었습니다',
      },
      disabled: {
        language: '선택한 언어는 아직 AutoChapters에서 지원되지 않습니다',
        file: {
          too: {
            long: '선택한 클립이 너무 깁니다. {limit}분 이내의 클립을 선택하십시오.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: '잘못된 시간 형식입니다. HH:MM:SS 형식을 사용해주세요.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: '애니메이션',
        },
        static: {
          option: '정적인',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                '자동 바이럴 클립 감지기를 기반으로 할 타임라인 상의 클립을 선택하세요',
              text1:
                '자동 바이럴 클립 감지기를 사용하려면 타임라인에서 적어도 하나의 오디오 클립을 선택해야 합니다.',
            },
          },
        },
        disabled: {
          language: '이 언어로는 자동 바이럴 클립 감지기를 사용할 수 없습니다',
          file: {
            too: {
              long: '선택한 클립이 너무 깁니다. {limit}분 이하의 클립을 선택하세요.',
            },
          },
        },
        process: {
          transcript: {
            button: '바이럴 클립 생성',
          },
        },
        button: {
          process: '클립 생성',
        },
        selector: {
          title: '{number}개의 잠재적인 바이럴 클립이 감지되었습니다',
        },
        virality: {
          score: '바이럴 잠재력 점수',
        },
        step: {
          language: '클립 및 언어 선택',
          selector: '바이럴 클립 선택',
        },
        backup: '시퀀스를 백업하는 중...',
        analyse: '오디오를 분석하는 중...',
        getting: {
          clips: '바이럴 클립 생성 중...',
        },
        transcript: {
          finished: '바이럴 클립이 생성되었습니다',
        },
        adding: {
          creating: {
            clips: '클립 생성 중...',
          },
        },
      },
      score: '바이럴 점수',
    },
    mode: {
      description: {
        silence: '타임라인에서 공백을 제거합니다',
        ai: '타임라인에서 공백을 제거합니다',
        podcast: '팟캐스트를 즉시 편집합니다',
        repeat: 'AI로 나쁜 테이크를 제거합니다',
        zoom: '자동으로 줌을 추가합니다',
        captions: 'AI로 애니메이션 캡션을 추가합니다',
        resize: 'AI로 시퀀스 크기를 조정합니다',
        broll: 'AI로 B-Roll을 자동으로 추가합니다',
        profanity: '욕설을 음소거합니다',
        chapter: '챕터를 감지하고 AI로 표시를 추가합니다',
        viral: '한 번의 클릭으로 긴 비디오에서 바이럴 쇼츠를 식별하세요',
      },
    },
    formatted: {
      numbers: {
        title: '숫자가 형식화되었습니다',
        modal: {
          cta: '모달 닫기',
          text: '전사에서 <b>{number} 단어</b>가 숫자로 대체되었습니다.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: '전사 모델 선택',
          model: {
            '1': 'AI 전사 (기본값)',
            '2': 'AI 전사 (고급)',
          },
        },
      },
    },
  },
} as const;

export default translations;
