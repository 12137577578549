const translations = {
  progress: {
    footer: {
      warning: 'Por favor, no haga nada en DaVinci, causará algunos problemas',
    },
    steps: {
      global: {
        starting: 'Iniciando...',
        processing: 'Procesando...',
        ending: 'Terminando...',
      },
      dev: {
        ping: 'Enviando ping a DaVinci Resolve...',
        pong: 'Enviando pong a DaVinci Resolve...',
      },
      common: {
        getSilences: 'Obteniendo silencios...',
        getAudioInfos: 'Obteniendo información de audio...',
        readAudioFile: 'Leyendo archivo de audio...',
        computeRMS: 'Analizando archivo de audio...',
        convertRMSToDB: 'Analizando archivo de audio...',
        exportDrt: 'Leyendo línea de tiempo...',
        extractDrt: 'Leyendo línea de tiempo...',
        getXmlTimelineFolderPath: 'Leyendo línea de tiempo...',
        getXmlTimelineFullpath: 'Leyendo línea de tiempo...',
        loadXmlTimeline: 'Leyendo línea de tiempo...',
        writeFile: 'Escribiendo archivo...',
        writeXml: 'Importando línea de tiempo modificada...',
        updateDrt: 'Importando línea de tiempo modificada...',
        importXml: 'Importando línea de tiempo modificada...',
      },
      podcast: {
        mainProcess: 'Editando podcast...',
        getVideoTracksWeight: 'Grabación de hablantes',
        getAudioTracks: 'Grabación de pistas de audio',
        getPodcastInterval: 'Calculando la duración del podcast',
        getPodcastDuration: 'Calculando la duración del podcast',
        renders:
          'Renderizando pistas de audio por Da Vinci\n(puede tardar unos minutos)',
        getTalkingTimelines: 'Distribución de tiempos de habla',
        getTransitions: 'Calculando cambios de cámara',
        getCutTimecodes: 'Preparando los cortes',
        cutAtTimecodes: 'Cortes de la línea de tiempo',
      },
      silences: {
        mainProcess: 'Editando silencios...',
        getTimelineInfos: 'Obteniendo información de línea de tiempo...',
        renders:
          'Renderizando pistas de audio por Da Vinci\n(puede tardar unos minutos)',
        convertAudioToMono: 'Procesando archivo de audio...',
        getCutTimecodes: 'Preparando los cortes...',
        cutAtTimecodes: 'Cortes de la línea de tiempo...',
        deleteSilentTrackItems: 'Eliminando silencios...',
      },
      zoom: {
        mainProcess: 'Agregando acercamientos...',
        getTimelineInfos: 'Obteniendo información de línea de tiempo...',
        getWavData:
          'Renderizando pistas de audio por Da Vinci\n(puede tardar unos minutos)',
        computeBestZoomIntervals: 'Computando mejores intervalos de zoom...',
        createVideoTrack: 'Creando pistas de video...',
        createZoomFusionComps: 'Importando composiciones de Fusion...',
      },
      captions: {
        transcript: {
          mainProcess: 'Generando transcripción...',
          get_language: 'Obteniendo idioma',
          render_wav:
            'Renderizando WAVs en DaVinci (puede tardar varios minutos)',
          split_chunks: 'Dividiendo fragmentos',
          uploadFile: 'Subiendo archivo...',
          getTranscript:
            'Generación de la transcripción por IA\n(puede tardar unos minutos)',
          writeFile: 'Escribiendo archivo...',
        },
        generation: {
          mainProcess: 'Agregando subtítulos...',
          initialization: 'Inicializando subtítulos...',
          readTranscript: 'Leyendo transcripción...',
          getTimelineInfos: 'Obteniendo información de línea de tiempo...',
          appendAdjustmentLayer: 'Añadiendo capa de ajuste...',
          importFusionComp: 'Importando composiciones de Fusion...',
        },
      },
    },
  },
  launchManager: {
    steps: {
      preload: {
        title: 'Precargar',
      },
      python: {
        title: 'Instalar Python',
      },
      ffmpeg: {
        title: 'Instalar FFMPEG',
      },
      resources: {
        title: 'Instalar recursos',
      },
      computeIPC: {
        title: 'Conectar a la IA',
      },
      daVinciIPC: {
        title: 'Conectar a DaVinci Resolve',
      },
    },
    errors: {
      compute:
        'No se puede conectar a la IA de cálculo, por favor contacta al soporte',
      davinci:
        'No se puede conectar a DaVinci, asegúrate de que DaVinci Resolve esté ejecutándose y que el script AutoCut haya sido ejecutado',
      cta: 'Reintentar',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Prueba',
        upgrade: 'Actualizar',
        coming_soon: 'Próximamente',
        version_not_supported: 'Versión PPro no soportada',
        upgrade_to: 'Actualizar a {plan}',
      },
    },
  },
  modes: {
    podcast: {
      title: 'Auto Podcast',
      steps: {
        select: {
          title:
            'Selecciona una línea de tiempo con al menos 2 pistas de video y audio',
          label: 'Seleccionar línea de tiempo',
          descriptions: {
            '1': 'Ve a la pestaña de edición',
            '2': 'Navega a la línea de tiempo que deseas editar',
          },
          cta: 'Seleccionar línea de tiempo activa',
        },
        customization: {
          label: 'Parámetros del podcast',
          steps: {
            speakers: {
              title: 'Ponentes',
              description:
                'Agrega a tus ponentes asignando a cada uno una pista de audio e ingresando sus respectivos nombres. Solo se puede seleccionar una pista de audio por ponente. Si varios ponentes comparten una sola pista de audio, ingresa ambos nombres (p. ej., "Alicia & Bob").',
              header: {
                audio: 'Pista de audio',
                name: 'Nombre del ponente',
              },
              textInputPlaceholder: 'Ingresa el nombre del ponente...',
              trackTemplate: 'Pista A{index}',
              cta: 'Agregar un ponente',
            },
            cameras: {
              title: 'Cámaras',
              description:
                'Agrega tus cámaras asignando a cada una una pista de video y seleccionando qué ponentes son visibles en pantalla. Solo se puede seleccionar una pista de video por cámara. Puedes seleccionar cualquier número de ponentes por cámara.',
              header: {
                audio: 'Pista de video',
                name: 'Ponente(s)',
              },
              speakerSelectPlaceholder: 'Seleccionar ponente(s)...',
              trackTemplate: 'Pista V{index}',
              cta: 'Agregar una cámara',
            },
            cameraTime: {
              title: 'Duraciones de tiempo de la cámara',
              description:
                'Define la duración mínima y máxima que se mostrará una cámara antes de cambiar.',
              presets: {
                title: 'Preajustes predeterminados',
                items: {
                  calm: 'Calmado',
                  paced: 'Ritmo moderado',
                  energetic: 'Energético',
                  hyperactive: 'Hiperactivo',
                },
              },
              minimumCamTime: {
                title: 'Tiempo mínimo de cámara',
                description:
                  'Duración mínima (en segundos) que una cámara se mostrará sin cambiar.',
                unit: 'segundos',
              },
              maximumCamTime: {
                title: 'Tiempo máximo de cámara',
                description:
                  'Duración máxima (en segundos) que una cámara se mostrará antes de ser forzada a cambiar.',
                unit: 'segundos',
              },
            },
            silentVideoClips: {
              title: 'Clips de video silenciosos',
              description:
                'Elige si deseas eliminar o desactivar las partes no utilizadas del podcast.',
              items: {
                remove: 'Eliminar',
                disable: 'Desactivar',
              },
            },
          },
          cta: {
            disable: 'Editar y desactivar clips no utilizados',
            delete: 'Editar y eliminar clips no utilizados',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: 'El tiempo mínimo de cámara debe ser al menos 0',
            higherThanMax:
              'El tiempo mínimo de cámara debe ser menor que el tiempo máximo de cámara',
          },
          maximumCamTime: {
            min: 'El tiempo máximo de cámara debe ser al menos 0',
          },
          deleteUnusedClips: {
            required:
              'Debe elegir si eliminar o deshabilitar clips no utilizados',
          },
          speakers: {
            videoTracks: {
              min: 'Debe seleccionar al menos una pista de vídeo por hablante',
            },
            audioTrack: {
              min: 'Debe seleccionar una pista de audio para cada hablante',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      steps: {
        noiseLevel: {
          title: 'Nivel de ruido',
          description:
            'Nivel de sonido considerado como silencio. Puede ver los efectos en la visualización a continuación.',
        },
        silenceSize: {
          title: 'Duración del silencio',
          items: {
            silence: {
              title: 'Eliminar silencios mayores que',
              description:
                'Duración mínima (en milisegundos) que un silencio debe tener para ser eliminado.',
              unit: 'milisegundos',
            },
            talk: {
              title: 'Eliminar charlas más cortas que',
              description:
                'Duración mínima (en milisegundos) que un segmento de habla debe tener para ser mantenido.',
              unit: 'milisegundos',
            },
          },
        },
        paddingSize: {
          title: 'Relleno',
          items: {
            before: {
              title: 'Relleno antes',
              description:
                'Tiempo de silencio antes de un ruido - para evitar una reanudación repentina del habla',
              unit: 'milisegundos',
            },
            after: {
              title: 'Relleno después',
              description:
                'Tiempo de silencio después de un ruido - para evitar interrupciones bruscas del habla',
              unit: 'milisegundos',
            },
          },
        },
        transition: {
          params: {
            title: 'Transiciones',
            warning: 'No puedes usar transiciones sin eliminar silencios.',
            default: {
              title: 'Por defecto',
              choices: {
                none: 'Ninguno',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'Esto añadirá relleno después y/o antes de cada corte. Creando una transición más suave entre los cortes. Si se selecciona, creará una nueva pista para las transiciones.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Ambos',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Potencia Constante',
              },
            },
          },
          progress: {
            insert: 'Insertando nuevas pistas de audio…',
            move: 'Moviendo audio a nuevas pistas para transiciones…',
            handling: 'Agregando transiciones: {transition}',
          },
        },
        preview: {
          title: 'Vista previa',
          description:
            'Vista previa de segmentos preservados y eliminados. Use las flechas para avanzar o retroceder, o centre la vista previa según la posición del cursor de tiempo.',
          legend: {
            kept: 'Mantenido',
            deleted: 'Eliminado',
            margin: 'Márgenes',
            cta: 'Generar vista previa',
            center: 'Centrar en el indicador',
            legend: {
              kept: 'Mantenido',
              deleted: 'Eliminado',
              margin: 'Márgenes',
            },
          },
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'El nivel de ruido debe ser un número negativo',
            int: 'El nivel de ruido debe ser un número',
          },
          minTimeOfSilence: {
            required: 'La duración mínima de silencio es requerida',
            min: 'La duración mínima de silencio debe ser al menos 0',
          },
          minTimeOfTalk: {
            required: 'La duración mínima de habla es requerida',
            min: 'La duración mínima de habla debe ser al menos 0',
          },
          marginBefore: {
            min: 'El margen antes debe ser al menos 0',
          },
          marginAfter: {
            min: 'El margen después debe ser al menos 0',
          },
        },
      },
    },
    zoom: {
      parameters: {
        preview: {
          title: 'Vista previa de Zoom',
          description: 'Definir el punto de anclaje',
          maximumZoom: 'Zoom máximo',
          errorMessage: 'Ocurrió un error al cargar la vista previa',
        },
        zoomTypes: 'Tipos de Zoom',
        defaultsPresets: {
          title: 'Configuraciones predeterminadas',
          calm: 'Calma',
          paced: 'Ritmo',
          energetic: 'Enérgico',
          hyperactive: 'Hiperactivo',
        },
        zoomCoverage: 'Cobertura total de zoom',
        constraint: {
          title: 'Limitar zooms a clip',
          helper:
            'Si sí, ningún zoom abarcará más de un clip. Si no, toda tu selección será procesada como un solo archivo de audio y los zooms ignorarán los clips individuales en la línea de tiempo.',
        },
        maximumZoomDuration: 'Duración máxima del zoom',
        smoothZooms: {
          title: 'Zooms suaves',
          coverage: 'Cobertura de zooms suaves',
          nervousness: 'Nerviosismo',
        },
        dynamicZooms: {
          title: 'Zooms dinámicos',
          coverage: 'Cobertura de zooms dinámicos',
        },
      },
      types: {
        smooth: 'Suave',
        dynamic: 'Dinámico',
      },
      footerButton: 'Zoom',
      infoText:
        'AutoZoom añadirá automáticamente zoom a cada clip seleccionado basado en decibelios.',
    },
    captions: {
      steps: {
        language: {
          button: 'Ir al editor de subtítulos',
        },
        customization: {
          button: 'Agregar subtítulos',
          parts: {
            style: {
              title: 'Estilo de subtítulos',
              modal: {
                title: 'Editar estilo',
                apply: 'Aplicar estilo',
                save: 'Aplicar estilo y guardar como preajuste',
                text: {
                  title: 'Fuente',
                  font: 'Nombre de la fuente',
                  help: '¿Cómo agregar una nueva familia de fuentes?',
                  color: 'Color',
                },
                animation: {
                  title: 'Animación',
                  wordBox: 'Caja con texto',
                },
                outline: {
                  title: 'Contorno',
                  width: 'Grosor',
                },
                box: {
                  title: 'Caja de texto',
                  paddingX: 'Relleno X',
                  paddingY: 'Relleno Y',
                  opacity: 'Opacidad',
                  radius: 'Radio',
                  disabled:
                    'La fuente que ha elegido no admite la función de caja para el idioma que ha seleccionado.',
                  tips: 'Elija una fuente que admita el idioma que ha seleccionado si desea utilizar esta función.',
                },
              },
            },
            preview: {
              title: 'Vista previa',
              modal: {
                title: 'Posición y tamaño',
                apply: 'Aplicar estilo',
                fontSize: 'Tamaño de fuente',
                nbLines: 'Número de líneas',
                maxWidth: 'Ancho máximo',
                help: 'La vista previa todavía está en desarrollo y puede no ser precisa.',
              },
            },
            transcription: {
              title: 'Editar transcripción',
              modal: {
                save: 'Guardar',
                select: 'Seleccionar subtítulos que desea editar',
                placeholder:
                  'Seleccione la parte de la transcripción que desea editar/personalizar',
                formatNumbers: 'Formatear números',
                checkTranscript: {
                  title: '¿Ha revisado la transcripción?',
                  description:
                    'Hemos notado que aún no ha revisado la transcripción.',
                  dontShowAgain: 'No mostrar este mensaje de nuevo',
                },
              },
            },
            presets: {
              delete: {
                title: 'Eliminar preajustes',
                cancel: 'Cancelar',
                confirm: 'Eliminar preajuste',
              },
              search: {
                title: 'Preajustes de la comunidad',
                input: 'Buscar preajuste',
                cancel: 'Cancelar',
                confirm: 'Importar preajuste',
                usage: 'Usado por {count} usuarios',
                error:
                  'Error al copiar el preajuste, contáctenos en discord o a contact@autocut.fr',
              },
              create: {
                title: 'Crear nuevo preajuste',
                placeholder: 'Nombre del preajuste (al menos dos palabras)',
                public: 'Compartir con la comunidad - público',
                cancel: 'Cancelar',
                confirm: 'Crear preajuste',
                alreadyExists:
                  'Este preajuste ya existe. Por favor, elija otro nombre.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'Faltan los ajustes preestablecidos del usuario, por favor restablece tus configuraciones en el menú del encabezado.',
          },
          position: {
            xPercentage: {
              min: 'La posición x del texto debe ser al menos 0',
              max: 'La posición x del texto debe ser como máximo 1',
            },
            yPercentage: {
              min: 'La posición y del texto debe ser al menos 0',
              max: 'La posición y del texto debe ser como máximo 1',
            },
            emojiXPercentage: {
              min: 'La posición x del emoji debe ser al menos 0',
              max: 'La posición x del emoji debe ser como máximo 1',
            },
            emojiYPercentage: {
              min: 'La posición y del emoji debe ser al menos 0',
              max: 'La posición y del emoji debe ser como máximo 1',
            },
          },
          text: {
            fontSize: {
              int: 'El tamaño de la fuente debe ser un número',
              min: 'El tamaño de la fuente debe ser al menos 1',
            },
            font: {
              missing:
                'Falta algo en la fuente que has seleccionado... Por favor, selecciona de nuevo o elige otra.',
            },
          },
          outline: {
            width: {
              int: 'El ancho del contorno debe ser un número',
              min: 'El ancho del contorno debe ser al menos 0',
            },
          },
          formating: {
            nbLines: {
              int: 'El número de líneas debe ser un número',
              min: 'El número de líneas debe ser al menos 1',
            },
            maxWidth: {
              int: 'El ancho máximo debe ser un número',
              min: 'El ancho máximo debe ser al menos 0',
            },
          },
          textBox: {
            xPadding: {
              int: 'El relleno x debe ser un número',
              min: 'El relleno x debe ser al menos 0',
            },
            yPadding: {
              int: 'El relleno y debe ser un número',
              min: 'El relleno y debe ser al menos 0',
            },
            opacity: {
              int: 'La opacidad debe ser un número',
              min: 'La opacidad debe ser al menos 0',
              max: 'La opacidad debe ser como máximo 100',
            },
            radius: {
              int: 'El radio debe ser un número',
              min: 'El radio debe ser al menos 0',
            },
          },
        },
      },
    },
    general: {
      steps: {
        select: {
          title: 'Definir puntos de entrada/salida',
          descriptions: {
            '1': 'Por favor, define los puntos de entrada y salida en Davinci sobre la porción de la línea de tiempo que deseas cortar',
            '2': 'Puedes silenciar manualmente clips o pistas si no quieres que se incluyan en el análisis de audio',
          },
          cta: 'Confirmar puntos de entrada/salida',
        },
        transcription: {
          title: 'Seleccione el idioma del clip de audio',
        },
        customization: {
          title: 'Personalizar parámetros',
        },
      },
    },
  },
  auth: {
    login: {
      title: 'Ingresa tu clave de licencia para activar AutoCut.',
      placeholder: 'Ingresa tu clave de licencia',
    },
    trial: {
      title: 'Obtén una prueba gratuita',
      steps: {
        email: {
          title: 'Ingresa tu dirección de correo electrónico',
          subtitle:
            'Ingresa tu dirección de correo electrónico para recibir una licencia de prueba gratuita',
          placeholder: 'correo@correo.com',
          cta: 'Siguiente',
        },
        sendingEmail: {
          title: 'Enviando el correo electrónico',
          success: 'Correo electrónico enviado con éxito a {email}',
        },
      },
    },
    errors: {
      no_key: 'Por favor, introduce una clave de licencia',
      is_key_valid_error:
        'Error al verificar la clave, por favor intenta de nuevo más tarde.\n',
      invalid_response: 'La respuesta no proviene del servidor.',
      cant_read_host_error: 'No se puede leer el nombre de la computadora.',
      cant_read_uuid_error: 'No se puede leer el UUID de la computadora.',
    },
    usageStats: {
      title: 'Estadísticas de uso',
      select: {
        label: 'Seleccionar un modo',
      },
      stats: {
        thisMonth: 'Este mes',
        allTime: 'Todo el tiempo',
        mostUsed: 'Más utilizado',
      },
    },
  },
  modals: {
    endProcess: {
      title: 'Proceso completado',
      time: 'Proceso completado en {minutes} minutos y {seconds} segundos !',
      timeSaved:
        'Has ahorrado: <b>{minutesSaved}</b> minuto(s) y <b>{secondsSaved}</b> segundo(s)',
      timeSavedAllTime:
        'Desde que usas AutoCut, has ahorrado: {hourSaved} hora(s) y {minutesSaved} minuto(s) ! 🎉',
      feedback: 'No dudes en darnos tu opinión sobre la calidad del resultado:',
      review: '¡Califica AutoCut!',
      captions: {
        title: '¡Subtítulos añadidos!',
        footer:
          'Si tu estilo de subtítulos o transcripción no está bien, puedes volver al editor de subtítulos.',
        button: 'Volver al editor de transcripciones',
        text_1: '¡Tus subtítulos animados han sido añadidos! 🎉',
        text_2: 'Si necesitas modificar los subtítulos:',
        list_1:
          'Pon el cursor de la línea de tiempo en los subtítulos que quieras actualizar',
        list_2: 'Ve a la página de fusión',
        list_3:
          'Haz clic en el nodo TextPlus y actualiza manualmente los subtítulos',
        text_3: 'Si quieres más consejos, puedes visitar este <a>tutorial</a>.',
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Versión no compatible de DaVinci Resolve',
      currentVersion:
        'Detectamos que está utilizando DaVinci Resolve {version}',
      text: 'AutoCut solo es compatible con DaVinci Resolve 18.6 y superiores.',
      update: 'Puede actualizarlo aquí: <a>Sitio web de DaVinci</a>',
    },
  },
  globals: {
    defaults: {
      components: {
        KebabMenu: {
          account: 'Gestionar clave de licencia',
          bug: 'Reporte de errores',
          review: 'Revisar AutoCut',
          tuto: 'Tutorial en video',
          logout: 'Cerrar sesión',
          settings: 'Restablecer configuraciones',
          featureRequest: 'Solicitud de característica',
          academy: 'Ayuda',
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} días restantes',
          info: 'Licencia {licenceType}',
          trialButton: 'Obtener más',
          paidButton: 'Gestionar',
        },
        PromoBanner: {
          promo: 'Hasta un 20% de descuento en las próximas 24 horas',
          shop: 'Comprar ahora',
        },
        FileInput: {
          upload_screenshot: 'Sube tu captura de pantalla',
        },
        LinkToUserFileInput: {
          send_log_link_to_user_file: {
            title:
              'Por favor proporciona un enlace a tu archivo de video y audio.',
            description:
              '(WeTransfer, Google Drive o cualquier otro servicio de compartición de archivos)',
            helper:
              "Para subir tu archivo en WeTransfer, ve a wetransfer.com y haz clic en 'Subir archivos'. Después de eso, recibirás un enlace, cópialo aquí.",
            end: 'Esto nos asistirá en resolver tu problema rápidamente.',
          },
        },
        Select: {
          'no-more': 'No hay más opciones disponibles',
          'select-all': 'Seleccionar todo',
          placeholder: 'Selecciona una opción',
        },
        PreviewFrame: {
          previewOnIndicator: 'Vista previa en indicador',
        },
      },
    },
    misc: {
      retry: 'Reintentar',
    },
    units: {
      second: 'segundos',
      millisecond: 'milisegundos',
      percentage: '%',
      px: 'px',
    },
  },
  old: {
    text: {
      errorSelection:
        'Selección no válida.{br}Por favor, seleccione pistas directamente de un archivo de audio o video.{br}Si se selecciona una secuencia, divida los videos dentro de la secuencia.',
      loading: 'Cortando...',
      transition: {
        step: {
          title: 'Transiciones',
          j_l_cut: 'Corte J y Corte L',
          default: 'Por defecto',
          premiere: {
            pro: 'DaVinci Resolve',
          },
          none: 'Ninguno',
          j_cut: 'Corte J',
          l_cut: 'Corte L',
          both: 'Ambos',
        },
        constant: {
          power: 'Potencia constante',
        },
        progress: {
          insert: 'Insertando nuevas pistas de audio…',
          move: 'Moviendo audio a nuevas pistas para transiciones…',
          handling: 'Agregando transiciones: {transition}',
        },
      },
      Step4: {
        AutoZoom: {
          ratio: 'Coeficiente de zoom máximo',
          step: 'Porcentaje de video con zoom',
        },
        Title: 'AutoZoom (Beta)',
      },
      Step5: {
        Backup: 'Habilitar copia de seguridad',
        Title: 'Previsualización',
      },
      autocutv2:
        'AutoCut v2 es la versión experta con muchas opciones configurables para una detección óptima de silencios.',
      autocutAi:
        'AutoCut AI detecta automáticamente los silencios con solo un parámetro, dejando que la IA maneje el resto.',
      autocutPodcast:
        'AutoCut Podcast le permite editar videos de podcast en cuestión de segundos. Simplemente proporcione su configuración de cámara y disfrute de su video final recién editado.',
      autocutRepeat:
        'Siéntese y deje que AutoCut Repeat elimine todas las tomas malas de su video y le deje solo con las mejores.',
      autocutCaptions:
        'Genere subtítulos al estilo de tiktok para sus videos a una velocidad impresionante.',
      Step3: {
        Remove: 'Eliminar',
        Keep: 'Mantener',
        CenterButton: 'Centrar en indicador',
        Previsualisation: {
          __text__: 'Previsualización:',
          loading: 'cargando...',
        },
        Noise: {
          unit: 'decibelios',
        },
        DeleteSilence: 'Eliminar silencios',
        KeepSilence: {
          Yes: 'SÍ',
          No: 'NO',
        },
        Title: 'Ajustar nivel de ruido',
      },
      Step2: {
        After: 'Relleno posterior',
        Before: 'Relleno anterior',
        Title: 'Relleno',
      },
      Step2Ai: {
        Title: 'Verificar previsualización',
      },
      Step1: {
        Talks: 'Eliminar charlas más cortas de',
        Silences: 'Eliminar silencios más largos de',
        Title: 'Duraciones de silencio',
      },
      Step1Ai: {
        Silences: 'Elegir agresividad de la IA',
        Title: 'Agresividad de la IA',
      },
      Step1Podcast: {
        Title: 'Participantes',
      },
      step6: {
        selected: {
          clips: 'Clips seleccionados',
        },
      },
      NoInternet: 'Se requiere conexión a Internet.',
      noClip: {
        title: 'Seleccione clip(s) para cortar en su línea de tiempo',
        no_selection:
          'Si AutoCut no responde, intente reiniciar DaVinci Resolve.',
        tuto: {
          __text__: '¿Necesitas ayuda? No dudes en ver nuestro',
          link: ' tutorial en video',
        },
        loading: {
          bar: 'Cargando - Análisis de secuencia en progreso...',
        },
      },
      needPProUpdate: 'Su versión de DaVinci Resolve debe ser 15 o posterior.',
      No_Key: 'Por favor, ingrese una clave de licencia',
      Login: {
        SubmitButton: 'Activar',
        SubmitButton2: 'Activar',
        PasteButton: 'Pegar',
      },
      Welcome: {
        Title: 'Bienvenido',
        freeTrial: 'Obtenga una prueba gratuita',
        UseLicence: 'Usar una clave de licencia',
        BuyLicence: 'Comprar una clave de licencia',
      },
      UseLicence: {
        Title: 'Usar una clave de licencia',
        Text: 'Ingrese su clave de licencia y comience a usar AutoCut!',
      },
      GetLicence: {
        Title: 'Recibe una licencia de prueba gratuita por correo electrónico',
        Select: '¿Dónde descubriste Autocut?',
        Text: 'Ingrese su dirección de correo electrónico y reciba una licencia de prueba gratuita',
        Button: 'Obtener una licencia gratuita',
      },
      UseNewLicence: {
        Title: '¡Su clave de licencia ha sido enviada a {s}!',
        Text: 'Revisa tu correo electrónico, ingresa tu clave de licencia y ¡comienza a usar AutoCut! Si no recibe la clave en los próximos minutos, por favor revise su correo no deseado.',
        Resend: '¿No recibiste el correo electrónico?',
        ResendButton: 'Reenviar la clave a {mail}',
        ResendSucces: '¡Correo enviado!',
      },
      NoFFMPEG:
        'Ha habido un problema con FFMPEG, por favor contacte al soporte',
      legend: {
        kept: 'Conservado',
        deleted: 'Eliminado',
      },
      yes: 'Sí',
      no: 'No',
      undo: {
        modal: '¿No está satisfecho con los cortes?',
      },
      since: {
        your: {
          last: {
            visit: 'Desde su última visita',
          },
        },
      },
      not: {
        supported: {
          modal: {
            corrupted:
              'Nos disculpamos, pero parece que el archivo {filename} tiene un formato no soportado...',
            speed:
              'Nos disculpamos, pero AutoCut no soporta cortar clips con velocidades alteradas...',
            multi: {
              audio:
                'Nos disculpamos, pero AutoCut puede no funcionar correctamente si todas las pistas de audio en un archivo no están seleccionadas.',
              stream:
                'Nos disculpamos, pero parece que uno de sus archivos seleccionados tiene múltiples flujos de audio...',
            },
            '51_stereo':
              'Nos disculpamos, pero parece que uno de sus archivos seleccionados tiene un diseño de audio 5.1 mostrado en estéreo por DaVinci Resolve...',
            audio: {
              layout:
                'Lo sentimos, pero parece que uno de sus archivos seleccionados tiene un diseño de audio no soportado...',
            },
            repeat: {
              multi: {
                audio:
                  'Lo sentimos, pero Autocut Repeat actualmente no soporta múltiples clips de audio.',
              },
              no_audio:
                'Debe seleccionar un clip de audio para usar Autocut Repeat.',
            },
            remote: {
              file: 'Nos disculpamos, pero parece que no pudimos acceder al archivo {filename}...',
            },
          },
        },
        maintained: {
          modal:
            'Por favor, actualice a una versión más nueva de DaVinci Resolve para usar AutoCut.',
        },
      },
      end: {
        not: {
          supported: {
            modal: {
              corrupted: 'Por favor, seleccione clips diferentes para cortar.',
              speed:
                'Por favor, restablezca las velocidades de los clips (a 100%) o seleccione clips diferentes para cortar.',
              multi: {
                audio:
                  'Asegúrese de que todos los canales de audio estén seleccionados y permanezcan en el orden predeterminado de DaVinci Resolve.',
                stream: 'Por favor, seleccione clips diferentes para cortar.',
              },
              '51_stereo':
                'Por favor, seleccione clips diferentes o cambie el diseño de canal de su secuencia para poder cortar.',
              audio: {
                layout:
                  'Por favor, seleccione otros clips para poder cortarlos, si el problema persiste, por favor envíenos una captura de pantalla de su secuencia.',
              },
              repeat: {
                multi: {
                  audio:
                    'Por favor, seleccione solo un clip de audio para cortar.',
                },
              },
              remote: {
                file: 'Por favor, asegúrese de que los archivos estén disponibles en su computadora y no en la nube.',
              },
            },
          },
        },
      },
      use: {
        paid: {
          licence: 'Ingrese su clave de licencia y comience a usar AutoCut!',
        },
      },
      no_audios:
        'Este audio se utilizará para detectar, cortar y eliminar silencios.',
      step1: {
        podcast: {
          title: 'Duraciones del tiempo de cámara',
          minimum: 'Tiempo mínimo de cámara',
          maximum: 'Tiempo máximo de cámara',
        },
      },
      silences: {
        step: {
          delete: {
            __text__: 'Eliminar {mode}',
            keep: {
              spaces: 'Eliminar {mode} pero mantener espacios',
            },
          },
          keep: 'Mantener {mode}',
          mute: 'Silenciar {mode}',
        },
      },
      repetitions: 'repeticiones',
      podcast: {
        add: {
          speaker: 'Añadir un ponente',
          camera: 'Añadir una cámara',
        },
        track: 'Pista',
        camera: {
          title: 'Cámaras',
          helper:
            'Agregue sus cámaras y especifique qué ponente es visible en cada cámara. Puede seleccionar cualquier número de ponentes por cámara.',
        },
        speaker: 'Ponente',
        all: {
          speakers: 'Todos los oradores',
        },
      },
    },
    help: {
      bug: 'Reporte de errores',
      review: 'Revisar AutoCut',
      multipiste:
        '¿Necesitas ayuda con multitrack? Vea nuestro tutorial en video',
      tuto: 'Tutorial en video',
      academy: 'Ayuda',
      buy: 'Comprar una clave de licencia',
      account: 'Gestionar clave de licencia',
      logout: 'Cerrar sesión',
      reset: {
        settings: 'Restablecer configuraciones',
      },
      transition: {
        step: 'Esto agregará un relleno después y/o antes de cada corte, creando una transición más suave entre los cortes. Si se selecciona, creará una nueva pista para las transiciones.',
      },
      transcription: {
        provider: {
          step: 'La calidad y duración de la transcripción pueden variar según el modelo seleccionado.',
        },
      },
    },
    bugBtnDialog: 'Enviar un correo a: contact@autocut.fr',
    ButtonCutText: 'Cortar',
    ButtonCutAndDeleteText: 'Cortar y eliminar',
    OptionCutText: 'Cortar y mantener silencios',
    OptionCutAndDeleteText: 'Cortar y eliminar silencios',
    first: {
      launch: {
        error:
          'Ocurrió un error durante el lanzamiento de AutoCut. Por favor, reinicie el plugin.',
      },
    },
    helper: {
      Step4: {
        '1': 'Si habilita la opción de AutoZoom, los clips se ampliarán después del corte.',
        '2': 'El coeficiente de zoom se calcula en base a los valores proporcionados a continuación y el volumen máximo del subclip.',
        '3': 'Elija el porcentaje de video para hacer zoom.',
      },
      Step5:
        'La copia de seguridad le permite deshacer todas las modificaciones después de los cortes.',
      Step1: {
        '1': 'Duración mínima (en segundos) que debe tener un silencio para ser eliminado.',
        '2': 'Duración mínima (en segundos) que debe tener un segmento de habla para ser conservado.',
        podcast: {
          minium:
            'Duración mínima (en segundos) que se mostrará una cámara sin cambiar.',
          maximum:
            'Duración máxima (en segundos) que se mostrará una cámara antes de ser forzada a cambiar.',
        },
      },
      Step1Ai:
        'La agresividad determina cuánto silencio eliminará la IA, con un mínimo de 1 y un máximo de 4.',
      Step2: {
        '1': 'Duración del silencio antes del segmento de habla.',
        '2': 'Duración del silencio después del segmento de habla.',
      },
      Step3: {
        '1': 'Nivel de sonido considerado como silencio. Puede ver los efectos en la visualización a continuación.',
        '2': 'Previsualización de segmentos conservados y eliminados. Use las flechas para moverse hacia adelante o hacia atrás, o centre la previsualización basada en la posición del cursor de tiempo.',
        '3': 'Si elige no eliminar los silencios, el clip se cortará, pero los silencios no se eliminarán.',
      },
      Save: {
        Default:
          'Guardar configuraciones. Guarde las configuraciones actuales y se cargarán la próxima vez que inicie.',
      },
      Undo: {
        Cut: 'Deshacer el último corte. Restaurar los clips a su estado antes del último corte.',
      },
      speakers: {
        podcast: {
          video: {
            tracks:
              'Seleccione las pistas de video donde aparece el participante, puede seleccionar cualquier número de pistas.',
          },
          audio: {
            tracks:
              'Seleccione la pista de audio donde podemos escuchar al ponente, solo puede seleccionar una pista por ponente.',
          },
        },
      },
    },
    warn: {
      Step5:
        'Advertencia: si deshabilita la copia de seguridad, no podrá deshacer sus cortes.',
    },
    title: {
      Step5: {
        Backup: 'Habilitar copia de seguridad',
      },
      undo: {
        modal: '¡Listo!',
      },
      not: {
        supported: {
          modal: {
            corrupted: 'Uno de sus clips tiene un formato no soportado',
            speed: 'AutoCut sólo soporta clips con velocidad predeterminada',
            multi: {
              audio:
                'AutoCut puede no funcionar bien con múltiples pistas de audio',
              stream: 'Uno de sus clips tiene múltiples flujos de audio',
            },
            '51_stereo':
              'Uno de sus clips tiene un diseño de audio 5.1 mostrado en estéreo',
            audio: {
              layout: 'Uno de sus clips tiene un diseño de audio no soportado',
            },
            repeat: {
              multi: {
                audio: 'Su selección contiene varios clips de audio',
              },
              no_audio: 'Su selección no contiene clips de audio',
            },
            remote: {
              file: 'Uno de sus clips no está en su computadora',
            },
          },
        },
        maintained: {
          modal:
            'AutoCut no se mantiene en versiones de DaVinci Resolve anteriores a la edición 2021.',
        },
      },
      use: {
        trial: {
          licence: 'Revise su correo electrónico y use su clave de licencia',
        },
        paid: {
          licence: 'Usar su clave de licencia',
        },
      },
      no_audios: 'No hay audio en su selección',
      banner: {
        podcast: {
          beta: 'Gracias por probar nuestro nuevo modo Podcast.',
        },
      },
      silences: {
        step: 'Gestionar los {mode}',
      },
      podcast: {
        silences: {
          step: 'Eliminar o silenciar los silencios',
        },
      },
    },
    secondUnit: 'segundos',
    millisecondUnit: 'milisegundos',
    percentageUnit: '%',
    silence: {
      link: {
        tuto: 'https://www.youtube.com/watch?v=7MEK3rZQDHI',
      },
      button: {
        delete: {
          j_cut: 'J-Cut y eliminar silencios',
          l_cut: 'L-Cut y eliminar silencios',
          both: 'J&L-Cut y eliminar silencios',
          constant: {
            power: 'Cortar, eliminar silencios y aplicar transición de audio',
          },
          none: 'Cortar y eliminar {mode}',
          keep: {
            spaces: {
              none: 'Cortar {mode} y conservar los espacios',
            },
          },
        },
        keep: {
          none: 'Cortar y mantener {mode}',
        },
        mute: {
          none: 'Cortar y silenciar {mode}',
        },
      },
    },
    podcast: {
      link: {
        tuto: 'https://youtu.be/EgsUlSLsK8Y?si=BHwFFuuLjw8DsHYX',
      },
      getting: {
        tracks: 'Obteniendo pistas...',
        silences: 'Obteniendo silencios...',
      },
      edit: {
        track: 'Editando pista {indexTrackInfo}: {current} de {max}',
      },
      final: {
        message: {
          __text__: '¡Éxito! Tu podcast ha sido editado',
          error: 'Ocurrió un error al editar tu podcast',
        },
      },
      button: {
        disable: 'Editar y deshabilitar clips no utilizados',
        delete: 'Editar y eliminar clips no utilizados',
      },
      not: {
        available: {
          title:
            'Seleccione una secuencia con al menos 2 pistas de video y audio',
        },
      },
      minimum: {
        higher: {
          maximum:
            'El tiempo mínimo de cámara debe ser menor que el tiempo máximo de cámara.',
        },
      },
      empty: {
        audio: {
          track: 'Las pistas de audio no pueden estar vacías.',
        },
        video: {
          track: 'Las pistas de video no pueden estar vacías.',
        },
      },
      preset: {
        title: 'Preajustes predeterminados',
        calm: 'Tranquilo',
        paced: 'Moderado',
        energetic: 'Energético',
        hyperactive: 'Hiperactivo',
      },
    },
    error: {
      UseLicence: {
        __text__:
          'Puede desvincular su clave desde su otro ordenador a través del',
        link: 'área de clientes de AutoCut.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'La subsecuencia no se puede alcanzar',
          unknown: 'Ocurrió un error desconocido',
          noSpace: 'Ha ocurrido un error: no hay suficiente espacio libre',
          exportUnknown:
            'DaVinci Resolve encontró un error durante la exportación',
          invalidInputData:
            'Ha ocurrido un error durante la generación de archivos temporales',
          fileNotFound: 'Ocurrió un error al leer uno de sus archivos',
          scriptNotLoaded: 'Ocurrió un error al cargar AutoCut',
          ffmpegError: 'No pudimos leer uno de sus archivos.',
          exportCancelled: 'Exportación cancelada',
          timelineMuted: 'No hay pistas audibles',
          noAudibleAudio: 'No hay audio audible',
          offline: 'Parece que estás sin conexión',
          resourceTimeout: 'No se puede obtener el recurso',
          backupNotFound: 'No se encontró la copia de seguridad',
          setCursorOutInterval: 'Fuera de puntos de entrada/salida',
          emptyPath: 'Ha ocurrido un error durante el análisis de la selección',
          sequenceMismatch: 'No se puede realizar la operación',
        },
        text: {
          subsequenceNotReachable:
            'Lo sentimos, pero no pudimos alcanzar una de tus subsecuencias seleccionadas.',
          unknown:
            'Ocurrió un error al usar AutoCut, pero no pudimos identificarlo.',
          noSpace:
            'Parece que no tiene suficiente espacio libre en su dispositivo.',
          exportUnknown:
            'Este error probablemente es causado por falta de espacio de almacenamiento en el disco.',
          invalidInputData:
            'Nos disculpamos, pero parece que ocurrió un error durante la generación de archivos temporales.',
          fileNotFound:
            'Por favor, verifique que el archivo {filename} todavía está disponible en la ubicación: {filepath}',
          scriptNotLoaded:
            'Por favor, cierre todas las otras extensiones y DaVinci Resolve y vuelva a intentarlo.',
          ffmpegError:
            'Por favor, verifique que el archivo {filepath} no esté corrupto.',
          exportCancelled:
            'AutoCut no puede analizar el audio si detienes la codificación. Por favor, intenta de nuevo.',
          timelineMuted: 'Todas tus pistas de audio usadas están silenciadas.',
          noAudibleAudio: 'No se ha detectado audio audible.',
          offline: 'No podemos acceder a nuestros servidores.',
          resourceTimeout:
            'El recurso en línea necesario para este proceso no pudo ser descargado en 30 segundos.',
          backupNotFound:
            'No podemos restaurar su línea de tiempo ya que no podemos encontrar la secuencia de copia de seguridad',
          setCursorOutInterval:
            'Has seleccionado un fragmento que no está contenido en tus puntos de entrada/salida.',
          emptyPath:
            'Pedimos disculpas, pero parece que uno de tus clips seleccionados no tiene una ruta en tu computadora.',
          sequenceMismatch:
            'Cambiaste de secuencia mientras AutoCut estaba procesando. Por favor, inténtelo de nuevo sin cambiar de secuencia.',
        },
        tips: {
          subsequenceNotReachable:
            'Por favor, asegúrate de que cada subsecuencia en la línea de tiempo tenga el mismo nombre que la secuencia original correspondiente en el panel del proyecto.',
          unknown:
            'Intente cambiar su selección o configuraciones. Si el problema persiste, por favor contáctenos.',
          noSpace:
            'Intente eliminar algunos archivos antes de volver a lanzar AutoCut.',
          exportUnknown:
            'Intenta eliminar algunos archivos antes de reiniciar AutoCut.',
          invalidInputData:
            'Por favor, intente de nuevo con la misma selección. Si el problema persiste, por favor contáctenos con su archivo.',
          fileNotFound:
            'Por favor, intente de nuevo con la misma selección. Si el problema persiste, por favor contáctenos con su archivo.',
          scriptNotLoaded: 'Si el problema persiste, por favor contáctenos.',
          ffmpegError: 'Si el problema persiste, por favor contáctenos.',
          exportCancelled: ' ',
          timelineMuted: 'Por favor, activa al menos una de ellas.',
          noAudibleAudio:
            'Por favor, verifica que al menos un clip esté activo y sea audible.',
          offline:
            'Por favor, verifica tu conexión a internet y vuelve a intentarlo.',
          resourceTimeout:
            'Por favor, verifica tu conexión a internet y vuelve a intentarlo.',
          backupNotFound:
            'Por favor, verifique la carpeta "AutoCut-Backup" en la raíz de su proyecto para ver si la copia de seguridad todavía está allí.',
          setCursorOutInterval:
            'Debes eliminar los puntos de entrada/salida para escuchar esta parte de la transcripción.',
          emptyPath:
            'Por favor, asegúrate de que todos tus clips provengan de medios atómicos o subsecuencias.',
          sequenceMismatch:
            'Cuando use AutoCut, por favor no cambie de secuencia, ni cambie nada en la secuencia actual, hasta que el proceso haya finalizado.',
          noSoundDetected:
            'AutoCut no pudo detectar ningún sonido. Por favor, verifica lo siguiente: {br}{br} <li>Asegúrate de que el idioma seleccionado coincida con el idioma de tus clips.</li><li>Asegúrate de que tus pistas no estén en silencio.</li><li>Confirma que tus puntos de entrada y salida incluyan clips con sonido.</li> {br}Si el problema persiste después de verificar estos puntos, no dudes en contactarnos en Discord o en contact@autocut.fr.',
        },
        lost: {
          cep: {
            title: 'Ha ocurrido un error: Conexión perdida',
            text: {
              '1': 'Ha perdido la conexión con Adobe DaVinci Resolve. Por favor, reinicie la extensión y vuelva a intentarlo.',
              '2': 'Si reiniciar la extensión no soluciona el problema, intente reiniciar DaVinci Resolve.',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'El idioma no es compatible con este modo.',
        },
      },
    },
    retour: '¡Esta clave no existe!',
    countdown: {
      days: 'días de prueba restantes',
    },
    saveas: {
      default: {
        settings: 'Guardar configuraciones actuales',
      },
    },
    button: {
      no_silences: 'No se detectaron silencios',
      no_audios: 'No se seleccionó ningún clip de audio',
      SavingBackup: 'Haciendo una copia de seguridad de su proyecto...',
      RestoringBackup: 'Restaurando su proyecto...',
      ExportingAudio: 'Exportando audio...',
      UploadingAudio: 'Subir audio...',
      SearchingSilences: 'Buscando silencios...',
      Cuttingtrack: 'Cortando pista {current}',
      CuttingStep: {
        __text__: 'Realizando corte {current} de {max}',
        Audio: 'Cortando pista de audio {numTrack}: {current} de {max}',
        Video: 'Cortando pista de vídeo {numTrack}: {current} de {max}',
      },
      PreDeletingStep: 'Buscando clips de silencio para eliminar...',
      DeletingStart: 'Eliminando silencios...',
      finalizing: 'Finalizando...',
      AssociatingStep: 'Realizando asociación {current} de {max}',
      Success: '¡Listo! Proceso finalizado con éxito',
      Failure: 'Fallo... Algo salió mal',
      NoSilences: 'No se encontraron silencios',
      undo: {
        modal: 'Deshacer',
      },
      not: {
        supported: {
          modal: 'Cerrar',
        },
      },
      PreZoomingStep: 'Obteniendo sub-clips para hacer zoom...',
      ZoomingStep: 'Agregando zoom: {current} de {max}',
      Autozoom: 'Preparando AutoZoom...',
      no_video: {
        to_zoom: 'No hay video para hacer zoom.',
      },
      getSubclip: {
        step: 'Obteniendo sub-clip para AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Comprobando si los silencios se eliminan correctamente...',
      },
      back: 'Atrás',
      DeletingStep: {
        delete: {
          Audio:
            'Eliminando silencios en pista de audio {numTrack}: {current} de {max}',
          Video:
            'Eliminando silencios en pista de video {numTrack}: {current} de {max}',
        },
        mute: {
          Audio:
            'Silenciando los silencios en pista de audio {numTrack}: {current} de {max}',
          Video:
            'Silenciando los silencios en pista de video {numTrack}: {current} de {max}',
        },
      },
      skip: 'Saltar',
      save: 'Guardar',
    },
    warning: {
      DontTouchTimeline:
        'Por favor, no modifique su línea de tiempo durante este proceso',
      undo: {
        modal:
          'Hemos notado que algunos metadatos no estaban presentes al analizar sus archivos de audio.',
      },
      modal: {
        title: {
          ffmpeg: 'AutoCut puede no funcionar como se espera',
        },
        text: {
          ffmpeg:
            'Nos disculpamos, pero parece que faltan algunos metadatos en el archivo {filename}.',
        },
        tips: {
          ffmpeg: 'Intente recodificar su archivo si encuentra algún problema.',
        },
      },
      transition: {
        step: 'No puedes usar transiciones sin eliminar silencios.',
      },
      beta: {
        version:
          'Parece que estás utilizando una versión beta de DaVinci Resolve. Podrías encontrar algunos problemas con AutoCut. Por favor, pruébalo en la última versión estable de DaVinci Resolve.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Verificando si su selección es válida...',
          parsing: {
            step: 'Obteniendo datos de su selección: clip {current} de {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Obteniendo silencio de su selección: clip {current} de {max}...',
          },
        },
        drawing: 'Generando previsualización...',
      },
      frame: {
        no_sequence: 'Abra una secuencia para previsualizar su formato',
      },
    },
    contact: {
      us_title: 'Lamentamos que haya encontrado un error',
      us_text: {
        '2': 'Si tiene un problema con su cuenta, su clave, su pago o cualquier otra cosa no relacionada con su proyecto de edición de video:',
        __text__:
          'Si AutoCut no funciona perfectamente con su proyecto y desea informar el problema:',
      },
      us_button: 'Informar un problema',
      us_contact: 'Contáctenos:',
      us_copy: '¡Copia de contacto al portapapeles!',
    },
    switch: {
      to_send: {
        log: {
          button: 'O envíe un archivo de registro',
        },
      },
    },
    send: {
      log: {
        title: 'Nos disculpamos por los inconvenientes causados por este error',
        details:
          'Solo tenemos acceso a información sobre el trabajo de los algoritmos con este archivo de registro.',
        select:
          'Por favor, seleccione un archivo de registro diario, nos ayudará a resolver su problema.',
        text: {
          area: 'Por favor, describa su problema con tanto detalle como sea posible:',
        },
        button: 'Enviar archivo de registro',
        success: {
          title: '¡Archivo enviado! Investigaremos su problema.',
        },
        thank: {
          text: 'Puede continuar cortando sus videos.',
          text2:
            'Nos pondremos en contacto con usted cuando el problema haya sido resuelto.',
        },
        message: {
          error: 'Por favor, ingrese un mensaje de al menos 10 caracteres',
        },
        link: {
          to_user: {
            file: {
              __text__:
                'Por favor, proporcione un enlace a su archivo de video y audio.',
              not: {
                valid:
                  'Por favor, proporcione un enlace válido a su archivo de video y audio.',
              },
              platform: {
                __text__:
                  '(WeTransfer, Google Drive o cualquier otro servicio de intercambio de archivos)',
                helper:
                  "Para subir su archivo en WeTransfer, vaya a wetransfer.com y haga clic en 'Subir archivos'. Después de eso, obtendrá un enlace, cópielo aquí.",
              },
              end: 'Esto nos ayudará a resolver su problema rápidamente.',
            },
          },
        },
        screen: {
          timeline: {
            __text__:
              'Por favor, adjunte una captura de pantalla de su selección en DaVinci Resolve.',
            before:
              'Por favor, adjunte una captura de pantalla de su selección en DaVinci Resolve antes de los cortes.',
            after:
              'Por favor, adjunte una captura de pantalla de su selección en DaVinci Resolve después de los cortes.',
          },
          error: {
            message:
              'Por favor, adjunte una captura de pantalla del mensaje de error.',
          },
        },
        trial: {
          mail: 'Por favor, proporcione el correo electrónico con el que solicitó la prueba.',
        },
        other:
          'Por favor, proporcione cualquier información que pueda ayudar a resolver su problema.',
      },
    },
    select: {
      issue: {
        title: 'Por favor, seleccione su problema en la lista a continuación.',
        subtitle: 'Nos ayudará a resolver su problema.',
      },
      one: {
        audios:
          'Por favor, seleccione al menos un clip de audio para poder usar AutoCut.',
      },
    },
    upload: {
      screenshot: 'Subir captura de pantalla',
    },
    modal: {
      feedback: {
        title: 'Comentarios',
        input: {
          feedback: {
            title: '¿Qué piensa de AutoCut?',
            placeholder: 'Escriba sus comentarios aquí...',
          },
          improve: {
            title: 'Díganos, ¿qué se puede mejorar?',
            placeholder: 'Escriba lo que se podría mejorar aquí...',
          },
        },
        button: {
          text: 'Enviar',
        },
        sent: {
          title: '¡Gracias por sus comentarios!',
          text: 'Si tiene alguna idea de característica para implementar en AutoCut, puede enviárnosla:',
          button: {
            text: 'Sugerir característica',
          },
        },
      },
      bug: {
        report: {
          title: 'Reporte de errores',
          description: {
            placeholder: 'Describa el error aquí...',
            error:
              'Por favor, proporcione una descripción del error de al menos 20 caracteres.',
          },
          reproduce: {
            placeholder: 'Pasos para reproducir el error aquí...',
            error:
              'Por favor, proporcione una reproducción de al menos 20 caracteres.',
          },
          blocking: 'El problema fue bloqueante',
          button: {
            text: 'Enviar reporte de errores',
          },
          link: {
            error: 'Por favor, proporcione un enlace válido a su archivo.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: '¿Ha encontrado algún problema con sus cortes?',
      },
    },
    star: {
      review: {
        title: '¡Deja un testimonio a AutoCut!',
        captions: {
          title: '¡Califique AutoCaptions!',
        },
        text: 'Comparta sus pensamientos sobre AutoCut!',
      },
    },
    review: {
      title: 'Cuéntenos más',
      text: '¿Fueron satisfactorios los cortes? ¿Tiene algún comentario para ayudarnos a mejorar AutoCut? Estamos atentos:',
      form: {
        placeholder: 'Introduzca su mensaje...',
      },
      button: 'Enviar',
      thank: {
        title: '¡Gracias por sus comentarios!',
        text: 'Puede continuar cortando sus videos.',
      },
    },
    exchange: {
      title: '¿Quiere 7 días adicionales de prueba?',
      text: 'Vaya a Adobe Exchange y califique AutoCut para obtener 7 días adicionales de prueba.',
      button: 'Califique AutoCut en Adobe Exchange',
      info: 'Si instaló AutoCut desde nuestra página web, no es necesario instalar la extesión nuevamente. Simplemente haga clic en "Gratis" para iniciar la descarga y acceder a la calificación.',
    },
    licence: {
      trial: 'Prueba',
      paid: 'Pagada',
      info: 'Licencia {licenceType}',
      time: {
        left: '{daysLeft} días restantes',
      },
    },
    trial: {
      promo: {
        text: 'Hasta un 20% de descuento en las próximas 24 horas',
      },
      resend: {
        error:
          'Error al reenviar la clave de prueba, por favor intente más tarde.\n',
      },
      expired: {
        modal: {
          title: 'Tu clave de prueba ha expirado.',
          text1:
            'Tu prueba gratuita ha expirado. Suscríbete a AutoCut para seguir usando la extensión.',
          text2: 'Visita tu panel de AutoCut en:',
          text3: 'Suscríbete a una clave de licencia.',
          text4:
            'Contacta a nuestro equipo de soporte si necesitas ayuda: {supportEmail}',
          button: 'Obtener una suscripción (-20%)',
        },
      },
      title: 'Prueba gratuita',
      stepper: {
        onboarding: 'Ayúdenos a mejorar AutoCut',
        email: 'Ingresa tu correo electrónico',
        sending: {
          email: 'Envío del correo electrónico',
        },
        login: 'Usa tu clave de licencia',
      },
      onboarding: {
        subtitle:
          'Para mejorar la extensión e imaginar nuevas características, por favor ayúdanos a entender tu uso de DaVinci Resolve',
        discover: '¿Dónde descubriste AutoCut?',
        job: {
          status: '¿Cuál es tu situación laboral?',
        },
        type: {
          creation: 'Tipo de creaciones que realizas en DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Correo electrónico enviado correctamente a {email}',
        },
      },
      task: {
        captions: 'Usa AutoCaptions: <link>aprende más</link>',
        podcast: 'Usa AutoCut Podcast: <link>aprende más</link>',
        zoom: 'Usa AutoZoom: <link>aprende más</link>',
        repeat: 'Usa AutoCut Repeat: <link>aprende más</link>',
        broll: 'Usa AutoB-Roll: <link>aprende más</link>',
        swear: {
          word: 'Usa AutoProfanity Filter: <link>aprende más</link>',
        },
        preset:
          'Crea un nuevo ajuste preestablecido para AutoCaptions, AutoZoom o AutoResize',
        testimonial:
          'Déjenos un testimonio (utilizando el mismo correo electrónico): <link>testimonio</link>',
      },
    },
    shop: {
      now: 'Comprar ahora',
    },
    back: {
      to_autocut: 'Regresar a AutoCut',
    },
    'discover-option-youtube-video': 'Video de YouTube',
    'discover-option-youtube-comment': 'Comentario en YouTube',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Amigo',
    'discover-option-school': 'Escuela',
    'discover-option-other': 'Otro',
    link: {
      not: {
        supported: {
          modal: {
            __text__:
              'Para entender mejor este error y cómo solucionarlo, vaya a:',
            no_solution: 'Para entender mejor este error, por favor vaya a:',
            end: ' nuestro sitio de documentación y tutoriales',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence:
            'Acaba de recibir un mensaje en su correo electrónico ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            'Si no ve el correo electrónico, revise su carpeta de spam o promociones.',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Ingrese su clave de licencia',
      },
    },
    "can't": {
      reach: {
        server: {
          error:
            'No se puede contactar al servidor. Por favor, intente más tarde.',
        },
      },
      read: {
        uuid: {
          error: 'No se puede leer el UUID del ordenador.',
        },
        host: {
          error: 'No se puede leer el nombre del ordenador.',
        },
      },
    },
    asking: {
      key: {
        error:
          'Error al solicitar la clave al servidor, por favor intente más tarde.\n',
      },
    },
    sending: {
      log: {
        file: {
          error:
            'No se puede enviar el registro diario, por favor intente más tarde.\n',
        },
      },
    },
    is_key: {
      valid: {
        error: 'Error al verificar la clave, por favor intente más tarde.\n',
      },
    },
    base: {
      error: {
        message: 'Ha ocurrido un error ',
      },
    },
    invalid: {
      response: {
        error: 'La respuesta no proviene del servidor.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'Aún está en pleno desarrollo, si tiene algún error o sugerencia, por favor contáctenos en contact@autocut.fr',
        },
      },
    },
    header: {
      trial: {
        button: 'Obtener más',
      },
      paid: {
        button: 'Gestionar',
      },
      speakers: {
        name: 'Nombre del ponente',
        track: 'Pista de audio del ponente',
      },
      cameras: {
        name: {
          __text__: 'Pista de video de la cámara',
          helper:
            'Seleccione la pista de video donde está la cámara, solo puede seleccionar una pista por cámara.',
        },
        speakers: {
          __text__: 'Ponente(s) en cámara',
          helper:
            'Seleccione los ponentes que son visibles en esta cámara, puede seleccionar cualquier número de ponentes.',
        },
      },
    },
    game: {
      saved: {
        hours: 'Acaba de ahorrar {hourSavedText} hora(s),',
      },
      have: {
        a_break: 'tómese un descanso, se lo merece!',
      },
    },
    generate: {
      preview: 'Generar la vista previa',
    },
    onboarding: {
      skip: 'Omitir introducción',
      close: 'Cerrar',
      previous: 'Anterior',
      next: 'Siguiente ({current}/{max})',
      onboarding: {
        step1: {
          title: '¡Bienvenido a AutoCut!',
          body: 'Durante su primer uso, se le presentarán las distintas funciones de AutoCut paso a paso, guiándole a lo largo del proceso. ¡Empecemos el viaje!',
        },
        step2: {
          title: 'Clave de prueba gratuita.',
          body: 'Si está interesado en probar las funciones de AutoCut por usted mismo, puede solicitar una clave de prueba gratuita en cualquier momento. Tenga en cuenta que solo podrá solicitar una clave de prueba por hasta 14 días.',
        },
        step3: {
          title: 'Clave de licencia.',
          body: 'Si ya tiene una clave de licencia, ya sea de prueba o pagada, puede usarla directamente para acceder a las herramientas de AutoCut.',
        },
        step4: {
          title: 'Comprar una clave de licencia.',
          body: 'Si desea comprar una clave de licencia, puede hacer clic en el botón proporcionado para ser redirigido al sitio web de AutoCut, donde encontrará toda la información que necesita para comprar AutoCut.',
        },
      },
      modeSelection: {
        step1: {
          title: 'Modos de AutoCut.',
          body: 'AutoCut ofrece tres modos diferentes para atender diferentes necesidades de edición. Puede seleccionar el modo que se ajuste a sus requisitos simplemente haciendo clic en uno de los modos.',
        },
        step2: {
          title: '¿Necesita ayuda?',
          body: 'Cada elemento en la interfaz viene equipado con una descripción emergente, que proporciona información adicional sobre su función. Puede obtener más información sobre un elemento en particular pasando el puntero del ratón sobre su descripción emergente.',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2.',
          body: 'La función AutoCut V2 se basa en el nivel de ruido de su archivo de audio para detectar silencios con precisión. Sin embargo, necesita configurarlo antes de usarlo.',
        },
        step2: {
          title: 'Nivel de ruido.',
          body: 'Ajuste el nivel mínimo de ruido que se detectará como un silencio usando el control deslizante.',
        },
        step3: {
          title: '¿Qué sigue?',
          body: 'Navegue a través de los pasos haciendo clic en las flechas para aprender más sobre la funcionalidad de AutoCut.',
        },
        step4: {
          title: 'Generando vista previa.',
          body: "El audio de tu línea de tiempo necesita ser exportado antes de ser procesado. Al hacer clic en 'Generar la vista previa', puedes pre-exportar el audio y visualizar el resultado con tus parámetros actuales.",
        },
        step6: {
          title: 'Hora de cortar.',
          body: 'Una vez que haya revisado todo y se vea bien, ¡es hora de cortar!',
        },
        step7: {
          title: 'Hora de cortar.',
          body: '¡Haga clic en la flecha para elegir las opciones de corte que prefiera!',
        },
        step8: {
          title: 'Hora de cortar.',
          body: '¡Siéntese, relájese y deje que AutoCut se encargue del resto haciendo clic en el botón!',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast.',
          body: 'AutoCut Podcast es una función poderosa que le permite editar sus video podcasts rápidamente.',
        },
        step2: {
          title: 'Participantes.',
          body: 'Puede crear un nuevo participante haciendo clic en "Añadir un participante". Para cada persona que habla en su podcast, necesita agregar un participante.',
        },
        step3: {
          title: 'Participantes.',
          body: 'Después de haber creado un participante, puede configurarlo para satisfacer sus necesidades.',
        },
        step4: {
          title: 'Nombre del participante.',
          body: 'En esta sección, puede asignar un nombre al participante que solo aparecerá visualmente para que pueda identificarlo.',
        },
        step5: {
          title: 'Selección de pistas de video.',
          body: 'El menú desplegable le permite seleccionar todas las pistas de video en las que aparece el participante.',
        },
        step6: {
          title: 'Selección de pista de audio.',
          body: 'Puede seleccionar la pista de audio del participante utilizando el menú desplegable. Cada participante solo puede tener una pista de audio.',
        },
        step7: {
          title: 'Hora de editar.',
          body: 'Una vez que haya definido todos los participantes y dónde aparecen, ¡todo está listo para comenzar!',
        },
        step8: {
          title: 'Hora de editar.',
          body: 'Use la flecha para seleccionar las opciones de edición que prefiera.',
        },
        step9: {
          title: 'Hora de editar.',
          body: 'Haga clic en el botón, siéntese, relájese y deje que AutoCut se encargue del resto mientras disfruta de una taza de café.',
        },
      },
      title: 'Bienvenido a AutoCut',
      subtitle: 'Tu extensión definitiva para DaVinci Resolve',
    },
    start: {
      transcription: 'Comenzar transcripción',
    },
    no_more: {
      credits: 'No hay más créditos disponibles',
    },
    file: {
      too: {
        long: 'El archivo de audio es demasiado largo',
      },
      sent: 'Archivo subido, esperando la transcripción...',
    },
    please: {
      wait1: 'Por favor espere mientras se transcribe su clip de audio.',
      wait2:
        'Este proceso puede llevar varios minutos, dependiendo de la longitud.',
      wait3: 'Subiendo archivo: ',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Idioma del audio',
            helper: 'Seleccione el idioma del clip de audio',
          },
        },
        french: {
          __text__: 'Francés',
          beta: 'Francés (beta)',
        },
        danish: 'Danés',
        dutch: 'Holandés',
        english: {
          __text__: 'Inglés',
          united: {
            states: 'Inglés - Estados Unidos',
          },
        },
        flemish: 'Flamenco',
        german: {
          __text__: 'Alemán',
          beta: 'Alemán (beta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (beta)',
        },
        italian: 'Italiano',
        japanese: 'Japonés',
        korean: 'Coreano',
        norwegian: {
          __text__: 'Noruego',
          nynorsk: 'Noruego nynorsk',
        },
        polish: 'Polaco',
        portuguese: {
          __text__: 'Portugués',
          beta: 'Portugués (beta)',
          brazil: {
            __text__: 'Portugués - Brasil',
            beta: 'Portugu��s - Brasil (beta)',
          },
        },
        spanish: {
          __text__: 'Español',
          beta: 'Español (beta)',
          latin: {
            america: {
              __text__: 'Español - América Latina',
              beta: 'Español - América Latina (beta)',
            },
          },
        },
        swedish: 'Sueco',
        tamil: 'Tamil',
        chinese: 'Chino',
        russian: 'Ruso',
        turkish: 'Turco',
        catalan: 'Catalán',
        arabic: {
          __text__: 'Árabe',
          algeria: 'Árabe - Argelia',
          bahrain: 'Árabe - Bahréin',
          egypt: 'Árabe - Egipto',
          iraq: 'Árabe - Irak',
          israel: 'Árabe - Israel',
          jordan: 'Árabe - Jordania',
          kuwait: 'Árabe - Kuwait',
          lebanon: 'Árabe - Líbano',
          mauritania: 'Árabe - Mauritania',
          morocco: 'Árabe - Marruecos',
          oman: 'Árabe - Omán',
          qatar: 'Árabe - Catar',
          saudi: {
            arabia: 'Árabe - Arabia Saudita',
          },
          palestine: 'Árabe - Palestina',
          syria: 'Árabe - Siria',
          tunisia: 'Árabe - Túnez',
          united: {
            arab: {
              emirates: 'Árabe - Emiratos Árabes Unidos',
            },
          },
          yemen: 'Árabe - Yemen',
        },
        indonesian: 'Indonesio',
        finnish: 'Finlandés',
        vietnamese: 'Vietnamita',
        hebrew: 'Hebreo',
        ukrainian: 'Ucraniano',
        greek: 'Griego',
        malay: 'Malayo',
        czech: 'Checo',
        romanian: 'Rumano',
        hungarian: 'Húngaro',
        thai: 'Tailandés',
        urdu: 'Urdu',
        croatian: 'Croata',
        bulgarian: 'Búlgaro',
        lithuanian: 'Lituano',
        latin: 'Latín',
        maori: 'Maorí',
        malayalam: 'Malayalam',
        welsh: 'Galés',
        slovak: 'Eslovaco',
        telugu: 'Telugu',
        persian: 'Persa',
        latvian: 'Letón',
        bengali: 'Bengalí',
        serbian: 'Serbio',
        azerbaijani: 'Azerbaiyano',
        slovenian: 'Esloveno',
        kannada: 'Kannada',
        estonian: 'Estonio',
        macedonian: 'Macedonio',
        breton: 'Bretón',
        basque: 'Vasco',
        icelandic: 'Islandés',
        armenian: 'Armenio',
        nepali: 'Nepalí',
        mongolian: 'Mongol',
        bosnian: 'Bosnio',
        kazakh: 'Kazajo',
        albanian: 'Albanés',
        swahili: 'Suajili',
        galician: 'Gallego',
        marathi: 'Maratí',
        punjabi: 'Punjabí',
        sinhala: 'Cingalés',
        khmer: 'Jemer',
        shona: 'Shona',
        yoruba: 'Yoruba',
        somali: 'Somalí',
        afrikaans: 'Afrikáans',
        occitan: 'Occitano',
        georgian: 'Georgiano',
        belarusian: 'Bielorruso',
        tajik: 'Tayiko',
        sindhi: 'Sindhi',
        gujarati: 'Guyaratí',
        amharic: 'Amárico',
        yiddish: 'Yidis',
        lao: 'Laosiano',
        uzbek: 'Uzbeko',
        faroese: 'Feroés',
        haitian: {
          creole: 'Criollo haitiano',
        },
        pashto: 'Pastún',
        turkmen: 'Turcomano',
        maltese: 'Maltés',
        sanskrit: 'Sánscrito',
        luxembourgish: 'Luxemburgués',
        burmese: 'Birmano',
        tibetan: 'Tibetano',
        tagalog: 'Tagalo',
        malagasy: 'Malgache',
        assamese: 'Asamés',
        tatar: 'Tártaro',
        hawaiian: 'Hawaiano',
        lingala: 'Lingala',
        hausa: 'Hausa',
        bashkir: 'Baskir',
        javanese: 'Javanés',
        sundanese: 'Sundanés',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            '¡Parece que tienes muchos clips en tu secuencia! Puede llevar un tiempo cargar. Si no necesita cortar todos estos clips, seleccione solo los que necesita cortar y córtelos en una secuencia vacía separada.',
        },
      },
    },
    homepage: {
      title: 'Página principal',
      comming: {
        soon: '¡Próximamente!',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dinamismo',
        normal: 'Normal',
        high: 'Alto',
        extreme: 'Extremo',
        custom: 'Personalizado:',
      },
      button: {
        __text__: 'Zoom',
        message: {
          step1: 'Preparación del Autozoom...',
          step2: 'Extrayendo datos de audio...',
          step3: 'Calculando intervalos de zoom...',
          step4: 'Aplicando zoom {current} de {max}...',
        },
      },
      success: '¡Éxito! Su selección ha sido ampliada',
      info: {
        text: 'AutoZoom agregará automáticamente zoom a cada clip seleccionado en función de los decibelios.',
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Seleccione al menos un clip de audio y uno de video en su línea de tiempo',
            text1:
              'Para usar AutoZoom, seleccione todos los clips que componen su video en su línea de tiempo.',
          },
        },
      },
      anchor: {
        title: 'Defina el punto de anclaje',
        helper:
          'El punto de anclaje es donde se aplicará el zoom. Puede visualizar el efecto de zoom máximo y mínimo usando las cajas de colores.',
      },
      dual: {
        range: {
          title: 'Coeficiente mínimo y máximo de zoom aplicado',
          helper:
            'El coeficiente de zoom se calcula en base a los valores proporcionados a continuación y el volumen máximo del subclip.',
        },
      },
      preview: {
        on_indicator: 'Vista previa en el indicador',
        error: {
          message: 'Ha ocurrido un error al cargar la vista previa',
          button: 'Reintentar',
        },
      },
      min: {
        zoom: {
          title: 'Duración mínima del zoom',
          helper: 'Cada zoom será al menos de esta duración.',
        },
      },
      max: {
        zoom: {
          title: 'Duración máxima del zoom',
          helper: 'Cada zoom será como máximo de esta duración.',
        },
      },
      constraint: {
        zoom: {
          title: 'Restricción de zoom a clips',
          helper:
            'Si es sí, ningún zoom abarcará más de un clip. Si es no, toda su selección se procesará como un único archivo de audio y los zooms ignorarán los clips individuales en la línea de tiempo.',
        },
      },
      zoom: {
        types: {
          __text__: 'Tipos de zoom',
          helper:
            'Determina el tipo de zoom a usar. Suave: Zoom progresivo con el tiempo. Dinámico: Zoom instantáneo, agudo.',
        },
        coverage: {
          __text__: 'Cobertura total de zoom',
          helper: 'Porcentaje del video a zoomear',
        },
      },
      smooth: {
        __text__: 'Suave',
        zoom: {
          __text__: 'Zooms suaves',
          coverage: 'Cobertura de zooms suaves',
          nervousness: {
            __text__: 'Nerviosismo',
            helper:
              'Un bajo nivel de nerviosismo significa zooms lentos, débiles. Un alto nivel de nerviosismo significa zooms rápidos, intensos.',
          },
        },
      },
      dynamic: {
        __text__: 'Dinámico',
        zoom: {
          __text__: 'Zooms dinámicos',
          coverage: 'Cobertura de zooms dinámicos',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Preajustes predeterminados',
        calm: 'Tranquilo',
        paced: 'Moderado',
        energetic: 'Energético',
        hyperactive: 'Hiperactivo',
      },
    },
    repeat: {
      info: {
        banner:
          'Ahora puedes corregir cuáles frases serán cortadas o no.{br}Las frases en gris se consideran repeticiones.{br}Las tijeras permiten definir el estado de una frase (repetición o no). También puedes resaltar una parte de la frase y apuntar precisamente las palabras que serán cortadas',
      },
      invalid: {
        selection: {
          screen: {
            title: 'Seleccione un clip en su línea de tiempo',
            text1:
              'Para usar AutoCut Repeat, seleccione un clip de audio y opcionalmente un clip de video en su línea de tiempo.',
          },
        },
      },
      select: {
        algorithm: 'Seleccionar algoritmo',
      },
      precise: {
        algorithm: {
          info: 'El algoritmo preciso es nuestro más reciente y eficiente, pero requiere aproximadamente 1 minuto de procesamiento por cada 6 minutos de video.',
        },
      },
      fast: {
        algorithm: 'Rápido',
      },
      toggle: {
        cut: {
          button: {
            text: 'Cortar/No cortar',
          },
        },
      },
      play: {
        button: {
          text: 'Reproducir',
        },
      },
      text: {
        step: {
          edit: {
            transcript: 'Corregir transcripción',
          },
        },
      },
    },
    captions: {
      step: {
        customization: 'Parámetros de subtítulos',
        script: 'Seleccionar clips e idioma',
        transcription: 'Editar transcripción',
      },
      script: {
        transcript: {
          part: 'Transcripción',
        },
        sequence: {
          part: 'Secuencia',
          name: 'Nombre',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} clips | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Agregar subtítulos',
        coming: {
          soon: '¡El editor de transcripciones está por llegar!{br}Por ahora, puede editar su transcripción una vez importada en el panel de Gráficos Esenciales.{br}Solo seleccione un subtítulo en la línea de tiempo y edítelo en la pestaña de modificación del panel.',
        },
        transcription: {
          part: 'Transcripción',
        },
        info: 'Bienvenido al editor de subtítulos - por supuesto, también podrá editar sus subtítulos en la línea de tiempo de DaVinci Resolve después de añadirlos.',
        add: {
          emojis: {
            cta: 'Generar emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Seleccione clip(s) para subtitular en su línea de tiempo',
          no_selection:
            'Si AutoCut no responde, intente reiniciar DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Procesar',
        },
      },
      customization: {
        info: 'AutoCaption está actualmente en Beta, planeamos la primera versión estable que estará disponible el 10 de noviembre',
        back: {
          button: 'Eliminar transcripción y volver atrás',
        },
        example: {
          __text__:
            'Vista previa <opacity50><i>Puedes arrastrar la leyenda o el emoji para cambiar su posición </i></opacity50>',
          text: 'Agrega subtítulos con AutoCaptions. Esta es una vista previa de AutoCaptions y te permite personalizar fácilmente AutoCaptions. Luego puedes comenzar la transcripción y editarla si es necesario, y AutoCaptions agregará automáticamente tus subtítulos a tu línea de tiempo.',
        },
        animation: {
          __text__: 'Animación',
          helper: 'Gestionar animaciones de texto',
          color: {
            __text__: 'Resalta con <color>audio</color>',
            text: {
              color: 'Color de texto',
            },
            before: {
              highlight: {
                color: 'Color antes del resaltado',
              },
            },
            after: {
              highlight: {
                color: 'Color después del resaltado',
              },
            },
          },
          advanced: {
            color: {
              options: 'Opciones avanzadas de color',
            },
          },
          word: {
            box: 'Texto con <color>caja</color>',
          },
          bold: {
            with: {
              text: 'Texto en <b>negrita</b>',
            },
          },
          floating: {
            text: 'Texto flotante',
          },
        },
        transition: {
          __text__: 'Animación y transición',
          helper: 'Gestionar animaciones y transiciones de texto',
          effect: {
            zoomIn: 'Zoom entrante',
            blurIn: 'Desenfoque entrante',
          },
        },
        outline: {
          __text__: 'Contorno',
          helper: 'Gestionar estilo de contorno de texto',
          color: 'Color',
          width: 'Ancho',
        },
        font: {
          __text__: 'Fuente',
          helper: 'Gestionar estilo de texto',
          font: 'Nombre de la fuente',
          help: '¿Cómo añadir una nueva familia tipográfica?',
          size: 'Tamaño de la fuente',
          weight: 'Grosor de la fuente',
          color: 'Color',
        },
        formating: {
          __text__: 'Formateo del texto',
          helper: 'Gestionar formateo del texto',
          ponctuation: 'Eliminar puntuación',
          uppercase: 'Mayúsculas',
          bold: 'Negrita',
          italic: 'Cursiva',
          glow: {
            __text__: 'Brillo',
            color: 'Color',
            intensity: 'Intensidad',
          },
        },
        transform: {
          __text__: 'Transformar',
          x: 'x',
          y: 'y',
          helper: 'Gestionar la posición y rotación de los subtítulos',
          position: {
            __text__: 'Posición vertical',
            emoji: 'Posición vertical de los emojis',
            info: 'También puede mover el texto en la vista previa',
          },
          rotation: 'Rotación',
        },
        multiple: {
          lines: 'Múltiples líneas',
        },
        box: {
          __text__: 'Cajas',
          helper: 'Gestionar estilo de caja',
        },
        boxes: {
          padding: {
            x: 'Relleno horizontal',
            y: 'Relleno vertical',
          },
          opacity: 'Opacidad de la caja',
          radius: 'Radio',
          color: 'Color de la caja',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'Generar emojis con IA',
          size: 'Tamaño de los emojis',
        },
        shadow: 'Sombra',
        transcript: 'Transcripción',
        action: 'Siguiente paso',
        select: {
          chunk:
            'Seleccione la parte de la transcripción que desea editar/personalizar',
        },
        delete: {
          caption: {
            modal: {
              title: '¿Estás seguro de que quieres eliminar este subtítulo?',
              cancel: 'No - mantenerlo',
              confirm: 'Sí - eliminarlo',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Error al obtener el número de preajustes, por favor contáctenos en discord o en contact@autocut.fr',
            },
            error:
              'Error al obtener los preajustes, por favor contáctenos en discord o en contact@autocut.fr',
          },
          public: {
            presets: {
              error:
                'Error al obtener los preajustes públicos, por favor contáctenos en discord o en contact@autocut.fr',
            },
          },
        },
        presets: {
          title: 'Preajustes',
          save: {
            new: {
              preset: 'Guardar los parámetros actuales',
            },
          },
          view: {
            more: 'Ver más',
            less: 'Ver menos',
          },
        },
        save: {
          preset: {
            info: {
              text: 'Has cambiado tus parámetros, ¡puedes guardarlos como un preset abajo y reutilizarlos infinitamente!',
            },
          },
        },
      },
      editor: {
        title: 'Editor de subtítulos',
        description: {
          title: 'Seleccione los subtítulos que desea editar',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Palabras inciertas',
            },
          },
          merge: {
            tooltip: 'Fusionar los fragmentos',
          },
          split: {
            tooltip: 'Dividir los fragmentos',
          },
        },
        sound: {
          play: 'Activar reproducción de sonido',
        },
      },
      presetCustomization: {
        createPresetModal: {
          title: {
            length: {
              error:
                'El nombre de su preajuste debe contener al menos 2 palabras.',
            },
          },
          error:
            'Error al crear el preajuste, por favor contáctenos en discord o en contact@autocu.fr',
          placeholder: 'Nombre del preajuste (al menos 2 palabras)',
          create: 'Crear preajuste',
          isPublic: 'Compartir con la comunidad - público',
        },
        searchPresetModal: {
          title: 'Buscar preajuste de la comunidad',
        },
        error: {
          preset: {
            already: {
              exists: 'Este preajuste ya existe. Por favor, elija otro nombre.',
            },
          },
        },
        copyPreset: {
          error:
            'Error al copiar el preajuste, por favor contáctenos en discord o en contact@autocut.fr',
        },
        no_preset: {
          selected: 'Seleccione un preset para ver la vista previa',
        },
        no_preview: {
          available: 'No hay vista previa disponible',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Fuente no encontrada',
          message:
            'La fuente <i><b>{fontLabel}</b></i> no se encontró en su sistema, se utilizará la fuente predeterminada.',
          helper:
            'Puede agregar una nueva familia de fuentes siguiendo este tutorial.',
        },
      },
    },
    version: {
      not: {
        supported: 'Versión de DaVinci Resolve no compatible',
      },
    },
    transcript: {
      usage: {
        unit: 'minutos',
      },
      upgrade: {
        button: 'Actualizar a AI para continuar con {mode}',
      },
      too: {
        short: {
          error:
            'Desafortunadamente, su transcripción puede ser demasiado corta o el idioma que está utilizando no está bien soportado. Si el problema persiste, por favor contáctenos en contact@autocut.fr.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Solicitud de característica',
        form: {
          placeholder:
            'Ingrese su solicitud de característica. No dude en explicar en detalle su solicitud para que podamos estudiarla más fácilmente.',
        },
        screenshot:
          'Puede adjuntar capturas de pantalla/videos para ayudarnos a comprender mejor su solicitud de característica:',
        button: 'Enviar solicitud de característica',
        success: {
          title: '¡Solicitud de característica enviada! 🥳',
          body: '¡Gracias por ayudar a AutoCut a crecer! Revisaremos su solicitud con la mayor atención.',
        },
        error: {
          title: 'Ups, algo salió mal... 🤕',
          body: 'Lamentamos profundamente las molestias. Por favor, intente nuevamente más tarde o contáctenos en contact@autocut.fr.',
        },
      },
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Añade puntos de entrada y salida en tu línea de tiempo',
            text1:
              'Para usar AutoResize, necesitas añadir puntos de entrada y salida.',
            cta: 'Establecer puntos de entrada y salida en secuencia completa',
          },
        },
      },
      text: {
        info: 'AutoResize redimensiona y reencuadra automáticamente tu secuencia para todas las dimensiones de las redes sociales.',
      },
      in_out: {
        step: {
          title: 'Puntos de Entrada / Salida',
          helper:
            'Por favor verifica tus puntos de entrada y salida en la línea de tiempo. Deberían coincidir con los valores que se muestran a continuación.',
        },
      },
      preset: {
        step: {
          title: 'Preajustes',
          helper:
            'Selecciona un preajuste para redimensionar tu secuencia. También puedes crear tu propio preajuste. Las dimensiones de tu nueva secuencia se muestran abajo.',
        },
        undefined: 'Por favor selecciona un preajuste.',
      },
      watermark: {
        step: {
          title: 'Marca de agua',
          helper:
            'Puedes añadir una marca de agua a tu secuencia. Se añadirá en la esquina inferior derecha de tu secuencia.',
          text: 'Sube tu marca de agua',
        },
      },
      button: 'Generar clip para redes sociales',
      reframe: {
        undefined: 'Seleccione la velocidad "Seguir tema".',
        step: {
          title: 'Seguir al sujeto',
          helper:
            'Puedes elegir la velocidad a la que la cámara seguirá al sujeto.',
          slower: 'Lento',
          default: 'Normal',
          faster: 'Rápido',
        },
      },
      backup: 'Haciendo una copia de seguridad de tu secuencia...',
      create: {
        new: {
          sequence: 'Creando una nueva secuencia...',
        },
      },
      resizing: {
        sequence: 'Redimensionando la nueva secuencia...',
      },
      adding: {
        watermark: 'Añadiendo marca de agua...',
      },
      cleaning: {
        sequence: 'Limpiando la secuencia...',
      },
    },
    endprocess: {
      modal: {
        title: '¡Proceso completado!',
        text: {
          '1': '¡Proceso finalizado con éxito!',
          '2': 'Tu retroalimentación es invaluable: déjanos saber cómo lo hicimos y cómo podemos mejorar tu experiencia.',
        },
        next: {
          mode: {
            silence: {
              text: '¿Listo para una experiencia de audio sin interrupciones? Usa AutoCut Silencios para eliminar sin esfuerzo los silencios incómodos de tu contenido.',
              button: 'Ir a AutoCut Silencios',
            },
            podcast: {
              text: 'Eleva tu producción de podcasts con AutoCut Podcast. Disfruta de una edición simplificada y transiciones automáticas de cámara.',
              button: 'Ir a AutoCut Podcast',
            },
            repeat: {
              text: '¡Mantén a tu audiencia enganchada! Utiliza AutoCut Repetición para editar tu video a través de un editor de texto, manteniendo solo las partes más atractivas de tu contenido.',
              button: 'Ir a AutoCut Repetición',
            },
            zoom: {
              text: 'Dale vida a tus videos con AutoZoom. Agrega efectos de zoom fluidos que capturan la atención de los espectadores y la mantienen.',
              button: 'Ir a AutoZoom',
            },
            caption: {
              text: 'Conecta mejor con tu audiencia usando AutoCaptions. Integra subtítulos elegantes y sincronizados que realzan la narrativa de tus videos.',
              button: 'Ir a AutoCaptions',
            },
            resize: {
              text: 'Domina el compartir en múltiples plataformas con AutoResize. Formatea tus videos a la perfección sin perder el enfoque en tu sujeto.',
              button: 'Ir a AutoResize',
            },
            broll: {
              text: 'Amplifica tu narrativa visual con AutoB-Roll, donde la IA integra a la perfección metraje de archivo relevante para complementar tu historia.',
              button: 'Ir a AutoB-Roll',
            },
            swear: {
              word: {
                text: 'Mantén un diálogo pulido con AutoProfanity Filter. Enmascara automáticamente el lenguaje no deseado para mantener el profesionalismo de tu contenido.',
                button: 'Ir a AutoProfanity Filter',
              },
            },
          },
        },
      },
      captions: {
        modal: {
          text: {
            '1': '¡Tus subtítulos animados han sido añadidos! 🎉 {br} Si necesitas modificar los subtítulos:',
            '2': 'Hay un <a>tutorial detallado si es necesario.</a>',
          },
          bullet: {
            point: {
              '1': 'Haz doble clic en la subsecuencia: "Double click to edit"',
              '2': 'Selecciona el subtítulo que quieres editar',
              '3': 'Abre los gráficos esenciales y haz clic en la pestaña "editar"',
            },
          },
        },
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title:
              'Seleccione clips en su línea de tiempo como base para B-Roll',
            text1:
              'Para usar Auto B-Roll, necesita seleccionar al menos un clip con audio en su línea de tiempo.',
          },
        },
      },
      text: {
        info: 'Auto B-Roll, gracias a la IA, analiza su audio y añade automáticamente {br} B-Rolls de @Pexels relacionados con el tema de su video.',
      },
      duration: {
        step: {
          title: 'Duración de los B-Rolls',
          minimum: {
            title: 'Duración mínima del B-Roll',
            helper: 'Cada B-Roll tendrá por lo menos esta duración.',
          },
          maximum: {
            title: 'Duración máxima del B-Roll',
            helper: 'Cada B-Roll tendrá como máximo esta duración.',
          },
        },
      },
      choice: {
        step: {
          title: 'Selección de B-Roll',
        },
      },
      no_broll: {
        screen: {
          title:
            'El procesamiento de la transcripción ha terminado, pero no se encontró nada.',
          text1:
            'Para utilizar el Auto B-Roll, necesitas seleccionar al menos un clip de audio con discurso en tu línea de tiempo.',
          text2:
            'Si ya hiciste eso, verifica el idioma de tu transcripción y comprueba que tus pistas de audio no estén silenciadas.',
        },
      },
      button: {
        __text__: 'Añadir B-Rolls',
        next: 'Siguiente',
      },
      exporting: 'Exportando los clips de audio seleccionados...',
      analyse: 'Analizando el audio...',
      getting: {
        broll: 'Obteniendo B-Rolls... (puede tardar unos minutos)',
      },
      transcript: {
        finished: 'Transcripción generada',
      },
      step: {
        parameters: 'Parámetros de B-Roll',
        language: 'Seleccionar clips e idioma',
        brolls: 'Editar selección de B-Roll',
      },
      adding: {
        broll: 'Añadiendo B-Rolls...',
      },
      restore: 'Restaurando su secuencia...',
      backup: 'Haciendo copia de seguridad de su secuencia...',
      credit: {
        modal: {
          title:
            'Aquí están los créditos de los B-Rolls utilizados en tu video: ',
          provided: {
            by: 'proporcionado por Pexels',
          },
          link: 'enlace',
          button: 'Copiar al portapapeles',
        },
      },
      search: {
        modal: {
          title: 'Elegir B-Roll',
          input: {
            placeholder: 'Escriba para buscar B-Rolls',
          },
          searching: 'Buscando B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Doble clic para editar',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title:
                'Seleccione clips en su línea de tiempo donde desee silenciar groserías',
              text1:
                'Para usar AutoProfanity Filter, necesita seleccionar al menos un clip con audio en su línea de tiempo.',
            },
          },
        },
        text: {
          info: 'AutoProfanity Filter, gracias a la IA, analiza su audio y automáticamente añade {br} sonidos de pitido sobre las groserías.',
        },
        mute: 'Silenciar',
        button: 'Eliminar groserías',
        audio: {
          export: 'Exportando audio...',
          analyse: 'Analizando audio...',
          insert: 'Insertando pitidos...',
          filter: 'Aplicando filtros de volumen...',
        },
        script: {
          transcript: {
            part: 'Transcripción',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: {
            helper: 'Seleccione efecto de sonido para el pitido',
          },
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Crear un nuevo formato',
          button: {
            __text__: 'Crear',
            disabled: 'Nombre ya en uso',
          },
          name: 'Nombre',
          width: 'Ancho',
          height: 'Alto',
        },
      },
    },
    process: {
      modal: {
        warning:
          'No haga nada en DaVinci Resolve, ya que esto causará problemas.',
        generating: {
          emojis: 'Generando emojis a partir de la transcripción...',
        },
        formatting: {
          numbers: 'Formateando números desde la transcripción...',
        },
      },
    },
    backup: {
      modal: {
        title: 'Copia de Seguridad de AutoCut',
        text: {
          '1': 'Se realiza una copia de seguridad antes de cualquier proceso de AutoCut. Puedes encontrar estas copias en la carpeta "AutoCut-Backup".',
          '2': 'Puedes utilizarlas como desees.',
        },
      },
    },
    confirm: {
      modal: {
        title: '¿Estás seguro?',
        confirm: {
          label: 'Confirmar',
        },
        cancel: {
          label: 'Cancelar',
        },
      },
    },
    login: {
      resend: {
        email: '¿No lo recibiste? Reenviar correo a {trialEmail}',
      },
    },
    typeofcreation: {
      option: {
        interview: 'Interviews',
        podcast: 'Podcasts',
        youtube: 'Videos de Youtube',
        short: 'TikTok/Shorts/Reels',
        internal: 'Videos internos',
        other: 'Otro',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Creador de contenido',
        },
        video: {
          editor: {
            professional: 'Editor de video profesional',
            semi: {
              professional: 'Editor de video semi-profesional',
            },
            beginner: 'Editor de video principiante',
          },
        },
        student: 'Estudiante',
        other: 'Otro',
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: 'Ingrese el nombre del ponente…',
        },
      },
    },
    progress: {
      captions: {
        modal: {
          message: {
            initialization: 'Creación de subtítulos...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal:
          '¡Excelente trabajo! Completaste el proceso en tan solo {minutes} minutos y {seconds} segundos.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Tiempo ahorrado: <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b> y <b>{secondsSaved} {secondsSaved, plural, one {segundo} other {segundos}}</b>.',
        },
        all: {
          cuts: 'Tiempo total ahorrado con AutoCut: ¡Un impresionante total de <b>{hourSaved} {hourSaved, plural, one {hora} other {horas}}</b> y <b>{minutesSaved} {minutesSaved, plural, one {minuto} other {minutos}}</b>! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Excluye ciertas pistas de audio de la detección de silencios desactivándolas. Esto es ideal para mantener música de fondo o sonidos de juegos. Para mantener los silencios en un clip específico sin alterar, simplemente no selecciones ese clip.',
        video: 'Pistas de vídeo seleccionadas:',
        audio: 'Pistas de audio seleccionadas:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: 'Límite de prueba gratuita alcanzado',
            cta: 'Suscribirse',
            text: 'Has utilizado más de 1000 minutos de funciones de IA. {br} Suscríbete al plan de IA para seguir utilizando las funciones de AutoCut AI.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Límite de transcripción alcanzado',
          text: 'Has utilizado más de 6000 minutos de funciones de IA. {br} Por favor, contáctanos para desbloquear la situación.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Please choose a valid transition type (See <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a> field)',
        ai_silences:
          'Please choose a valid silence treatment (See <a><b><embedTranslation>title_silences_step</embedTranslation></b></a> field)',
        broll: {
          min: {
            max: {
              duration:
                'Maximum B-Roll time cannot be smaller than minimum B-Roll time. (See <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a> field)',
            },
          },
          minimumBRollTime:
            'Minimum B-Roll time must be a positive number (See <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a> field)',
          maximumBRollTime:
            'Maximum B-Roll time must be a positive number (See <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a> field)',
        },
        caption: {
          position:
            'Captions must be inside the preview (See <a><b><embedTranslation>captions_customization_transform_position</embedTranslation></b></a> field)',
          emoji: {
            position:
              'Emojis must be inside the preview (See <a><b><embedTranslation>captions_customization_transform_position_emoji</embedTranslation></b></a> field)',
          },
          text: {
            fontSize:
              'Font size must be a positive number (See <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a> field)',
          },
          outline: {
            width:
              'Outline width must be a positive number (See <a><b><embedTranslation>captions_customization_outline_width</embedTranslation></b></a> field)',
          },
          transition: {
            effects:
              'Por favor, selecciona un efecto de transición de subtítulos válido (Ver campo <a><b><embedTranslation>captions_customization_transition</embedTranslation></b></a>)',
          },
          emojis: {
            size: 'El tamaño de los emojis debe ser un número positivo (Ver campo <a><b><embedTranslation>captions_customization_font_size</embedTranslation></b></a>)',
          },
          formating: {
            nbLines:
              'Por favor, selecciona al menos una línea para el formato de los subtítulos (Ver campo <a><b><embedTranslation>captions_customization_formating_nbLines</embedTranslation></b></a>)',
          },
          title: {
            too: {
              short: 'El título debe contener al menos 5 caracteres',
              long: 'El título debe contener como máximo 25 caracteres',
            },
            no_space:
              'El título debe contener al menos dos palabras, separadas por un espacio',
          },
        },
        font: {
          fontFamily:
            'Se requiere una familia de fuentes (Ver campo <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>)',
          label:
            'Por favor, selecciona una fuente (Ver campo <a><b><embedTranslation>captions_customization_font_font</embedTranslation></b></a>)',
        },
        languageOfTranscription:
          'Por favor, selecciona un idioma de transcripción (Ver campo <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>)',
        cameras: {
          speakerIds:
            'Se requiere al menos un orador para cada cámara (Ver campo <a><b><embedTranslation>header_cameras_speakers</embedTranslation></b></a>)',
          label:
            'Falta la pista de una de las cámaras (Ver campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'El tiempo mínimo de la cámara debe ser un número positivo (Ver campo <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>)',
            },
          },
          maximum: {
            cam: {
              time: 'El tiempo máximo de la cámara debe ser un número positivo (Ver campo <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>)',
            },
          },
          deleteUnusedClips:
            'Por favor, elige entre eliminar o silenciar los silencios (Ver campo <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>)',
          speakers: {
            cameras:
              'Se requiere al menos una cámara para cada orador (Ver campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
            audio:
              'Se requiere una pista de audio para cada orador (Ver campo <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>)',
          },
          cameras: {
            __text__:
              'Las cámaras no pueden tener la misma pista (Ver campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
            length:
              'Debe haber configurado al menos 2 cámaras diferentes (Ver el campo <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>)',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Se requiere un preset de Auto Reframe (Ver campo <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>)',
            invalid:
              'El preset de Auto Reframe debe ser más lento, predeterminado o más rápido (Ver campo <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>)',
          },
          watermark:
            'El watermark debe ser un archivo de imagen válido: JPEG, PNG o WebP (Ver campo <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>)',
          preset: {
            name: 'Por favor, introduce un nombre para el preset',
            width: 'El ancho debe ser mayor a 1px',
            height: 'La altura debe ser mayor a 1px',
          },
        },
        rgb: 'Los valores RGB deben estar entre 0 y 255',
        silence: {
          noiseLevel: {
            type: 'El nivel de ruido debe ser un número (Ver campo <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
            nonpositive:
              'El nivel de ruido debe ser un número positivo (Ver campo <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
            integer:
              'El nivel de ruido debe ser un número entero (Ver campo <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>)',
          },
          minTimeOfSilence: {
            type: 'El tiempo mínimo de silencio debe ser un número (Ver campo <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>)',
          },
          maxTimeOfSilence: {
            type: 'El tiempo máximo de silencio debe ser un número (Ver campo <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>)',
          },
          marginBefore: {
            type: 'El padding antes debe ser un número (Ver campo <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>)',
          },
          marginAfter: {
            type: 'El padding después debe ser un número (Ver campo <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>)',
          },
          transitions:
            'Por favor, elige un tipo de transición válido (Ver campo <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>)',
          silences:
            'Por favor, elige un tratamiento de silencio válido (Ver campo <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>)',
        },
        swearWord: {
          bleepFile:
            'Por favor, selecciona un efecto de pitido (Ver campo <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>)',
        },
        textBox: {
          xPadding:
            'El padding horizontal debe ser un número positivo (Ver campo <a><b><embedTranslation>captions_customization_boxes_padding_x</embedTranslation></b></a>)',
          yPadding:
            'El padding vertical debe ser un número positivo (Ver campo <a><b><embedTranslation>captions_customization_boxes_padding_y</embedTranslation></b></a>)',
          opacity: {
            min: 'La opacidad debe ser mayor a 0% (Ver campo <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>)',
            max: 'La opacidad debe ser menor a 100% (Ver campo <a><b><embedTranslation>captions_customization_boxes_opacity</embedTranslation></b></a>)',
          },
        },
        anchor:
          'El punto de anclaje debe estar dentro de la vista previa (Ver campo <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>)',
        zoom: {
          autoZoomMinCoef: {
            min: 'El coeficiente de zoom mínimo debe ser mayor a 1 (Ver campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
            max: 'El coeficiente de zoom mínimo debe ser menor a 2 (Ver campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          },
          autoZoomMaxCoef: {
            min: 'El coeficiente de zoom máximo debe ser mayor a 1 (Ver campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
            max: 'El coeficiente de zoom máximo debe ser menor a 2 (Ver campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          },
          minZoomTime: 'La duración mínima de zoom debe ser mayor a 0 segundos',
          maxZoomTime: 'La duración máxima de zoom debe ser mayor a 0 segundos',
          dynamicZoomPercentage: {
            min: 'El porcentaje de zoom debe ser mayor a 0%',
            max: 'El porcentaje de zoom debe ser menor a 100%',
          },
          coef: 'El coeficiente de zoom máximo no puede ser menor que el coeficiente de zoom mínimo (Ver campo <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>)',
          time: 'La duración máxima de zoom no puede ser menor que la duración mínima de zoom',
          dynamicMinZoomIntensity:
            'La intensidad de zoom dinámico debe estar entre 0% y 100% (Ver campo <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>)',
          smoothZoomPercentage:
            'El porcentaje de zoom suave debe estar entre 0% y 100% (Ver campo <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>)',
          nervousness:
            'La nerviosidad debe estar entre 0% y 100% (Ver campo <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>)',
          totalZoomPercentage:
            'La cobertura total de zoom debe ser mayor a 0% y menor o igual a 100% (Ver campo <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>)',
          types: {
            at_least: {
              one: {
                true: 'Se debe seleccionar al menos un tipo de zoom (Ver campo <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>)',
              },
            },
          },
        },
        repeat: {
          algorithm: 'El algoritmo seleccionado no es válido',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Falta de pago',
          button: {
            recover: 'Recuperar suscripción',
          },
          to_continue:
            'Tu suscripción actual ha sido <b>{status}</b> debido a un problema de pago. Para continuar usando AutoCut, por favor:',
          deactivated: 'desactivada',
          deleted: 'eliminada',
          recover: {
            list: {
              click:
                '<b>Haz clic en el botón de abajo</b> para recuperar tu suscripción o revisa la factura de correo electrónico para ver un mensaje nuestro o de Stripe pidiéndote pagar tu última factura',
              re_activated:
                'Tu clave será <b>re-activada inmediatamente</b> tras la actualización del pago',
            },
          },
          new: {
            list: {
              website:
                'Visita la página web de AutoCut: <span>https://www.autocut.fr/#pricing</span>',
              subscribe: 'Suscríbete a una nueva clave de licencia',
            },
          },
          support:
            'Contacta a nuestro equipo de soporte si necesitas ayuda: <span>contact@autocut.fr</span>',
          thanks: '¡Gracias!',
        },
        moda: {
          button: {
            new: 'Obtener una nueva suscripción',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Inicia tu Aventura con AutoCut',
          header: {
            days: {
              left: 'Tu viaje creativo comienza con una prueba gratuita de 14 días de AutoCut, has desbloqueado {completedElements} días más y te quedan <b>{daysLeft} días</b> para explorar la extensión!',
            },
            more: {
              days: 'Pero, ¿por qué detenerte ahí? Emprende una misión para desbloquear <b>{bonusDays} días adicionales</b> y desata por completo el poder de AutoCut.',
            },
          },
          timeline: {
            left: 'Prueba gratuita de 14 días',
            right: 'Prueba gratuita de 24 días',
          },
          task: {
            list: {
              title: 'Tareas completadas: {completed}/{maximum}',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'AutoCut necesita ser recargado',
          cta: 'Recargar AutoCut',
          text: 'Han pasado más de 30 minutos desde la última vez que se abrió AutoCut. {br} Para un rendimiento óptimo, recomendamos recargar AutoCut ahora.{br}Simplemente haz clic en el botón de abajo para recargar AutoCut',
        },
      },
    },
    'key-already-activated':
      'Esta clave ya ha sido activada en otro ordenador. Por favor, desasóciela en su panel de AutoCut (https://www.autocut.fr/es/) antes de intentarlo de nuevo.',
    'key-does-not-exist':
      'Esta clave no existe. Por favor, verifique su clave e inténtelo de nuevo.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Versión de DaVinci Resolve no compatible',
            '24.4.0':
              'Por favor, retrocede DaVinci Resolve a 24.3 o actualiza a 24.5',
          },
          cta: 'Entiendo',
          text: {
            __text__:
              'Actualmente estás utilizando una versión de DaVinci Resolve no compatible ({version}). <b>No</b> podrás utilizar AutoCut al máximo con esta versión.{br}{br}Por favor, utiliza la <b>última versión estable</b> para tener la mejor experiencia con AutoCut.',
            '24.4.0':
              'Actualmente estás utilizando una versión no soportada de DaVinci Resolve ({version}). Ha habido algunos cambios importantes en esta versión. Por favor, retrocede a la versión 24.3 o actualiza a la versión 24.5 para usar AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Motor de renderizado no soportado',
          modal: {
            text: 'Actualmente estás utilizando un motor de renderizado no compatible. No <b>podrás</b> usar AutoCaptions ni AutoZoom.{br}{br}Por favor, selecciona un motor de renderizado acelerado por GPU en la configuración de tu proyecto.',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Se necesita reinicio completo',
          cta: 'Cerrar todas mis extensiones',
          text: 'Detectamos varias instancias de AutoCut en ejecución. Haz clic en el botón de abajo para cerrar todas ellas y evitar cualquier problema.',
        },
      },
    },
    disabled: {
      features: {
        info: 'Ha seleccionado una combinación de idioma/fuente que no admite las siguientes funciones: <b>{features}</b> Estas funciones se desactivarán en los subtítulos importados.',
        tips: 'Puede estar relacionado con los caracteres admitidos por la fuente que ha elegido. Cambie la fuente o el idioma para habilitar estas funciones.',
      },
    },
    upgrade: {
      to: 'Actualizar a {plan}',
    },
    deepgram: {
      error: {
        modal: {
          title: 'Se ha producido un error de traducción',
          cta: 'Unirse a Discord',
          text: 'Se ha producido un error al traducir el audio. Puede estar relacionado con la calidad del audio o el idioma hablado, pero no tenemos más información por ahora. {br} Por favor, informe su problema en Discord o envíenos un correo electrónico a contact@autocut.fr.',
        },
      },
    },
    completing: {
      preset: {
        preview:
          'Completando la vista previa del preset... Puede tardar unos segundos.',
      },
    },
    chapters: {
      preview: {
        error:
          'Se ha producido un error al generar la vista previa. Intente actualizar las marcas de tiempo o reiniciar AutoCut si el problema persiste.',
      },
      step: {
        language: 'Seleccionar clips e idioma',
        editor: 'Editor de capítulos',
      },
      button: {
        chapters: 'Agregar capítulos',
      },
      editor: {
        chapters: {
          title: 'Capítulos detectados',
        },
        parameters: {
          title: 'Parámetros de capítulos',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Descargar archivo de capítulos de YouTube',
                success: 'chapter.txt se ha guardado en su proyecto PPRO',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Seleccione clips en su línea de tiempo en los que desea basar los capítulos',
            text1:
              'Para usar capítulos, debe seleccionar al menos un clip con audio en su línea de tiempo.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Generar capítulos',
        },
      },
      adding: {
        chapters: 'Agregando capítulos...',
      },
      backup: 'Realizando copia de seguridad de su secuencia...',
      analyse: 'Analizando su audio...',
      getting: {
        chapters: 'Generando capítulos...',
      },
      transcript: {
        finished: 'Se han generado los capítulos',
      },
      disabled: {
        language:
          'El idioma seleccionado aún no es compatible con AutoChapters',
        file: {
          too: {
            long: 'Los clips seleccionados son demasiado largos. Por favor, selecciona clips que tengan menos de {limit} minutos de duración.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format:
              'Formato de hora incorrecto. Por favor, use el formato HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animado',
        },
        static: {
          option: 'Estático',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Seleccione clips en su línea de tiempo en los que desea basar el Detector de Clips Virales Automático',
              text1:
                'Para usar el Detector de Clips Virales Automático, necesita seleccionar al menos un clip con audio en su línea de tiempo.',
            },
          },
        },
        disabled: {
          language:
            'El Detector de Clips Virales Automático no está disponible para este idioma aún',
          file: {
            too: {
              long: 'Sus clips seleccionados son demasiado largos. Por favor, seleccione clips que tengan menos de {limit} minutos de duración.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Generar clips virales',
          },
        },
        button: {
          process: 'Crear clips',
        },
        selector: {
          title: 'Detectados {number} clips potencialmente virales',
        },
        virality: {
          score: 'Puntuación de potencial viral',
        },
        step: {
          language: 'Seleccionar clips e idioma',
          selector: 'Seleccionar clips virales',
        },
        backup: 'Realizando copia de seguridad de su secuencia...',
        analyse: 'Analizando su audio...',
        getting: {
          clips: 'Generando clips virales...',
        },
        transcript: {
          finished: 'Se han generado clips virales',
        },
        adding: {
          creating: {
            clips: 'Creando clips...',
          },
        },
      },
      score: 'Puntuación viral',
    },
    mode: {
      description: {
        silence: 'Elimina los silencios en tu línea de tiempo',
        ai: 'Elimina los silencios en tu línea de tiempo',
        podcast: 'Edita podcast al instante',
        repeat: 'Elimina tomas incorrectas con IA',
        zoom: 'Agrega automáticamente zooms',
        captions: 'Añade subtítulos animados con IA',
        resize: 'Redimensiona tu secuencia con IA',
        broll: 'Agrega automáticamente B-Roll con IA',
        profanity: 'Censura palabras groseras',
        chapter: 'Detecta capítulos y agrega marcadores con IA',
        viral: 'Identificar cortos virales en videos largos con un clic',
      },
    },
    formatted: {
      numbers: {
        title: 'Números formateados',
        modal: {
          cta: 'Cerrar modal',
          text: '<b>{number} palabras</b> han sido reemplazadas por números en su transcripción.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Seleccionar un modelo de transcripción',
          model: {
            '1': 'Modelo 1 (pred determinado)',
            '2': 'Modelo 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
