import {TranslatedMessageProps} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import css from './SelectionScreen.module.scss';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {ReactNode} from 'react';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {IconMouse} from '@autocut/designSystem/components/atoms/Icon/media/IconMouse';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormattedMessage} from 'react-intl';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';

export type SelectionScreenProps = {
  title: TranslatedMessageProps;
  texts: TranslatedMessageProps[];
  help?: {
    text: TranslatedMessageProps;
    link: {
      href: string;
      text: TranslatedMessageProps;
    };
  };
  image?: {
    src: string;
    alt: string;
  };
  cta?: ReactNode;
};

export const SelectionScreen = ({
  title,
  texts,
  help,
  image,
  cta,
}: SelectionScreenProps) => {
  return (
    <FlexContainer
      className={css.container}
      flexDirection="column"
      justifyContent="space-between"
      gap={Spacing.s6}
    >
      <FlexContainer
        flexGrow={true}
        flexDirection="column"
        alignItems="center"
        gap={Spacing.s5}
      >
        <FlexContainer
          alignItems="flex-start"
          gap={Spacing.s3}
        >
          <IconMouse
            color="white"
            size={24}
            style={{
              marginTop: 2,
            }}
          />
          <Text
            variant="textLg.semibold"
            color="white"
          >
            <FormattedMessage {...title} />
          </Text>
        </FlexContainer>
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={Spacing.s2}
        >
          {texts.map((text, index) => (
            <Text
              className={css.text}
              variant="textSm"
              key={index}
            >
              <FormattedMessage {...text} />
            </Text>
          ))}
        </FlexContainer>
        {image && (
          <img
            className={css.image}
            src={image.src}
            alt={image.alt}
          />
        )}
        {help && (
          <FlexContainer
            alignItems="center"
            justifyContent="center"
            gap={Spacing.s1}
          >
            <Text
              variant="textXs"
              color="white"
            >
              <FormattedMessage {...help.text} />
            </Text>
            <Button
              variant="tertiary"
              color={colors.primary600}
              onClick={() => window.open(help.link.href)}
            >
              <Text
                variant="textXs"
                color={colors.primary600}
              >
                <FormattedMessage {...help.text} />
              </Text>
            </Button>
          </FlexContainer>
        )}
      </FlexContainer>
      <div className={css.ctaContainer}>{cta}</div>
    </FlexContainer>
  );
};
