import {preload} from '@autocut/types/ElectronPreload';
import {Font} from '@autocut/types/font';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';

import {create} from 'fontkit';

const REQUIRED_ARABIC_UNICODES = 100;
const REQUIRED_HEBREW_UNICODES = 40;
const ARABIC_UNICODE_RANGE = [1536, 1791];
const HEBREW_UNICODE_RANGE = [1424, 1535];

export const getFontMetadata = async (
  fontPath: string,
): Promise<Font | undefined> => {
  try {
    const buffer = preload().fs.readFileSync(fontPath);

    const getFontFaceUrl = async () => {
      const blob = new Blob([buffer]);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      const result = await new Promise(
        resolve =>
          (reader.onloadend = () => {
            resolve(reader.result);
          }),
      );

      return result as string;
    };

    const font = create(buffer) as any;

    const postscriptName = font.getName('postscriptName');
    const lineGap = font.lineGap;
    const ascent = font.ascent;
    const descent = font.descent;
    const unitsPerEm = font.unitsPerEm;
    const bbox = font.bbox;
    const fontFamily = font.getName('fontFamily');
    const label = fontFamily + ' - ' + font.getName('fontSubfamily');
    const fontStyle = font.getName('fontSubfamily');
    const fileUrl = fontPath;

    const characterSet = Object.values(
      font.characterSet as Record<string, number>,
    );

    const arabicCharacterSet = characterSet.filter(
      (unicode: number) =>
        unicode >= ARABIC_UNICODE_RANGE[0] &&
        unicode <= ARABIC_UNICODE_RANGE[1],
    );
    const supportsArabic = arabicCharacterSet.length > REQUIRED_ARABIC_UNICODES;

    const herbrewCharacterSet = characterSet.filter(
      (unicode: number) =>
        unicode >= HEBREW_UNICODE_RANGE[0] &&
        unicode <= HEBREW_UNICODE_RANGE[1],
    );
    const supportsHebrew =
      herbrewCharacterSet.length > REQUIRED_HEBREW_UNICODES;

    if (label.includes('.'))
      throw new Error(
        'Font name contains a dot, which seems not to be working',
      );

    return {
      fontFamily,
      postscriptName,
      fontStyle,
      label,
      fileUrl,
      lineGap,
      ascent,
      descent,
      unitsPerEm,
      bbox,
      getFontFaceUrl,
      supportsArabic,
      supportsHebrew,
    };
  } catch {
    return undefined;
  }
};

export const loadFontForCanvas = async (font: Font) => {
  try {
    const fontFaceUrl = await font.getFontFaceUrl?.();
    const loadedFont = await new FontFace(
      font.fontFamily ?? '',
      `url('${fontFaceUrl}')`,
    ).load();
    document.fonts.add(loadedFont);
    return loadedFont;
  } catch (e) {
    console.log(e);
  }
};

export const defaultFont: Font = {
  fontFamily: 'Arial',
  postscriptName: 'ArialMT',
  fontStyle: 'Regular',
  lineGap: 67,
  ascent: 1854,
  descent: -434,
  unitsPerEm: 2048,
  bbox: {
    minX: 0,
    minY: -434,
    maxX: 2048,
    maxY: 1854,
  },
  label: 'Arial - Regular',
};
const fileRegexp = new RegExp(/\.otf|\.woff|\.woff2|\.ttf|\.ttc$/);

export const getFolderFonts = async (folderPath: string) => {
  const foundFonts: Font[] = [];
  try {
    await new Promise(resolve =>
      preload().fs.readdir(folderPath, async (err: any, files: string[]) => {
        if (!err)
          try {
            for (const file of files) {
              if (file.toLowerCase().match(fileRegexp)) {
                const filePath = [folderPath, file].join('/');
                const metadata = await getFontMetadata(filePath);
                if (metadata) foundFonts.push(metadata);
              } else {
                const newFonts = await getFolderFonts(
                  preload().path.join(folderPath, file),
                );
                foundFonts.push(...newFonts);
              }
            }
          } catch (e) {
            console.log(e);
          }

        resolve(true);
      }),
    );
  } catch (e) {}

  return foundFonts;
};

export const loadUserLocalFonts = async () => {
  const foundFonts: Font[] = [];

  const systemFontFolderPath =
    preload().os.type() === 'Darwin'
      ? [
          '/System/Library/Fonts',
          preload().path.join(preload().os.homedir(), '/Library/Fonts'),
        ]
      : [
          'C:/Windows/Fonts',
          preload().path.join(
            preload().os.homedir(),
            '/AppData/Local/Microsoft/Windows/Fonts',
          ),
        ];

  for (const systemFontsFile of systemFontFolderPath) {
    const newFonts = await getFolderFonts(systemFontsFile);
    foundFonts.push(...newFonts);
  }
  setAutocutStore('fonts', foundFonts);

  return foundFonts;
};

export const loadSelectedFont = async () => {
  const preloadedFonts =
    autocutStoreVanilla().fonts ?? (await loadUserLocalFonts());
  const selectedFont =
    autocutStoreVanilla().ui.parameters.caption.text.font ?? defaultFont;

  const correspondingFont =
    preloadedFonts.find(
      font => font.postscriptName === selectedFont.postscriptName,
    ) ?? defaultFont;

  setAutocutStore('ui.parameters.caption.text.font', correspondingFont);

  return correspondingFont;
};
