import logLevel from '@autocut/enums/logLevel.enum';
import {reportSentryError} from '@autocut/utils/sentry.utils';
import {AutoCutApiError} from './AutoCutApiError';

export class IncrementalError extends Error {
  error: Error | AutoCutApiError | undefined;
  breadcrumb = '';
  fingerprint = '';
  status: number | undefined;
  type: string;

  constructor(
    baseError: Error | AutoCutApiError | string,
    fingerprint: string,
  ) {
    const error =
      typeof baseError === 'string' ? new Error(baseError) : baseError;

    super(error.message);
    this.cause = error.cause;
    this.name = error.name;
    this.type = error.name;
    this.stack = error.stack;
    this.message = error.message;

    if (error instanceof IncrementalError) {
      this.status = error.status;
      this.error = error.error;
      this.fingerprint = error.fingerprint;
      this.breadcrumb = `${fingerprint} - ${error.breadcrumb}`;
    } else {
      this.error = error;
      this.message = error.message;
      this.breadcrumb = fingerprint;
      this.fingerprint = fingerprint;

      if (error instanceof AutoCutApiError) {
        this.status = error.status;
        this.cause = error.description;
      } else {
        this.status = (baseError as any)?.response?.status;
      }

      console.trace(
        'NEW IncrementalError : ',
        this.breadcrumb,
        this.message,
        this.stack,
      );
    }
  }

  reportToSentry() {
    const tags = this.breadcrumb
      .split(' - ')
      .reduce((acc, fingerprint) => ({...acc, [fingerprint]: true}), {
        breadcrumb: this.breadcrumb,
      });

    reportSentryError(
      this.fingerprint,
      this,
      this.toJSON(),
      logLevel.error,
      scope => {
        scope.setTags(tags);
        scope.setTransactionName(this.name);
      },
    );
  }

  toString() {
    return `IncrementalError : [${this.breadcrumb}] ${this.message}\n${this.stack}`;
  }

  toJSON() {
    return {
      error: this.error,
      breadcrumb: this.breadcrumb,
      fingerprint: this.fingerprint,
      cause: this.cause,
      name: this.name,
      stack: this.stack,
      message: this.message,
    };
  }
}
