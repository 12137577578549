import {Navigate, Route, Routes} from 'react-router-dom';
import {CuttingDashboard} from './pages/CuttingDashboard/CuttingDashboard';
import {Homepage} from './pages/Homepage/Homepage';
import {NoScript} from './pages/NoScript';
import {LegacySilencesMode} from './pages/modes/legacy/LegacySilencesMode';
import {GameHub} from './components/GameHub';
import {Onboarding} from './pages/Onboarding/Onboarding';
import {Login} from './pages/Onboarding/Login/Login';

import {CURRENT_ENV, Env} from './utils/currentEnv.utils';
import {DevelopmentDashboard} from './pages/DevelopmentDashboard/DevelopmentDashboard';
import {DesignSystemExample} from './pages/DesignSystemExample/DesignSystemExample';

import '@autocut/designSystem/styles/index';
import {PodcastMode} from './pages/modes/podcast/podcastMode';
import {ZoomMode} from './pages/modes/zoom/ZoomMode';
import {CaptionsMode} from './pages/modes/captions/CaptionsMode';
import {Trial} from './pages/Onboarding/Trial/Trial';
import {UnsupportedVersionModal} from './modals/UnsupportedVersionModal/UnsupportedVersionModal';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useEffect} from 'react';
import {initAutocut} from '@autocut/utils/init';
import logLevel from '@autocut/enums/logLevel.enum';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {manageError} from '@autocut/utils/manageError';

/*
  This component is the root component in which we can be sure to have access to preload, davinci/ppro and compute
*/

export default function SafeApp() {
  const {isLogged} = useAutoCutStore(state => ({
    isLogged: state.user.isLogged,
  }));

  useEffect(() => {
    const init = async () => {
      await initAutocut().then(() => {
        console.log(logLevel.info, 'Autocut initialized.');
      });

      window.onunhandledrejection = (event: PromiseRejectionEvent) => {
        const error = event.reason;
        if (!error) return;

        const incrementalError = new IncrementalError(error, '');

        manageError({
          error: incrementalError,
          disableModal: false,
        });
      };

      window.onerror = async function (messageOrEvent, _, __, ___, error) {
        if (!error) return;
        manageError({
          error,
          additionalData: messageOrEvent,
          disableModal: false,
        });
      };
    };

    void init();
  }, []);

  return (
    <>
      <UnsupportedVersionModal />
      <Routes>
        <Route
          path="/index.html"
          element={<Navigate to="/" />}
        />

        <Route path="/onboarding">
          <Route
            path=""
            element={<Onboarding />}
          />
          <Route
            path="login"
            element={<Login />}
          />
          <Route
            path="trial/*"
            element={<Trial />}
          />
        </Route>

        {isLogged ? (
          <>
            <Route
              path="/homepage"
              element={<Homepage />}
            />
            {CURRENT_ENV === Env.Development && (
              <>
                <Route
                  path="/dev"
                  element={<DevelopmentDashboard />}
                />
                <Route
                  path="/ds"
                  element={<DesignSystemExample />}
                />
              </>
            )}
            <Route
              path="/cut"
              element={<CuttingDashboard />}
            >
              <Route
                path=""
                element={<Navigate to="legacy" />}
              />
              <Route
                path="legacy/*"
                element={<LegacySilencesMode />}
              />
              <Route
                path="podcast/*"
                element={<PodcastMode />}
              />

              <Route
                path="zoom/*"
                element={<ZoomMode />}
              />

              <Route
                path="caption/*"
                element={<CaptionsMode />}
              />
              {/* <Route path="ai" element={<AiSilencesMode />} />
                  <Route path="repeat/*" element={<RepeatMode />} />
                  
                  <Route path="resize" element={<ResizeMode />} />
                  <Route path="broll/*" element={<BRollMode />} />
                  <Route path="swear_word" element={<SwearWordsMode />} />
                  <Route path="caption/*" element={<CaptionsMode />} />
                  <Route path="chapters/*" element={<ChaptersMode />} />
                  <Route path="viral_clips/*" element={<ViralClipsMode />} /> */}
            </Route>
          </>
        ) : false ? ( // A priori on s'en fou de ça ?
          <Route
            path="/cut/*"
            element={<NoScript />}
          />
        ) : null}

        <Route
          path="/"
          element={<Navigate to="/onboarding" />}
        />
        <Route
          path="/*"
          element={<Navigate to="/onboarding" />}
        />
      </Routes>
      <GameHub />
    </>
  );
}
