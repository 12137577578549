import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {preload} from '@autocut/types/ElectronPreload';
import {useEffect, useState} from 'react';
import {daVinci} from '@autocut/utils/davinci';

export const UnsupportedVersionModal = () => {
  const [davinciVersion, setDavinciVersion] = useState<string | null>(null);

  useEffect(() => {
    // Open the unsupported version modal if DaVinci is not supported (<18.6)
    void daVinci.misc.davinciVersion().then(version => {
      if (version[1] >= 19 || (version[1] >= 18 && version[2] >= 6)) {
        return;
      }

      const formattedVersion = `${version[1]}.${version[2]}`;
      setDavinciVersion(formattedVersion);
    });
  }, []);

  if (!davinciVersion) return null;

  return (
    <Modal
      style={{
        zIndex: 1000,
      }}
      title={
        <p
          style={{
            color: 'white',
            fontSize: '20px',
            lineHeight: '40px',
            fontWeight: 'bold',
          }}
        >
          <TranslatedMessage
            id={'modals_unsupportedVersion_title'}
            defaultMessage="Unsupported DaVinci Resolve version"
          />
        </p>
      }
      closeOnClickOutside={false}
      withCloseButton={false}
    >
      <p
        style={{
          padding: '16px',
          fontSize: '13px',
          lineHeight: '40px',
          fontWeight: 'bold',
        }}
      >
        {!!davinciVersion && (
          <TranslatedMessage
            id={'modals_unsupportedVersion_currentVersion'}
            defaultMessage="We detected that you are using DaVinci Resolve {version}"
            values={{
              version: davinciVersion,
            }}
          />
        )}
        <TranslatedMessage
          id={'modals_unsupportedVersion_text'}
          defaultMessage="AutoCut is only compatible with DaVinci Resolve with 18.6 and higher."
        />
        <TranslatedMessage
          id={'modals_unsupportedVersion_update'}
          defaultMessage="You can update it there: <a>DaVinci Website</a>"
          values={{
            a: text => (
              <a
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  void preload().electron.openLinkInBrowser(
                    'https://www.blackmagicdesign.com/support/family/davinci-resolve-and-fusion',
                  );
                }}
              >
                {text}
              </a>
            ),
          }}
        />
      </p>
    </Modal>
  );
};
