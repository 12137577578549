import React, {useEffect, useState} from 'react';

import {setAutocutStore} from '@autocut/utils/zustand';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {IoWarningOutline} from 'react-icons/io5';
import {useLocale} from '@autocut/hooks/useLocale';
import {Font} from '@autocut/types/font';
import {FaSyncAlt} from 'react-icons/fa';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Button} from '@autocut/components/Button/Button';
import {CustomSelectText} from '@autocut/components/CustomSelectText/CustomSelectText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {defaultFont, loadUserLocalFonts} from '@autocut/utils/fonts.utils';

export const FontSelect = () => {
  const [loading, setLoading] = useState(false);
  const [fonts, setFonts] = useState<Font[]>([]);
  const {paramFont, fonts: preloadedFonts} = useAutoCutStore(state => ({
    paramFont: state.ui.parameters.caption.text.font,
    fonts: state.fonts,
  })) ?? {paramFont: defaultFont};
  const [selectedFont, setSelectedFont] = useState<Font>(
    paramFont ?? defaultFont,
  );
  const [fontNotFoundFontLabel, setFontNotFoundFontLabel] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!!preloadedFonts.length) {
      return setFonts(preloadedFonts);
    }
    const process = async () => {
      setLoading(true);
      setTimeout(async () => {
        setFonts(await loadUserLocalFonts());
        setLoading(false);
      }, 50);
    };

    void process();
  }, [preloadedFonts]);

  useEffect(() => {
    if (!fonts.length) return;

    const correspondingFont = fonts.find(
      fontItem => fontItem.postscriptName === paramFont?.postscriptName,
    );

    if (!correspondingFont) {
      setFontNotFoundFontLabel(paramFont?.label);
    }

    setSelectedFont(correspondingFont ?? defaultFont);

    setAutocutStore(
      'ui.parameters.caption.text.font',
      correspondingFont ?? defaultFont,
    );
  }, [fonts, paramFont]);

  return (
    <FlexContainer
      gap={4}
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
    >
      <NotFoundFontModal
        fontLabel={fontNotFoundFontLabel}
        onClose={() => setFontNotFoundFontLabel(undefined)}
      />
      <CustomSelectText
        customization="bleepSelect"
        isSearchable
        options={fonts.map(font => ({
          value: font.postscriptName,
          label: font.label,
        }))}
        handleSelectChange={font => {
          const correspondingFont = fonts.find(
            fontItem => fontItem.postscriptName === font?.value,
          );
          setSelectedFont(correspondingFont ?? defaultFont);
          setAutocutStore(
            'ui.parameters.caption.text.font',
            correspondingFont ?? defaultFont,
          );
        }}
        initialValue={{
          value: selectedFont.postscriptName,
          label: selectedFont.label,
        }}
        isLoading={loading}
        portalTarget={document.body}
      />
      <Button
        onClickFunction={loadUserLocalFonts}
        compact={true}
        color="transparent"
      >
        <FlexContainer
          style={{
            width: '16px',
            height: '16px',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <FaSyncAlt
            size={16}
            color="#F2F4F7"
          />
        </FlexContainer>
      </Button>
    </FlexContainer>
  );
};

const NotFoundFontModal = ({
  fontLabel,
  onClose,
}: {
  fontLabel?: string;
  onClose: () => void;
}) => {
  const {locale} = useLocale();

  if (!fontLabel) return null;

  return (
    <Modal
      closeModalFunction={onClose}
      title={
        <TranslatedMessage
          id={'captions_fontSelect_notFoundFontModal_title' as any}
          defaultMessage="Font not found"
        />
      }
      icon={<IoWarningOutline color="#E25D2A" />}
    >
      <>
        <TranslatedMessage
          id={'captions_fontSelect_notFoundFontModal_message' as any}
          defaultMessage="The font <b>{fontLabel}</b> was not found on your system, the default font will be used."
          values={{
            fontLabel,
          }}
        />
        <br />
        <a
          style={{fontSize: '10px', color: 'inherit'}}
          href={`https://www.autocut.fr/${locale.toLocaleLowerCase()}/academy/autocut-parameters/captions-fonts/`}
        >
          <TranslatedMessage
            id={'captions_fontSelect_notFoundFontModal_helper' as any}
            defaultMessage="You can add a new font family by following this tutorial."
          />
        </a>
      </>
    </Modal>
  );
};
