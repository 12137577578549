import {preload} from '@autocut/types/ElectronPreload';
import {TrackItem, TrackType} from '@autocut/types/JSONTimeline';
import {IncrementalError} from './errors/IncrementalError';

export type DaVinciRequestId = ReturnType<
  ReturnType<typeof preload>['daVinciNodeIpc']['request']
>['requestId'];

export type DaVinciHandler<OutputType> = Promise<OutputType> & {
  requestId: DaVinciRequestId;
};

type ExportArgs =
  | {mark_in: number; mark_out: number}[]
  | {export_between_markers?: boolean; mark_in?: number; mark_out?: number};

/* This function is there to check if the current environment is DaVinci or Premiere */
export const isDavinci = () => {
  return true;
};
export const isMac = () => {
  return navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
};

const daVinciHandler = <OutputType>(
  path: string,
  data?: {},
): DaVinciHandler<OutputType> => {
  const runRequest = () => {
    console.log(
      ...[
        `[Lua] Requesting "${path}"${data ? 'with data:' : ''}`,
        ...(data ? [data] : []),
      ],
    );
    return preload().daVinciNodeIpc.request(path, data);
  };

  const request = runRequest();
  const res = request.promise.catch(err => {
    throw new IncrementalError(err.content, err.from);
  }) as DaVinciHandler<OutputType>;
  res.requestId = request.requestId;

  return res;
};

export const daVinci = {
  misc: {
    isConnected: () => daVinciHandler<{isConnected: boolean}>('isConnected'),
    close: () => daVinciHandler<{isConnected: boolean}>('close'),
    version: () =>
      daVinciHandler<{luaVersion: string; tsVersion: string}>('/misc/version'),
    hostVersion: () => daVinciHandler<any[]>('/misc/host-version'),
    davinciVersion: () =>
      daVinciHandler<{
        1: number;
        2: number;
        3: number;
        4: number;
        5: string;
      }>('/misc/davinci-version'),
  },
  timeline: {
    getName: () => daVinciHandler<string>('/timeline/get-name'),
    getFrameRate: () => daVinciHandler<number>('/timeline/framerate'),
    getStartFrame: () => daVinciHandler<number>('/timeline/start-frame'),
    exportFrame: () => daVinciHandler<string>('/timeline/export-frame'),
    getCurrentTimecode: () =>
      daVinciHandler<number>('/timeline/get-current-timecode'),
    setCurrentTimecode: (timecode: number) =>
      daVinciHandler<number>('/timeline/set-current-timecode', timecode),
    getTrackCount: (trackType: TrackType) =>
      daVinciHandler<number>('/timeline/track-count', {
        track_type: trackType,
      }),
    getInOut: () =>
      daVinciHandler<{
        inPoint: number;
        outPoint: number;
      }>('/timeline/get-in-out'),
    getTimelineFormat: () =>
      daVinciHandler<{
        width: `${number}`;
        height: `${number}`;
      }>('/timeline/format'),
    getTrackItemsCount: () =>
      daVinciHandler<{audio: number; video: number}>(
        '/timeline/get-track-items-count',
      ),
    drt: {
      export: () => daVinciHandler<string>('/timeline/export'),
      import: (drtPath: string) =>
        daVinciHandler<void>('/timeline/import', drtPath),
    },
    wav: {
      export: <T extends string | string[]>(data: ExportArgs): Promise<T> =>
        daVinciHandler<T>('/export-audio/timeline', data),
    },
  },
  track: {
    enable: (trackType: TrackType, trackIndex: number) =>
      daVinciHandler<boolean>('/track/enable', {
        track_type: trackType,
        track_index: trackIndex,
      }),
    disable: (trackType: TrackType, trackIndex: number) =>
      daVinciHandler<boolean>('/track/disable', {
        track_type: trackType,
        track_index: trackIndex,
      }),
    isEnabled: (trackType: TrackType, trackIndex: number) =>
      daVinciHandler<boolean>('/track/is-enabled', {
        track_type: trackType,
        track_index: trackIndex,
      }),
    getTrackItems: (trackType: TrackType, trackIndex: number) =>
      daVinciHandler<TrackItem[]>('/track/get-track-items', {
        track_type: trackType,
        track_index: trackIndex,
      }),
  },
};
