import React, {useMemo} from 'react';
import css from './VersionDisplay.module.scss';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {AutoCutState} from '@autocut/utils/zustand';

type VersionDisplayProps = React.HTMLAttributes<HTMLParagraphElement>;

const compressVersion = (version: string) =>
  (version || '').replace('v', '').split('.').join('');
export const formatVersion = (versions: AutoCutState['ui']['versions']) =>
  `${compressVersion(versions.aea)}.${compressVersion(versions.front)}.${compressVersion(versions.compute)}.${compressVersion(versions.lua)}.${compressVersion(versions.luaServer)}`;

export const VersionDisplay: React.FC<VersionDisplayProps> = props => {
  const {versions} = useAutoCutStore(state => ({
    versions: state.ui.versions,
  }));

  const version = useMemo(() => formatVersion(versions), [versions]);

  return (
    <p
      className={css.applicationVersion}
      {...props}
    >
      <i>v{version}</i>
    </p>
  );
};
